<template>
		<div class="person">
			<cms-image
				class="person-image"
				lazy
				:src="photo"
				:base="{width: 330, height: 220}"
				:lg="{width: 700, height: 500}"
			/>
			<div class="person-wrapper">
				<div class="person-name">
					<div>
						<cms-text
							:value="name"
							:props="{tag: 'h4', variant: 'paragraph', color: 'secondary', uppercase: true, weight: 'semibold'}"
						/>
						<cms-text
							:value="job"
							:props="{tag: 'span', color: 'secondary', uppercase: true}"
						/>
					</div>
					<div class="person-links">
						<base-link v-for="link in links" :url="link.link.value" type="primary" :key="link.link_title.value" >
							<base-svg :name="link.link_icon.path" />
						</base-link>
					</div>
				</div>

				<cms-text
					:value="description"
					:props="{color: 'secondary'}"
					class="paragraph"
				/>
			</div>

		</div>

</template>

<script>
import baseSvg from '@f/components/BaseSvg/component/BaseSvg'
import baseLink from '~/website/front/components/atoms/BaseLink/BaseLink'
export default {
	components: { baseSvg, baseLink },
	props: {
		prefix: String,
		value: Object,
		name: String,
		job: String,
		description: String,
		photo: String,
		links: Array
	}
}
</script>
<style lang="scss" scoped>
.person {
	display: flex;
	flex-direction: column;
	width: 100%;

	background: white;
	border-radius: 1rem;
	box-shadow: 0 .3rem .6rem rgba(0,0,0,.16);
	margin-bottom: 2rem;
	@include media-breakpoint-up(md) {
		margin-left:1.5rem;
		margin-right: 1.5rem;
	}
	&:first-of-type{
		margin-left:0;
	}
	&:last-of-type {
		margin-right: 0;
	}
	@include media-breakpoint-up(xl) {
		margin-bottom: 0rem;
	}

	&-wrapper{
		padding:2rem;
		@include media-breakpoint-up(lg) {
			padding:3rem;
		}
	}
	&-name {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		padding-bottom: 3rem;
		h4 {
			font-size: 16px;
			line-height: 22px;
			@include media-breakpoint-up(lg) {
				font-size: 26px;
				line-height: 33px;
				font-weight: 600;
			}
		}
		span {
			font-size: 12px;
			font-weight: 500;
			letter-spacing: 0.02rem;
			line-height: 15px;
			opacity: 0.3;
			@include media-breakpoint-up(lg) {
				font-size: 16px;
				line-height: 21px;
			}
		}
	}
	&-links{
		display: flex;
		align-content: center;
		justify-content: center;
		gap: 10px;
	}
	.link {
		&:hover {
			:deep(svg) {
				opacity: .5;
			}
		}
	}
	:deep(.ql-editor) {
		padding: 0;
		height: auto;
	}
	.svg-icon {
		:deep(svg) {
			@include transition();
			width: 25px;
			height: 25px;
		}
	}

}
</style>
