<template v-once>
	<base-link
		class="article"
		to="article"
		v-if="article"
		:params="{alias: article.alias}"
		:title="`Read more about: ${article.title}`"
	>
		<div class="article-img-wrapper">
			<cms-image
				v-if="article.thumbnail"
				class="article-image"
				lazy
				:src="{
					alt: `Preview of article ${article.title}`,
					path: article.thumbnail.path,
					title: `${article.title} - read more`
				}"
				:base="{width: 450, height: 328}"
			/>
			<div class="article__overlay">
				<base-button class="article-btn btn secondary" type="white">
					{{langs.blog_button_text}}
				</base-button>
			</div>

		</div>
		<div class="article-preview-data">
			<base-font class="article-title" weight="bold" variant="small-header" :tag="homepage ? 'h3' : 'h2'">
				{{article.title}}</base-font>
			<base-font variant="small">{{article.datePublished | date}}</base-font>
			<base-font
				tag="div"
				class="article-post-preview"
				:html="article.introduction"
				variant="paragraph"
				:class="{'small-article': imageSize === 'sm'}"
			/>
		</div>
	</base-link>
</template>
<script>
import BaseFont from '~/website/front/components/atoms/BaseFont/BaseFont.vue'

export default {
	components: { BaseFont },
	props: {
		article: [Object, Number],
		prefix: String,
		imageSize: {
			type: String,
			default: 'sm'
		}
	},
	computed: {
		langs () {
			return this.$app.translator.get('articles')
		},
		homepage () {
			const routeAlias = this.$app.page.route.meta.alias
			return routeAlias === 'homepage'
		}
	},
	async created () {
		await this.$app.translator.prefetch('articles')
	}
}

</script>
<style lang="scss" scoped>
	.safari {
		.article {
			max-height: 420px;

			&-image {
				::v-deep(img) {
					width: 100%;

					@include media-breakpoint-up(xl) {
						max-height: none;
					}
				}
			}
		}
	}

	.article {
		@include transition;
		position: relative;
		display: flex;
		flex-direction: column;
		background-color: $white;
		width: 100%;
		text-decoration: none;
		border: 1px solid $gray-500;
		border-radius: 10px;
		overflow: hidden;

		&:hover {
			transform: translateY(-10px);

			.article__overlay {
				opacity: 1;
			}
		}

		::v-deep(h3) {
			color: $secondary;
			font-weight: 600;
			line-height: 23px;

			@include media-breakpoint-up(lg) {
				font-size: 26px;
				line-height: 33px;
			}
		}

		&__overlay {
			@include transition;
			opacity: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, .6);

			.btn {
				border-color: $white!important;
				color: $white!important;

				&:after {
					border-color: $white!important;
				}
			}
		}

		&-img-wrapper {
			display: block;
			width: 100%;
			overflow: hidden;
			position: relative;
		}

		&-image {
			width: 100%;
			height: 100%;
			display: block;

			::v-deep(img) {
				height: 100%;
				width: 100%;
			}
		}

		&-preview-data {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			flex-grow: 1;
			color: $secondary;
			padding: 2rem;

			:deep(.article-title) {
				margin-bottom: 0;
				@include media-breakpoint-up(lg) {
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					overflow: hidden;
				}

				@include media-breakpoint-up(xl) {
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 1;
					overflow: hidden;
				}
			}

			:deep(span) {
				opacity: .3;
				font-weight: 500;
				font-size: 14px;
				line-height: 18px;
				color: $secondary;

				@include media-breakpoint-up(lg) {
					font-size: 16px;
					line-height: 21px;
				}
			}
		}

		:deep(.article-post-preview) {
			display: -webkit-box;
			-webkit-box-orient: vertical;
			overflow: hidden;
			-webkit-line-clamp: 3;
			margin-top: 2rem;

			p {
				display: inline;
				text-transform: none;
				padding: 0;
				margin: 0;
			}

		}
	}

</style>
