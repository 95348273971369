<template>
	<li
		v-if="hasItemChildren(item)"
		class="nav-list-item with-dropdown"
		:class="{
			'active-page': isCurrentRoute,
			'dropdown-category': hasCategorySubmenu
		}"
	>
		<button @click="toggleDropdown" class="nav-link nav-dropdown-button">
			<base-font :class="{'show-title': item.showTitleInMenu}" variant="menu" class="nav-title">{{item.name}}</base-font>
			<base-svg name="menu-arrow-dropdown" class="dropdown-arrow" :class="{active: activeDropdown}"/>
		</button>
		<div class="submenu-wrapper" ref="dropdown" :class="{active: activeDropdown}">
			<menu-dropdown-mobile @close="emitClose" :items="item.children" :active="activeDropdown"/>
		</div>
	</li>

	<li
		v-else
		class="nav-list-item"
		:class="{'active-page': isCurrentRoute}"
	>
		<base-link @click="emitClose" :to="item.to" :params="{param: item.param}" class="nav-link" >
			<base-font :class="{'show-title': item.showTitleInMenu}" variant="menu" class="nav-title">{{item.name}}</base-font>
		</base-link>
	</li>
</template>
<script>
import MenuDropdownMobile from '~/website/front/components/organisms/BaseMenu/components/Mobile/atoms/MenuDropdownMobile'
import currentRouteMixin from '~/website/front/components/organisms/MenuBreadcrumbs/currentRouteMixin'

export default {
	components: {
		MenuDropdownMobile
	},
	props: {
		item: Object
	},
	data: () => ({
		activeDropdown: false
	}),

	mixins: [currentRouteMixin],

	computed: {
		countWrapperHeight () {
			return this.activeDropdown ? this.$refs.dropdown.scrollHeight + 'px' : '0px'
		},
		hasCategorySubmenu () {
			return this.item.children.filter(child => child.link_type === 'category').length !== 0
		}
	},
	methods: {
		open () {
			this.activeDropdown = !this.activeDropdown
			this.$refs.dropdown.style.height = this.countWrapperHeight
			if (this.activeDropdown) {
				document.body.addEventListener('click', this.handleBodyClick)
			}
		},
		toggleDropdown () {
			if (this.activeDropdown) this.close()
			else this.open()
		},
		handleBodyClick () {
			if (this.$refs.dropdown.clientHeight) {
				this.close()
			}
		},
		close () {
			this.activeDropdown = false
			this.$refs.dropdown.style.height = this.countWrapperHeight
			document.body.removeEventListener('click', this.handleBodyClick)
		},
		hasItemChildren (item) {
			return item.children.length !== 0
		},
		emitClose () {
			this.$emit('close')
		}
	}
}
</script>
<style lang="scss" scoped>
.nav-list-item {

	&.active-page {
		position: relative;

		.nav-link {
			&:before {
				content: '';
				width: 7px;
				height: 100%;
				position: absolute;
				display: block;
				left: 0;
				top: 0;
				background-color: $primary;

				@include media-breakpoint-up(xl) {
					display: none;
				}
			}
		}

	}
	&.dropdown-category {
		.submenu-wrapper {
		}
	}
	.nav-link {
		outline: none;
		border: none;
		background: none;
		padding: 1.5rem 2rem;
		width: 100%;
		cursor: pointer;
		text-align: left;
		border-bottom: 1px solid #F5F4F4;

		::v-deep(span){
			@include transition();
			font-weight: 700;
			font-size: 14px;
			line-height: 18px;
			color: $secondary;
			text-transform: uppercase;
		}
	}

	.nav-dropdown-button{
		position: relative;
	}

	.dropdown-arrow{
		position: absolute;
		top: 0;
		bottom: 0;
		right: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		@include transition();

		&.active{
			transform: rotate(180deg);
			::v-deep(svg path) {
				stroke: $primary;
			}
		}
	}

	&.with-dropdown {
		position: relative;

		.nav-link{
			&.nav-dropdown-button{
			}
		}
	}

	.submenu-wrapper {
		height: 0;
		overflow: hidden;
		transition: .3s ease-in;
		border-radius: 5px;
		background-color: #F6F6F6;

		::v-deep(.submenu-link){
			&.btn-link {

				span {
					@include transition();

					&:hover {
						color: $primary;
						border-color: $primary;
					}
				}
			}
		}
	}
}
</style>
