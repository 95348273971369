<template>
	<div class="products-wrapper">
		<div class="inner-wrapper">
			<product-card
				v-for="product in products"
				:product="product"
				:key="product.id"
			/>
			<more-products-card
				v-if="loadMore"
				@loadMoreProducts="handleLoadMoreProducts"
			/>
		</div>
	</div>
</template>

<script>
import ProductCard from '~/catalog/front/components/molecules/ProductCard/ProductCard'
import MoreProductsCard from '~/catalog/front/components/molecules/MoreProductsCard/MoreProductsCard.vue'

export default {
	components: { MoreProductsCard, ProductCard },
	props: {
		products: Array,
		loadMore: Boolean
	},
	methods: {
		handleLoadMoreProducts () {
			this.$emit('loadMore')
		}
	}
}
</script>

<style scoped lang="scss">
.inner-wrapper{
	margin-top: 4rem;
	display: grid;
	grid-template-columns: 1fr;
	gap: 30px;

	@include media-breakpoint-up(xl) {
		grid-template-columns: repeat(2, minmax(0, 1fr));
		grid-auto-rows: 1fr;
	}
}

.skeleton-product-card {
	@include media-breakpoint-up(xl) {
		height: 365px;
	}
}
</style>
