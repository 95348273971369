<template>
	<div class="wrapper" v-custom-background="backgroundImage">
		<base-link class="logo-link" target="_self" url="/">
			<base-svg name="logo" class="logo-icon"/>
		</base-link>
		<div class="error-wrapper">
			<p class="error">404</p>
			<base-font color="white" size="md"  class="error-info">{{langs.pageNotExist}}</base-font>
			<base-link class="back-link" url="/" target="_self">{{langs.backHomepage}}</base-link>
		</div>
	</div>
</template>

<script>
import { pageMixin } from '~/website/front/core/pages/page.mixin'

export default {
	mixins: [
		pageMixin
	],
	computed: {
		backgroundImage () {
			const path = 'poster404.jpg'
			return {
				src: path,
				breakpoints: {
					base: { width: 360, height: 640 },
					sm: { width: 576, height: 640 },
					md: { width: 768, height: 640 },
					lg: { width: 992, height: 768 },
					xl: { width: 1920, height: 730 }
				}
			}
		},

		langs () {
			return this.$app.translator.get('404')
		}
	},
	async prefetch () {
		await this.$app.translator.prefetch('404')
	}
}
</script>

<style lang="scss" scoped>
.wrapper {
	background-size: cover;
	background-position: center;
	padding: 0;
	margin: 0;
	height: 100vh;
	width: 100%;
	overflow: hidden;
	position: relative;
	display: grid;
	place-items: center;
	grid-template-rows: 200px 1fr 200px;
	@include media-breakpoint-landscape() {
		grid-template-rows: 75px 1fr 50px;
	}
	@include media-breakpoint-up(lg) {
		grid-template-rows: 200px 1fr 200px;
		grid-template-columns: repeat(3, 1fr);
	}

	& > * {
		z-index: 1;
	}
	.logo-link {
		grid-row: 1;
		background: none;
		animation: fadeToBottom 1s ease-in alternate;
		@include media-breakpoint-up(lg) {
			grid-column: 2;
			place-self: center;
		}
	}
	.logo-icon {
		height: auto;
		width: 20rem;
		@include media-breakpoint-landscape() {
			width: 15rem;
		}

		:deep(path) {
			fill: $white;
		}
	}

	&::before {
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 0;
		background: #333;
		background: linear-gradient(180deg, rgba(0, 0, 0, 0.9023984594) 0%, rgba(0, 0, 0, 0.5046393557) 10%, rgba(0, 0, 0, 0.4990371148) 50%, rgba(0, 0, 0, 0.4990371148) 89%, rgba(0, 0, 0, 0.8995973389) 100%);
	}

	.error-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		grid-row: 2;
		animation: fadeToTop 1s ease-in alternate;
		@include media-breakpoint-up(lg) {
			grid-column: 2;
		}

		.error {
			color: $white;
			font-size: 15rem;
			line-height: 15rem;
			margin-block: 2rem;
			font-weight: bold;
			text-shadow: 4px 4px $secondary-600;
			@include media-breakpoint-landscape() {
				font-size: 10rem;
				line-height: 10rem;
			}
			@include media-breakpoint-up(lg) {
				font-size: 25rem;
				line-height: 25rem;
			}
		}
		::v-deep .error-info {
			margin-top: 0rem;
			margin-bottom: 3rem;
			font-weight: 500;
			text-transform: uppercase;
			@include media-breakpoint-landscape() {
				margin-bottom: 1rem;
			}
			@include media-breakpoint-up(lg) {
				margin-top: 1rem;
				margin-bottom: 4rem;
			}

		}
	}
	& .back-link {
		max-width: 280px;
		margin: 0 auto 0;
		display: block;
		padding: 1.5rem 3rem;
		border-radius: 5px;
		display: inline-block;
		background-color: $white;
		text-transform: uppercase;
		text-decoration: none;
		color: $primary;
		transition: .3s ease;
		&:hover {
			background-color: rgba($white, 0.7);
		}
	}
}
@keyframes fadeToBottom {
	0% {
		transform: translateY(-50px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}
@keyframes fadeToTop {
	0% {
		transform: translateY(50px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}
</style>
