<template>
	<div class="specification-menu-wrapper">
		<div class="specification-menu-tabs">
			<transition-group>
				<specification-tab
					v-for="(item, i) in items"
					:key="item.menu_title"
					:index="parseInt(i)"
					:activeTab="activeTab"
					:text="item.menu_title"
					@changeTab="chooseActiveTab"
				/>
			</transition-group>
		</div>
		<div class="specification-menu-content">
			<specification-tab-content
				v-for="(item, i) in items"
				:key="item.menu_title + i"
				:item="item"
				:index="parseInt(i)"
				:activeTab="activeTab"
			/>
		</div>
	</div>
</template>

<script>
import SpecificationTab from '~/website/front/components/organisms/SpecificationTabs/SpecificationTab'
import SpecificationTabContent from '~/website/front/components/organisms/SpecificationTabs/SpecificationTabContent'

export default {
	components: { SpecificationTabContent, SpecificationTab },
	data () {
		return {
			activeTab: 0
		}
	},
	props: {
		items: Array
	},
	methods: {
		chooseActiveTab (i) {
			this.activeTab = i
		}
	}
}
</script>

<style lang="scss" scoped>
.specification-menu-wrapper {
	display: grid;
	grid-gap: 30px;

	@include media-breakpoint-up(lg) {
		grid-template-columns: 1fr 2fr;
	}

	.specification-menu-tabs {
		border-radius: 5px;
		position: relative;
		max-height: 35vh;
		overflow-y: auto;
		overflow-x: hidden;
		background-color: $white;
		height: fit-content;
		@include media-breakpoint-up(lg) {
			max-height: 50vh;
		}
		.specification-tab {
			@include transition();
			position: relative;
			border-radius: 5px;
			padding: 2rem 4.2rem;
			background-color: $white;
			cursor: pointer;

			&:after {
				@include pseudo;
				width: 90%;
				height: 1px;
				background-color: #E1E1E1;
				position: absolute;
				transform: translateX(-10px);
				bottom: 0;
			}

			&:last-child {
				&:after {
					display: none;
				}
			}

			&.active {
				:deep(.specification-tab-title) {
					font-weight: 700;
				}
			}
		}
	}
}
</style>
