import { Input } from '@f/core/forms/inputs/Input'
import FieldSelect from '~/website/front/components/molecules/fileds/FieldSelect/FieldSelect'
import FieldCustomSelect from '~/website/front/components/molecules/fileds/FieldCustomSelect/FieldCustomSelect'

class InputSelect extends Input {
	component = FieldSelect
	_options
	optionsGetter
	valueGetter

	constructor ({ options, optionsGetter, valueGetter, component = 'base', ...params }) {
		super(params)
		if (optionsGetter) this.optionsGetter = optionsGetter
		if (valueGetter) this.valueGetter = valueGetter
		if (options) this._options = options
		if (component !== 'base') {
			if (component === 'custom') this.component = FieldCustomSelect
		}
	}

	get value () {
		if (this._value) return this._value
		if (this.valueGetter) return this.valueGetter()
		return null
	}

	set value (value) {
		this._value = value
		this._emit('value:changed', value)
	}

	get disable () {
		if (!this.disableChecker || !this.disableChecker()) return false
		return this.disableChecker()
	}

	get options () {
		if (this.optionsGetter) return this.optionsGetter()
		return this._options
	}

	set options (value) {
		this._options = value
	}

	setOptions (options) {
		this.options = [...options]
	}

	clear () {
		this.value = null
		this.options = []
	}
}

export { InputSelect }
