
class Model {
	static async fetch ({ app, where, take, relations }) {
		const service = app.getService('rext')

		const data = service.getModels({ take, relations, where })

		if (data.error) {
			return []
		}

		return data
	}
}

export { Model }
