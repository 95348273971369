<template>
	<li
		v-if="hasItemChildren(item)"
		class="nav-list-item with-dropdown"
		:class="{
			'active-page': isChildrenActive,
			'dropdown-category': hasCategorySubmenu,
			'show-title': item.showTitleInMenu,
			'active-route': isCurrentRoute
		}"
	>
		<button @click="toggleDropdown" class="nav-link nav-dropdown-button">
			<base-svg class="menu-icon" v-if="hasIcon" :name="item.icon.path"/>
			<base-font :class="{'show-title': item.showTitleInMenu}" variant="menu" class="nav-title">{{item.name}}</base-font>
			<base-svg :name="'menu-arrow-dropdown'" class="dropdown-arrow" :class="{active: activeDropdown}"/>
		</button>
		<div class="submenu-wrapper" ref="dropdown" :class="{active: activeDropdown}">
			<menu-dropdown :items="item.children" :active="activeDropdown"/>
		</div>
		<div
			class="nav-list-item-overlay"
			:class="{active: activeDropdown}"
		/>
	</li>
	<li
		v-else
		class="nav-list-item"
		:class="{
			'active-page': isActive,
			'show-title': item.showTitleInMenu,
			'active-route': isCurrentRoute
	}"
	>
		<base-link :to="item.to" :params="{param: item.param}" class="nav-link" >
			<base-svg class="menu-icon" v-if="hasIcon" :name="item.icon.path"/>
			<base-font :class="{'show-title': item.showTitleInMenu}" variant="menu" class="nav-title">{{item.name}}</base-font>
		</base-link>
	</li>
</template>
<script>
import MenuDropdown from './MenuDropdown.vue'
import currentRouteMixin from '~/website/front/components/organisms/MenuBreadcrumbs/currentRouteMixin'

export default {
	components: {
		MenuDropdown
	},
	props: {
		item: Object
	},
	data: () => ({
		activeDropdown: false
	}),
	mixins: [currentRouteMixin],
	computed: {
		countWrapperHeight () {
			return this.activeDropdown ? this.$refs.dropdown.scrollHeight + 'px' : '0px'
		},
		isActive () {
			if (!this.item.to) return false
			return this.item.to.includes(this.$route.meta.alias)
		},
		isChildrenActive () {
			if (!this.item.children.length) return false
			return this.item.children.find(child => child.to ? child.to.includes(this.$route.meta.alias) : false)
		},
		hasCategorySubmenu () {
			return this.item.children.filter(child => child.link_type === 'category').length !== 0
		},
		hasIcon () {
			return !!this.icon?.path
		}
	},
	methods: {
		open () {
			this.activeDropdown = !this.activeDropdown
			this.$refs.dropdown.style.height = this.countWrapperHeight
			if (this.activeDropdown) {
				document.body.addEventListener('click', this.handleBodyClick)
			}
		},
		toggleDropdown () {
			if (this.activeDropdown) this.close()
			else this.open()
		},
		handleBodyClick () {
			if (this.$refs.dropdown.clientHeight) {
				this.close()
			}
		},
		close () {
			this.activeDropdown = false
			this.$refs.dropdown.style.height = this.countWrapperHeight
			document.body.removeEventListener('click', this.handleBodyClick)
		},
		hasItemChildren (item) {
			return item.children.length !== 0
		}
	}
}
</script>
<style lang="scss" scoped>
	.nav-list-item {

		&.dropdown-category {
			.submenu-wrapper {
					transform: translateX(-29%);
					max-width: 1140px;
				@media screen and (min-width: 1280px) {
					transform: translateX(-28%);
					max-width: fit-content;
				}
			}
		}

		&:not(.show-title) {
			align-self: center;
			transform: translateY(1px);
		}

		&.active-route {
			:deep(.nav-title) {
				color: $primary;
				font-weight: bold;
			}
		}

		&.active-page {
			:deep(svg) {
				g {
					#transparent_area {
						fill: $primary;
					}

					#filled_area {
						fill: $primary;
					}
				}
			}
		}

		.nav-link {
			outline: none;
			border: none;
			background: none;
			padding: 0;
			width: 100%;
			cursor: pointer;
			text-align: left;

			::v-deep(span){
				@include transition();
				font-weight: 600;
				font-size: 14px;
				color: $secondary;
				text-transform: uppercase;

				@include media-breakpoint-up(xxl) {
					font-size: 17px;
				}

				&:not(.show-title) {
					display: none;
					align-self: flex-end;
				}
			}

			.menu-icon{
				display: block;
			}

		}

		.nav-dropdown-button{
			position: static;
		}

		.dropdown-arrow{
			position: absolute;
			top: 30px;
			left: 0;
			right: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			@include transition();

			&.active{
				transform: rotate(180deg);
				::v-deep(svg path) {
					stroke: $primary;
				}
			}
		}

		&.with-dropdown {
			position: relative;
		}

		.submenu-wrapper {
			height: 0;
			overflow: hidden;
			transition: .3s ease-in;
			border-radius: 5px;
			box-shadow: $base-shadow;
			position: absolute;
			top: 8rem;
			left: 0;
			right: 0;
			margin: auto;
			width: fit-content;
			transform: translateX(-125px);
			background-color: $white;

			::v-deep(.submenu-link){
				&.btn-link {
					display: flex;
					justify-content: center;
					align-items: center;
					position: absolute;
					top: calc(50% - 43px - 19px / 2); //43px - Category title width, 19px - link height
					right: 0;
					bottom: 0;
					left: 0;

					span {
						@include transition();
						border: 1px solid $secondary;
						padding: 15px 40px;
						text-transform: uppercase;
						font-weight: 700;
						font-size: 16px;
						letter-spacing: 0.032em;
						line-height: 21px;
						border-radius: 4px;

						&:hover {
							color: $primary;
							border-color: $primary;
						}
					}
				}
			}
		}

		.nav-list-item-overlay{
			@include transition();
			position: fixed;
			top: 55px;
			height: 100vh;

			left: -38%;
			right: 0;
			width: 120vw;
			z-index: -1;
			background-color: rgba(0,0,0,.5);
			visibility: hidden;
			opacity: 0;

			&.active {
				visibility: visible;
				opacity: 1;
			}
		}
	}
</style>
