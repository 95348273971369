<template>
	<section class="text-content" >
		<base-container>
			<div class="text-content-inner">
				<custom-title :title="`${prefix}.section_title`" :subtitle="`${prefix}.section_subtitle`" :header_tag="value.header_tag"/>
				<cms-text :value="`${prefix}.section_description`" :props="{variant: 'paragraph', tag: 'div'}" v-if="value.section_description" class="paragraph"/>
			</div>
		</base-container>
	</section>
</template>

<script>
import BaseContainer from '~/website/front/components/atoms/BaseContainer/BaseContainer'
import CustomTitle from '~/website/front/components/atoms/CustomTitle/CustomTitle'

export default {
	components: { CustomTitle, BaseContainer },
	props: {
		prefix: String,
		value: Object
	}
}
</script>

<style scoped lang="scss">
.text-content {
	:deep(p) {
		@include p_styles();
	}

	:deep(a) {
		@include link_styles();
	}

	:deep(h3) {
		@include h3_styles();
	}
	:deep(.ql-editor) {
		padding: 0;
		padding-top:2rem;
		@include media-breakpoint-up(xl) {
			padding-top:4rem;
		}
	}
	.section-title-wr {
		padding-bottom: 0;
	}
}
</style>
