<template>
	<section class="review">
		<base-container>
			<base-animation
				v-if="value.section_title"
				animation="slideRight"
			>
				<custom-title
					:title="`${prefix}.section_title.title`"
					:subtitle="`${prefix}.section_title.subtitle`"
					:header_tag="value.section_title?.title_tag"
					:color="value.section_title.title_color"
					:transform="value.section_title.title_transform"
					:type="value.section_title_type"
				/>
			</base-animation>

			<div class="review__inner">
				<base-animation
					v-for="item in value.cards"
					:key="item.partner_logo.path"
				>
					<cms-image
						class="lazy review__item"
						:src="item.partner_logo"
						v-bind="defaultCmsImageBreakpoints"
					/>
				</base-animation>

			</div>
		</base-container>
	</section>
</template>

<script>

import BaseContainer from '~/website/front/components/atoms/BaseContainer/BaseContainer'
import CustomTitle from '~/website/front/components/atoms/CustomTitle/CustomTitle'
import { evenAnimationMixin } from '~/cms/front/mixins/evenAnimationMixin'
import BaseAnimation from '@f/components/BaseAnimation/BaseAnimation'
import { defaultCmsImageBreakpoints } from '~/cms/front/mixins/defaultCmsImageBreakpoints.js'
export default {
	components: { CustomTitle, BaseContainer, BaseAnimation },
	props: {
		prefix: String,
		value: Object
	},
	mixins: [evenAnimationMixin, defaultCmsImageBreakpoints]
}

</script>
<style lang="scss" scoped>
	.review{
		@include spacing-section();
		background-color: $white;

		&__inner{
			max-width: 100%;
			display: grid;
			align-items: center;
			justify-content: center;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 20px;

			@media screen and (max-height: 600px) {
				grid-template-columns: repeat(5, 1fr);
			}

			@include media-breakpoint-up(lg){
				grid-template-columns: repeat(5, 1fr);
				grid-gap: 50px;
			}

			.base-animation-content-wrapper{
				height: fit-content;
			}

			::v-deep(.image-wrapper){
				width: 100%;
				img{
					width: 100%;
				}

				&:first-child{
					grid-column: span 2;
				}
			}
		}
	}
</style>
