import { rextServiceExtend } from './core/services/RextService'

class AuthorizationModule {
	readyToExtendServices (app) {
		app.extendService('rext', rextServiceExtend)
	}

	async afterServicesCreated (app) {
		await app.waitForVue()
	}
}

export { AuthorizationModule }
