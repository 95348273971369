import { Page } from '~/cms/front/core/pages/Page.js'
import { BreadcrumbsBuilder } from '~/website/front/core/elements/Breadcrumb/BreadcrumbsBuilder.js'
import Component from './ProductListPage.vue'
import { AccesoryView, DedicatedAntennasView, DefaultView, GroupByBandsView, EnclosureView } from './views/Views'
class ProductListPage extends Page {
	static alias = 'product-list'
	static keepAlive = false
	value
	subcategory = {}
	view = {}

	component = Component

	get subcategoryAlias () {
		return this.route.params.param
	}

	async init () {
		await this.fetch()
		await this.chooseView()

		if (this.route.meta.showBreadcrumbs) {
			this.breadcrumbs = new BreadcrumbsBuilder(this.app)
				.build()
				.generateFromMenuTree(this.alias, this.subcategoryAlias)
		}
	}

	async fetchData () {
		await super.fetchData()
		await this.fetchSubcategory()
	}

	async fetchSubcategory () {
		const subcategory = await this.app.getService('rext').getSubcategory({
			alias: this.subcategoryAlias,
			query: {
				relations: ['traits']
			}
		})

		if (subcategory.error) return this.app.vue.$redirect({ path: '/404' })

		this.subcategory = subcategory
		this.value.content = subcategory.value
		this.meta = subcategory.meta
	}

	async chooseView () {
		const View = {
			'dedicated-antennas': DedicatedAntennasView,
			'5-&-4g---lte': GroupByBandsView
		}[this.subcategory.category.alias] || {
			'outdoor-enclosures': EnclosureView
		}[this.subcategory.alias] || {
			true: AccesoryView,
			false: DefaultView
		}[this.subcategory.isAccessory] || DefaultView

		if (this.view && this.view instanceof View) {
			// update view
		} else {
			this.view = new View(this.app, this)
		}
		await this.view.init()
	}

	store () {
		return {
			...super.store(),
			subcategory: this.subcategory,
			products: this.view.products,
			filters: this.view.filters
		}
	}

	restore () {
		super.restore()
		this.subcategory = window.__PAGE__.subcategory
	}
}
export { ProductListPage }
