<template>
	<section class="distributors-section" :class="padding">
		<base-container>
			<div class="distributors-inner">
				<base-animation
					v-if="value.section_title"
					animation="slideRight"
				>
					<custom-title
						:title="`${prefix}.section_title.title`"
						:subtitle="`${prefix}.section_title.subtitle`"
						:header_tag="value.section_title?.title_tag"
						:color="value.section_title.title_color"
						:transform="value.section_title.title_transform"
						:type="value.section_title_type"
					/>
				</base-animation>

				<div class="distributors-items">
					<base-animation
						v-for="(distributor,i) in value.cards"
						:key="distributor.lat_lng"
						animation="slideTop"
					>
						<distributor
							:distributor="distributor"
							:prefix="`${prefix}.cards.${i}`"
						/>
					</base-animation>
				</div>
			</div>
		</base-container>
	</section>
</template>

<script>
import { paddingSectionMixin } from '~/website/front/utils/paddingSection.mixin'
import CustomTitle from '~/website/front/components/atoms/CustomTitle/CustomTitle'
import Distributor from '~/website/front/sections/DistributorsSection/components/Distributor'
import BaseAnimation from '@f/components/BaseAnimation/BaseAnimation'

export default {
	components: { Distributor, CustomTitle, BaseAnimation },
	props: {
		prefix: String,
		value: Object
	},
	mixins: [paddingSectionMixin]
}
</script>

<style lang="scss" scoped>
	.distributors-section{
		.distributors-inner {
			.distributors-items{
				display: grid;
				grid-template-columns: repeat(1, 1fr);
				gap: 30px;

				@include media-breakpoint-up(sm) {
					grid-template-columns: repeat(2, 1fr);
				}

				@include media-breakpoint-up(lg) {
					grid-template-columns: repeat(3, 1fr);
				}

				@include media-breakpoint-up(xxl) {
					grid-template-columns: repeat(4, 1fr);
				}
			}
		}
	}
</style>
