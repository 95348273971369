import { config } from '@f/config/config.js'
import { Seo } from '~/cms/front/core/seo/Seo'

class ContactSeo extends Seo {
	get openGraph () {
		return [
			{
				property: 'og:title',
				content: this.page.meta.title
			},
			{
				property: 'og:description',
				content: this.page.meta.description
			},
			{
				property: 'og:type',
				content: 'website'
			},
			{
				property: 'og:image',
				content: (() => {
					const contactImageUrl = this.page.value?.contact_page.formImage?.path
					const layoutImageUrl = this.layout.value?.open_graph.main_picture?.path
					return `${this.app.settings.env.origin}${config.services.rext.imagesUrl}${contactImageUrl || layoutImageUrl}?width=1200&height=630`
				})()
			},
			{
				property: 'og:site_name',
				content: this.app.settings.env.origin
			},
			{
				property: 'og:url',
				content: this.app.settings.env.origin + this.page.route.path
			}
		]
	}
}

export { ContactSeo }
