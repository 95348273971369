<template>
	<section
		class="iconsLeft-section"
		:class="{'padding-top': !hasSectionTitle}"
		v-custom-background="getBackgroundImage"
	>
		<base-container>
			<div class="iconsLeft__inner">
				<div class="iconsLeft__text-wr">
					<custom-title
						:title="`${prefix}.section_title.title`"
						:subtitle="`${prefix}.section_title.subtitle`"
						:header_tag="value.section_title?.title_tag"
						:color="value.section_title.title_color"
						:transform="value.section_title.title_transform"
						:type="value.section_title_type"
					/>

					<cms-text
						:value="`${prefix}.introduction`"
						:props="{
							tag: 'div',
							color: 'white',
							weight: 'medium',
							variant: 'paragraph'
						}"
						class="iconsLeft__text"
					/>

					<div class="buttons-wrapper">
						<base-link v-bind="value.button">
							<cms-text :props="{variant: 'button-text'}" :value="`${prefix}.button.content`"/>
						</base-link>
					</div>
				</div>
				<div class="iconsLeft__items-wr" v-if="value.cards.length !== 0">
					<icon-card
						v-for="(item, i)  in value.cards"
						:svg="item.card_icon.path"
						:subtitle="`${prefix}.cards.${i}.card_title`"
						v-bind:key="item.card_title + i"
					/>
				</div>
			</div>
		</base-container>
	</section>
</template>

<script>
import IconCard from './components/IconCard'
import CustomTitle from '~/website/front/components/atoms/CustomTitle/CustomTitle'

export default {
	components: {
		CustomTitle,
		IconCard
	},
	props: {
		prefix: String,
		value: Object
	},
	computed: {
		getBackgroundImage () {
			const path = this.value.image.path
			return {
				src: path,
				breakpoints: {
					base: {
						width: 600,
						height: 900
					},
					lg: {
						width: 1920,
						height: 450
					}
				}
			}
		},

		hasSectionTitle () {
			return this.value.section_title.subtitle
		}
	}
}

</script>

<style lang="scss" scoped>
.iconsLeft-section {
	background-color: #0385E2;
	overflow: hidden;
	@include spacing-section();
	position: relative;
	background-repeat: no-repeat;
	background-size: cover;

	&.padding-top {
		@include media-breakpoint-up(lg) {
			padding-top: 10rem;
		}
	}

	:deep(.base-animation-content-wrapper) {
		height: fit-content;
	}

	.iconsLeft__inner {
		display: flex;
		flex-direction: column;
		position: relative;
		z-index: 1;
		color: $white;

		@include media-breakpoint-up(xl) {
			align-items: center;
			display: grid;
			grid-template-columns: 3fr 4fr;
			gap: 0 150px;
		}

		.iconsLeft__text-wr {
			//h2 {
			//	text-transform: uppercase;
			//	font-size: 20px;
			//	margin-bottom: 20px;
			//
			//	@include media-breakpoint-up(lg) {
			//		font-size: 38px;
			//		margin-bottom: 30px;
			//	}
			//}

			//:deep(.iconsLeft__text) {
			//	padding: 0;
			//}

			//:deep(p) {
			//	color: $white;
			//}
		}

		.iconsLeft__items-wr {
			margin-top: 40px;
			overflow: hidden;
			display: grid;
			gap: 20px;
			grid-template-columns: repeat(1, 1fr);
			@include media-breakpoint-up(sm) {
				grid-template-columns: repeat(2, minmax(0, 1fr))
			}
			@include media-breakpoint-up(xl) {
				overflow: visible;
				gap: 30px;
				margin-top: 0;
			}
		}
	}

	.buttons-wrapper {
		margin-top: 40px;
		order: 3;
		align-items: center;
		justify-content: center;

		@include media-breakpoint-up(xl) {
			order: 2;
			margin-top: 60px;
			align-items: flex-start;
			justify-content: flex-start;
		}
	}

	::v-deep(.btn) {
		color: $white;
		border-color: $white;

		&:after {
			border-color: $white;
		}
	}
}

</style>
