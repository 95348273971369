<template>
	<div class="field-checkbox-wrapper"
		:info="input.info"
	>
		<div class="field-checkbox-main">
			<div class="field-checkbox-box">
				<input
					type="radio"
					class="field-checkbox-input"
					:id="input.name"
					v-model="input.parent.value"
					:name="input.radioName"
					:value="input.value"
				/>

			</div>
			<label
				:for="input.name"
				class="field-checkbox-checkmark"
				:class="{
					invalid: input.isInvalidAndTouched
				}"
			>
				{{input.name}}
			</label>
		</div>
	</div>
</template>

<script>
import mixin from '../utils/input.mixin'

export default {
	mixins: [mixin]
}
</script>

<style lang="scss" scoped src="../utils/fields.scss" />
<style lang="scss" scoped>
	.field-checkbox-box {
		display: none;
	}

	:deep(.field-checkbox-label){
		display: block;
		padding: 10px 30px;
		border-radius: $border-radius;
		border: 1px solid $white;
	}
</style>
