
class Subcategory {
	static async fetchOne ({ app, data }) {
		const service = app.getService('rext')
		const response = await service.getSubcategory(data)

		if (response.error) return []
		return response
	}
}

export { Subcategory }
