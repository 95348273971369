import { Page } from '~/cms/front/core/pages/Page'
import { config } from '~/website/front/config/config.js'
import { BreadcrumbsBuilder } from '~/website/front/core/elements/Breadcrumb/BreadcrumbsBuilder.js'
import Component from './WhereToBuyPage.vue'

class WhereToBuyPage extends Page {
	static alias = 'where-to-buy'
	component = Component

	constructor (data) {
		super(data)
		this.config = config.services.leaflet
	}

	async init () {
		await super.init(...arguments)

		this.breadcrumbs = new BreadcrumbsBuilder(this.app)
			.build()
			.addCrumb('Home', 'homepage')
			.addCrumb('Where to buy', this.route.meta.alias)
	}
}

export { WhereToBuyPage }
