<template>
	<div
		:class="{
			select: true,
			[theme]: true,
			disabled: disabled
		}"
	>
		<base-font
			v-if="label"
			class="select-label"
		>
			{{label}}:
		</base-font>
		<div
			class="select-inner"
			@click="toggleIsOpen"
			v-click-outside="handleClickOutside"
		>
			<div
				class="select-value-wrapper"
			>
				<base-font> {{activeOption?.name || 'Not selected'}} </base-font>
				<base-svg class="select-icon" name="keyboard_arrow_down" :class="{active: isActive}"/>
			</div>
			<div
				class="select-options"
				:class="{active: isActive}"
			>
				<div
					v-for="option in options"
					:key="option.value"
					class="select-option"
					:class="{active: activeOption?.value === option.value}"
					@click="() => onValueChange(option.value)"
				>
					<base-font class="select-option-name">
						{{option.name}}
					</base-font>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import BaseFont from '~/website/front/components/atoms/BaseFont/BaseFont.vue'

export default {
	name: 'AppSelect',
	components: { BaseFont },
	data () {
		return {
			isActive: false,
			activeOption: this.options.find(option => option.value === this.defaultValue) || {}
		}
	},
	props: {
		// {name: '', value: ''}[]
		options: [],
		label: String,
		defaultValue: String,
		disabled: {
			type: Boolean,
			default: false
		},
		theme: {
			type: String,
			default: 'white',
			validator (value) {
				return ['white', 'default'].includes(value)
			}
		},
		dependsOn: [String, Boolean, Number]
	},
	methods: {
		onValueChange (value) {
			this.$emit('valueChanged', value)
			this.activeOption = this.options.find(item => item.value === value) || {}
		},

		toggleIsOpen () {
			this.isActive = !this.isActive
		},

		handleClickOutside () {
			if (this.isActive) this.toggleIsOpen()
		}
	},
	watch: {
		dependsOn () {
			this.activeOption = {}
		},
		options () {
			this.activeOption = this.options.find(option => option.value === this.defaultValue)
		}
	}
}
</script>

<style lang="scss" scoped>
.select {

	&-label {
		font-size: 1.4rem;
		line-height: 1.9rem;
		letter-spacing: .018px;
		margin-bottom: 10px;

		@include media-breakpoint-up(md) {
			font-size: 1.8rem;
			line-height: 2.4rem;
		}
	}

	&-inner {
		cursor: pointer;
		position: relative;
		padding: 10px 20px;
		border-radius: 5px;
		border: 1px solid rgba(0,0,0,0);
		outline: none;
		-webkit-tap-highlight-color: rgba(0,0,0,0);
		@include transition();
	}

	&-value-wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 10px;
		position: static;
	}

	&-icon {
		@include transition;

		&.active {
			transform: rotate(180deg);
		}
	}

	&-options {
		visibility: hidden;
		overflow-y: auto;
		left: 0;
		max-height: 120px;
		opacity: 0;
		right: 0;
		position: absolute;
		transform: translateY(20px);
		z-index: 999;
		box-shadow: $base-shadow;
		width: 100%;
		pointer-events: none;
		@include transition;

		@include media-breakpoint-up(lg) {
			max-height: 200px;
		}

		&.active {
			visibility: visible;
			opacity: 1;
			pointer-events: all;
		}
	}

	&-option {
		transition: all .3s ease-in-out;
		padding: 10px;

		&-name {
			@include transition;
		}
	}
}

.select.disabled {

	.select-inner {
		cursor: not-allowed;
		pointer-events: none;
	}

	&.white,
	&.default {
		.select-inner {
			background-color: #EDEDED;
		}
	}

}

.select.white {
	:deep(.select-label) {
		color: $white;
		font-weight: 700;
	}

	.select-inner {
		background-color: $white;
	}

	.select-options {
		background-color: $white;
		border-radius: 0 0 5px 5px;
	}

	:deep(.select-option) {
		background-color: $white;

		&.active,
		&:hover {
			background-color: $primary;

			.select-option-name {
				color: $white;
			}
		}
	}
}

.select.default {

	.select-inner {
		background-color: $white;
		border-color: #EAEAEA;
	}

	.select-options {
		background-color: $white;
		border-radius: 0 0 5px 5px;
	}

	:deep(.select-option) {
		background-color: $white;

		&.active,
		&:hover {
			background-color: $primary;

			.select-option-name {
				color: $white;
			}
		}
	}
}
</style>
