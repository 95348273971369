<template>
  <router-link
      v-if="product"
      :to="linkToProduct"
      :title="`Learn more about ${product.name}`"
      class="product"
  >
		<cms-image
			class="product-image"
			:src="{
				path: product.thumbnail?.path,
				alt: product.name,
				title: `Product ${product.name}`
			}"
			:base="{
				width: 124,
				height: 137,
				fit: 'contain',
				background: 'transparent'
			}"
			:xl="{
				width: 200,
				height: 220,
				fit: 'contain',
				background: 'transparent'
			}"
		/>

		<div class="product-wrapper">
			<div class="product-title-wrapper">
				<h3
					:class="{
						'product-title': true,
						'with-variants': hasManyVariants
					}"
					v-html="productName"
				/>
				<div
					v-if="hasManyVariants"
					class="product-variant"
				>
					<span
						v-for="(variant, i) in product.variants"
						:key="i"
					>
						{{ i !== product.variants.length - 1 ? `${variant}, ` : variant }}
					</span>
				</div>
			</div>
			<span class="product-serial-number" v-if="partNumber">P/N: {{ partNumber }}</span>
			<cms-text
				:value="product.description"
        :props="{tag: 'div'}"
				class="product-description"
				use-string
				no-inner-links
			/>

			<div class="product-icons" v-if="product.icons.length">
				<cms-image
					class="product-icon"
					v-for="(item, i) in product.icons"
					:key="i"
					:src="item.icon"
					:base="{format: 'webp', height: 60}"
				/>
			</div>
			<div class="buttons-wrapper">
				<span class="buttons-wrapper-link">
					{{ lang.learn_more }}
				</span>

			</div>
		</div>

		<div
			v-if="product.isUltraWideBand"
			class="product-ultraWideBand"
		>
			<base-font
				tag="span"
				color="primary"
				uppercase
				size="sm"
				weight="bold"
			>
				{{ lang.ultra_wide_band }}
			</base-font>
		</div>

	</router-link>
</template>
<script>

import { getPath } from 'utils/getPath'
import BaseFont from '~/website/front/components/atoms/BaseFont/BaseFont.vue'
import { config } from '@f/config/config.js'
import { formatProductName } from '~/catalog/front/utils/formatProductName.js'

export default {
	components: {
		BaseFont
	},
	data () {
		return {
			imagesUrl: config.services.rext.imagesUrl
		}
	},
	props: {
		product: Object
	},
	computed: {

		productName () {
			return formatProductName(this.product?.name)
		},

		hasManyVariants () {
			return this.product.variants.length > 1
		},

		partNumber () {
			return this.product.partNumbers.length ? this.product.partNumbers[0] : ''
		},
		lang () {
			return this.$app.translator.get('products')
		},
		linkToProduct () {
			if (this.product.redirectToVariant && this.product.variants.length === 1) {
				return `/product/${this.product.alias}?variant=${this.product.variants[0]}`
			} else {
				const path = getPath({
					app: this.$app,
					to: 'product',
					params: { alias: this.product.alias }
				})
				return path
			}
		}
	},
	async prefetch () {
		await this.$app.translator.prefetch('products')
	}
}
</script>
<style lang="scss" scoped>
.product {
	text-decoration: none;
	height: 100%;
	display: flex;
	background: #FFFFFF;
	border-radius: 1rem;
	box-shadow: 0 .3rem .6rem rgb(0, 0, 0, .16);
	padding: 2rem 0rem;
	position: relative;
	cursor: pointer;
	@include transition();

	&:hover {
		border-bottom: 5px solid $primary;
		transform: translateY(-.5rem);
	}

	@include media-breakpoint-up(lg) {
		border-bottom: 5px solid transparent;
	}

	&-ultraWideBand {
		position: absolute;
		text-align: center;
		top: 40px;
		max-width: 145px;
		padding: 0 10px;

		@include media-breakpoint-up(lg) {
			top: 20px;
			left: 29px;
			max-width: 260px;
			padding: 0;
		}
	}

	.product-image {
		width: 124px;
		margin: auto 9px;
		min-width: unset;
		min-height: unset;
		display: flex;
		align-items: center;
		height: 100%;

		@include media-breakpoint-up(lg) {
			padding-right: 0;
		}

		@include media-breakpoint-up(xl) {
			width: 200px;
			margin: auto 0;
		}

		:deep(img) {
			@include media-breakpoint-up(xl) {
				margin: auto 0;
				width: 200px;
				height: 220px;
			}
		}
	}

	&-wrapper {
		display: flex;
		flex-direction: column;
		border-left: 1px solid $gray-color;
		padding-left: 1rem;
		padding-right: 1rem;
		@include media-breakpoint-up(md) {
			padding-left: 2rem;
			padding-right: 2rem;
		}

		@include media-breakpoint-up(lg) {
			position: relative;
		}
	}

	&-title {
		color: $primary-product-title;
		text-transform: uppercase;
		font-weight: 600;
		font-size: 1.6rem;

		@include media-breakpoint-up(md) {
			font-size: 2.4rem;
		}

		@include media-breakpoint-up(lg) {
			height: 52px;
			&.with-variants {
				padding-right: 110px;
			}
		}

		@include media-breakpoint-up(xl) {

			&.with-variants {
				padding-right: 110px;
			}
		}

		@include media-breakpoint-up(xxl) {
			height: auto;
		}

	}

	&-serial-number {
		color: $secondary;
		opacity: .3;
		padding: 0.5rem 0rem;
		display: flex;
		padding-right: 60px;
	}

	:deep(.product-description) {
		padding: 0;
		height: auto;
		margin-top: 1rem;
		margin-bottom: 1rem;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-transform: none;

		@include media-breakpoint-up(md) {
			margin-top: 2rem;
			margin-bottom: 3rem;
		}

		& > p {
			cursor: pointer;
			font-weight: 500;
			letter-spacing: 0.01em;
			font-size: 1.4rem;
			word-break: break-word;
			line-height: 1.8rem;
			@include media-breakpoint-up(md) {
				font-size: 1.8rem;
				line-height: 2.4rem;
			}
		}
	}

	&-title-wrapper {
		display: flex;
		flex-direction: column-reverse;
		margin-top: 20px;

		@include media-breakpoint-up(lg) {
			flex-direction: row;
			justify-content: space-between;
			margin-top: 0;
		}
	}

	&-variant {
		background: $variants-gray;
		padding: .5rem 1rem;
		font-size: 1.4rem;
		letter-spacing: 0.02em;
		line-height: 1.8rem;
		font-weight: 500;
		margin-left: auto;
		height: fit-content;
		width: fit-content;
		position: absolute;
		color: $secondary;
		top: 0;
		right: 0;
		border-radius: 0 5px 0 0;

		@include media-breakpoint-up(lg) {
			right: 0;
			border-radius: 0;
			max-width: 110px;
		}
	}

	&-icons {
		display: grid;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		gap: 1rem;

		.product-icon {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.svg-icon {
			height: auto;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		@media screen and (min-width: 460px) {
			grid-template-columns: repeat(3, minmax(0, 1fr));
		}
		@include media-breakpoint-up(sm) {
			grid-template-columns: repeat(4, minmax(0, 1fr));
		}

		@include media-breakpoint-up(md) {
			display: flex;
		}

		@include media-breakpoint-up(xl) {
			display: grid;
			grid-template-columns: repeat(4, minmax(0, 1fr));
		}

		@include media-breakpoint-up(xxl) {
			display: flex;
		}
	}

	&-icon {
		:deep(img) {
			max-width: 100%;
			@include media-breakpoint-up(xl) {
				max-height: 45px;
			}

			@include media-breakpoint-up(xxl) {
				max-height: none;
			}
		}
	}

	:deep(.buttons-wrapper) {
		justify-content: flex-end;
		padding-top: 2rem;
		margin-top: auto;
		display: flex;
		align-items: flex-end;
		@include media-breakpoint-up(lg) {
			align-items: center;
		}

		.buttons-wrapper-link {
			width: fit-content;
			display: flex;
			align-items: center;
			position: relative;
			justify-content: center;
			text-transform: uppercase;
			outline: none;
			cursor: pointer;
			font-family: "Rajdhani", sans-serif;
			text-decoration: none;
			transition: all 0.3s ease-in-out;
			border-radius: 4px;
			padding: 1.2rem 4rem;
			font-weight: 700;
			letter-spacing: 0.02rem;
			font-size: 14px;
			gap: 15px;
			color: #0099FF;
			margin-bottom: 2rem;
			background-color: transparent;
			border: 1px solid #333333;

			@include media-breakpoint-up(lg) {
				font-size: 16px;
				padding: 1.7rem 4rem;
			}
		}
	}

	:deep(.btn.secondary) {
		color: $primary;
		margin-bottom: 2rem;

	}
}

</style>
