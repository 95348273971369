<template>
	<section class="expression-section" :style="{color: backgroundColor}" v-custom-background="backgroundImage">
		<base-container>
			<cms-multi-section :value="`${prefix}.content`"/>
			<div class="expression-section-inner">
				<router-expression-row
					v-for="(row, i) in value.rows"
					:row="row"
					:key="`${row.title}-${i}`"
					:prefix="`${prefix}.rows.${i}`"
				/>
			</div>
		</base-container>
	</section>
</template>

<script>
import BaseContainer from '~/website/front/components/atoms/BaseContainer/BaseContainer.vue'
import CmsMultiSection from '~/cms/front/components/CmsMultiSection/CmsMultiSection.vue'
import { backgroundSectionMixin } from '~/website/front/utils/backgroundSection.mixin.js'
import RouterExpressionRow from '~/website/front/sections/RouterExpressionSection/components/RouterExpressionRow.vue'
import { evenAnimationMixin } from '~/cms/front/mixins/evenAnimationMixin'
export default {
	components: { RouterExpressionRow, CmsMultiSection, BaseContainer },
	props: {
		prefix: String,
		value: Object
	},
	mixins: [backgroundSectionMixin, evenAnimationMixin]
}
</script>

<style lang="scss" scoped>
.expression-section{
	padding-bottom: 100px;
	.expression-section-inner {
		display: flex;
		flex-direction: column;
		gap: 90px;
	}
}
</style>
