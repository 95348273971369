<template>
	<div class="skeleton-wrapper">
		<skeleton-loader v-show="isLoading" class="base-skeleton" :is-visible="true">
			<div></div>
		</skeleton-loader>
		<client-only>
			<slot />
		</client-only>
	</div>
</template>

<script>
import SkeletonLoader from '~/website/front/components/molecules/loaders/SkeletonLoader/SkeletonLoader.vue'
import ClientOnly from 'vue-client-only'
export default {
	components: { SkeletonLoader, ClientOnly },
	props: {
		loading: {
			type: Boolean,
			default: null
		}
	},

	computed: {
		isLoading () {
			if (!this.loading) {
				return process.server
			}
			return this.loading
		}
	}
}
</script>

<style lang="scss" scoped>
.base-skeleton-wrapper{

}
</style>
