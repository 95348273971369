import { getQuery } from 'utils/query'

export function rextServiceExtend (Service) {
	return class RextService extends Service {
		async fetchOpinions (queryObject) {
			const query = getQuery(queryObject)

			const { data, status } = await this.http.get(`/opinion${query}`)
			if (status !== 200) return { error: true }
			return data
		}
	}
}
