<template>
	<section
		class="antenna-photo-section"
		:class="padding"
	>
		<base-container>
			<div v-if="hasSectionImage" class="section-wrapper with-img">
				<div>
					<base-animation
						v-if="value.section_title"
						animation="slideRight"
					>
						<custom-title
							:title="`${prefix}.section_title.title`"
							:subtitle="`${prefix}.section_title.subtitle`"
							:header_tag="value.section_title?.title_tag"
							:color="value.section_title.title_color"
							:transform="value.section_title.title_transform"
							:margin="value.section_title.title_margin"
							:type="value.section_title_type"
						/>
					</base-animation>

					<base-animation animation="slideLeft">
						<cms-text
							v-if="value.content"
							:value="`${prefix}.content`"
							:props="{tag: 'div'}"
						/>
						<buttons :value="value.buttons" />
					</base-animation>

				</div>
				<cms-image
					:value="`${prefix}.img`"
					lazy
				/>
			</div>

			<div v-else class="section-wrapper no-img">
				<base-animation
					v-if="value.section_title"
					animation="slideRight"
				>
					<custom-title
						:title="`${prefix}.section_title.title`"
						:subtitle="`${prefix}.section_title.subtitle`"
						:header_tag="value.section_title?.title_tag"
						:color="value.section_title.title_color"
						:transform="value.section_title.title_transform"
						:type="value.section_title_type"
						:margin="value.section_title.title_margin"
					/>
				</base-animation>

				<base-animation animation="slideRight">
					<cms-text
						v-if="value.content"
						:value="`${prefix}.content`"
						:props="{tag: 'div'}"
					/>
					<buttons :value="value.buttons" />
				</base-animation>
			</div>
		</base-container>
	</section>
</template>

<script>
import BaseContainer from '~/website/front/components/atoms/BaseContainer/BaseContainer'
import CustomTitle from '~/website/front/components/atoms/CustomTitle/CustomTitle'
import BaseAnimation from '@f/components/BaseAnimation/BaseAnimation'
import Buttons from '~/website/front/sections/Buttons/Buttons'
import { paddingSectionMixin } from '~/website/front/utils/paddingSection.mixin.js'
export default {
	components: { Buttons, CustomTitle, BaseContainer, BaseAnimation },
	mixins: [paddingSectionMixin],
	props: {
		prefix: String,
		value: Object
	},
	computed: {

		hasSectionImage () {
			return this.value.img
		}
	}
}
</script>

<style lang="scss" scoped>
.antenna-photo-section {

	.section-wrapper {
		@include media-breakpoint-up(lg) {
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap:30px;
		}

		&.no-img {
			display: block;
		}
		&.with-img {
			:deep(.section-title-wr.h2) {
				@include media-breakpoint-up(lg) {
					padding-top:3rem;
				}
			}

			:deep(.buttons-wrapper) {
				justify-content: flex-start;
			}
		}
		:deep(.image-wrapper) {
			width: 100%;
			img {
				margin-top:2rem;
				max-width: 100%;
				margin-left: auto;
				margin-right: auto;
				display: flex;
				width: 100%;
				justify-content: center;
				align-items: center;
				@include media-breakpoint-up(xl) {
					margin-top:0;
				}
			}
		}

	}
	:deep(.ql-editor) {
		padding:0;
		height: auto;
	}

	:deep(.base-animation-content-wrapper) {
		height: fit-content;
	}

}
</style>
