<template>
	<button class="hamburger" :class="{active: activeMenu}" @click.stop="emitToggle">
		<div class="hamburger-inner">
			<span class="hamburger-lines"></span>
		</div>
	</button>
</template>
<script>
export default {
	props: {
		activeMenu: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		emitToggle () {
			this.$emit('handleMenuToggle')
		}
	}
}
</script>
<style lang="scss" scoped>
 .hamburger {
	padding: 0;
	border: none;
	background: none;
	outline: none;

	@include media-breakpoint-up(xl) {
		display: none;
	}
	&.active {
		.hamburger-lines {
			width: 100%;

			&::before, &::after {
				width: 100%;
			}
		}
	}
	&-inner {
		width: 22px;
		height: 22px;
		display: inline-block;
		position: relative;
	}
	&-lines {
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		height: 2px;
		transform: translateY(-50%);
		background: $black;
		transition: .4s ease-in;

		&::before, &::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 2px;
			background: $black;
			transition: .4s ease-in;
		}
		&::before {
			transform: translateY(-7px);
		}
		&::after {
			transform: translateY(7px);
		}
	}
 }
</style>
