/* eslint-disable import/no-anonymous-default-export */
import Vue from 'vue'
import App from './App.vue'
import { Application } from './core/application/Application'
import { useModules } from './modules'
import createRouter from './router'
import VueMeta from 'vue-meta'
import { backgroundDirective, backgroundCustomDirective } from './directives/background'
import { dateFilter } from './filters/date'
import { animatedScrollObserver } from '@f/directives/animation'
import Vue2TouchEvents from 'vue2-touch-events'
import wrap from '@vue/web-component-wrapper'
import BaseLinkWebComponent from '~/cms/front/components/BaseLinkWebComponent/BaseLinkWebComponent.vue?shadow'
import vClickOutside from 'v-click-outside'

Vue.config.productionTip = false

export default () => {
	const app = new Application()

	app._Vue = Vue

	Vue.prototype.$app = app
	if (process.client) window.app = app

	useModules(app, Vue)

	app.init()

	const router = createRouter()
	const vue = new Vue({
		router,
		render: h => h(App)
	})

	app.setRouter(router)

	vue.then(vue => app.setVue(vue))

	Vue.use(VueMeta)
	Vue.use(Vue2TouchEvents)
	Vue.use(vClickOutside)

	Vue.directive('background', backgroundDirective())
	Vue.directive('custom-background', backgroundCustomDirective())
	Vue.directive('animate', animatedScrollObserver())

	Vue.filter('date', dateFilter)

	if (process.client) {
		const EditorBaseLinkWebComponent = wrap(Vue, BaseLinkWebComponent)
		window.customElements.define('web-component-base-link', EditorBaseLinkWebComponent)
	}

	return vue
}
