export function rextServiceExtend (Service) {
	return class RextService extends Service {
		async getSvg (path) {
			if (!path) return { error: true }
			const { status, data } = await this.http.get(path)
			if (status !== 200) return { error: true }

			return data
		}
	}
}
