<template>
	<section class="antenna-image-section" v-custom-background="getBackgroundImage">
		<base-container>
			<div class="antenna-image-inner">
				<base-animation animation="slideRight">
					<div class="cards">
						<div class="card" v-for="(item, i) in value.items" :key="item.button + i">
							<cms-image
								v-if="item.img"
								ref="cardImage"
								:value="`${prefix}.items.${i}.img`"
								:base="{format: 'webp'}"
								lazy
							/>
							<cms-text :value="`${prefix}.items.${i}.button`"/>
							<div class="line">
								<div class="pulsatingCircle">
									<span class="firstCircle"></span>
									<span class="secondCircle"></span>
								</div>
							</div>
						</div>
					</div>
				</base-animation>

				<cms-image
					class="antenna-image"
					:value="`${prefix}.section_img`"
					:base="{width: 645, height: 781}"
					lazy
				/>

				<base-animation animation="slideLeft">
					<div class="cards cards-right">
						<div class="card" v-for="(itemright, i) in value.itemsright" :key="itemright.button + i">
							<cms-image
								:value="`${prefix}.itemsright.${i}.img`"
								v-if="itemright.img"
								lazy
							/>
							<cms-text :value="`${prefix}.itemsright.${i}.button`"/>
							<div class="line">
								<div class="pulsatingCircle">
									<span class="firstCircle"></span>
									<span class="secondCircle"></span>
								</div>
							</div>
						</div>
					</div>
				</base-animation>
			</div>
		</base-container>
	</section>
</template>

<script>
import BaseContainer from '~/website/front/components/atoms/BaseContainer/BaseContainer'
import BaseAnimation from '@f/components/BaseAnimation/BaseAnimation'
export default {
	components: {
		BaseContainer,
		BaseAnimation
	},
	props: {
		value: Object,
		prefix: String
	},
	computed: {
		getBackgroundImage () {
			if (this.value.bgImg) {
				return {
					src: this.value.bgImg.path ? this.value.bgImg.path : '',
					breakpoints: {
						base: { width: 1920, height: 661 }
					}
				}
			} else return ''
		}
	}
}
</script>

<style lang="scss" scoped>
.antenna-image-section{
	background-color: #1A86C8;
	background-size: cover;
	background-repeat: no-repeat;
	padding-bottom: 60px;

	:deep(.ql-editor) {
		padding:0;
	}

	@include media-breakpoint-up(xl) {
		padding-bottom: 0;
	}
}
.antenna-image-inner {
	display: grid;
	grid-template-columns: 1fr;
	padding-top:6rem;
	align-items: center;
	@include media-breakpoint-up(xl) {
		max-height: 66.1rem;
		padding-top:0;
		grid-template-columns: 3fr 550px 3fr;
	}

	:deep(.base-animation-content-wrapper) {
		height: fit-content;
	}

	@include media-breakpoint-up(xxl) {
		grid-template-columns: 3fr 645px 3fr;
	}
	:deep(.antenna-image) {
		order: 3;
		justify-content: center;
		display: flex;
		width: 100%;
		@include media-breakpoint-up(xl) {
			position: relative;
			top: -5rem;
			z-index: 1;
			order: inherit;
		}
		.base-image {
			max-width: 100%;
			height: auto;
		}
	}
}

:deep(.card) {
	background: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	padding: 2rem 2rem;
	text-transform: uppercase;
	border-radius: 1rem;
	z-index: 9;
	margin-bottom: 2rem;
	width: 100%;
	@include media-breakpoint-up(md) {
		max-width: 70%;
	}
	@include media-breakpoint-up(xl) {
		flex-direction: row;
		margin-left: auto;
		margin-bottom: 10rem;
		padding: 2rem 5rem;
		max-width: 25rem;
		width: fit-content;
	}

	@include media-breakpoint-up(xxl) {
		max-width: 26rem;
	}

	p {
		width: 100%;
		font-weight: bold;
		font-size: 2.2rem;
		line-height: 2.6rem;
		text-align: center;
		@include media-breakpoint-up(xl) {
			text-align: right;
			font-size: 2rem;
		}
		@include media-breakpoint-up(xxl) {
			font-size: 2.2rem;
		}
	}
	.image-wrapper {
		transform: translateX(-20px);
		@include media-breakpoint-up(xl) {
			transform: none;
			left:-30%;
			position: absolute;
			img {
				max-width: auto;
			}
		}

		@include media-breakpoint-up(xxl) {
			left: -35%;
		}
	}
	@include transition;
	&:hover {
		@include media-breakpoint-up(xl) {
			transform: translateX(20px);
			.line {
				.secondCircle {
					animation:.8s pulseIn ease-in-out infinite;
					-webkit-animation:.8s pulseIn ease-in-out infinite;
				}
			}
		}
	}
	&:nth-of-type(2) {
		.line {
			right: -70%;
			width: 70%;
		}
	}
	&:nth-of-type(3) {
		.line {
			right: -85%;
			width: 85%;
		}
	}
	&:nth-of-type(4) {
		.line {
			right: -95%;
			width: 95%;
		}
	}
	.line {
		@include transition;
		display: none;
		@include media-breakpoint-up(xl) {
			display: block;
			background: white;
			width: 100%;
			height: 2px;
			position: absolute;
			right: -100%;
			z-index: 1;
			&:before {
				content:"";
				display: block;
				position: relative;
				top:-.5rem;
				left: -.4rem;
				width: 1rem;
				height: 1rem;
				border-radius: 50%;
				background: white;
			}
			.pulsatingCircle{
				@include transition;
				width:2.5rem;
				height:2.5rem;
				display:block;
				position:relative;
				margin-left: auto;
				margin-right: -4rem;
				margin-top: -2.1rem;
			}

			.firstCircle,
			.secondCircle,
			.thirdCircle{
				@include transition;
				display:block;
				border-radius:50%;
				vertical-align:middle;
			}

			.firstCircle{
				width:2.5rem;
				height:2.5rem;
				margin:-.5rem 0 0 -.5rem;
				background-color: transparent;
				border: .4rem solid $primary;
			}

			.secondCircle{
				opacity: 0;
				width:5rem;
				height:5rem;
				margin:-3.7rem 0 0 -1.8rem;
				border:.2rem solid $primary;
			}
		}
	}
}
:deep(.cards) {
	display: flex;
	flex-direction: column;
	align-items: center;

	@include media-breakpoint-up(xl) {
		max-height: 66.1rem;
		flex-direction: column;
		justify-content: center;
	}
	&.cards-right {
		.image-wrapper {
			left:auto;
			right:-30%;

			@include media-breakpoint-up(xxl) {
				right:-40%;
			}
		}
		.card{
			@include media-breakpoint-up(xl) {
				margin-right: auto;
				margin-left: inherit;
			}
			p {
				text-align: center;
				@include media-breakpoint-up(xl) {
					text-align: left;
				}
			}
			@include transition;
			&:hover {
				transform: translateX(20px);
				.line {
					.secondCircle {
						animation:.8s pulseIn ease-in-out infinite;
						-webkit-animation:.8s pulseIn ease-in-out infinite;
					}
				}
			}
			&:nth-of-type(2) {
				.line {
					width: 90%;
					right: auto;
					left: -90%;
				}
			}
			&:nth-of-type(3) {
				.line {
					right: auto;
					left: -85%;
					width: 85%;
				}
			}
			&:nth-of-type(4) {
				.line {
					right: auto;
					left: -95%;
					width: 95%;
				}
			}
		}
		.line {
			transform: rotate(180deg);
			right: auto;
			left: -100%;
		}
	}
}

@keyframes pulseIn{
	0%{
		opacity:0;
		transform:scale(0.2,0.2);
		border:1rem solid $primary;

	}
	25%{
		opacity:.3;
	}
	50%{
		opacity:1;
		transform:scale(1.2,1.2);
		border:.2rem solid $primary;
	}
	75%{
		opacity:.3;
	}
	100%{
		opacity:0;
		transform:scale(0.2,0.2);
		border:1rem solid $primary;

	}
}

</style>
