<template>
	<div class="downloads-page">
		<downloads-search
			:categories="categories"
			:active-category="activeCategory"
			:active-subcategory="activeSubcategory"
			:products="products"
			:isDedicatedCategory="isDedicatedCategory"
			:brands="brands"
			:models="models"
			@categoryChanged="(props) => $app.page.handleCategoryChanged(props)"
			@subcategoryChanged="(props) => $app.page.handleSubcategoryChanged(props)"
			@brandChanged="handleBrandChanged"
			@modelChanged="handleModelChanged"
		/>
	</div>
</template>

<script>
import { pageMixin } from '~/website/front/core/pages/page.mixin.js'
import DownloadsSearch from '~/website/front/core/pages/DownloadsPage/components/DownloadsSearch.vue'

export default {
	mixins: [pageMixin],
	components: {
		DownloadsSearch
	},
	data () {
		return {
			service: null
		}
	},
	computed: {
		categories () {
			return this.$app.page.categories
		},
		activeCategory () {
			return this.$app.page.activeCategory
		},

		activeSubcategory () {
			return this.$app.page.activeSubcategory
		},
		products () {
			return this.$app.page.products
		},
		isDedicatedCategory () {
			return this.$app.page.activeCategory?.alias === 'dedicated-antennas'
		},
		brands () {
			return this.$app.page.brands.map(brand => ({ name: brand.name, value: brand.alias }))
		},
		models () {
			return this.$app.page.models.map(model => ({ name: model.name, value: model.name }))
		}
	},
	methods: {
		handleBrandChanged (brand) {
			this.$app.page.handleBrandChanged(brand)
		},
		handleModelChanged (model) {
			this.$app.page.handleModelChanged(model)
		}
	}
}
</script>

<style lang="scss" scoped>
</style>
