<template>
<section class="section">
	<base-container>
		<div class="section_inner">
			<custom-title
				:title="`${prefix}.section_title.title`"
				:subtitle="`${prefix}.section_title.subtitle`"
				:header_tag="value.section_title?.title_tag"
				:color="value.section_title.title_color"
				:transform="value.section_title.title_transform"
				:type="value.section_title_type"
			/>
			<div class="content-wrapper">
				<cms-image
					:src="{
						alt: `Working scheme of ${pageProduct?.name}`,
						title: `Check how it works - ${pageProduct?.name}`,
						path: value.image.path
					}"
					v-bind="defaultCmsImageBreakpoints"
					lazy
					class="content-wrapper-image"
				/>
			</div>
		</div>
	</base-container>
</section>
</template>

<script>
import BaseContainer from '~/website/front/components/atoms/BaseContainer/BaseContainer.vue'
import CustomTitle from '~/website/front/components/atoms/CustomTitle/CustomTitle.vue'
import { productSectionMixin } from '~/catalog/front/sections/product-section.mixin'
import { defaultCmsImageBreakpoints } from '~/cms/front/mixins/defaultCmsImageBreakpoints.js'

export default {
	components: { CustomTitle, BaseContainer },
	mixins: [productSectionMixin, defaultCmsImageBreakpoints],
	props: {
		prefix: String,
		value: Object
	}
}
</script>

<style lang="scss" scoped>
.section {
	@include spacing-section();
}

.content-wrapper {
	&-image {
		max-width: 100%;
		:deep(img) {
			width: 100%;
		}
	}
}
</style>
