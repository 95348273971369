<template>
	<div class="general">
		<section class="general-search">
			<base-container>
				<custom-title
					header_tag="h1"
					type="h1"
					:title="lang.products_list.our_products"
					:subtitle="lang.products_list.find_the_best_solution"
					:use-string="true"
					class="general-search-title"
				/>
				<global-search-engine
					:background-image="$app.page.value?.background_image"
					:additional-info="$app.page.value?.additional_info"
				/>
			</base-container>
		</section>
		<section class="general-products">
			<dedicated-antennas-lists
				v-if="page.dedicatedAntennasLists"
				:dedicatedAntennas="page.dedicatedAntennasLists.dedicatedAntennas"
				:dedicatedAccessories="page.dedicatedAntennasLists.dedicatedAccessories"
				:compatibleAntennasLists="page.dedicatedAntennasLists.compatibleAntennasLists"
				:form="page.brandForm"
			/>
			<base-container v-else-if="page.products">
				<product-list :products="page.products" />
			</base-container>
			<no-filtered-products-notification v-if="shouldShowNoFilteredProductsNotification" />
		</section>
	</div>
</template>

<script>
import ProductList from '~/catalog/front/components/organisms/ProductList/ProductList.vue'
import BaseContainer from '~/website/front/components/atoms/BaseContainer/BaseContainer.vue'
import GlobalSearchEngine from '~/catalog/front/components/organisms/GlobalSearchEngine/GlobalSearchEngine.vue'
import CustomTitle from '~/website/front/components/atoms/CustomTitle/CustomTitle.vue'
import DedicatedAntennasLists from '../../../components/organisms/DedicatedAntennasLists/DedicatedAntennasLists'
import NoFilteredProductsNotification
from '~/catalog/front/components/organisms/NoFilteredProductsNotification/NoFilteredProductsNotification'

export default {
	name: 'GeneralProductListPage',
	components: {
		NoFilteredProductsNotification,
		CustomTitle,
		GlobalSearchEngine,
		BaseContainer,
		ProductList,
		DedicatedAntennasLists
	},
	computed: {
		lang () {
			return this.$app.translator.get('products')
		},
		page () {
			return this.$app.page
		},
		shouldShowNoFilteredProductsNotification () {
			return !this.page.products?.length && (this.page.dedicatedAntennasLists ? !Object.keys(this.page.dedicatedAntennasLists).length : true)
		}
	},
	async prefetch () {
		await this.$app.translator.prefetch('products')
	}
}
</script>

<style lang="scss" scoped>
.general {

	&-search {

		&-title {
			:deep(.section-title-wr) {
				padding-top: 40px;
			}
		}
	}

	&-products {
		@include spacing-section();
	}
}
</style>
