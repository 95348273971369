import axios from 'axios'
import { getBlockedLinks } from 'utils/getBlockedLinks'

const fetchPagesPlugin = {
	async beforeCreate (context, inject, vueOptions) {
		if (process.server) {
			const host = 'http://back:3000'
			const { data } = await axios.get(host + '/path')

			const routes = data.map(page => ({
				path: page.path,
				meta: {
					alias: page.alias,
					language: page.language,
					...page.meta
				}
			}))
			context.router.addRoutes(routes)

			inject('routes', routes)
		}
		if (process.client) {
			let routes = window.__ROUTES__

			if (process.env.VUE_BLOCK_LINKS) {
				routes = getBlockedLinks([...routes])
			}

			context.router.addRoutes(routes)
			inject('routes', routes)
		}
	}
}

export default fetchPagesPlugin
