<template>
	<section class="antenna-call" v-custom-background="getBackgroundImage">
		<base-container>
				<custom-title
					:title="`${prefix}.section_title`"
					:subtitle="`${prefix}.section_subtitle`"
					header_tag="h2"
					color="white"
					:showBaseSvg=false
				/>
			<div class="antenna-call-inner">
					<ul class="antenna-call-list">
						<client-only>
							<li v-for="(item, i) in value.items" :key="item.title"
								:style="{ 'margin-left': padding*i + 'px' }">
								<cms-text :value="`${prefix}.items.${i}.title`" />
							</li>
						</client-only>
					</ul>
					<div class="antenna-call-box">
						<div class="phones">
							<base-icon-link
								v-for="phone in value.phones"
								:icon="'phone_footer'"
								:type="'number'"
								:link="phone.phone"
								:key="phone.phone"
							/>
						</div>
						<div class="emails">
							<div class="email-card" >
								<base-icon-link
									v-for="email in value.emails"
									:icon="'mail_footer'"
									:type="'email'"
									:link="email.email"
									:text="email.text"
									:key="email.email"/>
							</div>
						<buttons :value="value.buttons" />
						</div>
					</div>
					<base-svg class="phone-svg" v-if="value.call_svg" :name="value.call_svg.path" />
				</div>

		</base-container>
	</section>
</template>

<script>
import BaseContainer from '~/website/front/components/atoms/BaseContainer/BaseContainer'
import CustomTitle from '~/website/front/components/atoms/CustomTitle/CustomTitle'
import BaseSvg from '~/website/front/components/atoms/BaseSvg/BaseSvg'
import BaseIconLink from '~/website/front/components/atoms/BaseIconLink/BaseIconLink'
import Buttons from '~/website/front/sections/Buttons/Buttons'
import ClientOnly from 'vue-client-only'
export default {
	data () {
		return {
			padding: '10'
		}
	},
	name: 'AntennaCallSection',
	components: { BaseSvg, CustomTitle, BaseContainer, BaseIconLink, Buttons, ClientOnly },
	props: {
		prefix: String,
		value: Object
	},
	computed: {
		getBackgroundImage () {
			if (this.value.section_bgImg) {
				return {
					src: this.value.section_bgImg.path ? this.value.section_bgImg.path : '',
					breakpoints: {
						base: { width: 1920, height: 661 }
					}
				}
			} else return ''
		}
	}
}
</script>

<style lang="scss" scoped>
.antenna-call {
	:deep(.section-subtitle-wrapper .subtitle-after) {
		display: none;
	}
	@include spacing-section();
	&-inner {

		@include media-breakpoint-up(lg) {
			display: grid;
			grid-template-columns: 3fr 8fr 1fr;
			align-items: center;
			.phone-svg {
				display: flex;
				justify-content: center;
				margin-top: 2rem;
				margin-left: 3rem;
			}
		}
		.phone-svg {
			display: none;
		}
	}
	&-list {
		color:white;
		:deep(p), :deep(strong) {
			color:white;
		}
		//padding-right: 10rem;
		li {
			@include media-breakpoint-up(lg) {
				position: relative;
				&::after {
					position: absolute;
					top: 1.5rem;
					left: -1.55rem;
					content:"";
					display: block;
					width: 2px;
					height: 40px;
					background: #FFFFFF;
					opacity: .3;
				}
				&:before {
					position: absolute;
					top: 5.5rem;
					left: -1.55rem;
					content:"";
					display: block;
					width: 350px;
					height: 2px;
					background: #FFFFFF;
					opacity: .3;
					z-index: 1;
				}
				&:nth-child(1) {
					&::after {
						height: 145px;
					}
					&:before {
						top: 16rem;
					}
				}
				&:nth-child(2) {
					&::after {
						height: 110px;
					}
					&:before {
						top: 12.5rem;
					}
				}
				&:nth-child(3) {
					&::after {
						height: 85px;
					}
					&:before {
						top: 9rem;
					}
				}
			}

		}
	}
	&-box {
		padding: 2rem 1rem;
		border-radius: 1rem;
		background: rgba(255,255,255,.9);
		position: relative;
		z-index: 9;

		@include media-breakpoint-up(lg) {
			padding: 6rem 4rem;
		}
		.phones {
			border-bottom: 1px solid #D6D6D6;
			display: flex;
			gap: 20px;
			justify-content: center;
			@include media-breakpoint-up(lg) {
				display: flex;
				padding-bottom: 3rem;
				justify-content: space-between;
			}
			:deep(.link) {
				margin-bottom: 2rem;

				.svg-icon{
					margin-right: 5px;
					width: 15px;
				}

				@include media-breakpoint-up(lg) {
					margin-bottom: 0;
				}
			}
			:deep(.link-phone) {
				font-size: 1.8rem;
				@include media-breakpoint-up(xl) {
					font-size: 3.8rem;
				}
			}
		}
		.emails {
			display: flex;
			gap: 20px;
			justify-content: center;
			margin-top: 20px;
			flex-direction: column;
			align-items: center;

			:deep(.buttons-wrapper) {
				margin-top: 0;
			}

			@include media-breakpoint-up(xl) {
				flex-direction: row;
				margin-top: 4rem;
			}
			@include media-breakpoint-up(xl) {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
			.email-card {
				@include media-breakpoint-up(lg) {
					display: flex;
					align-items: center;
				}
				:deep(.base-icon-link-text) {
					color: $primary;
					margin-right: 1rem;
				}
			}
		}
	}
}
</style>
