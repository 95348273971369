<template>
	<div class="dimensions-section">
		<base-container>
			<div class="dimensions-inner">
				<custom-title
					v-if="value.section_title"
					:title="`${prefix}.section_title.title`"
					:subtitle="`${prefix}.section_title.subtitle`"
					:header_tag="value.section_title?.title_tag"
					:color="value.section_title.title_color"
					:transform="value.section_title.title_transform"
					:type="value.section_title_type"
				/>
				<div class="dimensions-cards">
					<div
						class="dimensions-card"
						v-for="card in value.cards"
						:key="`${card.card_image.path}`"
					>
						<cms-image
							:src="{
								path: card.card_image.path,
								alt: pageProduct?.name,
								title: `Dimensions of ${pageProduct?.name}`
							}"
						/>
					</div>
				</div>
			</div>
		</base-container>
	</div>
</template>

<script>
import BaseContainer from '~/website/front/components/atoms/BaseContainer/BaseContainer'
import CustomTitle from '~/website/front/components/atoms/CustomTitle/CustomTitle'
import { evenAnimationMixin } from '~/cms/front/mixins/evenAnimationMixin'
import { productSectionMixin } from '~/catalog/front/sections/product-section.mixin'
import { defaultCmsImageBreakpoints } from '~/cms/front/mixins/defaultCmsImageBreakpoints.js'
export default {
	components: { CustomTitle, BaseContainer },
	props: {
		prefix: String,
		value: Object
	},
	mixins: [evenAnimationMixin, productSectionMixin, defaultCmsImageBreakpoints]
}
</script>

<style lang="scss" scoped>
.dimensions-section {
	@include spacing-section();

	.dimensions-inner{

		::v-deep(.dimensions-cards) {
			display: flex;
			flex-direction: column;
			gap: 20px;

			@include media-breakpoint-up(lg) {
				flex-direction: row;
				justify-content: center;
				align-items: center;
			}

			.dimensions-card {
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;

				img {
					max-width: 100%;
				}
			}
		}
	}
}
</style>
