import { Article } from '~/blog/front/core/entities/Article'
import { Page } from '~/cms/front/core/pages/Page'

class AbstractArticleLazyListPage extends Page {
	static alias = 'abstract-article-lazy-list-page'

	component
	loading = false

	loadedArticles = 0
	allArticlesNumber = 0
	take = 6
	articles = []

	async fetchData () {
		await super.fetchData()
		await this.fetchArticles()
	}

	async fetchArticles () {
		this.loading = true

		const fetchOptions = {
			app: Object.assign(this.app, { page: this }),
			take: this.loadedArticles + this.take,
			skip: 0,
			where: {
				language: this.app.language
			},
			relations: ['category']
		}
		const { numberOfAllArticles } = await Article.fetch(fetchOptions)
		this.loadedArticles += this.take
		this.allArticlesNumber = numberOfAllArticles

		this.loading = false
	}

	store () {
		const data = super.store()
		return {
			...data,
			articles: this.articles,
			loadedArticles: this.loadedArticles,
			allArticlesNumber: this.allArticlesNumber
		}
	}

	restore () {
		super.restore()
		this.allArticlesNumber = window.__PAGE__.allArticlesNumber
		this.articles = window.__PAGE__.articles
		this.loadedArticles = window.__PAGE__.loadedArticles
	}
}

export { AbstractArticleLazyListPage }
