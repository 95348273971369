import { getQuery } from 'utils/query'

export function rextServiceExtend (Service) {
	return class RextService extends Service {
		async sendNotFoundPage (path, fullPath) {
			const { data } = await this.http.post('/not-found', {
				path,
				fullPath
			})
			return data
		}

		async getBrandsWithCompatibleRouters (id) {
			const { data, status } = await this.http.get(`/antenna/${id}/compatible-routers`)
			if (status !== 200) return { error: true }
			return data
		}

		async getFilteredProducts (queryObject) {
			const query = getQuery(queryObject)

			const { data, status } = await this.http.get(`/filtered-products${query}`)
			if (status !== 200) return { error: true }
			return {
				products: data.products,
				moreProductsUrl: data.next
			}
		}

		async getMoreFilteredProducts (url) {
			const { data, status } = await this.http.get(url)
			if (status !== 200) return { error: true }
			return {
				products: data.products,
				moreProductsUrl: data.next
			}
		}

		async getProductsLength ({ where }) {
			const query = getQuery({ where })
			const { data, status } = await this.http.get(`/product/count${query}`)
			if (status !== 200) return { error: true }
			return data
		}

		async getSubcategory ({ alias, query }) {
			const queryString = getQuery({ ...query })
			const { data, status } = await this.http.get(`/product-subcategory/${alias}/get${queryString}`)
			if (status !== 200) return { error: true }
			return data
		}

		async getDownloadsCategories ({ where, relations }) {
			const query = getQuery({
				where,
				relations
			})
			const { data, status } = await this.http.get(`/product-category${query}`)
			if (status !== 200) return { error: true }
			return data
		}

		async getBrands () {
			const { data, status } = await this.http.get('/brand-ordered')
			if (status !== 200) return { error: true }
			return data
		}

		async getModels (queryObject) {
			const query = getQuery(queryObject)
			const { data, status } = await this.http.get(`/model${query}`)
			if (status !== 200) return { error: true }
			return data
		}

		async getTraits (queryObject) {
			const query = getQuery(queryObject)
			const { data, status } = await this.http.get(`/trait${query}`)
			if (status !== 200) return { error: true }
			return data
		}

		async getArticlesById (queryObject) {
			const query = getQuery(queryObject)
			const { data, status } = await this.http.get(`/article${query}`)
			if (status !== 200) return { error: true }
			return data
		}

		async getDedicatedAntennasListsForModel (modelId) {
			const { data, status } = await this.http.get(`/dedicated-antennas/model/${modelId}`)
			if (status !== 200) return { error: true }
			return data
		}

		async getDedicatedAntennasForBrand (brandId) {
			const { data, status } = await this.http.get(`/dedicated-antennas/brand/${brandId}`)
			if (status !== 200) return { error: true }
			return data
		}

		async getTechnologies () {
			const { data, status } = await this.http.get('/technology')
			if (status !== 200) return { error: true }
			return data
		}

		async getMimos () {
			const { data, status } = await this.http.get('/mimo')
			if (status !== 200) return { error: true }
			return data
		}

		async getFrequencies () {
			const { data, status } = await this.http.get('/frequency')
			if (status !== 200) return { error: true }
			return data
		}

		async setDirectionalities () {
			const { data, status } = await this.http.get('/directionalities')
			if (status !== 200) return { error: true }
			return data
		}

		async getFilteredByParameter (queryObject) {
			const query = getQuery(queryObject)
			const { data, status } = await this.http.get(`/antenna-filter${query}`)
			if (status !== 200) return { error: true }
			return data
		}

		async getAntennaFilter (queryObject) {
			const query = getQuery(queryObject)
			const { data, status } = await this.http.get(`/antenna-filter${query}`)
			if (status !== 200) return { error: true }
			return data
		}

		async getLTEAntennaFilter (queryObject) {
			const query = getQuery(queryObject)
			const { data, status } = await this.http.get(`/lte-antenna-filter${query}`)
			if (status !== 200) return { error: true }
			return data
		}

		async getAllProducts (queryObject) {
			const query = getQuery({
				response: 'product-card',
				relations: [
					'product',
					'product.page',
					'variants'
				]
			})
			const { data, status } = await this.http.get(`/antenna${query}`)
			if (status !== 200) return { error: true }
			return data
		}

		async getProductByAlias (alias = '') {
			const { data, status } = await this.http.get(`/product-alias/${alias}`)
			if (status !== 200) return { error: true }
			return data
		}

		async getFilters () {
			const { data } = await this.http.get('/filter')
			return data
		}

		async getAccessory (where = {}) {
			const query = getQuery({
				response: 'product-card',
				order: {
					order: 'ASC'
				},
				where,
				relations: ['product', 'product.subcategory']
			})
			const { data } = await this.http.get(`/accessory${query}`)
			return data
		}

		async getEnclosures () {
			const query = getQuery({
				response: 'product-card',
				order: {
					order: 'ASC'
				}
			})
			const { data } = await this.http.get(`/enclosure${query}`)
			return data
		}

		async getBestSellers (productsIds) {
			const query = getQuery({ products: productsIds })
			const { data, status } = await this.http.get(`/antenna-best-sellers${query}`)
			if (status !== 200) return { error: true }
			return data
		}

		async getRelatedProducts (productId) {
			const { data, status } = await this.http.get(`/product/${productId}/related`)
			if (status !== 200) return { error: true }
			return data
		}
	}
}
