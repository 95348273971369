<template>
	<section class="related" v-if="products.length">
		<base-container>
			<div class="related-inner">
				<custom-title
					v-if="value.section_title"
					:title="`${prefix}.section_title.title`"
					:subtitle="`${prefix}.section_title.subtitle`"
					:header_tag="value.section_title?.title_tag"
					:color="value.section_title.title_color"
					:transform="value.section_title.title_transform"
					:type="value.section_title_type"
				/>
				<div class="related-carousel swiper">
					<div class="swiper-wrapper">
						<product-card
							v-for="(product, i) in products"
							:key="`${product.id}-${i}`"
							:product="product"
							class="swiper-slide"
						/>
					</div>
					<div class="swiper-pagination"></div>
					<div class="swiper-button swiper-button-prev">
						<svg xmlns="http://www.w3.org/2000/svg" width="22.556" height="42.284" viewBox="0 0 22.556 42.284">
							<path id="Path_9347" data-name="Path 9347" d="M550.786,89.248,529.644,68.106l1.414-1.414L550.786,86.42l19.727-19.728,1.414,1.414Z" transform="translate(-66.692 571.928) rotate(-90)" fill="#3c3c3c"></path>
						</svg>
					</div>
					<div class="swiper-button swiper-button-next">
						<svg xmlns="http://www.w3.org/2000/svg" width="22.556" height="42.284" viewBox="0 0 22.556 42.284">
							<path id="Path_9347" data-name="Path 9347" d="M550.786,89.248,529.644,68.106l1.414-1.414L550.786,86.42l19.727-19.728,1.414,1.414Z" transform="translate(-66.692 571.928) rotate(-90)" fill="#3c3c3c"></path>
						</svg>
					</div>
				</div>
			</div>
		</base-container>
	</section>
</template>

<script>
import BaseContainer from '~/website/front/components/atoms/BaseContainer/BaseContainer.vue'
import CustomTitle from '~/website/front/components/atoms/CustomTitle/CustomTitle.vue'
import ProductCard from '~/catalog/front/components/molecules/ProductCard/ProductCard.vue'
import Swiper, { Navigation, Pagination } from 'swiper'
Swiper.use([Navigation, Pagination])

export default {
	data () {
		return {
			products: []
		}
	},
	components: { ProductCard, CustomTitle, BaseContainer },
	props: {
		value: Object,
		prefix: String
	},
	methods: {
		async fetchProducts () {
			const service = this.$app.getService('rext')

			const response = await service.getRelatedProducts(this.$app.page.product.id)

			if (response.error) return

			this.products = response
			this.$app.page.hasRelatedProducts = !!response.length
		},
		createSlider () {
			this.$nextTick(() => {
				this.slider = new Swiper('.swiper', {
					slidesPerView: 1,
					direction: 'horizontal',
					loop: this.products.length > 2,
					spaceBetween: 30,
					pagination: {
						el: '.swiper-pagination',
						clickable: true
					},
					breakpoints: {
						992: {
							slidesPerView: 2
						}
					},
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev'
					}
				})
			})
		}
	},
	watch: {
		products: 'createSlider',
		'$app.page.product': 'fetchProducts'
	},
	async mounted () {
		if (!process.client) return

		await this.fetchProducts()
	}
}
</script>

<style lang="scss" scoped>
.related {
	overflow: hidden;
	@include spacing-section();
	background-color: #fff;

	&-inner {

	}
	&-carousel {
		position: relative;
	}
}

.swiper {
	overflow: initial !important;
	&-wrapper {
		display: flex;
		align-items: center;
	}

	:deep(.product.swiper-slide) {
		pointer-events: none;
		min-width: 100%;
		@include media-breakpoint-up(lg) {
			min-width: 465px;
		}
		@include media-breakpoint-up(xl) {
			min-width: 555px;
		}
		@include media-breakpoint-up(xxl) {
			min-width: 690px;
		}
	}

	:deep(.product.swiper-slide-active),
	:deep(.product.swiper-slide-next) {
		pointer-events: all;
	}

	&-pagination {
		margin-top: 20px;
		display: flex;
		gap: 10px;
		justify-content: center;
		align-items: center;
	}

	:deep(.swiper-pagination-bullet) {
		cursor: pointer;
		@include transition;
		background-color: $gray-400;
		width: 10px;
		height: 10px;
		border-radius: 50%;
	}

	:deep(.swiper-pagination-bullet-active) {
		background-color: $primary;
	}
}

.swiper-slide {
	@include transition;
	opacity: 0.5;

	&-active,
	&-next{
		opacity: 1;
	}
}

.swiper-button {
	position: absolute;
	top: 0;
	bottom: 0;
	display: none;
	align-items: center;

	@media screen and (min-width: 1300px) {
		display: flex;
	}

	svg {
		cursor: pointer;
		@include transition;
		opacity: .75;

		&:hover {
			opacity: 1;
		}

	}

	&-next {
		right: -60px;

		@media screen and (min-width: 1400px) {
			right: -70px;
		}

		@media screen and (min-width: 1500px) {
			right: -80px;
		}
	}

	&-prev {
		left: -60px;
		transform: rotate(180deg);

		@media screen and (min-width: 1400px) {
			left: -70px;
		}

		@media screen and (min-width: 1500px) {
			left: -80px;
		}
	}
}
</style>
