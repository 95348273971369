import { getImageUrl } from 'utils/getImageUrl'
import { serialize } from 'utils/strings.js'
import { Breakpoints } from 'utils/enums/Breakpoints.js'

class WebsiteHeadManager {
	_preloadTags = []

	get preloadTags () {
		return this._preloadTags.join('\n')
	}

	addImagePreload (picture) {
		const getMediaQuery = (source, previousSource) => {
			if (source.minWidth && previousSource) {
				return `(min-width: ${source.minWidth}.1px) and (max-width: ${previousSource.minWidth}px)`
			} else if (!source.minWidth) {
				return `(max-width: ${previousSource.minWidth})`
			}
			return `(min-width: ${source.minWidth}.1px)`
		}

		for (let i = 0; i < picture.length; i++) {
			const source = picture[i]
			const previousSource = picture[i - 1]

			const tag = `<link rel="preload" as="image" href="${source.src}" media="${getMediaQuery(source, previousSource)}">`
			this._preloadTags.push(tag)
		}
	}

	addVBackgroundImagePreload (app, background) {
		Object.entries(background.breakpoints).forEach(([key, value]) => {
			const breakpointSrc = getImageUrl(app, background.src + serialize({ ...value, format: 'webp' }).toLowerCase())
			const mediaQuery = `(min-width: ${Breakpoints[key.toUpperCase()]}px)`
			const tag = `<link rel="preload" as="image" href="${breakpointSrc}" media="${mediaQuery}">`

			this._preloadTags.push(tag)
		})
	}

	addVBackgroundPreload (src, media) {
		const tag = `<link rel="preload" as="image" href="${src}" media="${media}" crossorigin="anonymous">`
		this._preloadTags.push(tag)
	}
}

export { WebsiteHeadManager }
