<template>
	<div class="currency-item">
		<base-font weight="medium" uppercase class="currency-item-title"><span>{{currency}}</span> {{title}}</base-font>
		<base-font uppercase weight="bold" class="currency-item-label">{{accountNumberLabel}}</base-font>
		<base-font uppercase class="currency-item-name">{{accountNumber}}</base-font>
		<base-font uppercase weight="bold" class="currency-item-label">{{swiftLabel}}</base-font>
		<base-font uppercase class="currency-item-name">{{swift}}</base-font>
		<base-font uppercase weight="bold" class="currency-item-label">{{addressLabel}}</base-font>
		<base-font uppercase class="currency-item-name">{{address}}</base-font>

	</div>
</template>
<script>
export default {
	props: {
		title: String,
		content: String,
		accountNumber: String,
		swift: String,
		address: String,
		currency: String,
		accountNumberLabel: String,
		swiftLabel: String,
		addressLabel: String
	}
}
</script>
<style lang="scss" scoped>
.currency-item {
	border-radius: 1rem;
	background: $white;
	padding: 2rem;
	@include media-breakpoint-up(xl) {
		padding: 3rem;
	}
	&-title {
		font-size: 2rem;
		margin-bottom: 2rem;
		@include media-breakpoint-up(xl) {
			font-size:2.6rem;
			line-height: 3.3rem;
		}
		span {
			color: $primary;
		}
	}
	&-name{
		padding-left:1rem;
	}
}
</style>
