<template>
	<div class="field-checkbox-wrapper"
		:info="input.info"
	>
		<div class="field-checkbox-main">
			<div class="field-checkbox-box">
				<input
					type="checkbox"
					class="field-checkbox-input"
					:id="input.name"
					v-model="input.value"
				/>
				<label
					:for="input.name"
					class="field-checkbox-checkmark"
					:class="{
						invalid: input.isInvalidAndTouched
					}"
				></label>
			</div>
			<base-font
				variant="label"
				color="secondary"
				class="field-checkbox-label"
				weight="medium"
				:for="input.name"
				@click.stop="$emit('click')"
				:html="input.label"
			/>
		</div>
		<base-error :error="input.error" />
	</div>
</template>

<script>
import mixin from '../utils/input.mixin'
import BaseError from '@f/components/BaseError'

export default {
	mixins: [mixin],
	components: {
		BaseError
	}
}
</script>

<style lang="scss" scoped src="../utils/fields.scss" />
