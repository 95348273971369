<template>
	<a
		:href="href"
		download
		class="downloadable-item"
		:title="`Download ${name}`"
	>
		<base-svg name="download" />
		<base-font variant="paragraph" weight="bold" uppercase class="downloadable-text">
			{{name}}
		</base-font>
	</a>
</template>

<script>
import { config } from '@f/config/config.js'

export default {
	props: {
		file: Object,
		alias: String,
		productName: String
	},
	data () {
		return {
			config
		}
	},
	computed: {
		href () {
			if (this.file.type === 'autogenerated') return `/api/product/${this.alias}/datasheet`
			return `/api/filemanager/files/${this.file.value.link.path || this.file.path}`
		},
		name () {
			if (this.file.card_title) return this.file.card_title
			return 'Datasheet ' + this.productName
		}
	}
}
</script>

<style lang="scss" scoped>

</style>
