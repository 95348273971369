<template>
	<component :is="component" v-bind="link" class="advantages__card">
		<div class="advantages__icon-wr">
			<base-svg :name="image" v-if="image"/>
		</div>
		<div class="advantages__text-wr">
			<cms-text :value="title" :props="{tag: 'span' }"/>
		</div>
		<div class="advantages__image-wr">
			<cms-image
				v-if="bgimage" class="advantages_bgimage"
				lazy :src="bgimage"
				:base="{width: 314, height: 119}"
				:md="{width: 690, height: 223}"
				:xl="{width: 690,height: 237}"
			/>
			<div class="advantages__image-overlay"></div>
			<div class="advatanges__border-left"></div>
		</div>
	</component>
</template>
<script>
export default {
	props: {
		title: String,
		image: String,
		bgimage: Object,
		link: Object
	},
	computed: {
		component () {
			if (this.link) return 'base-link'
			return 'div'
		}
	}
}

</script>
<style lang="scss" scoped>
	.advantages__card {
		height: 100%;
		@include transition;
		position: relative;
		display: grid;
		grid-template-columns: 1fr 1.5fr;
		overflow: hidden;
		border-radius: 10px;
		padding: 24px 0;
		align-items: center;

		@include media-breakpoint-up(lg) {
			padding: 40px 0;
		}

		&:hover {
			.advantages__image-wr .advantages__image-overlay {
				background-color: rgba(0, 0, 0, .25);
			}

			.advantages__text-wr {
				opacity: 0.5;

				span {
					transform: translateX(10px);
				}
			}
		}

		@include media-breakpoint-up(xl) {
			padding: 80px 0;
			grid-template-columns: 1fr 2fr;
		}

		.advantages__icon-wr {
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			z-index: 2;
			:deep(svg) {
				max-width: 5.5rem;
				@include media-breakpoint-up(xl) {
					max-width: max-content;
				}
			}
		}

		.advantages__text-wr {
			transition: opacity .3s ease-in-out, transform .3s ease-in-out;
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			z-index: 2;
			text-align: center;
			span {
				transition: opacity .3s ease-in-out, transform .3s ease-in-out;
				font-weight: 600;
				font-size: 24px;
				line-height: 28px;
				text-transform: uppercase;
				color: $white;
				padding-right: 15px;

				@include media-breakpoint-up(lg) {
					font-size: 22px;
					padding-right: 30px;
				}
				&:after {
					display: block;
					content: '';
					position: relative;
					top: 0;
					left: 0;
					right: 0;
					width: 18px;
					margin: 5px auto 0 auto;
					height: 2px;
					background-color: $white;
				}

				@include media-breakpoint-up(xl) {
					font-size: 38px;
					text-align: center;
					line-height: 42px;
				}
			}
		}

		.advantages__image-wr {
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			z-index: 1;
			height: 100%;
			width: 100%;

			::v-deep(img) {
				height: 100%;
				width: 100%;
				@include media-breakpoint-up(lg) {
					width: auto;
				}
			}

			.advantages_bgimage {
				height: 100%;
			}

			.advantages__image-overlay {
				@include transition;
				top: 0;
				right: 0;
				width: 100%;
				height: 100%;
				position: absolute;
				background-color: rgba(0, 0, 0, .5);
				z-index: 0;
			}

			.advatanges__border-left {
				height: 100%;
				width: 5px;
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				background-color: $primary;

				@include media-breakpoint-up(lg) {
					width: 7px;
				}
			}
		}
	}

</style>
