<template>
	<section class="our-bestsellers">
		<base-container>

			<base-animation
				v-if="value.section_title"
				animation="slideRight"
			>
				<custom-title
					:title="`${prefix}.section_title.title`"
					:subtitle="`${prefix}.section_title.subtitle`"
					:header_tag="value.section_title?.title_tag"
					:color="value.section_title.title_color"
					:transform="value.section_title.title_transform"
					:type="value.section_title_type"
				/>
			</base-animation>

			<div class="products-wrapper">
				<base-animation
					v-for="(product, i) in products"
					:key="product.id"
					:animation="evenAnimation(i, 'slideRight', 'slideLeft')"
				>
					<product-card :product="product" />
				</base-animation>

			</div>

			<div class="our-bestsellers-description">
				<questions-button
					v-if="value.section_description || value.section_btn"
					:text="value.section_description"
					:primary-link="value.section_btn"
				/>
			</div>

		</base-container>

	</section>
</template>

<script>
import BaseContainer from '~/website/front/components/atoms/BaseContainer/BaseContainer'
import CustomTitle from '~/website/front/components/atoms/CustomTitle/CustomTitle'
import ProductCard from '~/catalog/front/components/molecules/ProductCard/ProductCard'
import QuestionsButton from '~/website/front/components/molecules/QuestionsButton/QuestionsButton'
import BaseAnimation from '@f/components/BaseAnimation/BaseAnimation'
import { isPageFromNull } from 'utils/ssrCheck'
import { evenAnimationMixin } from '~/cms/front/mixins/evenAnimationMixin'

export default {
	components: { QuestionsButton, CustomTitle, BaseContainer, ProductCard, BaseAnimation },
	mixins: [evenAnimationMixin],

	data () {
		return {
			products: [],
			loading: true
		}
	},

	props: {
		prefix: String,
		value: Object
	},

	computed: {
		lang () {
			const langs = this.$app.translator.get('products')
			return langs.products_section
		}
	},

	methods: {
		async fetchBestsellers () {
			const products = await this.$app.getEntity('antenna').fetchBestSellers({
				app: this.$app,
				productsIds: this.value.products.map(id => parseInt(id))
			})

			this.products = products
		},
		recreateDataFromComponentsData () {
			this.products = this.$app.page.componentsData.ourBestsellers.products
		}
	},

	async mounted () {
		if (isPageFromNull(this.$app)) this.recreateDataFromComponentsData()
		else {
			await this.fetchBestsellers()
			this.loading = false
		}
	},

	async prefetch () {
		await this.$app.translator.prefetch('products')

		if (process.server) {
			await this.fetchBestsellers()
			this.loading = false
			this.$app.page.componentsData.ourBestsellers = { products: this.products }
		}
	}
}

</script>

<style lang="scss" scoped>
.our-bestsellers {
	background: $gray-400;
	@include spacing-section();
	&-description {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		margin-top:6rem;
		p {
			font-size: 1.6rem;
			line-height: 2.1rem;
			color:$secondary;
			text-transform: uppercase;
			text-align: right;
			@include media-breakpoint-up(lg) {
				font-size: 2.6rem;
				line-height: 3.3rem;
			}
		}
		.buttons-wrapper {
			margin-top:0;
			margin-left:2rem;
			@include media-breakpoint-up(lg) {
				margin-left:3rem;
			}
			:deep(.btn) {
				min-width: max-content;
				padding-left:2.5rem;
				padding-right: 2.5rem;
			}
		}
	}
}
.products-wrapper {
	display: grid;
	grid-gap: $cards-gap-mobile;
	grid-template-columns: repeat(1, minmax(0, 1fr));
	@include media-breakpoint-up(lg) {
		grid-template-columns: repeat(2, minmax(0, 1fr));
		grid-gap: 3rem;
	}

}
</style>
