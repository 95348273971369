<template functional>
	<component
		v-if="props.html"
		:is="$options.tag(props)"
		:ref="data.ref"
		:class="$options.classes(props, data)"
		:style="[data.style, data.staticStyle]"
		v-bind="data.attrs"
		v-on="listeners"
		v-html="$options.cut(props)"
	/>
	<component
		v-else
		:is="$options.tag(props)"
		:ref="data.ref"
		:class="$options.classes(props, data)"
		:style="[data.style, data.staticStyle]"
		v-bind="data.attrs"
		v-on="listeners"
	>
		<slot />
	</component>
</template>

<script>
import BaseFont from '@f/components/BaseFont'
export const tags = ['', 'div', 'a', 'p', 'span', 'label', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6']
export const colors = ['primary', 'secondary', 'black', 'white', 'gold', 'slider-colored', 'slider-title']
export const fontFamily = ['primary', 'secondary']
export const weights = ['', 'bold', 'semibold', 'medium', 'regular']
export const sizes = ['', 'price', 'date', 'menu', 'menu-category', 'label', 'xs', 'sm', 'md', 'lg', 'cta', 'hero-medium', 'hero', 'product-title', 'filter-category-title', 'h1', 'h2', 'h3', 'h4', 'paragraph', 'slider-title', 'search-by-brand-title', 'product-variant-button', 'icons-card-text']
export const variants = {
	menu: {
		color: 'primary',
		tag: 'span',
		size: 'menu',
		weight: 'medium',
		family: 'primary'
	},
	'menu-category': {
		color: 'white',
		tag: 'span',
		size: 'menu-category',
		weight: 'medium',
		family: 'primary'
	},
	small: {
		color: 'primary',
		tag: 'span',
		size: 'date',
		family: 'primary'
	},
	price: {
		color: 'primary',
		tag: 'span',
		size: 'price',
		family: 'secondary'
	},
	paragraph: {
		color: 'secondary',
		tag: 'p',
		size: 'paragraph',
		family: 'primary',
		weight: 'medium'
	},
	label: {
		color: 'primary',
		tag: 'label',
		size: 'label',
		weight: 'bold',
		family: 'primary'
	},
	'small-header': {
		color: 'black',
		size: 'sm',
		weight: 'bold',
		family: 'primary',
		tag: 'h3'
	},
	'slider-title': {
		color: 'white',
		size: 'slider-title',
		family: 'primary',
		uppercase: true,
		weight: 'bold'
	},
	'slider-colored': {
		color: 'slider_colored',
		tag: 'span',
		size: 'slider-title',
		family: 'primary',
		uppercase: true,
		weight: 'bold'
	},
	'medium-header': {
		color: 'primary',
		tag: 'h2',
		size: 'md',
		family: 'secondary'
	},
	'section-header': {
		color: 'primary',
		size: 'lg',
		tag: 'h1',
		family: 'secondary'
	},
	cta: {
		color: 'white',
		tag: 'h3',
		size: 'cta',
		family: 'secondary'
	},
	'hero-header-medium': {
		color: 'white',
		tag: 'h3',
		size: 'hero-medium',
		family: 'secondary'
	},
	'hero-header': {
		color: 'white',
		tag: 'h2',
		size: 'hero',
		family: 'secondary'
	},
	'downloads-title': {
		color: 'white',
		tag: 'h2',
		size: 'lg',
		weight: 'bold',
		family: 'primary'
	},
	'product-card-title': {
		tag: 'h1',
		family: 'primary',
		weight: 'semibold',
		size: 'product-card-title'
	},
	'grid-item-desc': {
		color: 'secondary',
		tag: 'span',
		family: 'primary',
		weight: 'medium',
		size: 'grid-item-text'
	},
	'grid-item-desc-bottom': {
		color: 'secondary',
		tag: 'span',
		family: 'primary',
		weight: 'bold',
		size: 'grid-item-text-bottom'
	},
	'specification-tab': {
		color: 'secondary',
		tag: 'h4',
		family: 'primary'
	},
	h1: {
		color: 'secondary',
		size: 'h1',
		family: 'primary',
		weight: 'bold'
	},
	h2: {
		color: 'black',
		size: 'h2',
		family: 'primary',
		weight: 'semibold',
		uppercase: true
	},
	h3: {
		color: 'black',
		tag: 'h3',
		size: 'h3',
		family: 'primary',
		weight: 'semibold',
		uppercase: true
	},
	h4: {
		color: 'secondary',
		tag: 'h4',
		size: 'h4',
		family: 'primary',
		weight: 'bold',
		uppercase: true
	},
	subheading: {
		color: 'black',
		tag: 'span',
		size: 'subheading',
		family: 'primary'
	},
	'blog-header': {
		tag: 'h2',
		size: 'lg',
		family: 'primary'
	},
	'filter-category-title': {
		color: 'secondary',
		tag: 'h4',
		family: 'primary',
		weight: 'medium',
		size: 'xs'
	},
	notice: {
		color: 'secondary',
		tag: 'span',
		family: 'primary',
		weight: 'medium',
		size: 'notice'
	},
	'button-text': {
		tag: 'span',
		family: 'primary',
		weight: 'bold'
	},
	'search-by-brand-title': {
		tag: 'h4',
		family: 'primary',
		weight: 'medium',
		color: 'white',
		size: 'search-by-brand-title'
	}
}

export default {
	extends: BaseFont,
	name: 'BaseFont',
	globalComponent: true
}
</script>

<style lang="scss" scoped>
.fs {
	&-search-by-brand-title{
		font-size: 19px;
		line-height: 25px;

		@include media-breakpoint-up(xl) {
			font-size: 38px;
			line-height: 48px;
		}
	}
	&-hero {
		font-size: 4.3rem;
		line-height: 4.5rem;
		letter-spacing: 0.03em;
		font-weight: normal;
		@include media-breakpoint-up(md) {
			font-size: 5.5rem;
			line-height: 8.3rem;
		}
		@include media-breakpoint-up(lg) {
			font-size: 7rem;
		}
		@include media-breakpoint-up(xl) {
			font-size: 9rem;
			line-height: 13.3rem;
		}
		@include media-breakpoint-up(xxl) {
			font-size: 10rem;
		}
		&-medium {
			font-size: 2.4rem;
			line-height: 2.5rem;
			letter-spacing: 0.03em;
			font-weight: normal;
			@include media-breakpoint-up(md) {
				font-size: 3.2rem;
				line-height: 3.6rem;
			}
			@include media-breakpoint-up(xl) {
				font-size: 4.2rem;
				line-height: 5.6rem;
			}
		}
	}
	&-cta {
		font-size: 2.6rem;
		line-height: 3.5rem;
		letter-spacing: 0.03em;
		font-weight: 400;
		@include media-breakpoint-up(md) {
			font-size: 5.4rem;
			line-height: 6.6rem;
			margin-bottom: 2rem;
		}
		@include media-breakpoint-up(xl){
			font-size: 6.2rem;
			line-height: 7.6rem;
			margin-bottom: 3rem;
		}
		@include media-breakpoint-up(xxl) {
			font-size: 8rem;
			line-height: 10.6rem;
			margin-bottom: 4rem;
		}
	}
	&-lg {
		position: relative;
		display: inline-block;
		font-weight: normal;
		font-size: 2.6rem;
		line-height: 3.5rem;
		letter-spacing: 0.00em;
		z-index: 2;
		margin-bottom: 4rem;
		overflow: hidden;

		@include media-breakpoint-up(md) {
			font-size: 3.6rem;
			line-height: 4.2rem;
			margin-bottom: 6rem;
		}
		@include media-breakpoint-up(lg) {
			font-size: 5.4rem;
			line-height: 6.9rem;
		}
	}
	&-slider-title {
		font-size: 2.6rem;
		line-height: 3.5rem;

		@include media-breakpoint-up(lg){
			font-size: 50px;
			line-height: 62px;
		}
		@include media-breakpoint-up(xxl) {
			font-size: 80px;
			line-height: 102px;
		}

		@include media-slider-low {
			font-size: 50px;
			line-height: 55px;
		}
	}
	&-md {
		font-size: 1.8rem;
		line-height: 3rem;
		letter-spacing: 0.03em;
		font-weight: normal;
		@include media-breakpoint-up(md) {
			font-size: 2.2rem;
			margin-bottom: 1.5rem;
		}
		@include media-breakpoint-up(lg) {
			font-size: 2.8rem;
			margin-bottom: 3rem;
		}
	}
	&-sm {
		font-size: 1.6rem;
		line-height: 2rem;
		letter-spacing: 0.06em;
		text-transform: uppercase;
		@include media-breakpoint-up(md) {
			font-size: 1.8rem;
			line-height: 2.5rem;
		}
		@include media-breakpoint-up(xl) {
			margin-bottom: 2rem;
		}
	}
	&-xs {
		font-size: 1.4rem;
		line-height: 2.4rem;
		@include media-breakpoint-up(md) {
			font-size: 1.8rem;
			line-height: 3rem;
		}
	}

	&-paragraph {
		font-size: 14px;
		line-height: 22px;
		letter-spacing: 0.14px;
		@include media-breakpoint-up(xl) {
			font-size: 18px;
			line-height: 24px;
			letter-spacing: 0.18px;
		}
	}
	&-label {
		font-size: 1.4rem;
		line-height: 1.9rem;
		letter-spacing: 0.018px;
		@include media-breakpoint-up(md) {
			font-size: 1.8rem;
			line-height: 2.4rem;
		}
	}
	&-price {
		font-size: 1.8rem;
		line-height: 3.2rem;
		letter-spacing: 0.0em;
		@include media-breakpoint-up(md) {
			font-size: 2rem;
		}
		@include media-breakpoint-up(xxl) {
			font-size: 2.4rem;
		}
	}
	&-date {
		font-size: 1rem;
		line-height: 1rem;
		letter-spacing: 0.06em;
	}
	&-menu {
		font-size: 1.4rem;
		line-height: 1.9rem;
		letter-spacing: 0.02em;
		text-transform: uppercase;
		@include media-breakpoint-up(xxl) {
			font-size: 1.6rem;
		}
	}
	&-menu-category {
		font-size: 1.8rem;
		line-height: 23px;
		letter-spacing: 0.036em;
	}

	&-product-card-title {
		font-size: 30px;
		line-height: 30px;

		@include media-breakpoint-up(lg) {
			line-height: 69px;
			font-size: 52px;
		}
	}

	&-grid-item-text {
		font-size: 18px;
		line-height: 24px;

		&-bottom {
			font-size: 14px;
			line-height: 24px;
		}
	}
	.specification-tab {
		font-size: 1.7rem;
		line-height: 2.2rem;
		letter-spacing: 0.34px;
	}

	&-h1 {
		font-size: 30px;
		line-height: 30px;

		@include media-breakpoint-up(lg) {
			font-size: 54px;
			line-height: 60px;
		}
	}

	&-h2 {
		font-size: 24px;
		line-height: 30px;

		@include media-breakpoint-up(lg) {
			font-size: 38px;
			line-height: 48px;
		}
	}

	&-h3 {
		@include media-breakpoint-up(lg) {
			font-size: 26px;
			line-height: 33px;
		}

	}

	&-h4 {
		@include media-breakpoint-up(lg) {
			font-size: 22px;
			line-height: 26px;
			letter-spacing: 0.44px;
		}
	}

	&-notice {
		font-size: 12px;
	}

	&-product-variant-button{
		font-size: 12px;
		line-height: 14px;

		@include media-breakpoint-up(lg) {
			font-size: 16px;
			line-height: 21px;
		}
	}

	&-icons-card-text {
		font-size: 18px;
		@include media-breakpoint-up(lg) {
			font-size: 22px;
		}
	}
}
.ff {
	&-primary {
		font-family: $primary-font;
	}
	&-secondary {
		font-family: $secondary-font;
	}
}
.fc {
	&-primary {
		color: $primary;
	}
	&-secondary {
		color: $secondary;
	}
	&-white {
		color: $white
	}
	&-gold {
		color: $secondary;
	}
	&-slider-colored {
		color: $slider-colored;
	}
	&-black {
		color: $secondary;
	}
}

.fuc {
	text-transform: uppercase;
}
.fi {
	font-style: italic;
}
.fw {
	&-bold {
		font-weight: bold;
	}
	&-medium {
		font-weight: 500;
	}
	&-regular {
		font-weight: normal;
	}
	&-semibold {
		font-weight: 600;
	}
}
.mr{
	margin-block: 0!important;
}

.fstyle{

	&-notice{
		letter-spacing: 0.12px;
		line-height: 17px;
		display: inline-block;

		:deep(p) {
			letter-spacing: 0.12px;
			line-height: 17px;
		}
	}

	&-h3 {
		margin: 20px 0;
	}

	&-h4 {
		margin: 20px 0;
	}

	&-paragraph {

		:deep(p) {
			margin-bottom: 10px;

			&:last-child{
				margin-bottom: 0;
			}
		}
	}
}

</style>
