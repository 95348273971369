import { rextServiceExtend } from '~/opinions/front/core/services/RextService'
import { Opinion } from '~/opinions/front/core/entities/Opinion'
const OpinionListPage = async () => (await import('./core/pages/OpinionListPage')).OpinionListPage
const OpinionListSection = () => import('./sections/OpinionListSection')
const OpinionSliderListSection = () => import('./sections/OpinionSliderListSection')

class OpinionModule {
	readyToAddPages (app) {
		app.addPage('opinion-list', OpinionListPage)
	}

	readyToAddEntities (app) {
		app.addEntity('opinion', Opinion)
	}

	readyToExtendServices (app) {
		app.extendService('rext', rextServiceExtend)
	}

	beforeInitReactive (app) {
		app.addSection('opinion_list_section', OpinionListSection)
		app.addSection('opinion_slider_list_section', OpinionSliderListSection)
	}
}

export { OpinionModule }
