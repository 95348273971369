<template>
	<div class="files">
		<base-animation
			v-for="(product, i) in products"
			:key="product.name"
			class="file"
			:animation="evenAnimation(i, 'slideRight', 'slideLeft')"
		>
			<base-font class="file-name" variant="h4"> {{product.name}} </base-font>

			<div class="downloadable-block">
				<file-link-button
					v-for="file in product.files"
					:file="file"
					:productName="product.name"
					:alias="product.alias"
					:key="file.card_title"
				/>
			</div>

		</base-animation>
	</div>
</template>

<script>
import BaseFont from '~/website/front/components/atoms/BaseFont/BaseFont.vue'
import { evenAnimationMixin } from '~/cms/front/mixins/evenAnimationMixin'
import FileLinkButton from '~/website/front/core/pages/DownloadsPage/components/FileLinkButton.vue'
import BaseAnimation from '@f/components/BaseAnimation/BaseAnimation'
export default {
	components: { FileLinkButton, BaseFont, BaseAnimation },
	props: {
		products: Array
	},
	mixins: [evenAnimationMixin]
}
</script>

<style lang="scss" scoped>
.files {
	margin-top: 4rem;

	@include media-breakpoint-up(lg) {
		margin-top: 6rem;
	}

	.file {
		width: 100%;
		border-radius: 10px;
		background-color: $white;
		border: 1px solid #EEEEEE;
		padding: 20px;
		margin-top: 30px;

		@include media-breakpoint-up(lg) {
			padding: 30px;
		}

		@include media-breakpoint-up(lg) {
			display: grid;
			grid-template-columns: 4fr 8fr;
		}

		:deep(.file-name){
			display: flex;
			align-items: center;
			padding-right: 30px;
			margin: 0;
		}

		.downloadable-block{
			display: flex;
			flex-wrap: wrap;
			gap: 10px 20px;
			margin-top: 20px;

			@include media-breakpoint-up(lg) {
				margin-top: 0;
				padding-left: 30px;
				border-left: 1px solid #E9E9E9;
			}

			.downloadable-item{
				border-radius: $border-radius;
				border: 1px solid #D8D8D8;
				display: flex;
				align-items: center;
				width: 100%;
				gap: 10px;
				padding: 10px;
				text-decoration: none;

				@include media-breakpoint-up(lg) {
					width: fit-content;
					padding: 10px 30px;
				}

				&:hover {

					:deep(.downloadable-text) {
						color: $primary;
						transform: translateX(5px);
					}
				}

				:deep(.downloadable-text){
					@include transition;
				}

			}
		}
	}
}
</style>
