<template>
	<div class="field-wrapper" :info="input.info">
		<div class="field-inner-wrapper" :class="{'rotate180': isActive}">
			<div class="field-label">
				<base-font variant="label" color="white" :for="input.name">{{ input.label }}:</base-font>
			</div>
			<select class="input" ref="select" :id="input.name" :disabled="input.disable" v-model="input.value" @mousedown="toggle" @change="$emit('change', input.value)">
				<option :value="null" selected>{{ input.placeholder }}</option>
				<option class="option" v-for="option in input.options" :key="option.id" :value="option[$attrs.valueKey || 'id']">{{ option.name }}</option>
			</select>
			<base-svg :class="{'input-arrow': true, disabled: input.disable}" name="keyboard_arrow_down" @mousedown="toggle" />
		</div>
		<base-error v-show="input.error" :error="input.error" />
	</div>
</template>

<script>
import mixin from '~/website/front/components/molecules/fileds/utils/input.mixin'
import BaseError from '@f/components/BaseError/BaseError'
import BaseSvg from '@f/components/BaseSvg/component/BaseSvg'

export default {
	mixins: [mixin],
	components: {
		BaseError,
		BaseSvg
	},
	data () {
		return {
			isActive: false
		}
	},
	methods: {
		toggle () {
			this.isActive = !this.isActive
		},
		onClick () {
			this.isActive = false
		}
	},
	mounted () {
		document.addEventListener('click', this.onClick)
	},
	beforeDestroy () {
		document.removeEventListener('click', this.onClick)
	}
}
</script>

<style lang="scss" scoped>
.field-inner-wrapper{
	display: flex;
	flex-direction: column;
	gap: 10px;
	width: 100%;
	position: relative;
	@include transition;

	@include media-breakpoint-up(lg) {
		width: 330px;
	}

	.field-label {
		display: none;
		font-size: 18px;
		line-height: 24px;
		letter-spacing: 0.18px;
		font-weight: 600;

		@include media-breakpoint-up(lg) {
			display: block;
		}
	}

	.input {
		@include transition;
		background-color: #fff;
		padding: 10px 20px;
		font-size: 16px;
		line-height: 21px;
		letter-spacing: 0.32px;
		border-radius: 5px;
		border: 1px solid transparent;
		outline: none;
		color: $secondary;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);

		-webkit-appearance: none;
		-moz-appearance: none;
		text-indent: 1px;
		text-overflow: '';

		&::placeholder {
			opacity: .8;
		}

		&:active,
		&:focus,
		&:hover{
			outline: none;
			border-color: $primary;
		}

		&:disabled {
			pointer-events: none;
			background-color: $input-disabled-background;
		}

		&-arrow {
			&.disabled {
				pointer-events: none;
			}
		}

	}
	&.rotate180 {
		@include transition();
		:deep(.svg-icon svg) {
			transform: rotate(180deg);
		}
	}
	.svg-icon {
		position: absolute;
		bottom: 4px;
		right: 17px;
		@include transition();
	}
}
</style>

<style lang="scss" scoped src="../utils/fields.scss" />
