<template>
	<base-link
		v-if="type==='email'"
		class="base-icon-link icon-text"
		:url="`mailto:${link.replace(/\s/g, '')}`"
	>
		<base-svg v-if="icon" :name="icon" class="icon-wrapper"/>
		<base-font v-if="text" class="base-icon-link-text">{{ text }}:</base-font>
		<base-font class="base-icon-link-link notranslate">{{ link }}</base-font>
	</base-link>
	<base-link class="base-icon-link" :url="`tel:${link.replace(/\s/g, '')}`" v-else-if="type==='number'">
		<base-svg v-if="icon" :name="icon"/>
		<base-font class="base-icon-link-link link-phone notranslate">{{ link }}</base-font>
	</base-link>
</template>

<script>
import BaseSvg from '@f/components/BaseSvg/component/BaseSvg.vue'
import BaseLink from '~/cms/front/components/BaseLink/BaseLink.vue'

export default {
	components: {
		BaseSvg,
		BaseLink
	},
	props: {
		icon: String,
		link: String,
		text: String,
		type: String
	}
}
</script>

<style lang="scss" scoped>
.base-icon-link {
	display: flex;
	align-items: center;
	text-decoration: none;

	&:hover {
		::v-deep(.base-icon-link-link) {
			color: $primary;
		}
	}

	.svg-icon {
		margin-right: 10px;
		display: inline-flex;
		display: -webkit-inline-flex;

		@include media-breakpoint-up(lg) {
			margin-right: 20px;
		}
	}

	&.icon-text {
		:deep(.svg-icon){
			margin-right: 0;
		}

		:deep(.base-icon-link-link) {
			font-weight: 600;
			text-decoration: underline;
		}

		:deep(.base-icon-link-text) {
			margin-left: 5px;
			margin-right: 10px;
			line-height: normal;
			font-weight: 700;
			font-size: 14px;
			letter-spacing: 0.01rem;
			text-transform: capitalize;

			@include media-breakpoint-up(lg) {
				font-size: 14px;
			}

			@include media-breakpoint-up(xl) {
				font-size: 16px;
			}

			@include media-breakpoint-up(xxl) {
				font-size: 18px;
				margin-right: 20px;
			}
		}
	}

	:deep(.base-icon-link-link) {
		transition: .3s all linear;
		display: block;
		text-transform: lowercase;
		color: $secondary;
		font-size: 16px;
		font-weight: 700;
		text-decoration: none;

		@include media-breakpoint-up(xl) {
			font-size: 18px;
		}

		@include media-breakpoint-up(xxl) {
			font-size: 22px;
		}

		&.link-phone {
			@include media-breakpoint-up(lg) {
				font-size: 26px;
			}
		}
	}
}
</style>
