<template>
	<div v-show="value" class="placeholder">
		<span class="placeholder-value">
			{{value}}
		</span>
	</div>
</template>

<script>
export default {
	props: {
		value: String
	}
}
</script>

<style lang="scss" scoped>
.placeholder{
	.placeholder-value {
		text-transform: capitalize;
	}
}
</style>
