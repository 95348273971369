<template>
	<div class="expression">
		<div class="expression-inner">
			<div
				class="expression-image"
				v-for="(image, i) in row.images"
				:key="i"
			>
				<cms-image
					class="expression-item-image"
					:value="`${prefix}.images.${i}.img`"
					:base="{
						width: 212,
						height: 284,
						fit: 'contain',
						background: 'transparent'
					}"
					:lg="{
						width: 144,
						height: 193,
						fit: 'contain',
						background: 'transparent'
					}"
					:xl="{
						width: 180,
						height: 241,
						fit: 'contain',
						background: 'transparent'
					}"
					:xxl="{
						width: 212,
						height: 284,
						fit: 'contain',
						background: 'transparent'
					}"
				/>

				<base-svg
					v-if="i === row.images.length - 1"
					name="router_expression_equals"
				/>
				<base-svg v-else name="router_expression_plus" />

			</div>
			<div class="expression-row-title-block">
				<cms-text
					class="title-block"
					:value="`${prefix}.title`"
					:props="{
						color: 'white',
						weight: 'bold',
						size: 'h4',
						tag:'span'}"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import BaseSvg from '~/website/front/components/atoms/BaseSvg/BaseSvg.vue'
export default {
	components: { BaseSvg },
	props: {
		row: Object,
		prefix: String
	},
	computed: {
	}
}
</script>

<style lang="scss" scoped>
.expression {
	.expression-inner {
		padding: 10px 0 0 0;
		border-radius: $border-radius;
		background-color: $white;
		border: 1px solid #E9E9E9;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		gap: 20px;

		@include media-breakpoint-up(lg) {
			padding: 10px;
			flex-direction: row;
			align-items: center;
		}

		@include media-breakpoint-up(xl) {
			padding: 30px 30px 40px 30px;
			align-items: initial;
			gap: 15px;
		}

		@include media-breakpoint-up(xxl) {
			gap: 30px;
		}

		.expression-image{
			display: flex;
			flex-direction: column;
			gap: 10px;
			align-items: center;

			@include media-breakpoint-up(lg) {
				flex-direction: row;
				gap: 20px;
			}

			@include media-breakpoint-up(xxl) {
				gap: 30px;
			}
		}

		.expression-row-title-block{
			width: 100%;

			@include media-breakpoint-up(lg) {
				width: auto;
			}

			:deep(.title-block) {
				display: flex;
				background-color: $primary;
				color: $white;
				border-radius: 0 0 $border-radius $border-radius;
				align-items: center;
				justify-content: center;
				padding: 35px;
				text-align: center;
				height: calc(115% + 70px);

				@include media-breakpoint-up(lg) {
					border-radius: $border-radius;
					max-width: 360px;
				}

				@include media-breakpoint-up(xl) {
					min-width: 207px;
					line-height: 24px;
					font-size: 26px;
					transform: none;
					margin-top: -14%;
				}

				@include media-breakpoint-up(xxl) {
					margin-top: -13%;
					min-width: auto;
					line-height: 33px;
				}
			}
		}

		:deep(img) {
			&.base-image {
				max-width: 100%;
			}
		}
	}
}
</style>
