export class WebsiteStructuredDataManager {
	#scripts = []

	#createStructuredDataScript (value) {
		this.#scripts.push(`<script type="application/ld+json">${JSON.stringify(value)}</script>`)
	}

	createStructuredData (structuredData = {}) {
		this.#createStructuredDataScript(structuredData)
	}

	get script () {
		return this.#scripts.reduce((acc, currentValue) => acc + currentValue, '')
	}
}
