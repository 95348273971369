<template>
	<div class="navbar" :class="{'blue-bg': shouldBgBeBlue}">
		<base-link to="homepage" :params="{alias: '/'}" :title="title" class="navbar-logo">
			<base-svg name="logo" class="logo-icon"/>
		</base-link>
		<button-hamburger :activeMenu="isMobileMenuActive" @handleMenuToggle="toggleMobileMenu"/>
	</div>
</template>

<script>
import ButtonHamburger from '~/website/front/components/organisms/BaseMenu/components/Mobile/atoms/ButtonHamburger'
export default {
	name: 'MobileNavbar',
	components: { ButtonHamburger },
	props: {
		isMobileMenuActive: Boolean,
		shouldBgBeBlue: Boolean,
		title: String
	},
	methods: {
		toggleMobileMenu () {
			this.$emit('handleMenuToggle')
		}
	}
}
</script>

<style lang="scss" scoped>
.navbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	gap: 60px;

	&-logo {
		position: relative;
		z-index: 1;
		max-width: 146px;
		max-height: 26px;

		@include media-breakpoint-up(md) {
			max-width: 210px;
			max-height: 40px;
		}

		.logo-icon {
			::v-deep(svg) {
				width: 100%;
				max-width: 145px;

				path {
					@include transition();
				}
			}
		}
	}
}
</style>
