<template>
	<div v-if="!optimization || elements?.length <= numberToShow">
		<lazy-hydrate
			when-idle
			:idle-timeout="5000"
			v-for="(element, key) in elements"
			:key="$app.page.alias + key"
		>
			<component
				:is="sections[element.alias]"
				:prefix="`${value}.${key}.value`"
				:value="element.value"
				:alias="`${element.alias}_${key}`"
				:ref="element.alias"
				:data-section-index="`${elements[key]?.alias}_${key}`"
			/>
		</lazy-hydrate>
	</div>
	<div v-else>
		<div>
			<lazy-hydrate
				when-idle
				:idle-timeout="5000"
				v-for="(val, index) in numberToShow"
				:key="$app.page.alias + index"
			>
				<component
					:is="sections[elements[index]?.alias]"
					:prefix="`${value}.${index}.value`"
					:value="elements[index]?.value"
					:alias="elements[index]?.alias"
					:data-section-index="`${elements[index]?.alias}_${index}`"
				/>
			</lazy-hydrate>
		</div>
		<div>
			<lazy-hydrate
				never
				:trigger-hydration="shouldShowAll"
				:idle-timeout="5000"
				v-for="(val, index) in elements.length - numberToShow"
				:key="$app.page.alias + index"
			>
				<component
					:is="sections[elements[index + numberToShow]?.alias]"
					:prefix="`${value}.${index + numberToShow}.value`"
					:value="elements[index + numberToShow]?.value"
					:alias="elements[index + numberToShow]?.alias"
					:data-section-index="`${elements[index + numberToShow]?.alias}_${index + numberToShow}`"
				/>
			</lazy-hydrate>
		</div>

	</div>
</template>

<script>
import { getValue } from 'utils/objects'
import LazyHydrate from 'vue-lazy-hydration'

export default {
	data () {
		return {
			shouldShowAll: false,
			observer: undefined
		}
	},
	props: {
		value: String,
		layout: Boolean,
		numberToShow: {
			type: Number,
			default: 3
		},
		optimization: {
			type: Boolean,
			default: false
		}
	},
	components: {
		LazyHydrate
	},
	computed: {
		elements () {
			let value
			if (this.layout) value = this.$app.layout.value
			else value = this.$app.page.value

			return getValue(this.value, value, 'none')
		},
		sections () {
			return this.$app.sections
		}
	},
	mounted () {
		this.$nextTick(() => {
			if (process.client && (this.optimization || this.numberToShow - 1 > this.elements?.length)) {
				this.observer = new IntersectionObserver((entries) => {
					entries.forEach(entry => {
						if (entry.isIntersecting) {
							this.shouldShowAll = true
							this.observer.unobserve(entry.target)
						}
					})
				})

				setTimeout(() => {
					const selectors = this.elements.map((selector, index) => {
						if (index < this.numberToShow - 1) return null
						return `[data-section-index="${this.elements[index].alias}_${index}"]`
					}).filter(item => !!item)
					const lastRenderedSections = selectors.map((selector) => document.querySelector(selector))
					lastRenderedSections.filter(section => !!section).map((lastRenderedSection) => this.observer.observe(lastRenderedSection))
				}, 100)
			}
		})
	}
}
</script>
