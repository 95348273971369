<template>
	<section class="why-choose-us">
	<base-container>
		<base-animation
			animate="slideRight"
		>
			<custom-title
				:title="`${prefix}.section_title.title`"
				:subtitle="`${prefix}.section_title.subtitle`"
				:header_tag="value.section_title?.title_tag"
				:color="value.section_title.title_color"
				:transform="value.section_title.title_transform"
				:type="value.section_title_type"
			/>
		</base-animation>

		<base-animation
			v-for="(item, index) in value.cards"
			animation="slideRight"
			:key="index"
		>
			<base-svg
				:name="item.slide_svg.path"
				class="word"
			/>
		</base-animation>

	</base-container>
	</section>

</template>

<script>

import BaseContainer from '~/website/front/components/atoms/BaseContainer/BaseContainer'
import BaseSvg from '~/website/front/components/atoms/BaseSvg/BaseSvg'
import CustomTitle from '~/website/front/components/atoms/CustomTitle/CustomTitle'
import BaseAnimation from '@f/components/BaseAnimation/BaseAnimation'
export default {
	components: { CustomTitle, BaseContainer, BaseSvg, BaseAnimation },
	props: {
		prefix: String,
		value: Object
	}
}
</script>
<style lang="scss" scoped>
.why-choose-us {
	h2 {
		margin-bottom: 4rem;
		@include media-breakpoint-up(lg){
			margin-bottom: 8rem;
		}
	}

	:deep(.base-animation-content-wrapper):last-child {
		svg {
			margin-bottom: 0;
		}
	}

	@include spacing-section();
	.word {
		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
		}

		@include media-breakpoint-up(lg) {
			padding-top:1rem;
			padding-bottom: 1rem;
		}
			:deep(svg), :deep(path) {
			@include transition();
		}

		&:deep(svg) {
			margin-bottom: 10px;
			animation-name: example;
			animation-duration: .5s;
			max-width: 100%;
			@include media-breakpoint-up(lg) {
				max-width: 800px;
				margin-bottom: 20px;
			}
			@include media-breakpoint-up(xl) {
				max-width: 1000px;
				margin-bottom: 0;
			}
		}

		&:hover {
			@include media-breakpoint-up(xl) {
				:deep(svg) {
				transform: translateX(50px);
			}
				:deep(path) {
					fill:$primary;
				}
			}

		}
	}
}
</style>
