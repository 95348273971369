<template>
	<div class="support-page">
		<base-container>
			<div class="support-page-inner">
				<custom-title
					header_tag="h1"
					type="h1"
					:title="translate.title"
					:subtitle="translate.subtitle"
					:use-string="true"
				/>
				<support-form />
			</div>

		</base-container>

	</div>
</template>
<script>
import { pageMixin } from '~/website/front/core/pages/page.mixin'
import CustomTitle from '~/website/front/components/atoms/CustomTitle/CustomTitle.vue'
import SupportForm from '~/website/front/components/sections/SupportForm/SupportForm.vue'

export default {
	components: {
		CustomTitle,
		SupportForm
	},
	mixins: [
		pageMixin
	],
	computed: {
		translate () {
			return this.$app.translator.get('support').ask_a_question_section || {}
		}
	},
	async prefetch () {
		await this.$app.translator.prefetch('support')
	}
}
</script>
<style lang="scss" scoped>
.support-page{
	@include spacing-section();

	.support-page-inner{

	}
}
</style>
