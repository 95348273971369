import { rextServiceExtend } from './core/services/RextService'
import { rextServiceExtend as baseSvgRextServiceExtend } from '@f/components/BaseSvg/service/RextService'
import { Subcategory } from '~/catalog/front/core/entities/Subcategory/Subcategory'
import { Brand } from '~/catalog/front/core/entities/Brand/Brand'
import { Model } from '~/catalog/front/core/entities/Model/Model'
import { Trait } from '~/catalog/front/core/entities/Trait/Trait'
import { Technology } from '~/catalog/front/core/entities/Technology/Technology'
import { Frequency } from '~/catalog/front/core/entities/Frequency/Frequency'
import { Directionality } from '~/catalog/front/core/entities/Directionality/Directionality'
import { Mimo } from '~/catalog/front/core/entities/Mimo/Mimo'
import { Antenna } from '~/catalog/front/core/entities/Antenna/Antenna'
import { BrandService } from './core/services/BrandService'
import { FiltersService } from './core/services/FiltersService'

import GridSection from '~/catalog/front/sections/GridSection/GridSection.vue'
import WhyOurEnclosures from '~/catalog/front/sections/WhyOurEnclosures/WhyOurEnclosures.vue'
import ProductCardSection from '~/catalog/front/sections/ProductCardSection/ProductCardSection.vue'
import DownloadableFilesSection from '~/catalog/front/sections/DownloadableFilesSection/DownloadableFilesSection.vue'
import QuestionsAccordionSection from '~/catalog/front/sections/QuestionsAccordionSection/QuestionsAccordionSection.vue'
import SpecificationSection from '~/catalog/front/sections/SpecificationSection/SpecificationSection.vue'
import DimensionsSection from '~/catalog/front/sections/DimensionsSection/DimensionsSection.vue'
import PlotsSection from '~/catalog/front/sections/PlotsSection/PlotsSection.vue'
import UseCases from '~/catalog/front/sections/UseCases/UseCases.vue'
import AdditionalDimensionsSection from '~/catalog/front/sections/AdditionalDimensionsSection/AdditionalDimensionsSection.vue'
import MountingBracketSection from '~/catalog/front/sections/MountingBracketSection/MountingBracketSection.vue'
import ProductIframeSection from '~/catalog/front/sections/ProductIframeSection/ProductIframeSection.vue'
import InstallationManualSection from '~/catalog/front/sections/InstallationManualSection/InstallationManualSection.vue'
import RelatedProductsSection from '~/catalog/front/sections/RelatedProductsSection/RelatedProductsSection.vue'

const ProductPage = async () => (await import('./core/pages/ProductPage')).ProductPage
const ProductListPage = async () => (await import('./core/pages/ProductListPage')).ProductListPage
const GeneralProductListPage = async () => (await import('./core/pages/GeneralProductListPage')).GeneralProductListPage

class CatalogModule {
	sections = []

	readyToAddPages (app) {
		app.addPage('product', ProductPage)
		app.addPage('product-list', ProductListPage)
		app.addPage('general-product-list', GeneralProductListPage)
	}

	beforeInitReactive (app) {
		app.addSection('product_card_section', ProductCardSection)
		app.addSection('grid_section', GridSection)
		app.addSection('downloadable_files_section', DownloadableFilesSection)
		app.addSection('questions_accordion_section', QuestionsAccordionSection)
		app.addSection('specification_section', SpecificationSection)
		app.addSection('dimensions_section', DimensionsSection)
		app.addSection('plots_section', PlotsSection)
		app.addSection('why_our_enclosures', WhyOurEnclosures)
		app.addSection('use_cases', UseCases)
		app.addSection('installation_manual_section', InstallationManualSection)
		app.addSection('product_iframe_section', ProductIframeSection)
		app.addSection('mounting_bracket_section', MountingBracketSection)
		app.addSection('additional_dimensions_section', AdditionalDimensionsSection)
		app.addSection('related_products_section', RelatedProductsSection)
	}

	readyToAddEntities (app) {
		app.addEntity('product-category', Subcategory)
		app.addEntity('brand', Brand)
		app.addEntity('model', Model)
		app.addEntity('trait', Trait)
		app.addEntity('mimo', Mimo)
		app.addEntity('technology', Technology)
		app.addEntity('frequency', Frequency)
		app.addEntity('directionality', Directionality)
		app.addEntity('antenna', Antenna)
	}

	readyToAddServices (app) {
		app.addService(BrandService)
		app.addService(FiltersService)
	}

	readyToExtendServices (app) {
		app.extendService('rext', rextServiceExtend)
		app.extendService('rext', baseSvgRextServiceExtend)
	}
}

export { CatalogModule }
