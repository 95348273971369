<template>
	<section class="testimonials">
		<base-container>
			<div class="testimonials-items">
				<base-animation
					v-for="(testimonial, index) in opinions"
					:animation="evenAnimation(index, 'slideRight', 'slideLeft')"
					:key="`${prefix}.cards.${index}`"
				>
					<opinion-reviews-item
						:opinion="{
							title: testimonial.author,
							subtitle: testimonial.place,
							description: testimonial.content,
							image: testimonial.image,
							pageLink: testimonial.pageLink
						}"
					/>
				</base-animation>

			</div>
		</base-container>
	</section>
</template>

<script>
import BaseContainer from '~/website/front/components/atoms/BaseContainer/BaseContainer'
import { evenAnimationMixin } from '~/cms/front/mixins/evenAnimationMixin'
import BaseAnimation from '@f/components/BaseAnimation/BaseAnimation'
import { isPageFromNull } from 'utils/ssrCheck'
import OpinionReviewsItem from '~/opinions/front/components/OpinionReviewsItem.vue'
export default {

	components: {
		OpinionReviewsItem,
		BaseContainer,
		BaseAnimation
	},
	data () {
		return {
			opinions: null
		}
	},
	props: {
		prefix: String,
		value: Object
	},
	mixins: [evenAnimationMixin],
	methods: {

		recreateDataFromComponentsData () {
			this.opinions = this.$app.page.componentsData.opinionsSlider.opinions
		},

		async getOpinions (queryObject) {
			const service = this.$app.getService('rext')

			const data = await service.fetchOpinions(queryObject)

			if (data.error) {
				this.opinions = []
				return
			}

			this.opinions = data
		}
	},
	async mounted () {
		if (isPageFromNull(this.$app)) {
			this.recreateDataFromComponentsData()
		} else {
			await this.getOpinions({
				app: this.app,
				take: 999,
				skip: 0,
				type: 'list'
			})
		}
	},
	async prefetch () {
		if (process.server) {
			await this.getOpinions({
				app: this.app,
				take: 999,
				skip: 0,
				type: 'list'
			})
			this.$app.page.componentsData.opinionsSlider = {}
			this.$app.page.componentsData.opinionsSlider.opinions = this.opinions
		}
	}
}

</script>
<style lang="scss" scoped>
.testimonials {
	@include spacing-section();

	.testimonials-items{
		display: flex;
		flex-direction: column;
		gap: 4rem;
	}
}
</style>
