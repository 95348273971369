import { Page } from '~/cms/front/core/pages/Page'
import Component from './DownloadsPage.vue'

class DownloadsPage extends Page {
	static alias = 'where-to-buy'
	categories = []
	activeCategory = {}
	activeSubcategory = {}
	products = []
	brands = []
	activeBrand = {}
	activeModel = {}

	component = Component

	get models () {
		return this.activeBrand.models || []
	}

	async fetch () {
		await super.fetch()
		await this.fetchCategories()
		await this.fetchProducts()
		await this.getBrands()
	}

	async init () {
		await super.init()
		this.activeCategory = this.categories[0]
	}

	handleCategoryChanged (category) {
		this.activeCategory = category
		this.activeSubcategory = null
		if (this.activeBrand) {
			this.activeBrand = {}
			this.activeModel = {}
		}
		this.fetchProducts()
	}

	handleSubcategoryChanged (subcategory) {
		this.activeSubcategory = subcategory
		this.fetchProducts()
	}

	async getBrands () {
		const service = this.app.getService('brand')
		const data = await service.getBrands()
		this.brands = data
	}

	async handleBrandChanged (brandAlias) {
		this.activeBrand = this.brands.find(brand => brand.alias === brandAlias) || {}
		this.activeModel = {}
		this.fetchProducts()
	}

	async handleModelChanged (modelAlias) {
		this.activeModel = this.models.find(model => model.name === modelAlias) || {}
		this.fetchProducts()
	}

	async fetchCategories () {
		const service = this.app.getService('rext')

		const data = await service.getDownloadsCategories({
			relations: ['subcategories']
		})

		if (data.error) {
			this.categories = []
			return
		}

		/* Duplicated categories in subcategory mistake bad fix */
		data.forEach(category => {
			if (!category.subcategories) return
			category.subcategories = category.subcategories.filter(subcategory => subcategory.alias !== category.alias)
		})

		data.unshift({
			name: 'All',
			id: -1
		})

		this.categories = data
	}

	async fetchProducts () {
		const service = this.app.getService('rext')

		if (this.activeCategory.id === -1) {
			const data = await service.getProductsDownloadable()

			if (data.error) {
				this.products = []
				return
			}

			this.products = data
			return
		}

		let queryObject = {}

		if (this.activeModel.id) {
			queryObject = {
				model: this.activeModel.id
			}
		} else if (this.activeBrand.id) {
			queryObject = {
				brand: this.activeBrand.id
			}
		} else if (this.activeSubcategory) {
			queryObject = {
				relations: ['subcategory'],
				take: 999,
				where: {
					subcategory: {
						alias: this.activeSubcategory.alias
					}
				}
			}
		} else if (this.activeCategory) {
			queryObject = {
				relations: ['subcategory', 'subcategory.category'],
				take: 999,
				where: {
					subcategory: {
						category: {
							id: this.activeCategory.id
						}
					}
				}
			}
		}

		const data = await service.getProductsDownloadable(queryObject)

		if (data.error) {
			this.products = []
			return
		}

		this.products = data
	}
}

export { DownloadsPage }
