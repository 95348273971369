<template>
	<div class="slider-thumbnails-wrapper">
		<div class="slider-thumbnails">
			<div class="slider-thumbnail" v-for="(slide, i) in slides" :key="i" @click="goTo(slide)">
				<cms-image
					:base="{width: 60, height: 70}"
					:src="{
						alt: product.name,
						path: slide.image?.path,
						title: `Product - ${product.name}`
					}"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import CmsImage from '~/cms/front/components/CmsImage/CmsImage'

export default {
	components: {
		CmsImage
	},
	computed: {
		slides () {
			const slides = [...this.slider.slides]
			const lastSlide = slides.pop()
			slides.unshift(lastSlide)
			return slides || []
		}
	},
	props: {
		slider: Object,
		product: Object
	},
	methods: {
		goTo (slide) {
			slide.goTo()
		}
	}
}
</script>

<style lang="scss" scoped>

.slider-thumbnails-wrapper {
	display: flex;
	width: 90%;
	height: 20%;
	overflow: hidden;

	.slider-thumbnails {
		@include transition();
		display: flex;
		position: absolute;
		bottom: 0;
		gap: 25px;
		padding-right: 20px;
		padding-left: 25px;
		width: 100%;

		@include media-breakpoint-up(xxl) {
			width: 95%;
			overflow: hidden;
			gap: 30px;

		}
	}

	.slider-thumbnail {
		border: 1px solid #EEE;
		border-radius: 10px;
		cursor: pointer;
		width: 80px;
		height: 80px;
		@include media-breakpoint-up(xl) {
			width: 85px;
			height: 85px;
		}
			@include media-breakpoint-up(xxl) {
			width: 100px;
		}

		:deep(.image-wrapper) {
			display: flex;
			justify-content: center;
			align-items: center;
			width: inherit;
			height: 100%;
		}

		&.active {

		}
	}
}

::v-deep(img),
::v-deep(.image-wrapper) {
	pointer-events: none;
}

</style>
