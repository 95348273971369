<template>
	<div class="card" v-custom-background="getBackgroundImage">
		<div class="card-inner">
			<cms-text :value="`${prefix}.title`" :props="{tag: 'h3', uppercase: true, weight: 'semibold'}"/>

			<div class="card-buttons">
				<base-button
					v-for="item in card.buttons"
					:key="item.button.content"
					:to="item.button.to"
					:params="item.button.params"
					:title="item.button.title"
					button_type="secondary"
				>
					{{item.button.content}}
				</base-button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		card: Object,
		prefix: String
	},
	computed: {
		getBackgroundImage () {
			if (this.card.bgImg) {
				return {
					src: this.card.bgImg.path ? this.card.bgImg.path : '',
					breakpoints: {
						base: { width: 330, height: 405 }
					}
				}
			} else return ''
		}
	},

	methods: {
		convertNewButtonToOld (newButton) {
			return {
				content: newButton.button.content,
				button_type: 'secondary',
				value: newButton.button.data?.alias,
				type: newButton.type,
				title: newButton.title
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.card {
	:deep(.ql-editor) {
		padding:0;
	}
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	&-inner {
		display:flex;
		flex-direction: column;
		justify-content: center;
		align-items:center;
		padding: 3rem;
		border-radius: 1rem;

		@include media-breakpoint-up(sm) {
			padding: 3rem .5rem;
		}

		@include media-breakpoint-up(lg) {
			padding: 3rem;
		}

		@include media-breakpoint-up(xl) {
			padding: 3rem 1rem;
		}
		@include media-breakpoint-up(xxl) {
			padding: 3rem;
		}
		:deep(h3) {
			color: $primary-product-title;
			margin-bottom: 3rem;
			font-size: 2.3rem;

			@include media-breakpoint-up(lg) {
				font-size: 2.6rem;
			}

		}
		.card-buttons {
			display: flex;
			justify-content: center;
			flex-direction: column;
			align-items: center;
			gap: 20px;
		}
	}
}
</style>
