var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"presentation"},[_c('div',{staticClass:"presentation-inner"},_vm._l((_vm.gifs),function(item,i){return _c('cms-image',{key:item.gif.path,class:{
				'presentation-gif': true,
				active: i === _vm.currentIndex
			},attrs:{"src":{
				path: item.gif.path,
				alt: 'WiMount system presentation',
				title: `WiMount system presentation of ${_vm.productName}`
			}}})}),1),_c('div',{staticClass:"presentation-buttons"},_vm._l((_vm.gifs),function(item,i){return _c('base-link',{key:item.gif.path + i,attrs:{"href":"#","button_type":i === _vm.currentIndex ? 'primary' : 'secondary'},on:{"click":function($event){return _vm.setActive(i)}}},[_vm._v(" "+_vm._s(_vm.lang.mounting_presentation[item.direction])+" ")])}),1)])
}
var staticRenderFns = []

export { render, staticRenderFns }