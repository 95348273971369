<template>
		<div class="copyright">
			<cms-text value="copyright_text" :props="{ color: 'secondary', tag: 'span'}" layout/>
			<span> - Created by:</span>
			<base-link underline type="gold" url="https://www.rekinysukcesu.pl/">
				Marketing Agency
			</base-link>
		</div>
</template>
<script>

export default {
	props: {
		link: {
			type: String
		}
	}
}
</script>
<style lang="scss" scoped>
.copyright {
	margin-top: auto;
	padding-top: 20px;
	padding-right: 80px;

	@include media-breakpoint-up(lg) {
		padding-right: 0;
	}

	span {
		font-size: 1.4rem;
		font-weight: 500;
		letter-spacing: .01rem;
		line-height: 22px;
		@include media-breakpoint-up(xl) {
			font-size: 1.8rem;
			line-height: 24px;
		}
	}

	& * {
		display: inline;
		text-align: left;
	}

	& a {
		color: $primary;
		text-transform: initial;
		@include media-breakpoint-up(xl) {
			font-size: 1.8rem;
		}
	}
}
</style>
