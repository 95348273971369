import Cookies from 'js-cookie'
import { ReactiveClass } from '@f/core/ReactiveClass'

class WebsiteLanguageManager extends ReactiveClass {
	static create (app) {
		const instance = new WebsiteLanguageManager(app)
		return instance
	}

	constructor (app) {
		super()
		this.app = app
	}

	initialLanguage

	get defaultCmsLanguage () {
		return this.app.settings.general.languages.defaultCmsLanguage
	}

	get languageSelect () {
		return document.querySelector('.goog-te-combo') || null
	}

	setInitialLanguage () {
		try {
			this.initialLanguage = Cookies.get('googtrans').split('/')[2]
		} catch (e) {
			this.initialLanguage = this.app.language
		}
	}

	initTranslator () {
		if (window.google && window.google.translate) return

		this.setInitialLanguage()
		this.app.on('language:change', languageAlias => this.changeLanguage(languageAlias))

		return new Promise(resolve => {
			window.googleTranslateElementInit = () => new window.google.translate.TranslateElement({ pageLanguage: 'en' }, 'app')

			const script = document.createElement('script')
			document.body.appendChild(script)
			script.onload = resolve
			script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
		})
	}

	changeLanguage (languageAlias) {
		if (languageAlias === this.app.language) {
			this.showOriginalContent()
			return
		}

		const event = new Event('change')
		if (this.languageSelect) {
			this.languageSelect.value = languageAlias
			this.languageSelect.dispatchEvent(event)
		} else {
			setTimeout(() => {
				this.languageSelect.value = languageAlias
				this.languageSelect.dispatchEvent(event)
			}, 300)
		}
	}

	showOriginalContent () {
		const iframe = document.getElementById(':1.container')
		if (!iframe) return

		const innerDoc = iframe.contentDocument || iframe.contentWindow.document
		const restoreEl = innerDoc.getElementById(':1.restore')
		restoreEl.click()
	}
}

export { WebsiteLanguageManager }
