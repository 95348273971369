
class Directionality {
	static async fetch ({ app }) {
		const service = app.getService('rext')
		const response = await service.getDirectionalities()

		if (response.error) return []
		return response
	}
}

export { Directionality }
