<template>
	<section class="use-cases">
		<base-container>
			<custom-title
				v-if="value.section_title"
				:title="`${prefix}.section_title.title`"
				:subtitle="`${prefix}.section_title.subtitle`"
				:header_tag="value.section_title?.title_tag"
				:color="value.section_title.title_color"
				:transform="value.section_title.title_transform"
				:type="value.section_title_type"
			/>
			<div class="wrapper">

				<use-case-single
					v-for="(single, i) in value.list"
					:key="i"
					:use-case="single"
					:article="articles.find((item) => item.id === single.item.id)"
					:productName="pageProduct?.name"
				/>
			</div>
		</base-container>
	</section>
</template>

<script>
import BaseContainer from '~/website/front/components/atoms/BaseContainer/BaseContainer'
import CustomTitle from '~/website/front/components/atoms/CustomTitle/CustomTitle'
import UseCaseSingle from '~/catalog/front/sections/UseCases/components/UseCaseSingle.vue'
import { productSectionMixin } from '~/catalog/front/sections/product-section.mixin'

export default {
	components: {
		UseCaseSingle,
		BaseContainer,
		CustomTitle
	},
	mixins: [productSectionMixin],
	props: {
		prefix: String,
		value: Object
	},
	data () {
		return {
			articles: []
		}
	},

	methods: {
		async getArticlesById () {
			const service = await this.$app.getService('rext')
			const params = this.value.list.map(item => item.item.id).filter((item) => !!item)
			if (!params.length) return []
			return await service.getArticlesById({
				where: {
					id: {
						$sql: 'in',
						param: params
					}
				}
			})
		}
	},
	async prefetch () {
		if (this.articles.length) return

		const service = await this.$app.getService('rext')
		this.articles = await service.getArticlesById()
	}
}

</script>

<style lang="scss" scoped>
.use-cases {
	@include spacing-section();
	background-color: $white;

	.wrapper {
		display: grid;
		justify-content: center;
		gap: 1.5rem;

		@include media-breakpoint-up(sm) {
			grid-template-columns: 1fr 1fr;
		}
		@include media-breakpoint-up(lg) {
			grid-template-columns: 1fr 1fr 1fr;
		}
	}
}
</style>
