import component from '~/blog/front/components/ArticleList/ArticleList.vue'
import { AbstractArticleListPage } from '../../AbstractArticleListPage/AbstractArticleListPage'
import { Article } from '~/blog/front/core/entities/Article'

class ArticleListCategoryPage extends AbstractArticleListPage {
	static alias = 'article-list-category'
	component = component
	loading = true

	get showArticleInital () {
		return this.value.articlePerPage || 2
	}

	async afterRedirect () {
		try {
			await super.afterRedirect()
			await this.fetchArticles()
		} catch {
			return this.app.vue.$redirect({ path: '/404' })
		}
	}

	async fetchArticles () {
		const category = this.articleCategories.find(category => category.alias === this.route?.params?.category)
		const fetchOptions = {
			app: this.app,
			take: this.showArticleInital,
			skip: this.currentPage * this.showArticleInital - this.showArticleInital,
			where: {
				category: category.id,
				language: this.app.language
			}
		}
		const { numberOfAllArticles } = await Article.fetch(fetchOptions)
		this.numberOfPages = Math.ceil(numberOfAllArticles / this.showArticleInital)
	}
}

export { ArticleListCategoryPage }
