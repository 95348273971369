<template>
	<section class="company-details">
		<base-container>
			<div class="company-details-inner">
				<company-details-map />
				<company-details-content :data="settingsData" />
			</div>
		</base-container>
	</section>
</template>

<script>
import CompanyDetailsContent from '~/website/front/core/pages/ContactPage/components/CompanyDetailsContent.vue'
import CompanyDetailsMap from '~/website/front/core/pages/ContactPage/components/CompanyDetailsMap.vue'

export default {
	components: { CompanyDetailsMap, CompanyDetailsContent },
	props: {
		translate: Object,
		settingsLangs: Object,
		pageLangs: Object
	},
	computed: {
		headquaters () {
			return `${this.settingsLangs.streetAddress},<br>${this.settingsLangs.postalCode} ${this.settingsLangs.addressLocality}`
		},

		settingsData () {
			return this.$app.settings?.general?.contact
		}
	}
}
</script>

<style lang="scss" scoped>
.company-details {

	.company-details-inner{
		@include media-breakpoint-up(lg) {
			display: grid;
			grid-template-columns: 50% 50%;
		}
		@include media-breakpoint-up(xl) {
			grid-template-columns: 45% 55%;
		}
		@include media-breakpoint-up(xxl) {
			grid-template-columns: 5fr 7fr;
		}
	}
}
</style>
