const paddingSectionMixin = {
	computed: {
		padding () {
			return this.value?.padding
		}
	}
}

export {
	paddingSectionMixin
}
