import { render, staticRenderFns } from "./BaseLinkWebComponent.vue?vue&type=template&id=c3b8e702&shadow"
import script from "./BaseLinkWebComponent.vue?vue&type=script&lang=js&shadow"
export * from "./BaseLinkWebComponent.vue?vue&type=script&lang=js&shadow"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports