import { Input } from '@f/core/forms/inputs/Input'
import InputFileComponent from '~/website/front/components/molecules/fileds/FieldFile/FieldFile'

class InputFile extends Input {
	component = InputFileComponent
	type = 'file'

	constructor ({ multiple, ...params }) {
		super(params)
		this.multiple = multiple
	}
}

export { InputFile }
