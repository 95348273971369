<template>
	<section class="text-content" :class="setMarginClasses">
		<base-container>
			<div class="text-content-inner">
				<custom-title
					v-if="value.section_title"
					:title="`${prefix}.section_title.title`"
					:subtitle="`${prefix}.section_title.subtitle`"
					:header_tag="value.section_title?.title_tag"
					:color="value.section_title.title_color"
					:transform="value.section_title.title_transform"
					:type="value.section_title_type"
				/>
				<cms-text
					v-if="value.section_description"
					:value="`${prefix}.section_description`"
					:props="{variant: 'paragraph', tag: 'div'}"
					class="paragraph"
				/>
			</div>
		</base-container>
	</section>
</template>

<script>
import BaseContainer from '~/website/front/components/atoms/BaseContainer/BaseContainer'
import CustomTitle from '~/website/front/components/atoms/CustomTitle/CustomTitle'

export default {
	components: { CustomTitle, BaseContainer },
	props: {
		prefix: String,
		value: Object
	},
	computed: {
		setAlignment () {
			if (this.value.alignment === 'right') {
				return 'flex-end'
			} else if (this.value.alignment === 'left') {
				return 'flex-start'
			} else if (this.value.alignment === 'center') {
				return 'center'
			} else {
				return 'center'
			}
		},

		setMarginClasses () {
			if (!this.value.padding) return ''

			const classes = {}
			this.value.padding.forEach(item => {
				classes[`margin-${item}`] = true
			})
			return classes
		}
	}
}
</script>

<style scoped lang="scss">
.text-content {

	&.margin-top {
		margin-top: 4rem;

		@include media-breakpoint-up(xl) {
			margin-top: 6rem;
		}
	}

	&.margin-bottom {
		margin-bottom: 4rem;

		@include media-breakpoint-up(xl) {
			margin-bottom: 6rem;
		}
	}

	&.margin-middle-top {
		margin-top: 3rem;
	}

	&.margin-middle-bottom {
		margin-bottom: 3rem;
	}

	.text-content-inner {
		.section-title {
			margin-bottom: 30px;
		}
	}

	:deep(a) {
		@include link_styles();
	}

	:deep(h3) {
		@include h3_styles();
	}

	:deep(p) {
		direction: ltr;
	}
}
</style>
