export function rextServiceExtend (Service) {
	return class RextService extends Service {
		async sendContactFormMessage (data) {
			const { attachments = [], police, ...restOfData } = data
			const formData = new FormData()

			Object.entries(restOfData).forEach(([key, value]) => {
				formData.append(key, value)
			})
			for (let i = 0; i < attachments.length; i++) {
				formData.append(`attachments${i}`, attachments[i])
			}

			const http = await this.getHttpWithRecaptcha()
			const { status } = await http.post('/contact-form-message', formData, {
				headers: { 'content-type': `multipart/form-data; boundary=${formData._boundary}` }
			})

			if (status !== 201) return { error: true }
			return status
		}

		async sendSupportFormMessage (data) {
			const { attachments = [], police, ...restOfData } = data
			const formData = new FormData()

			Object.entries(restOfData).forEach(([key, value]) => {
				formData.append(key, value)
			})
			for (let i = 0; i < attachments.length; i++) {
				formData.append(`attachments${i}`, attachments[i])
			}

			const http = await this.getHttpWithRecaptcha()
			const { status } = await http.post('/support-form-message', formData, {
				headers: { 'content-type': `multipart/form-data; boundary=${formData._boundary}` }
			})

			if (status !== 201) return { error: true }
			return status
		}
	}
}
