var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"modal",staticClass:"product-slider-modal",class:{opened: _vm.isOpened},on:{"click":function($event){$event.stopPropagation();return _vm.close.apply(null, arguments)}}},[_c('div',{staticClass:"slider-button-wrapper slide-left-btn"},[_c('div',{staticClass:"slide-left-btn transparent-btn",on:{"click":_vm.prevImage}},[_c('base-svg',{staticClass:"slider-btn-icon btn-left",attrs:{"name":"modal_product_arrow"}})],1)]),_c('div',{staticClass:"slider"},[_c('div',{ref:"closeBtn",staticClass:"close",on:{"click":function($event){$event.stopPropagation();return _vm.close.apply(null, arguments)}}},[_c('base-svg',{staticClass:"btn-close",attrs:{"name":"close_modal_product"}})],1),(_vm.showThumbnails)?_c('div',{staticClass:"thumbnails"},_vm._l((_vm.slides),function(slide,i){return _c('figure',{key:slide.image.path,staticClass:"thumbnail",class:{active: i === _vm.index},on:{"click":function($event){return _vm.goTo(i)}}},[_c('cms-image',{staticClass:"slide-product-img",attrs:{"src":{
						alt: _vm.product.name,
						path: slide.image?.path,
						title: `Product - ${_vm.product.name}`
					},"base":{height: 30, width: 30},"md":{height: 44, height: 76}}})],1)}),0):_vm._e(),_c('cms-image',{staticClass:"slide-product-img landscape-image",attrs:{"position":"","src":{
				alt: _vm.product.name,
				path: _vm.currentSlide.image?.path,
				title: `Product - ${_vm.product.name}`
			}}})],1),_c('div',{staticClass:"slider-button-wrapper slide-right-btn"},[_c('div',{staticClass:"slide-right-btn transparent-btn",on:{"click":_vm.nextImage}},[_c('base-svg',{staticClass:"slider-btn-icon btn-right",attrs:{"name":"modal_product_arrow"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }