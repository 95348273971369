var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid-items-wrapper",style:({gridTemplateAreas: _vm.gridArea})},_vm._l((_vm.items),function(item,i){return _c('div',{key:item.alias,staticClass:"grid-item",class:{
			small: _vm.isTemplateSmall(item),
			hasDescBottom: _vm.hasItemDescBottom(item),
			hasDescTop: _vm.hasItemDescTop(item)
		},style:({gridArea: item.alias})},[(_vm.isTemplateDefault(item))?_c('div',{staticClass:"item-inner"},[(item.data.image)?_c('cms-image',{key:i,attrs:{"value":`${_vm.prefix}.grid_builder.items.${i}.data.image`,"src":{
					alt: `Parts of ${_vm.productName}`,
					path: item.data.image.path,
					title: `In the box ${_vm.productName}`
				},"lazy":""}}):_vm._e(),(item.data.text_top)?_c('base-font',{staticClass:"text-top",attrs:{"variant":"grid-item-desc"}},[_vm._v(" "+_vm._s(item.data.text_top)+" ")]):_vm._e(),(item.data.text_bottom)?_c('base-font',{staticClass:"text-bottom",attrs:{"variant":"grid-item-desc-bottom"}},[_vm._v(" "+_vm._s(item.data.text_bottom)+" ")]):_vm._e()],1):(_vm.isTemplateSmall(item))?_c('div',{staticClass:"item-inner"},[_c('div',{staticClass:"img-bottom-wrapper"},[(item.data.image)?_c('cms-image',{key:i,attrs:{"value":`${_vm.prefix}.grid_builder.items.${i}.data.image`,"src":{
					alt: `Parts of ${_vm.productName}`,
					path: item.data.image.path,
					title: `In the box ${_vm.productName}`
				},"lazy":""}}):_vm._e(),(item.data.text_bottom)?_c('base-font',{staticClass:"text-bottom",attrs:{"variant":"grid-item-desc-bottom"}},[_vm._v(" "+_vm._s(item.data.text_bottom)+" ")]):_vm._e()],1),(item.data.text_top)?_c('base-font',{staticClass:"text-top",attrs:{"variant":"grid-item-desc"}},[_vm._v(" "+_vm._s(item.data.text_top)+" ")]):_vm._e()],1):_vm._e()])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }