<template>
	<div class="testimonial">
		<div class="testimonial-wrapper">
			<base-font tag="h2" variant="h2" uppercase>{{opinion.title}}</base-font>
			<base-font tag="span">{{opinion.subtitle}}</base-font>
			<cms-text :props="{tag: 'div'}" :value="opinion.description" use-string />
			<base-link
				v-if="opinion.pageLink?.to || opinion.pageLink?.url"
				v-bind="opinion.pageLink"
				class="testimonial-button"
				button_type="primary"
			>
				{{opinion.pageLink.content}}
			</base-link>
		</div>
		<cms-image
			class="testimonial-image"
			:src="{
				alt: `Context image for review - ${opinion.title}`,
				title: `We help our customers with different difficulty problems - ${opinion.title}`,
				path: opinion.image.path
			}"
			:base="{width: 349, height: 149}"
			:sm="{width: 496, height: 210}"
			:md="{width: 706, height: 300}"
			:lg="{width: 886, height: 380}"
		/>
	</div>
</template>

<script>
import BaseFont from '~/website/front/components/atoms/BaseFont/BaseFont.vue'

export default {
	components: { BaseFont },
	props: {
		opinion: {
			type: Object,
			default: () => ({
				title: '',
				subtitle: '',
				description: '',
				image: { path: '', alt: '', title: '' },
				pageLink: null
			})
		}
	},
	computed: {
		lang () {
			return this.$app.translator.get('opinions')
		}
	},
	methods: {
		onOpinionClick (e) {
			e.preventDefault()
			if (this.opinion.article) this.$router.push(this.opinion.article)
		}
	},
	async prefetch () {
		await this.$app.translator.prefetch('opinions')
	}
}
</script>

<style lang="scss" scoped>

:deep(.base-skeleton) {
	height: 100px;
	width: 100%;
	margin-top: 1rem;
	margin-bottom: 1rem;
	@include media-breakpoint-up(xl) {
		margin-top: 4rem;
		margin-bottom: 0;
	}
}

.testimonial {
	overflow: hidden;
	background: #FFFFFF;
	border-left: .4rem solid $primary;
	border-radius: 1rem;
	padding-left: 1rem;
	padding-top: 2rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;

	@include media-breakpoint-up(xl) {
		flex-direction: row;
		padding-left: 4rem;
		padding-top: 0;
	}

	&-button {
		margin-top: 20px;
	}

	&-wrapper {
		width: 100%;
		padding-right: 10px;
		@include media-breakpoint-up(xl) {
			padding: 30px 0;
			max-width: 430px;
		}
		@include media-breakpoint-up(xxl) {
			max-width: 530px;
		}
	}

	&-image {
		display: flex;
		justify-content: flex-end;
		width: 100%;

		@include media-breakpoint-up(md) {
			height: 100%;
		}

		:deep(img) {
			height: 100%;
			max-width: 100%;
			margin-top: 1rem;

			@include media-breakpoint-up(lg) {
				margin-top: 0;
			}

			@include media-breakpoint-up(xxl) {
				min-width: 886px;
			}
		}
	}

	:deep(span) {
		margin-top: 1rem;
		color: $secondary;
		opacity: .3;
	}

	:deep(.fstyle-paragraph) {
		padding: 0;
		margin-top: 1rem;
		margin-bottom: 1rem;
		@include media-breakpoint-up(xl) {
			margin-top: 4rem;
			margin-bottom: 0;
		}

		p {
			font-style: italic;
			letter-spacing: 0.01em;
			line-height: 2.4rem;
			color: $secondary;
			font-family: $secondary-font;
		}

	}
}

</style>
