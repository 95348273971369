<template>
	<div class="navbar-wrapper" :class="{'blue-bg': shouldBgBeBlue}" v-custom-background="'homepage/slider/slider_bg_1.jpg'">
		<div class="navbar">
			<base-link
				to="homepage"
				:title="title"
				class="navbar-logo"
			>
				<base-svg name="logo" class="logo-icon"/>
			</base-link>
			<nav class="nav">
				<ul class="nav-list">
					<menu-item
						v-for="item in items"
						:key="item.name"
						:item="item"
					/>
					<!-- Hide translations but not remove at all -->
					<base-translations v-if="false"/>
				</ul>
			</nav>
			<base-link
				class="navbar-button primary"
				to="where-to-buy"
				button_type="primary"
			>
				BUY
			</base-link>
		</div>
	</div>
</template>

<script>
import MenuItem from '~/website/front/components/organisms/BaseMenu/components/Desktop/MenuItem'
import BaseTranslations from '~/website/front/components/molecules/BaseTranslations/BaseTranslations'
import BaseSvg from '~/website/front/components/atoms/BaseSvg/BaseSvg'
export default {
	components: { BaseSvg, BaseTranslations, MenuItem },
	props: {
		items: Array,
		shouldBgBeBlue: Boolean,
		title: String
	}
}
</script>

<style lang="scss" scoped>
.navbar-wrapper {
	padding: 23px 30px;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 1001;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	@include transition();

	@include media-breakpoint-up(xl) {
		max-width: 100vw;
	}

	&:not(.active) {
		background: $white;
	}

	&.active {

		&:after{
			content: '';
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			z-index: -1;
			width: 100%;
			height: 100%;
			background-color: rgba(0,0,0, .5);
		}

		.navbar{
			padding-top: 20px;
			gap: 0;
		}

		.navbar-logo{
			::v-deep(.svg-icon) {
				width: 235px;
				svg {
					g:not(#Group_1586) > path {
						fill: $white;
					}
				}
			}
		}
	}

	&.blue-bg {
		background-color: #2092DD!important;

		:deep(.nav-list-item) {
			&.active-page {
				svg {
					#filled_area {
						fill: $white;
					}

					#transparent_area {
						fill: $white;
					}
				}
			}

			svg {
				#filled_area {
					fill: $white;
				}

				#transparent_area {
					fill: $primary;
				}
			}
		}

		:deep(.btn.primary.navbar-button){
			color: $secondary;
			background-color: $white;
		}

		:deep(.nav-title.show-title) {
			color: $white;
		}

		:deep(.nav-list){
			background-color: transparent;
		}

		:deep(.translate-choosen) {
			p {
				color: $white;
			}

			path {
				stroke: $white;
			}
		}

		:deep(#Group_1) {
			fill: $white;
		}

		:deep(#Group_1586 path) {
			fill: $white;
		}

		:deep(#path_7108) {
			fill: $white;
		}

		:deep(.nav-list-item .dropdown-arrow svg path) {
			stroke: $white;
		}
	}

	.navbar {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		gap: 30px;

		@include media-breakpoint-up(xxl) {
			gap: 60px;
		}

		&-logo {
			position: relative;
			z-index: 1;
			max-width: 210px;
			max-height: 40px;
			flex-basis: 10%;
			gap: 20px;

			.logo-icon {
				::v-deep(svg) {
					width: 100%;

					@include media-breakpoint-up(xl) {
						width: 210px;
					}

					path {
						@include transition();
					}
				}
			}
		}
		.nav {
			max-height: 100vh;
			top: 100%;
			left: 0;
			margin: 0;
			transition: .3s ease-in;
			z-index: 1;
			transform: translateX(0);
			grid-template-rows: 100px 1fr;
			width: auto;
			background: transparent;
			display: flex;
			align-items: center;
			flex-basis: 70%;
			padding-bottom: 0px;

			&.active {
				transform: translateX(0);
			}

			&-list {
				list-style-type: none;
				margin: 0;
				padding: 0;
				grid-row: 2;
				background: $white;
				align-items: center;
				display: flex;
				justify-content: space-between;
				width: 100%;
				overflow: visible;
				background: transparent;
			}
		}
		.navbar-button {
			padding: 10px 40px;
		}
	}

	.overlay {
		@include media-breakpoint-up(xl) {
			width: 100%;
			height: 100%;
			display: block;
			position: fixed;
			top: 85px;
			left: 0;
			z-index: -99;
			right: 0;
			background: rgba(0,0,0,.5);
			opacity: 0;
			@include transition();
		}

		&.active{
			opacity: 1;
			z-index: 99;
		}
	}
}
</style>
