<template>
	<base-link
		class="footer-button"
		v-bind="button"
	>
		{{button.content}}
	</base-link>
</template>

<script>
export default {
	props: {
		button: Object
	}
}
</script>
