<template>
	<div class="product-menu-section">
			<div class="menu-list">
				<product-menu-item
					v-for="(item, i) in filteredContent"
					:item="item"
					:activeSection="activeSection"
					:index="i"
					:key="`${item.section.alias}-${i}`"
				/>
			</div>
	</div>
</template>

<script>
import ProductMenuItem from '~/catalog/front/sections/ProductMenuSection/components/ProductMenuItem'
export default {
	components: { ProductMenuItem },
	data () {
		return {
			activeSection: 0,
			scrollY: 0
		}
	},
	props: {
		content: {
			type: Array,
			default: () => []
		}
	},
	computed: {

		hasRelatedProducts () {
			return this.$app.page.hasRelatedProducts || false
		},

		filteredContent () {
			// if (!this.$app.page.product?.relatedProducts.length) {
			// 	return this.content.filter(item => item.alias !== 'related_products_section')
			// } else if (this.$app.page.product?.relatedProducts.error) {
			// 	return this.content.filter(item => item.alias !== 'related_products_section')
			// }
			return this.content.map((section, index) => {
				if (section.alias === 'plots_section') return !section.value.should_show_in_menu ? null : { section, index }
				else if (section.alias === 'related_products_section') return !this.hasRelatedProducts ? null : { section, index }
				else return { section, index }
			}).filter(section => !!section)
		}
	},
	methods: {
		setActiveSection (index) {
			this.activeSection = index
		}
	},
	mounted () {
		this.scrollY = window.scrollY
		window.addEventListener('scroll', () => {
			this.scrollY = window.scrollY

			this.filteredContent.forEach((item) => {
				const section = document.querySelector(`[alias=${item.section.alias}_${item.index}]`)
				if (section && this.scrollY >= section.offsetTop) {
					this.setActiveSection(item.index)
					return false
				}
			})
		}, { passive: true })
	}
}

</script>

<style lang="scss" scoped>
.product-menu-section{
	overflow-x: auto;
	position: fixed;
	top: 70px;
	width: 100%;
	z-index: 10;
	padding: 12px 7%;
	background-color: $variants-gray;
	box-shadow: $base-shadow;

	&:after,
	&:before {
		position: fixed;
		display: block;
		top: 70px;
		bottom: 0;
		height: 46px;
		content: '';
		z-index: 5;
		width: 7%;
		@include media-breakpoint-up(xl) {
			display: none;
		}
	}

	&:after{
		right: 0;
		background: rgb(255,255,255);
		background: linear-gradient(280deg, rgba(238,238,238,1) 0%, rgba(238,238,238,.4) 100%);
	}

	&:before {
		left: 0;
		background: rgb(255,255,255);
		background: linear-gradient(90deg, rgba(238,238,238,1) 0%, rgba(238,238,238,.4) 100%);
	}

	@include media-breakpoint-up(xl) {
		top: 85px;
	}

	.menu-list{
		display: flex;
		gap: 30px;

		@include media-breakpoint-up(xl) {
			justify-content: center;
			gap: 0;
		}

		@include media-breakpoint-up(xxl) {
		}
	}
	:deep(.menu-item-text) {
		text-transform: capitalize;
		cursor: pointer;
		color: $secondary;
		opacity: .85;
		font-size: 17px;
		line-height: 22px;
		font-weight: 500;
		white-space: nowrap;
		z-index: -1;
		@include media-breakpoint-up(xl) {
			padding-left: 1rem;
			padding-right: 1rem;
			font-size: 14px;
		}

		@include media-breakpoint-up(xxl) {
			padding-left: 1rem;
			padding-right: 1rem;
			font-size: 17px;
		}
	}

	:deep(.product-menu-item) {
		&:last-child {
			padding-right: 20px;

			@include media-breakpoint-up(xxl) {
				padding-right: 0;
			}
		}
	}
}
</style>
