import { getQuery } from 'utils/query'

export function rextServiceExtend (Service) {
	return class RextService extends Service {
		getAxiosConfig () {
			const config = super.getAxiosConfig()
			config.headers['X-Language'] = this.app.language || null
			return config
		}

		async getPage (alias, queryObject) {
			let query = getQuery({
				relations: ['layout', 'type', 'type.layout']
			})
			if (queryObject) {
				query = getQuery({
					...queryObject,
					relations: ['layout', 'type', 'type.layout', ...queryObject.relations]
				})
			}
			const { data } = await this.http.get(`/page/${alias}${query}`)
			return data
		}

		async getLayout (alias) {
			const { data } = await this.http.get(`/layout/${alias}`)
			return data
		}

		async getLazyLoadImage (url, imageSize) {
			const query = getQuery({
				...imageSize
			})
			const path = url.replace(`${this.app.settings.env.origin}/api`, '') + query
			const { data } = await this.http.get(path, {
				responseType: 'arraybuffer'
			})
			return data
		}
	}
}
