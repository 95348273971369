<template>
	<div
		class="specification-tab"
		@click="emitChangeTab"
		:class="{'active': isActive}"
	>
		<base-font
			variant="specification-tab"
			weight="semibold"
			class="specification-tab-title"
		>
			{{ text }}
		</base-font>
	</div>
</template>

<script>
import BaseFont from '~/website/front/components/atoms/BaseFont/BaseFont'

export default {
	components: { BaseFont },
	props: {
		index: Number,
		text: String,
		activeTab: Number
	},
	computed: {
		isActive () {
			return this.index === this.activeTab
		}
	},
	methods: {
		emitChangeTab () {
			this.$emit('changeTab', this.index)
		}
	}
}
</script>

<style lang="scss" scoped>

.specification-tab {
	@include transition();
	position: relative;
	border-radius: 5px;
	padding: 2rem 4rem;
	cursor: pointer;

	&:before {
		content: '';
		position: absolute;
		display: block;
		top: 0;
		left: 0;
		height: 100%;
		width: 9px;
		background-color: transparent;
		@include transition
	}

	&.active {
		:deep(.specification-tab-title) {
			font-weight: 700;
			color: $primary!important;
		}

		&:before {
			background-color: $primary;
		}
	}

	.specification-tab-title {
		@include transition;
		font-size: 1.7rem;
		line-height: 22px;
	}
}
</style>
