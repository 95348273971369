<template>
	<div
		class="field-wrapper field-file"
		:info="input.info"
	>
		<base-font
			variant="label"
			color="secondary"
			:for="input.name"
			@click.stop="$emit('click')"
		>
			{{input.label}}:
		</base-font>
		<div class="field-label">
			<base-font
				variant="paragraph"
				class="extensions"
				:html="lang.acceptableExtensions"
			/>
			<base-button
				button_type="secondary"
				class="add-file-btn"
				@click="addFile"
			>
				<span class="fc-white ff-primary"> {{ lang.addAttachments }} </span>
			</base-button>
		</div>
		<div
			v-if="input.value?.length"
			class="files-list"
		>
			<div
				class="file"
				v-for="file in input.value"
				:key="file.name"
			>
				<base-button
					class="close-btn"
					:rightSlot="false"
					@click="removeFile(file)"
				>
					<base-svg class="icon" name="close"/>
				</base-button>
				<base-svg
					class="file-icon"
					name="file"
				/>
				<base-font
					class="file-name"
					tag="span"
					size="xxs"
				>
					{{ file.name }}
				</base-font>
			</div>
		</div>
		<base-error
			v-if="input.error"
			:error="input.error"
		/>
		<input
			class="input file-input"
			ref="fileInput"
			type="file"
			:multiple="input.multiple"
			@change="addFilesToList"
		>
	</div>
</template>

<script>
import mixin from '../utils/input.mixin'
import BaseError from '@f/components/BaseError'
import BaseSvg from '~/website/front/components/atoms/BaseSvg/BaseSvg'

export default {
	mixins: [mixin],
	components: {
		BaseSvg,
		BaseError
	},
	computed: {
		lang () {
			return this.$app.translator.get('forms').contactForm
		}
	},
	methods: {
		addFile () {
			this.$refs.fileInput.click()
		},
		removeFile (file) {
			this.input.value = this.input.value.filter(f => f !== file)
			this.$refs.fileInput.value = ''
		},
		async addFilesToList (event) {
			const filesData = event.target.files
			Array.isArray(this.input.value) ? this.input.value.push(...filesData) : this.input.value = [...filesData]
		}
	}
}
</script>

<style lang="scss" scoped src="../utils/fields.scss" />
<style lang="scss" scoped>
.field-wrapper {
	margin-bottom: 2rem;

	::v-deep .input {
		display: none;
	}

	.field-label {
		display: flex;
		margin-top: 1rem;
		gap: 20px;

		::v-deep label {
			margin-bottom: 0;
		}

		:deep(button) {
			border-color: $secondary;

			&:after {
				border-color: $white;
			}
		}
	}

	.files-list {
		display: flex;
		flex-wrap: wrap;
		gap: 1rem;
		.file {
			display: flex;
			flex-direction: column;
			gap: 1rem;
			min-width: 6rem;
			button {
				border-color: $white;
				position: relative;

				&:after {
					border-color: $white;
				}

				:deep(svg) {
					position: absolute;
					top: 0;
					right: 0;
					left: 0;
					bottom: 0;
					width: 12px;
					height: 12px;

				}
			}

			.svg-icon.file-icon {
				:deep(svg) {
					width: 100%;
					height: 100%;
				}
			}

			:deep(.file-name) {
				max-width: min-content;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				overflow: hidden;
				font-size: 14px;
			}
		}
	}

	.add-file-btn {
		margin-bottom: 1.5rem;
	}

	.close-btn {
		background: transparent;
		border: none;
		padding: 0;

		&:hover {
			padding: 0;
		}
	}

	.file-icon {
		width: 48px;
		height: 48px;
	}

	::v-deep .file-name {
		display: block;
	}

	.icon {
		width: 12px;
		height: 12px;
	}
}
::v-deep input {
	border: 0;
}
</style>
