<template>
	<li class="accordion-wrapper">
		<div
			v-if="$slots['accordion-trigger']"
			class="accordion-trigger accordion"
			:class="{'active': visible}"
			@click="open"
		>
			<button class="accordion-button" :class="{'open': visible}">
				<slot name="accordion-trigger"></slot>
				<base-svg class="accordion-arrow" name="arrow_down"/>
			</button>
		</div>
		<transition
			name="accordion"
			@enter="start"
			@after-enter="end"
			@before-leave="start"
			@after-leave="end"
		>
			<div v-if="$slots['accordion-content']" class="accordion-content-wrapper" v-show="visible">
				<div class="accordion-content">
					<slot name="accordion-content"></slot>
				</div>
			</div>
		</transition>
	</li>
</template>

<script>
export default {
	props: {},
	inject: ['Accordion'],
	data () {
		return {
			index: null
		}
	},
	computed: {
		visible () {
			return this.index === this.Accordion.active
		}
	},
	methods: {
		open () {
			if (this.visible) {
				this.Accordion.active = null
			} else {
				this.Accordion.active = this.index
			}
		},
		start (el) {
			el.style.height = el.scrollHeight + 'px'
		},
		end (el) {
			el.style.height = ''
		}
	},
	created () {
		this.index = this.Accordion.count++
	}
}
</script>

<style lang="scss" scoped>

.accordion-enter-active,
.accordion-leave-active {
	will-change: height, opacity;
	transition: height 0.3s ease, opacity 0.3s ease;
	overflow: hidden;
}

.accordion-enter,
.accordion-leave-to {
	height: 0 !important;
	opacity: 0;
}

.accordion {
	&-wrapper {
		margin-bottom: 2rem;

		.accordion-arrow {
			@include transition;
		}

		&:hover {

			.accordion-button {
				border-color: $primary;
			}
		}
	}
	&-button {
		padding: 1rem;
		outline: none;
		cursor: pointer;
		width: 100%;
		position: relative;
		border-radius: .3rem;
		background-color: $white;
		display: flex;
		justify-content: space-between;
		align-items: center;
		transition: .2s ease-in;
		border: 2px solid transparent;

		@include media-breakpoint-up(lg) {
			padding: 2rem 3rem;
		}

		:deep(p) {
			color: $secondary;
			line-height: 1.8rem;
			text-align: left;
			@include media-breakpoint-up(lg) {
				line-height: 2.3rem;
				text-align: center;
			}
		}
		:deep(.svg-icon) {
			flex-shrink: 0;
			position: relative;
			transition: .3s ease-in;
			pointer-events: none;
			margin-left: 2rem;
			height: 11px;
			width: 11px;
			transform: rotate(-90deg);

			@include media-breakpoint-up(lg) {
				margin-left: 0;
			}

		}

		.accordion-button__title{
			text-transform: uppercase;
			font-size: 14px;
			line-height: 22px;
			letter-spacing: 0.014em;
			@include media-breakpoint-up(xl) {
				font-size: 18px;
				letter-spacing: 0.018em;
				line-height: 24px;
			}

		}

		&.open {
			:deep(.svg-icon) {
				transform: rotate(90deg)!important;
			}

			.accordion-button__title{
				color: $primary;
			}
		}
	}
	&.active &-button {
		border-color: $primary;
	}
	&-content {
		:deep(ul) {
			font-size: 18px;
			line-height: 24px;
			letter-spacing: 0.018em;
		}

		:deep(a) {
			color: $primary;
		}
	}
	&-content-wrapper {
		transition: .5s ease-in-out;
		overflow: hidden;
		background: $white;
		& .accordion-content {
			padding: 1rem;
			background-color: #f6f6f6;
			font-weight:500;

			@include media-breakpoint-up(lg) {
				padding: 3rem;
			}

			:deep(p) {
				font-size: 14px;
				line-height: 22px;
				letter-spacing: 0.014em;
				@include media-breakpoint-up(xl) {
					font-size: 18px;
					line-height: 24px;
					letter-spacing: 0.018em;
				}
			}
		}
		.accordion-content-inner {
			padding: 3rem 4rem 4rem 3rem;
		}
	}
}
</style>
