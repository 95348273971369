<template>
	<div class="product-slider-modal" :class="{opened: isOpened}" ref="modal" @click.stop="close">
		<div class="slider-button-wrapper slide-left-btn">
			<div class="slide-left-btn transparent-btn" @click="prevImage">
				<base-svg class="slider-btn-icon btn-left" name="modal_product_arrow" />
			</div>
		</div>
		<div class="slider">
			<div class="close" ref="closeBtn" @click.stop="close">
				<base-svg class="btn-close" name="close_modal_product" />
			</div>
			<div class="thumbnails" v-if="showThumbnails">
				<figure
					class="thumbnail"
					:class="{active: i === index}"
					v-for="(slide, i) in slides"
					:key="slide.image.path"
					@click="goTo(i)"
				>
					<cms-image
						class="slide-product-img"
						:src="{
							alt: product.name,
							path: slide.image?.path,
							title: `Product - ${product.name}`
						}"
						:base="{height: 30, width: 30}"
						:md="{height: 44, height: 76}"
					/>
				</figure>
			</div>
			<cms-image
				class="slide-product-img landscape-image"
				position=""
				:src="{
					alt: product.name,
					path: currentSlide.image?.path,
					title: `Product - ${product.name}`
				}"
			/>
		</div>
		<div class="slider-button-wrapper slide-right-btn ">
			<div class="slide-right-btn transparent-btn" @click="nextImage">
				<base-svg class="slider-btn-icon btn-right" name="modal_product_arrow" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data () {
		return {
			index: 0,
			showThumbnails: false
		}
	},
	props: {
		slider: Object,
		isOpened: Boolean,
		product: Object
	},
	computed: {
		slides () {
			return this.slider.slides
		},
		currentSlide () {
			return this.slider.slides[this.index]
		}
	},
	methods: {
		nextImage () {
			this.goTo(this.index + 1)
		},
		prevImage () {
			this.goTo(this.index - 1)
		},
		goTo (index) {
			this.index = (index + this.slides.length) % this.slides.length
		},
		close (e) {
			if (e.target === this.$refs.modal || e.target === this.$refs.closeBtn) {
				this.$emit('closeModal')
			}
		}
	}
}
</script>

<style lang="scss" scoped>

.slider {
	background-color: white;
	width: 100%;
	height: 100%;
	.thumbnails {
		display: flex;
		position: absolute;
		bottom: 20px;
		left: 0;
		padding: 20px;
		width: 100%;
		flex-wrap: wrap;
		gap: 1rem;

		@include media-breakpoint-up(xl) {
			justify-content: center;
		}
	}
	.thumbnail {
		cursor: pointer;
		height: 45px;
		overflow: hidden;
		margin: 0;
		padding: 5px;
		opacity: .5;
		@include transition();

		&.active {
			opacity: 1;
		}

		@include media-breakpoint-up(md) {
			height: 60px;
		}

		@include media-breakpoint-up(xl) {
			height: 75px;
		}
	}
	.close {
		position: absolute;
		top: 20px;
		right: 20px;
		cursor: pointer;

		.btn-close {
			pointer-events: none;
			:deep(svg) {
				pointer-events: none;
				width: 23px;
				height: 23px;
			}
		}
	}
}
.product-slider-modal {
	display: flex;
	visibility: hidden;
	@include transition;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	opacity: 0;
	right: 0;
	justify-content: center;
	align-items: center;
	z-index: $level2;
	background-color: rgba($black, 0.35);
	backdrop-filter: blur(4px);

	&.opened {
		visibility: visible;
		opacity: 1;
	}

	::v-deep(.landscape-image img) {
		width: auto;
		height: auto;
		max-height: 90%;
		max-width: 90%;
		@media screen and (max-height: map-get($grid-breakpoints, sm)) and (orientation: landscape){
			height: 100vh;
		}

		@include media-breakpoint-up(xl) {
			max-width: 65%;
		}
	}

	.slider-button-wrapper {
		margin: 2rem 0;
		z-index: $level1;
		position: absolute;

		:deep(.svg-icon) {
			display: flex;
			align-items: center;
		}

	}
	.slide-left-btn {
		left: 5%;
		@include media-breakpoint-up(xl) {
			left: 15%;
		}

		.btn-left {
			:deep(svg) {
				height: 30px;
				width: 15px;
			}
		}
	}
	.slide-right-btn {
		right: 5%;
		@include media-breakpoint-up(xl) {
			right: 15%;
		}

		.btn-right{
			transform: rotate(180deg);

			:deep(svg) {
				height: 30px;
				width: 15px;
			}
		}
	}
	.transparent-btn {
		background: transparent;
		border: none;
		cursor: pointer;
		transition: 0.5s;

		@include media-breakpoint-up(xl) {
			&:hover {
				transition: 0.5s;
				transform: scale(1.2);
			}
		}

		.slider-btn-icon {
			width: 2em;
		}
	}

	::v-deep(.slide-product-img.landscape-image) {
		display: block;

		picture {
			overflow: visible;
		}
	}

	:deep(.image-wrapper) {
		width: 100%;
		height: 100%;

		picture {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 100%;
		}
	}
}
</style>
