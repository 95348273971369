<template>
	<div class="enclosure">
		<base-svg :name="item.card_icon.path" />
		<cms-text :value="`${prefix}.list.${index}.item`" :props="{color: 'white', tag: 'div'}"/>
		<div class="line">
			<div class="pulsatingCircle">
				<span class="firstCircle"></span>
				<span class="secondCircle"></span>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	props: {
		prefix: String,
		item: Object,
		index: Number
	}
}
</script>

<style lang="scss" scoped>
.enclosure {
	display: flex;
	gap: 2rem;
	align-items: center;
	border-radius: 1rem;
	border:2px solid white;
	padding: 2rem;
	background: linear-gradient(180deg, rgba(0,136,255,0.5214460784313726) 0%, rgba(6,139,255,0.5186449579831933) 100%);
	margin-bottom: 2rem;
	position:relative;
	@include transition;
	@include media-breakpoint-up(lg) {
		padding: 2rem;
	}
	@include media-breakpoint-up(xl) {
		max-width: 69rem;
		padding: 3rem;
	}

	&:nth-of-type(2) {
		.line {
			right: -40%;
			width: 40%;
		}
	}
	&:nth-of-type(3) {
		.line {
			right: -55%;
			width: 55%;
		}
	}
	&:nth-of-type(4) {
		.line {

			right: -60%;
			width: 60%;
		}
	}
	&:last-of-type {
		margin-bottom: 3rem;
	}
	:deep(p)  {
		letter-spacing: 0.02em;
		text-transform: uppercase;
		padding:0;
		@include media-breakpoint-up(lg) {
			font-size: 2.2rem;
			padding-left: 3rem;
		}
		strong {
			color:white;
		}
	}
	&:hover {
		transform: translateX(20px);
	}
	.line {
		@include transition;
		display: none;
		@include media-breakpoint-up(xl) {
			display: block;
			background: white;
			width: 50%;
			height: 2px;
			position: absolute;
			right: -50%;
			&:before {
				content:"";
				display: block;
				position: relative;
				top:-.5rem;
				left: -.4rem;
				width: 1rem;
				height: 1rem;
				border-radius: 50%;
				background: white;
			}
			.pulsatingCircle{
				@include transition;
				width:2.5rem;
				height:2.5rem;
				display:block;
				position:relative;
				margin-left: auto;
				margin-right: -4rem;
				margin-top: -2.1rem;
			}

			.firstCircle,
			.secondCircle,
			.thirdCircle{
				@include transition;
				display:block;
				border-radius:50%;
				vertical-align:middle;
			}

			.firstCircle{
				width:2.5rem;
				height:2.5rem;
				margin:-.5rem 0 0 -.5rem;
				background-color: transparent;
				border: .4rem solid $primary;
			}

			.secondCircle{
				opacity: 0;
				width:5rem;
				height:5rem;
				margin:-3.7rem 0 0 -1.8rem;
				border:.2rem solid $primary;
			}
		}
	}

}

@keyframes pulseIn{
	0%{
		opacity:0;
		transform:scale(0.2,0.2);
		border:1rem solid $primary;

	}
	25%{
		opacity:.3;
	}
	50%{
		opacity:1;
		transform:scale(1.2,1.2);
		border:.2rem solid $primary;
	}
	75%{
		opacity:.3;
	}
	100%{
		opacity:0;
		transform:scale(0.2,0.2);
		border:1rem solid $primary;

	}
}

</style>
