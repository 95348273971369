<template v-once>
	<section class="means-section" v-custom-background="getBackgroundImage">
		<base-container>
			<div class="means__inner">
                <base-svg v-if="value.section_logo" :name="value.section_logo.path"/>
                <cms-text class="means__description" :value="`${prefix}.description`" :props="{tag: 'h3'}" />
				<div class="means__items-wr" v-if="value.cards.length !== 0">
					<means-card
						v-for="(item, i)  in value.cards"
						:key="i"
						:card="item"
						:prefix="`${prefix}.cards.${i}`"
					/>
				</div>
				<div class="buttons-wrapper">
					<base-link v-bind="value.button">
						<base-font variant="button-text">
							{{ value.button && value.button.content }}
						</base-font>
					</base-link>
				</div>

			</div>
		</base-container>

	</section>
</template>

<script>
import MeansCard from './components/MeansCard'
export default {
	components: {
		MeansCard
	},
	props: {
		prefix: String,
		value: Object
	},
	computed: {
		getBackgroundImage () {
			const path = this.value.image.path
			return {
				src: path,
				breakpoints: {
					base: { width: 600, height: 900 },
					lg: { width: 1920, height: 683 }
				}
			}
		}
	}
}

</script>

<style lang="scss" scoped>
	.means-section {
		background-color: #1a5ced;
		background-repeat: no-repeat;
        overflow: hidden;
		padding: 6rem 0;
		position: relative;
		background-size: cover;

		@include media-breakpoint-up(lg) {
			padding: 10rem 0;
		}

		.means__inner {
            position: relative;
            z-index: 1;
			color: $white;

            .svg-icon{
                display: flex;
                justify-content: center;
            }

            .means__description{
                color: $white;
                text-align: center;
                margin-top: 10px;
                font-size: 16px;
                line-height: 21px;
                letter-spacing: $letter-spacing;
            }

            .means__items-wr{
                margin-top: 20px;
                display: grid;
                gap: 30px;
                grid-template-columns: repeat(1, 1fr);
                @include media-breakpoint-up(sm){
                    grid-template-columns: repeat(2, 1fr);
                }

				@include media-breakpoint-up(lg) {
					margin-top: 75px;
				}

                @include media-breakpoint-up(xxl) {
                    grid-template-columns: repeat(4, 1fr);
                }

				.base-animation-content-wrapper {
					&:nth-child(3) {

						:deep(.means__item){
							h4 {
								@include media-breakpoint-up(xl) {
									font-size: 41px;
									line-height: 56px;
								}

							}
						}
					}
				}
            }
		}

        .buttons-wrapper{
			margin-top: 40px;
			display: flex;
            align-items: center;
            justify-content: center;
            @include media-breakpoint-up(lg) {
				margin-top: 60px;
            }
        }
        ::v-deep(.btn) {
            color: $white;
            border-color: $white;

            &:after{
                border-color: $white;
            }
        }
	}

</style>
