import path from 'path'

const required = getMsg => ({
	validate: value => !!value,
	message: () => getMsg || window.app.translator.get('errors').required
})
const min = (nbr, msg) => ({
	validate: value => value.length >= nbr,
	message: () => msg
})
const max = (nbr, msg) => ({
	validate: value => value.length <= nbr,
	message: () => msg
})
const minInteger = (nbr, msg) => ({
	validate: value => Number(value) >= nbr,
	message: () => msg + nbr
})
const maxInteger = (nbr, msg) => ({
	validate: value => Number(value) <= nbr,
	message: () => msg + nbr
})
const minNumber = (nbr, msg) => ({
	validate: value => parseFloat(value.replace(',', '.')) >= parseFloat(nbr),
	message: () => msg
})
const maxNumber = (nbr, msg) => ({
	validate: value => parseFloat(value.replace(',', '.')) <= parseFloat(nbr),
	message: () => msg
})
const pesel = msg => ({
	validate: pesel => {
		const arr = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3]
		let sum = 0

		for (let i = 0; i < 10; i++) sum += arr[i] * pesel[i]

		sum = sum % 10 === 0 ? 0 : 10 - sum % 10
		return sum === +pesel[10]
	},
	message: () => msg
})
const email = getMsg => ({
	validate: value => {
		const email = String(value).toLowerCase()
		// eslint-disable-next-line no-useless-escape
		var regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
		return regexp.test(email)
	},
	message: () => getMsg ? getMsg() : window.app.translator.get('errors').email
})
const minAge = msg => ({
	validate: value => {
		const birthday = new Date(value).getTime()
		const now = new Date().getTime()
		const i18yo = 18 * 365.25 * 24 * 60 * 60 * 1000
		return birthday < now - i18yo
	},
	message: () => msg
})
const dateMinNow = msg => ({
	validate: value => {
		const date = new Date(value).getTime()
		const now = new Date().getTime()
		return date > now
	},
	message: () => msg
})
const nrb = msg => ({
	validate: nrb => {
		var weight = [1, 10, 3, 30, 9, 90, 27, 76, 81, 34, 49, 5, 50, 15, 53, 45, 62, 38, 89, 17, 73, 51, 25, 56, 75, 71, 31, 19, 93, 57]

		nrb = nrb + '2521' // PL
		nrb = nrb.substr(2) + nrb.substr(0, 2)
		var Z = 0
		for (var i = 0; i < 30; i++) {
			Z += nrb[29 - i] * weight[i]
		}
		if (Z % 97 === 1) return true
		else return false
	},
	message: () => msg
})

const phoneNumber = msg => ({
	validate: value => !!value && value.length >= 11,
	message: () => msg
})

const extensions = (extensions, msg) => ({
	validate: value => {
		if (value && value.length) {
			const isValid = value.map(file => extensions.includes(path.extname(file.name))).every(f => !!f)
			return isValid
		} else {
			return true
		}
	},
	message: () => msg || window.app.translator.get('errors.invalid_extension')
})

const isNumber = msg => ({
	validate: value => {
		return !isNaN(value)
	},
	message: () => msg || 'Proszę wpisać poprawny format'
})

export {
	required,
	min,
	max,
	pesel,
	email,
	minAge,
	dateMinNow,
	nrb,
	minNumber,
	maxNumber,
	phoneNumber,
	minInteger,
	maxInteger,
	extensions,
	isNumber
}
