import { Page } from '~/cms/front/core/pages/Page'
import Component from './ContactPage.vue'
import { ContactSeo } from '~/cms/front/core/seo/ContactSeo'

class ContactPage extends Page {
	static alias = 'contact'
	component = Component

	setConstructorData ({ app, route, from }) {
		this.app = app
		this.route = route
		this.from = from
		this.seo = new ContactSeo(this.app, { page: this })
	}

	get header () {
		if (!this.value.title) return false
		return {
			title: this.value.title
		}
	}
}

export { ContactPage }
