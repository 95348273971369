import { render, staticRenderFns } from "./FieldCustomSelect.vue?vue&type=template&id=889898b4&scoped=true"
import script from "./FieldCustomSelect.vue?vue&type=script&lang=js"
export * from "./FieldCustomSelect.vue?vue&type=script&lang=js"
import style0 from "./FieldCustomSelect.vue?vue&type=style&index=0&id=889898b4&prod&lang=scss&scoped=true"
import style1 from "../utils/fields.scss?vue&type=style&index=1&id=889898b4&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "889898b4",
  null
  
)

export default component.exports