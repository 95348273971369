<template>
	<footer class="footer">
		<base-container>

			<div class="footer__inner">
				<div class="footer__block">
					<div class="footer-bg">
						<cms-image
							class="footer-bg-image"
							:src="footerData.footer_bg"
							lazy
							:base="{ width: 1920 }"
							:xl="{ width: 1080 }"
						/>
					</div>
					<div class="footer__block-logo-wr">
						<base-svg class="footer-logo" name="logo"/>
						<base-font class="footer__block-logo-text">{{ footerData.logoSubscribe || '' }}</base-font>
					</div>
					<div class="footer__block-text">
						<base-font weight="bold" color="secondary">
							{{ footerData.pageName }}
						</base-font>
						<base-font color="secondary">
							{{ footerData.streetAddress }} <br/>
							{{ footerData.postalCode }}, {{ footerData.addressLocality }}
						</base-font>
					</div>
					<div class="footer__block-text">
						<base-font v-if="footerData.vatid" variant="paragraph" color="secondary"><span>VAT ID:</span> {{footerData.vatid}}</base-font>
						<base-font v-if="footerData.regon" variant="paragraph" color="secondary"><span>REGON:</span> {{footerData.regon}}</base-font>
						<base-font v-if="footerData.krs" variant="paragraph" color="secondary"><span>KRS:</span> {{ footerData.krs}}</base-font>
						<base-font v-if="footerData.EORI" variant="paragraph" color="secondary"><span>EORI:</span> {{ footerData.EORI}}</base-font>
					</div>
					<base-copyright class="default-layout-footer" link="https://www.rekinysukcesu.pl/"/>
				</div>

				<div class="footer__block">
					<div class="footer__block-info-wr">
						<div class="footer__block-info-wr-title" v-html="footerData.numbersSubscribe"/>
						<div class="footer__links-wr">
							<base-icon-link
								v-for="link in footerData.numbers"
								:icon="'phone_footer'"
								:type="'number'"
								:link="link.numer"
								:key="link.numer"
							/>
							<base-icon-link
								v-for="link in footerData.emails"
								:icon="'mail_footer'"
								:type="'email'"
								:link="link.email"
								:text="link.text"
								:key="link.email"
							/>
						</div>
						<div class="footer__block-info-wr-buttons">
							<footer-button
								v-for="item in getFooterButtons"
								:button="item.button"
								:key="item.button.content"
							/>
						</div>

					</div>
					<div class="footer-links ds-grid">
						<div class="footer-links">
							<base-link
								v-for="item in footerLinks.firstColumn"
								:class="[
									'footer-link',
									`footer-link--${item.elementStyles}`
								]"
								:key="item.link.to"
								v-bind="item.link"
							>
								{{ item.link.content }}
							</base-link>

							<!-- Added for SEO -->
							<base-link
								:class="[
									'footer-link',
									`footer-link--primary`
								]"
								title="Check our 5G antennas!"
								to="product-list"
								:params="{
									param: '5g-antennas'
								}"
								type="internal"
							>
								5G antennas
							</base-link>
							<base-link
								:class="[
									'footer-link',
									`footer-link--primary`
								]"
								title="Learn more about outdoor LTE routers!"
								to="outdoor-lte-routers"
								type="internal"
							>
								5G outdoor router
							</base-link>
							<router-link
								:class="[
									'footer-link',
									`footer-link--primary`
								]"
								title="Check technical specification of our 4x4 MIMO 5G antenna"
								to="/product/qupanel-5glte-global-mimo-4x4"
							>
								4x4 MIMO antenna 5G
							</router-link>
						</div>

						<div  class="footer-links">
							<base-link
								v-for="item in footerLinks.secondColumn"
								:class="[
									'footer-link',
									`footer-link--${item.elementStyles}`
								]"
								:key="item.link.to"
								v-bind="item.link"
							>
								{{ item.link.content }}
							</base-link>
						</div>

						<div  class="footer-links">
							<base-link
								v-for="item in footerLinks.thirdColumn"
								:class="[
									'footer-link',
									`footer-link--${item.elementStyles}`
								]"
								:key="item.link.to"
								v-bind="item.link"
							>
								{{ item.link.content }}
							</base-link>
						</div>

					</div>
				</div>
			</div>
		</base-container>
	</footer>
</template>
<script>
import BaseCopyright from '~/website/front/components/sections/BaseCopyright'
import BaseIconLink from '../../atoms/BaseIconLink/BaseIconLink.vue'
import FooterButton from '~/website/front/components/sections/BaseFooter/components/FooterButton'
import BaseLink from '~/cms/front/components/BaseLink/BaseLink.vue'

export default {
	components: {
		BaseLink,
		FooterButton,
		BaseCopyright,
		BaseIconLink
	},
	computed: {
		footerData () {
			return this.$app.getSettings().general.contact
		},
		getFooterButtons () {
			return this.$app.layout.value.footer_buttons ? this.$app.layout.value.footer_buttons : []
		},
		footerMenu () {
			const menu = this.$app.layout.value.menu.items

			return {
				no_children: menu.filter(item => !item.children.length),
				with_children: menu.filter(item => item.children.length).filter(item => item.to !== 'routers' && item.to !== 'outdoor-antennas')
				// last filter is used for deleting categories which client want to hide
			}
		},
		footerLinks () {
			return this.$app.layout.value.footer
		}
	}
}

</script>
<style lang="scss" scoped>
.footer {
	position: relative;
	justify-self: flex-end;
	overflow: hidden;

	.footer-bg {
		background-position: left;
		background-size: cover;
		background-repeat: no-repeat;
		position: absolute;
		bottom: 0;
		right: -25%;
		z-index: -1;
		width: 150%;
		height: 100%;

		@include media-breakpoint-up(lg) {
			height: 100%;
			width: 200%;
			right: 0;
		}
		@media screen and (min-width: 1921px) {
			width: 50vw;
		}

		&-image {
			width: 100%;
			height: 100%;

			:deep(img) {
				height: 100%;
				width: 100%;
			}
		}
	}

	&__inner {
		display: flex;
		flex-direction: column-reverse;
		@include media-breakpoint-up(lg) {
			flex-direction: row;
		}
	}

	&__block {
		width: 100%;
		position: relative;

		@include media-breakpoint-up(xl) {
			@include spacing-section();
		}

		&:nth-child(1) {
			padding-top: 35px;
			padding-bottom: 40px;
			@include media-breakpoint-up(lg) {
				position: relative;
				padding-right: 70px;
				flex-basis: 30%;
				padding-bottom: 40px;
			}

			@include media-breakpoint-up(xl) {
				display: flex;
				flex-direction: column;
				padding-bottom: 60px;
				padding-top: 100px;
			}

			@include media-breakpoint-up(xxl) {
				padding-right: 80px;
				flex-basis: 40%;
			}
		}

		&:nth-child(2) {
			padding-top: 60px;
			padding-bottom: 40px;

			@include media-breakpoint-up(lg) {
				padding-left: 30px;
				flex-basis: 60%;
				padding-bottom: 60px;
			}

			@include media-breakpoint-up(xl) {
				padding-top: 100px;
				padding-left: 80px;
			}

			@include media-breakpoint-up(xxl) {
				padding-left: 80px;
				flex-basis: 60%;
			}
		}

		&-logo-wr {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			margin-bottom: 30px;
			padding: 0 40px;
			margin-left: auto;
			margin-right: auto;
			max-width: max-content;

			@include media-breakpoint-up(sm) {
				max-width: 60%;
			}
			@include media-breakpoint-up(lg) {
				padding: 0;
				max-width: max-content;

			}
			@include media-breakpoint-up(xl) {
				margin-bottom: 80px;
				max-width: none;
				width: 100%;

			}

			.svg-icon {
				width: 100%;

				::v-deep(svg) {
					width: 100%;

				}
			}

			.footer__block-logo-text {
				color: $secondary;
				text-transform: uppercase;
				font-size: 17px;

				@include media-breakpoint-up(xl) {
					font-size: 28px;
				}
			}

		}

		&-text {
			margin-top: 30px;

			p {
				@include media-breakpoint-up(xl) {
					letter-spacing: .01rem;
					font-size: 18px;
					line-height: 24px;
				}
			}

			::v-deep(span) {
				font-weight: 700;
			}
		}

		&-info-wr {

			&-title {
				margin-bottom: 30px;
				font-size: 16px;
				text-transform: uppercase;

				@include media-breakpoint-up(lg) {
					font-size: 26px;
				}
			}

			&-links {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				margin-bottom: 20px;
				gap: 10px;

				::v-deep(p) {
					font-size: 14px;

					@include media-breakpoint-up(xl) {
						font-size: 16px;
					}
				}

				@include media-breakpoint-up(lg) {
					grid-template-columns: repeat(2, 1fr);
					gap: 0;
				}

				&.links-email {
					display: flex;
					flex-direction: column;

					@include media-breakpoint-up(lg) {
						display: grid;
						grid-template-columns: repeat(2, 1fr);
						gap: 0;
					}
				}
			}

			&-buttons {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				gap: 20px;
				margin-top: 30px;

				@include media-breakpoint-up(md) {
					gap: 30px;
				}

				a {
					@include media-breakpoint-up(xl) {
						font-size: 16px;
					}
				}
			}
		}

		.footer-links {
			&.ds-grid {
				position: relative;
				margin-top: 30px;
				gap: 3rem 1.5rem;
				padding-top: 30px;
				display: grid;
				grid-template-columns: repeat(2, minmax(0, 1fr));

				@include media-breakpoint-up(lg) {
					display: grid;
					grid-template-columns: repeat(3, minmax(0, 1fr));
					gap:4rem 2rem;
				}
			}

			& > div {
				flex-direction: column;
				gap: 5px;

			}

		}

		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			width: 100%;
			height: 2px;
			background-color: #D6D6D6;
		}

		&-block {
			display: flex;
			flex-direction: column;
			gap: 10px;
		}
	}

	&__links-wr {
		display: grid;
		grid-template-columns: 1fr;
		gap: 20px;

		@media screen and (min-width: 600px) {
			grid-template-columns: repeat(2, 1fr);
		}
	}
}

.footer-link {
	display: block;
	text-transform: uppercase;
	text-decoration: none;
	font-size: 1.4rem;
	line-height: 18px;
	color: $secondary;
	font-weight: 600;
	letter-spacing: 0.02rem;
	@include transition;
	margin-bottom: 10px;

	&:hover {
		color: $primary;
	}

	&:last-child {
		margin-bottom: 0;
	}

	&:not([href]) {
		cursor: default;
	}

	@include media-breakpoint-up(lg) {
		font-size: 1.5rem;
	}

	@include media-breakpoint-up(xl) {
		font-size: 1.6rem;
		line-height: 2.1rem;
	}

	&--primary {
		color: $primary;
		font-weight: 700;
		font-size: 110%;
	}
}
</style>
