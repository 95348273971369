<script>
import BaseButton from '~/cms/front/components/BaseButton'

export default {
	extends: BaseButton,
	name: 'BaseButton',
	globalComponent: true
}

</script>

<style lang="scss" scoped>
.btn {
	border: 1px solid transparent;
	width: fit-content;
	display: flex;
	align-items: center;
	position: relative;
	justify-content: center;
	text-transform: uppercase;
	outline: none;
	cursor: pointer;
	padding: 1.2rem 4rem;
	font-family: $primary-font;
	text-decoration: none;
	@include transition;
	background: $secondary;
	color: $white;
	border-radius: 4px;
	font-weight: 700;
	letter-spacing: 0.02rem;
	font-size: 14px;
	gap: 10px;

	@include media-breakpoint-up(lg) {
		font-size: 16px;
		padding: 1.7rem 4rem;
		gap: 15px;
	}

	&.margin-reset {
		margin: 0;
	}

	&-disabled {
		border: 1px solid #999999;
		background-color: #cccccc;
		color: #666666;
		cursor: not-allowed;
	}

	&.primary {
		background-color: $primary;
		align-items: center;
		border: 1px solid $primary;

		&:hover {
			background-color: $primary-strong;
		}

		:deep(.svg-icon) {
			display: flex;
			justify-content: center;
			align-items: center;

			svg {
				height: 12px;

				@include media-breakpoint-up(lg) {
					height: 15px;
				}
			}
		}
	}

	&.primary-white {
		background-color: $white;
		align-items: center;
		padding: 15px 40px;

		&:hover {
			background-color: #EFEFEF;
		}

		:deep(.svg-icon) {
			display: flex;
			justify-content: center;
			align-items: center;

			svg {
				height: 12px;

				@include media-breakpoint-up(lg) {
					height: 15px;
				}
			}
		}
	}

	&.secondary {
		background-color: transparent;
		color: $secondary;
		border: 1px solid $secondary;

		&:after {
			@include transition;
			opacity: 0;
			content: '';
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			width: 100%;
			height: 100%;
			border: 1px solid $secondary;
		}

		&:hover {
			&:after {
				opacity: 1;
			}
		}
	}
}

</style>
