<template>
	<section
		class="photo-section"
		:class="{padding: !value.section_title}"
		v-custom-background="backgroundImage"
		:style="{'background-color': backgroundColor}"
	>
		<base-container>
			<base-animation
				animation="slideRight"
				v-if="value.section_title"
			>
				<custom-title
					:title="`${prefix}.section_title.title`"
					:subtitle="`${prefix}.section_title.subtitle`"
					:header_tag="value.section_title?.title_tag"
					:color="value.section_title.title_color"
					:transform="value.section_title.title_transform"
					:type="value.section_title_type"
				/>
			</base-animation>
			<base-animation>
				<div class="grid-wrapper" :class="{reversed: value.reverse}">
					<div class="text-content">
						<cms-multi-section
							class="section-elements"
							:value="`${prefix}.section_content`"
						/>
						<buttons :value="value.section_buttons"/>
					</div>
					<image-wrapper class="img-wrapper" wider>
						<cms-image
							:value="`${prefix}.image`"
							:class="{shadow: value.shadow}"
							v-bind="defaultCmsImageBreakpoints"
							lazy
						/>
					</image-wrapper>
				</div>
			</base-animation>

		</base-container>
	</section>
</template>

<script>
import CustomTitle from '../../components/atoms/CustomTitle'
import ImageWrapper from '~/website/front/components/atoms/ImageWrapper'
import CmsMultiSection from '~/cms/front/components/CmsMultiSection/CmsMultiSection.vue'
import { backgroundSectionMixin } from '~/website/front/utils/backgroundSection.mixin.js'
import Buttons from '~/website/front/sections/Buttons/Buttons'
import BaseAnimation from '@f/components/BaseAnimation/BaseAnimation'
import { defaultCmsImageBreakpoints } from '~/cms/front/mixins/defaultCmsImageBreakpoints.js'

export default {
	components: {
		Buttons,
		ImageWrapper,
		CustomTitle,
		CmsMultiSection,
		BaseAnimation
	},
	data () {
		return {
			backgroundHeight: {
				base: 640,
				sm: 640,
				md: 640,
				lg: 768,
				xl: 720,
				xxl: 1080
			}
		}
	},
	mixins: [backgroundSectionMixin, defaultCmsImageBreakpoints],
	props: {
		prefix: String,
		value: Object
	}
}

</script>
<style lang="scss" scoped>
:deep(.img-wrapper) {
	.image-wrapper.shadow {
		img {
			box-shadow: 6px 6px 20px #0000003D;
		}
	}

	@include media-breakpoint-up(lg) {
		min-height: 300px;
	}

	.base-image {
		height: 100%;
		width: 100%;
		object-fit: cover;
		border-radius: 10px;
		margin-bottom: 0;
	}
}

.photo-section {
	overflow-x: hidden;
	padding-bottom: 4rem;
	background-color: #fff;

	@include media-breakpoint-up(xl) {
		padding-bottom: 6rem;
	}

	&.padding {
		padding: 6rem 0 4rem 0;

		@include media-breakpoint-up(lg) {
			padding: 10rem 0 6rem 0;
		}
	}

	.grid-wrapper {
		display: grid;
		grid-template-rows: repeat(1, 1fr);
		grid-template-columns: 1fr;
		gap: 4rem;

		&.reversed {
			grid-auto-flow: dense;
			direction: rtl;
		}

		@include media-breakpoint-up(xl) {
			gap: 5rem;
		}

		@include media-breakpoint-up(xxl) {
			gap: 15rem;
		}

		@include media-breakpoint-up(lg) {
			grid-template-columns: 3fr 4fr;
			align-items: center;
		}

		::v-deep .text-content {
			.section-elements {

				h1,
				h2,
				h3 {
					direction: ltr;
					font-weight: 600;
					text-transform: uppercase;
					font-size: 20px;
					color: $secondary;
					font-style: normal;
					font-family: $primary-font;
					margin-bottom: 19px;
					@include media-breakpoint-up(lg) {
						margin-bottom: 30px;
						font-size: 38px;
					}
				}

				.ql-editor {
					padding: 0;

					p {
						color: $secondary;
						font-weight: 500;
						direction: ltr;
						font-size: 14px;
						line-height: 22px;
						letter-spacing: 0.14px;
						@include media-breakpoint-up(xl) {
							font-size: 18px;
							line-height: 24px;
							letter-spacing: 0.18px;
						}
					}
				}
			}

		}

		:deep(.buttons-wrapper) {
			align-items: center;
			justify-content: center;

			@include media-breakpoint-up(lg) {
				justify-content: inherit;
			}
		}
	}

	:deep(.img-wrapper) {
		&.content-wrapper {
			margin-bottom: 0;
		}

		@include media-breakpoint-up(lg) {
			grid-column: 2;
		}

	}

	.text-content {
		grid-column: 1;
		direction: ltr;
	}
}

</style>
