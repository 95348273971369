<template>
	<main>
		<div class="filters-and-products">
			<base-container>
				<custom-title
					header_tag="h1"
					type="h1"
					:title="title"
					:subtitle="lang"
					:use-string="true"
				/>

				<cms-text
					v-if="introText"
					:value="introText"
					:props="{tag: 'div'}"
					class="intro-text"
					use-string
				/>

				<filters-panel
					v-if="view.filtersManager?.filters.length"
					:filtersManager="view.filtersManager"
				/>
				<product-list
					v-if="view.products.length"
					:products="view.products"
				/>
				<product-list-skeleton v-else-if="isLoading" />
				<no-filtered-products-notification v-else />
			</base-container>
		</div>
		<cms-multi-section value="content" />
	</main>
</template>

<script>
import CmsMultiSection from '~/cms/front/components/CmsMultiSection'
import ProductList from '~/catalog/front/components/organisms/ProductList/ProductList.vue'
import BaseContainer from '~/website/front/components/atoms/BaseContainer/BaseContainer.vue'
import CustomTitle from '~/website/front/components/atoms/CustomTitle/CustomTitle.vue'
import FiltersPanel from '~/catalog/front/core/pages/ProductListPage/components/FiltersPanel.vue'
import NoFilteredProductsNotification
from '~/catalog/front/components/organisms/NoFilteredProductsNotification/NoFilteredProductsNotification.vue'
import ProductListSkeleton from '~/catalog/front/components/organisms/ProductListSkeleton/ProductListSkeleton'
export default {
	components: {
		ProductListSkeleton,
		NoFilteredProductsNotification,
		FiltersPanel,
		CustomTitle,
		BaseContainer,
		ProductList,
		CmsMultiSection
	},
	props: {
		view: Object,
		isLoading: Boolean
	},
	computed: {
		title () {
			return this.$app.page.subcategory.name || ''
		},
		lang () {
			return this.$app.translator.get('products').products_list?.find_the_best_solution
		},
		introText () {
			return this.$app.page.subcategory?.intro || ''
		}
	},
	async prefetch () {
		await this.$app.translator.prefetch('products')
	}
}
</script>

<style lang="scss" scoped>
.filters-and-products{
	@include spacing-section();
}

.intro-text {
	margin-bottom: 20px;
}
</style>
