const mixin = {
	computed: {
		isHomepage () {
			return this.$app.page.route.meta.isHomepage
		},

		isCurrentRoute () {
			if (this.isHomepage) return this.item.to === 'homepage'
			return this.$app.page.breadcrumbs?.findCrumb
				? this.$app.page.breadcrumbs.findCrumb(this.item.to, this.item.param, true)
				: false
		}
	}
}

export default mixin
