export const defaultCmsImageBreakpoints = {
	data () {
		let data = {}
		if (this.data) data = this.data()
		return {
			...data,
			defaultCmsImageBreakpoints: {
				base: { width: 320 },
				sm: { width: 540 },
				lg: { width: null }
			}
		}
	}
}
