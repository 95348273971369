<template>
	<section class="form-section" v-custom-background="backgroundImage">
		<base-container>
			<custom-title
				class="form-section-header"
				title="contact_page.formTitle"
				subtitle="contact_page.formSubTitle"
				header_tag="h2"
				color="white"
			/>
			<div class="form-wrapper">
				<contact-form/>
				<cms-image
					class="article-image"
					lazy
					v-if="image"
					:src="image"
					:base="{width: 694, height: 940}"
					:xxl="{width: 694, height: 800}"
				/>
			</div>
		</base-container>
	</section>
</template>

<script>
import CustomTitle from '~/website/front/components/atoms/CustomTitle/CustomTitle.vue'
import ContactForm from '~/website/front/components/sections/ContactForm/ContactForm.vue'
export default {
	components: { CustomTitle, ContactForm },
	props: {
		backgroundImage: Object,
		image: Object
	}
}
</script>

<style lang="scss" scoped>

.form-section{
	overflow: hidden;
	background-color: #2285C5;

	background-size: cover;
	background-repeat: no-repeat;
	padding-bottom: 6rem;
	@include media-breakpoint-up(lg) {
		padding-bottom: 10rem;
	}

	.form-wrapper{
		overflow: hidden;
		display: grid;

		@include media-breakpoint-up(xl) {
			overflow: visible;
			gap: 30px;
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}

		@include media-breakpoint-up(xxl) {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}

		.article-image{
			:deep(picture) {
				img {
					display: none;
					max-width: 100%;
					border-radius: 1rem;

					@include media-breakpoint-up(xl) {
						display: block;
					}
				}
			}
		}
	}
}

</style>
