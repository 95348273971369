<template>
	<div class="presentation">
		<div class="presentation-inner">
			<cms-image
				v-for="(item, i) in gifs"
				:src="{
					path: item.gif.path,
					alt: 'WiMount system presentation',
					title: `WiMount system presentation of ${productName}`
				}"
				:key="item.gif.path"
				:class="{
					'presentation-gif': true,
					active: i === currentIndex
				}"
			/>
		</div>
		<div class="presentation-buttons">
			<base-link
				href="#"
				:button_type="i === currentIndex ? 'primary' : 'secondary'"
				v-for="(item, i) in gifs"
				:key="item.gif.path + i"
				@click="setActive(i)"
			>
				{{lang.mounting_presentation[item.direction]}}
			</base-link>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		prefix: String,
		gifs: Array,
		productName: String
	},
	data () {
		return {
			currentIndex: 0
		}
	},
	computed: {
		lang () {
			return this.$app.translator.get('products')
		}
	},
	methods: {
		setActive (index) {
			this.currentIndex = index
		}
	},
	async prefetch () {
		await this.$app.translator.prefetch('products')
	}
}
</script>

<style lang="scss" scoped>
.presentation {
	&-gif {
		@include transition;
		opacity: 0;
		display: none;
		visibility: hidden;
		:deep(img) {
			width: 100%;
		}

		&.active {
			display: block;
			visibility: visible;
			opacity: 1;
		}
	}

	&-buttons {
		margin-top: 15px;
		display: flex;
		gap: 15px;
	}
}
</style>
