<template>
	<section class="our-team">
		<base-container>
			<base-animation
				animation="slideRight"
			>
				<custom-title
					:title="`${prefix}.section_title`"
					:subtitle="`${prefix}.section_subtitle`"
					:header_tag="value.header_tag"
				/>
			</base-animation>

			<div class="team-wrapper">
				<div class="persons">
					<base-animation
						v-for="(person, index) in value.cards"
						animation="slideRight"
						:key="`${prefix}.cards.${index}`"
					>
						<person
							:name="`${prefix}.cards.${index}.name`"
							:job="`${prefix}.cards.${index}.job`"
							:description="`${prefix}.cards.${index}.description`"
							:photo="person.image"
							:links="person.links"
						/>
					</base-animation>
				</div>
			</div>
		</base-container>
	</section>
</template>

<script>
import Person from '~/website/front/sections/Team/components/Person'
import BaseContainer from '~/website/front/components/atoms/BaseContainer/BaseContainer'
import CustomTitle from '~/website/front/components/atoms/CustomTitle/CustomTitle'
import BaseAnimation from '@f/components/BaseAnimation/BaseAnimation'
export default {
	components: { BaseContainer, Person, CustomTitle, BaseAnimation },
	props: {
		prefix: String,
		value: Object
	}
}
</script>
<style lang="scss" scoped>
.our-team {
	@include spacing-section();
}
.team {
	&-wrapper {
		@include media-breakpoint-up(xl) {
			display:flex;
			gap: 30px;
		}
		&:deep(img) {
			width: 100%;
			height: auto;
			border-radius: 1rem;
			display: block;
		}
	}

}
.persons {
	@include media-breakpoint-up(sm) {
		display: flex;
		flex-direction: row;
		gap: 10px;
		justify-content: center;
	}

	@include media-breakpoint-up(xl) {
		justify-content: space-between;
		gap: 30px;
	}

	& > *{
		width: 100%;
		display: flex;
	}

}

</style>
