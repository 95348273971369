<template>
	<base-font class="breadcrumb" tag="span" color="secondary" size="xs" weight="medium">
		<slot />
	</base-font>
</template>

<script>
export default {
	props: {
		isLast: Boolean
	}
}
</script>

<style scoped lang="scss">
.breadcrumb {
	opacity: .30;
	letter-spacing: 0.16px;
	text-transform: none;
}
</style>
