import { render, staticRenderFns } from "./FiltersPanel.vue?vue&type=template&id=c0f805e4&scoped=true"
import script from "./FiltersPanel.vue?vue&type=script&lang=js"
export * from "./FiltersPanel.vue?vue&type=script&lang=js"
import style0 from "./FiltersPanel.vue?vue&type=style&index=0&id=c0f805e4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c0f805e4",
  null
  
)

export default component.exports