import { Page } from '~/cms/front/core/pages/Page.js'
import Component from './GeneralProductListPage.vue'

class GeneralProductListPage extends Page {
	static alias = 'general-product-list'
	static keepAlive = true
	component = Component
	products = null
	dedicatedAntennasLists = null
	filtersManager = null
	brandForm = null

	async init () {
		await super.init()

		if (!this.filtersManager) await this.createFilterManager()
		this.filtersManager.setActiveFiltersFromQuery(this.query)

		if (!this.brandForm) await this.createBrandForm()
		this.brandForm.setActiveFromQuery(this.query)

		if (process.client) this.fetchProducts()
	}

	async createFilterManager () {
		const service = this.app.getService('filters')
		this.filtersManager = await service.getFilterManagerWithAllFilters(['technology', 'mimo', 'directionality', 'connector'])
		this.filtersManager.on('state:changed', () => {
			this.app.router.push({ query: this.filtersManager.getQuery() })
		})
	}

	async createBrandForm () {
		const service = this.app.getService('brand')
		this.brandForm = await service.getForm()

		this.brandForm.on('*:changed', () => {
			this.app.router.push({ query: this.brandForm.getQuery() })
		})
	}

	async fetchProducts () {
		const service = await this.app.getService('rext')

		const filtersQuery = this.filtersManager.getQueryWithIds()
		if (this.brandForm.modelId) {
			const response = await service.getDedicatedAntennasListsForModel(this.brandForm.modelId)
			this.dedicatedAntennasLists = response
			this.products = null
		} else if (this.brandForm.brandId) {
			const response = await service.getDedicatedAntennasForBrand(this.brandForm.brandId)
			this.dedicatedAntennasLists = response
			this.products = null
		} else {
			const response = await service.getAntennaFilter({
				filterParameters: filtersQuery
			})
			this.products = response
			this.dedicatedAntennasLists = null
		}
	}
}

export { GeneralProductListPage }
