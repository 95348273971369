<template>
	<cms-multi-section value="content"/>
</template>
<script>
import { pageMixin } from '~/website/front/core/pages/page.mixin'
import CmsMultiSection from '~/cms/front/components/CmsMultiSection'

export default {
	components: {
		CmsMultiSection
	},
	mixins: [
		pageMixin
	]
}
</script>
<style lang="scss" scoped>

</style>
