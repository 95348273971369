<template>
	<div v-if="isTypePhone" class="info phone">
		<base-svg class="icon" name="phone_footer" />
		<a
			:href="`tel:${this.info.value?.replaceAll(' ', '')}`"
			:title="`Call to ${title} by number: ${this.info.value}`"
		>
			<cms-text :value="`${prefix}.value`" />
		</a>
	</div>

	<div v-else-if="isTypeEmail" class="info email">
		<base-svg class="icon" name="mail_footer" />
		<a
			:href="`mailto:${this.info.value}`"
			:title="`Mail to ${title} by e-mail: ${this.info.value}`"
		>
			<cms-text :value="`${prefix}.value`" />
		</a>
	</div>

	<div v-else-if="isTypeSite" class="info site">
		<base-svg class="icon" name="domain-www-svgrepo-com" />
		<a
			target="_blank"
			:href="this.info.value"
			:title="`Visit ${title} website: ${this.info.value}`"
		>
			<cms-text :value="`${prefix}.value`" />
		</a>
	</div>
</template>

<script>
export default {
	props: {
		prefix: String,
		info: {
			type: Object,
			default: () => {
				return {
					type: '',
					value: ''
				}
			}
		},
		title: String
	},

	computed: {
		isTypePhone () {
			return this.info?.type === 'phone'
		},
		isTypeSite () {
			return this.info?.type === 'site'
		},
		isTypeEmail () {
			return this.info?.type === 'email'
		}
	}
}
</script>

<style lang="scss" scoped>
.info{
	@include transition;
	display: flex;
	gap: 10px;

	&:hover {
		transform: translateX(5px);

		a {
			p {
				color: $primary;
			}
		}
	}

	.icon {
		display: flex;
		justify-content: center;
		align-items: center;

		:deep(svg) {
			height: 20px;
			width: 20px;
		}

	}

	a {
		text-decoration: none;

		p {
			font-weight: bold;
			text-transform: uppercase;
			color: $secondary;
			@include transition;
		}
	}
}
</style>
