<template>
	<div class="field-wrapper" :info="input.info">
		<div class="field-inner-wrapper">
			<div class="field-label">
				<base-font variant="label" color="white" :for="input.name">{{ input.label }}:</base-font>
			</div>
			<div class="select-wrapper">
				<div
					class="select"
					:class="{disabled: isInputDisabled}"
					:id="input.name"
					@click="toggle"
				>
					<field-custom-placeholder :value="placeholderValue" />
					<base-svg
						class="select-arrow"
						name="keyboard_arrow_down"
						:class="{active: isActive}"
					/>
				</div>
				<div
					class="select-options"
					:class="{active: isActive, disabled: !input.options.length}"
					ref="optionWrapper"
				>
					<field-custom-option
						class="select-option"
						v-for="option in input.options"
						:key="option.id"
						:option="option"
						:click-handler="optionClickHandler"
						:activeOption="activeValue"
					/>
				</div>
			</div>
		</div>
		<base-error v-show="input.error" :error="input.error" />
	</div>
</template>

<script>
import mixin from '~/website/front/components/molecules/fileds/utils/input.mixin'
import BaseError from '@f/components/BaseError/BaseError'
import BaseSvg from '@f/components/BaseSvg/component/BaseSvg'
import FieldCustomOption from '~/website/front/components/molecules/fileds/FieldCustomSelect/FieldCustomOption'
import FieldCustomPlaceholder
from '~/website/front/components/molecules/fileds/FieldCustomSelect/FieldCustomPlaceholder'

export default {
	mixins: [mixin],
	components: {
		FieldCustomPlaceholder,
		FieldCustomOption,
		BaseError,
		BaseSvg
	},
	data () {
		return {
			activeValue: false,
			isActive: false
		}
	},

	computed: {
		placeholderValue () {
			return this.input.value?.name || this.input.placeholder
		},

		isInputDisabled () {
			return this.input.disable || !this.input.options.length
		}
	},

	methods: {
		onDocumentClick () {
			this.isActive = false
			document.body.removeEventListener('click', this.onDocumentClick, { passive: true })
		},

		toggle () {
			this.isActive = !this.isActive

			if (this.isActive) {
				setTimeout(() => {
					document.body.addEventListener('click', this.onDocumentClick, { passive: true })
				}, 100)
			}
		},

		optionClickHandler (option) {
			this.toggle()
			this.activeValue = option

			this.input.value = option
			this.$emit('change', this.activeValue)
		}
	}
}
</script>

<style lang="scss" scoped>
.field-inner-wrapper{
	display: flex;
	flex-direction: column;
	gap: 10px;
	width: 100%;
	position: relative;
	@include transition;

	@include media-breakpoint-up(xl) {
		width: 330px;
	}

	.select-wrapper{
		position: relative;

		.select {
			cursor: pointer;
			position: relative;
			padding: 10px 20px;
			background-color: #fff;
			border-radius: 5px;
			border: 1px solid transparent;
			outline: none;
			color: $secondary;
			-webkit-tap-highlight-color: rgba(0, 0, 0, 0);

			&.disabled {
				pointer-events: none;
				background-color: $input-disabled-background;
			}

			.select-arrow{
				@include transition;
				display: flex;
				align-items: center;
				position: absolute;
				top: 0;
				bottom: 0;
				right: 20px;
				margin: auto;

				&.active {
					transform: rotate(180deg);
				}
			}
		}

		.select-options {
			box-shadow: $base-shadow;
			@include transition;
			border: 1px solid #fff;
			visibility: hidden;
			opacity: 0;
			border-radius: 5px;
			position: absolute;
			top: 50px;
			width: 100%;
			left: 0;
			z-index: $level1;
			max-height: 200px;
			overflow-y: auto;
			pointer-events: none;

			&.active {
				opacity: 1;
				visibility: visible;
				pointer-events: all;
			}

			&.disabled {
				visibility: hidden;
			}

		}
	}

	.field-label {
		display: none;
		font-size: 18px;
		line-height: 24px;
		letter-spacing: 0.18px;
		font-weight: 600;

		@include media-breakpoint-up(lg) {
			display: block;
		}
	}
}
</style>

<style lang="scss" scoped src="../utils/fields.scss" />
