var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.product)?_c('router-link',{staticClass:"product",attrs:{"to":_vm.linkToProduct,"title":`Learn more about ${_vm.product.name}`}},[_c('cms-image',{staticClass:"product-image",attrs:{"src":{
				path: _vm.product.thumbnail?.path,
				alt: _vm.product.name,
				title: `Product ${_vm.product.name}`
			},"base":{
				width: 124,
				height: 137,
				fit: 'contain',
				background: 'transparent'
			},"xl":{
				width: 200,
				height: 220,
				fit: 'contain',
				background: 'transparent'
			}}}),_c('div',{staticClass:"product-wrapper"},[_c('div',{staticClass:"product-title-wrapper"},[_c('h3',{class:{
						'product-title': true,
						'with-variants': _vm.hasManyVariants
					},domProps:{"innerHTML":_vm._s(_vm.productName)}}),(_vm.hasManyVariants)?_c('div',{staticClass:"product-variant"},_vm._l((_vm.product.variants),function(variant,i){return _c('span',{key:i},[_vm._v(" "+_vm._s(i !== _vm.product.variants.length - 1 ? `${variant}, ` : variant)+" ")])}),0):_vm._e()]),(_vm.partNumber)?_c('span',{staticClass:"product-serial-number"},[_vm._v("P/N: "+_vm._s(_vm.partNumber))]):_vm._e(),_c('cms-text',{staticClass:"product-description",attrs:{"value":_vm.product.description,"props":{tag: 'div'},"use-string":"","no-inner-links":""}}),(_vm.product.icons.length)?_c('div',{staticClass:"product-icons"},_vm._l((_vm.product.icons),function(item,i){return _c('cms-image',{key:i,staticClass:"product-icon",attrs:{"src":item.icon,"base":{format: 'webp', height: 60}}})}),1):_vm._e(),_c('div',{staticClass:"buttons-wrapper"},[_c('span',{staticClass:"buttons-wrapper-link"},[_vm._v(" "+_vm._s(_vm.lang.learn_more)+" ")])])],1),(_vm.product.isUltraWideBand)?_c('div',{staticClass:"product-ultraWideBand"},[_c('base-font',{attrs:{"tag":"span","color":"primary","uppercase":"","size":"sm","weight":"bold"}},[_vm._v(" "+_vm._s(_vm.lang.ultra_wide_band)+" ")])],1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }