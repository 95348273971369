import { Page } from '~/cms/front/core/pages/Page'
import { BreadcrumbsBuilder } from '~/website/front/core/elements/Breadcrumb/BreadcrumbsBuilder.js'
import Article from './Article.vue'
import { ArticleSeo } from '~/cms/front/core/seo/ArticleSeo'

class ArticlePage extends Page {
	static alias = 'article'
	component = Article

	setConstructorData ({ app, route, from }) {
		this.app = app
		this.route = route
		this.from = from
	}

	async init () {
		await super.init(...arguments)
		this.seo = new ArticleSeo(this.app, { page: this })
		if (this.value.category.alias === 'use-cases-and-stories') {
			this.breadcrumbs = new BreadcrumbsBuilder(this.app)
				.build()
				.generateFromMenuTree('use-cases-and-stories')
				.addCrumb(this.value?.title, this.route.meta?.alias, this.route.params?.alias)
		} else if (this.value.category.alias === 'basic') {
			this.breadcrumbs = new BreadcrumbsBuilder(this.app)
				.build()
				.generateFromMenuTree('article-list')
				.addCrumb(this.value?.title, this.route.meta?.alias, this.route.params?.alias)
		}
	}

	async fetch () {
		if (this.from.meta.alias || process.server) {
			await this.fetchData()
			// await this.fetchRecommendedArticles()
		} else this.restore()
	}

	async fetchData () {
		const data = await this.app.getService('rext').getArticle(this.alias)
		if (data.error) return this.app.vue.$redirect({ path: '/404' })
		await this.setPageData(data.page)
		delete data.page
		this.language = data.language
		this.value = data
	}

	async fetchRecommendedArticles () {
		const data = await this.app.getService('rext').getRecommendedArticles(this.value.id)
		if (data.errors) return this.app.vue.$redirect({ path: '/404' })
		this.value.recommendedArticles = data
	}

	async afterRedirect () {
		if (process.client) await this.app.getService('rext').createArticleView(this.alias)
	}

	get header () {
		return {
			title: this.app.translator.get('articles').blog_list_title,
			background: this.value.thumbnail
		}
	}

	restore () {
		super.restore()
		this.language = window.__PAGE__.language
	}

	store () {
		const store = super.store()
		store.language = this.language
		return store
	}
}

export { ArticlePage }
