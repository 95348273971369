import axios from 'axios'
import { config } from '../../config/config'

class RextService {
	static alias = 'rext'

	constructor (app) {
		this.app = app
		this.config = config.services.rext
	}

	getAxiosConfig () {
		return {
			baseURL: this.config.url,
			headers: {
				destination: 'front'
			},
			validateStatus: () => true
		}
	}

	get http () {
		const instance = axios.create(this.getAxiosConfig())

		instance.interceptors.request.use(function (config) {
			return config
		}, function (error) {
			console.error(error)
			return { error: true }
		})
		instance.interceptors.response.use(function (config) {
			return config
		}, function (error) {
			console.error(error)
			return { error: true }
		})
		return instance
	}

	async getHttpWithRecaptcha () {
		const token = await this.app.getService('recaptcha').getToken()
		const axiosConfig = this.getAxiosConfig()
		axiosConfig.headers['X-Recaptcha-Token'] = token
		return axios.create(axiosConfig)
	}
}

export { RextService }
