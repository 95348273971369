<template>
	<div class="distributor">
		<cms-text
			class="distributor-name"
			:value="`${prefix}.title`"
			:props="{variant: 'h4'}"
		/>
		<cms-text
			class="distributor-description"
			:value="`${prefix}.text`"
			:props="{tag: 'div'}"
		/>
		<div class="distributor-info-wr">
			<distributor-info
				v-for="(item, i) in distributor.info"
				:prefix="`${prefix}.info.${i}`"
				:info="item"
				:title="distributor.title"
				:key="item.value"
			/>
		</div>
	</div>
</template>

<script>
import DistributorInfo from '~/website/front/sections/DistributorsSection/components/DistributorInfo'
export default {
	components: { DistributorInfo },
	props: {
		prefix: String,
		distributor: {
			type: Object,
			default: () => {
				return {
					info: [],
					text: '',
					title: ''
				}
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.distributor{

	.distributor-name{

	}

	:deep(.distributor-description){
		padding: 0;
		height: fit-content;

		p {
			margin-bottom: 0;
		}
	}

	.distributor-info-wr{
		margin-top: 20px;
		display: flex;
		flex-direction: column;
		gap: 10px;
	}
}
</style>
