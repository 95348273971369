<template>
	<div v-if="form">
		<section class="filters">
			<base-container>
				<custom-title
					header_tag="h1"
					type="h1"
					:title="title"
					:subtitle="lang.find_the_best_solution"
					:use-string="true"
				/>

				<cms-text
					v-if="introText"
					:value="introText"
					:props="{tag: 'div'}"
					class="intro-text"
					use-string
				/>

				<div class="filters-inner">
					<app-select
						:options="form.options.brands"
						:label="lang.filters.brand"
						theme="default"
						:default-value="form.brandValue"
						@valueChanged="alias => form.brandChangedHandler(alias)"
					/>
					<app-select
						:options="form.options.models"
						:label="lang.filters.model"
						theme="default"
						:default-value="form.modelValue"
						:disabled="!form.activeBrand?.models.length"
						@valueChanged="name => form.modelChangedHandler(name)"
					/>
				</div>
			</base-container>
			<dedicated-antennas-lists
				:dedicatedAntennas="view.dedicatedAntennas"
				:dedicatedAccessories="view.dedicatedAccessories"
				:compatibleAntennasLists="view.compatibleAntennasLists"
				:form="form"
				:isLoading="isLoading"
			/>
			<client-only>
				<base-container v-if="isLoading">
					<product-list-skeleton  />
				</base-container>
			</client-only>
		</section>
		<cms-multi-section value="content" />
	</div>
</template>

<script>
import CmsMultiSection from '~/cms/front/components/CmsMultiSection'
import CustomTitle from '~/website/front/components/atoms/CustomTitle/CustomTitle.vue'
import AppSelect from '~/catalog/front/components/molecules/AppSelected/AppSelect.vue'
import DedicatedAntennasLists from '../../../../components/organisms/DedicatedAntennasLists/DedicatedAntennasLists'
import ProductListSkeleton from '~/catalog/front/components/organisms/ProductListSkeleton/ProductListSkeleton'
import ClientOnly from 'vue-client-only'
export default {
	components: {
		ProductListSkeleton,
		AppSelect,
		CustomTitle,
		CmsMultiSection,
		DedicatedAntennasLists,
		ClientOnly
	},
	props: {
		view: Object,
		isLoading: Boolean
	},
	computed: {
		title () {
			return `${this.$app.page.view.form.activeBrand?.name || this.$app.page.subcategory.name} ${this.$app.page.view.form.activeModel?.name || ''}`
		},
		lang () {
			return this.$app.translator.get('products').products_list
		},
		form () {
			return this.view.form
		},
		introText () {
			return this.$app.page.subcategory?.intro || ''
		}
	},
	async prefetch () {
		await this.$app.translator.prefetch('products')
	}
}
</script>

<style lang="scss" scoped>
.filters{
	@include spacing-section();

	&-inner {
		padding: 20px;
		background-color: $white;
		border-radius: 5px;
		display: flex;
		flex-wrap: wrap;
		gap: 30px;

		& > div {
			width: 330px;
		}
	}
}

.intro-text {
	margin-bottom: 20px;
}
</style>
