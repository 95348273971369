<template>
	<section class="product-card-section" v-if="product">
		<base-container>
			<div class="product-card-inner">
				<base-link
					class="product-compare"
					button_type="primary"
				>
					<base-svg name="justice-scale"/>
					<base-font variant="button-text">
						Porównaj
					</base-font>
				</base-link>
				<product-slider
					:sliderConfig="sliderConfig"
					:images="product.gallery"
					:product="pageProduct"
				/>
				<div class="content-product" >
					<div class="title-wrapper">
						<base-font class="product-card-title"  variant="product-card-title">
							<span v-html="productName"/>
						</base-font>
						<div class="subtitle-wrapper">
							<div class="subtitle-line line-first"></div>
							<base-font
								v-if="isAntenna && product.activeVariant?.partNumber"
								class="card-subtitle"
							>
								{{ lang.pn }}: {{product.activeVariant?.partNumber || 'To check PN please select variant below' }}
							</base-font>
							<base-font
								v-else-if="isAntenna && !product.activeVariant?.partNumber"
								class="card-subtitle"
								:uppercase="false"
							>
								To check PN please select variant below
							</base-font>
							<base-font
								v-else-if="!isAntenna && value.section_subtitle"
								class="card-subtitle"
							>
								{{ value.section_subtitle }}
							</base-font>
							<div class="subtitle-line line-second"></div>
						</div>

					</div>
					<div class="product-content content-wrapper">
						<cms-text :value="`${prefix}.section_title`" class="content-heading" :props="{weight:'bold', color: 'primary', tag: 'h4'}" />
						<cms-text
							:props="{
								color: 'secondary',
								variant: 'paragraph',
								tag: 'div'
							}"
							:value="product.description"
							class="content-text"
							use-string
						/>
					</div>
					<div class="product-icons-wrapper" v-if="product.icons.length">
						<product-tech-icon v-for="item in product.icons" :icon="item" :key="item.path" />
					</div>
					<cms-text v-if="value.notice" :value="`${prefix}.notice`" :props="{variant: 'notice'}" class="text-amplification"  />
					<div class="variants-wrapper" v-if="product.variants.length > 1">
						<base-font class="variants-title" weight="bold" color="secondary">Variants:</base-font>
						<div class="product-variants">
							<product-variant-button
								v-for="variant in product.variants"
								:key="variant.name"
								:name="variant.name"
								:value="variant.name"
								:active="variant.partNumber === product.activeVariant?.partNumber"
								@handleClick="handleVariantClick"
							/>
						</div>
					</div>
					<div
						v-if="!isDeviceMobile"
						class="wrapper-btns"
					>
						<base-link
							v-show="doesHasSpecification"
							class="scroll-btn"
							:title="lang.secondary_button"
							button_type="secondary"
							@click="scrollToSpecification"
						>
							<base-font variant="button-text"> {{ lang.secondary_button }} </base-font>
						</base-link>
						<questions-button
							:text="lang.any_question"
							:primaryLink="{
								type: 'internal',
								to: 'contact',
								content: lang.primary_button,
								button_type: 'primary',
								title: 'Contact with QuWireless experts'
							}"
						/>
					</div>
					<div
						v-else
						class="wrapper-btns tablet"
					>
						<base-link
							button_type="secondary"
							:title="lang.secondary_button"
						>
							<base-font variant="button-text"> {{ lang.secondary_button }} </base-font>
						</base-link>
						<questions-button
							:text="lang.any_question"
							:click-handler="scrollToSpecification"
							:primaryLink="{
								type: 'internal',
								to: 'contact',
								content: lang.primary_button,
								button_type: 'primary',
								title: 'Contact with QuWireless experts'
							}"
						/>
					</div>
				</div>
			</div>
		</base-container>
	</section>
</template>

<script>
import BaseContainer from '~/website/front/components/atoms/BaseContainer/BaseContainer'
import ProductTechIcon from '~/catalog/front/sections/ProductCardSection/components/ProductTechIcon'
import ProductSlider from '~/catalog/front/components/organisms/ProductSlider/components/ProductSlider'
import QuestionsButton from '~/website/front/components/molecules/QuestionsButton/QuestionsButton'
import BaseFont from '~/website/front/components/atoms/BaseFont/BaseFont.vue'
import ProductVariantButton from './components/ProductVariantButton.vue'
import { productSectionMixin } from '~/catalog/front/sections/product-section.mixin'
import { formatProductName } from '~/catalog/front/utils/formatProductName.js'

export default {
	components: {
		ProductVariantButton,
		BaseFont,
		QuestionsButton,
		ProductSlider,
		ProductTechIcon,
		BaseContainer
	},
	mixins: [productSectionMixin],
	data () {
		return {
			isDeviceTablet: false,
			isDeviceMobile: false,
			sliderConfig: {
				slidePerClick: 1,
				arrows: true,
				autoplay: false,
				interval: 2000
			}
		}
	},
	props: {
		prefix: String,
		value: Object
	},
	computed: {
		isAntenna () {
			return !!this.$app.page.product.antennaId
		},

		productName () {
			let name = formatProductName(this.product.name)

			if (this.pageProduct.antennaId) name = `Antenna ${name}`

			return name
		},

		doesHasSpecification () {
			return this.$app.page.value.content.find(section => section.alias === 'specification_section')
		},

		product () {
			return this.$app.page.product || null
		},

		lang () {
			const langs = this.$app.translator.get('products')
			return langs.products_section
		}
	},
	methods: {
		scrollToSpecification () {
			const specification = document.querySelector('section#product_specification_section')

			if (!specification) return

			this.$nextTick(() => {
				window.scrollTo({ top: specification.offsetTop + 75, behavior: 'smooth' })
			})
		},

		handleVariantClick (name = '') {
			this.$app.page.handleVariantClick(name)
		},

		checkDeviceSize () {
			this.isDeviceMobile = window.innerWidth < 1200
			this.isDeviceTablet = (window.innerWidth < 1200 && window.innerWidth > 575)
		}
	},
	mounted () {
		window.addEventListener('resize', this.checkDeviceSize)
	},
	async prefetch () {
		await this.$app.translator.prefetch('products')
	}
}

</script>

<style lang="scss" scoped>
.product-card-section {
	background-color: $white;
	padding: 0 0 4rem 0;

	@include media-breakpoint-up(xl) {
		padding: 10rem;
	}

	.product-card-inner {
		position: relative;
		display: flex;
		flex-direction: column;
		gap: 30px;

		@include media-breakpoint-up(xl) {
			display: grid;
			grid-template-columns: 4fr 8fr;
		}

		.content-product {
			position: relative;

			@include media-breakpoint-up(xl) {
				padding-left: 30px;
			}

			&:before {
				content: '';
				display: none;
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 2px;
				background-color: #EEE;

				@include media-breakpoint-up(xl) {
					display: block;
				}
			}
		}

		.title-wrapper {
			text-align: center;

			:deep(.product-card-title) {
				color: #2092DD;
			}
		}

		:deep(.card-subtitle) {
			display: block;
			font-size: 18px;
			text-transform: uppercase;
			align-self: center;
			color: $secondary;
			margin-top: 10px;
			position: relative;
			white-space: nowrap;
		}

		::v-deep(.subtitle-wrapper) {
			display: flex;
			align-items: center;
			gap: 20px;
		}

		::v-deep(.subtitle-line) {
			position: relative;
			height: 2px;
			background-color: #EEE;
			width: 100%;
		}
	}

	.product-content {
		padding-top: 30px;

		.content-heading {
			font-size: 18px;
		}

		::v-deep(.content-text) {
			margin: 20px 0 0 0;
			padding: 0;
			color: $secondary;
			tab-size: 0;
			white-space: normal;
			overflow: hidden;

			p {
				font-weight: 500;
				letter-spacing: 0.01em;
				line-height: 22px;
			}

			@include media-breakpoint-up(lg) {
				font-size: 1.8rem;
				line-height: 2.4rem;
			}
		}
	}

	.variants-wrapper {
		margin-top: 23px;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 15px;

		@include media-breakpoint-up(xl) {
			flex-direction: row;
		}

		.variants-title {
			font-size: 18px;
			letter-spacing: 0.18px;
			line-height: 24px;
		}
	}

	::v-deep(.product-icons-wrapper) {
		margin-top: 20px;
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
	}

	.text-amplification {
		margin-top: 20px;
	}

	::v-deep(.product-variants) {
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
	}

	::v-deep(.btn.secondary) {
		margin-top: 20px;

		@include media-breakpoint-up(lg) {
			margin-top: 0;
		}
	}

	::v-deep(.buttons-wrapper) {
		margin-top: 0;
	}

	.wrapper-btns {
		display: flex;
		flex-direction: column-reverse;
		justify-content: space-between;
		margin-top: 50px;

		@include media-breakpoint-up(lg) {
			flex-direction: row;
		}

		&.tablet {
			align-items: center;

			.buttons-wrapper {
				@include media-breakpoint-up(sm) {
					flex-direction: row;
					margin-top: 20px;
				}

				:deep(.btn.secondary) {
					@include media-breakpoint-up(sm) {
						margin-top: 0;
					}
				}
			}
		}
	}

	:deep(.scroll-btn) {

		margin-left: auto;
		margin-right: auto;

		@include media-breakpoint-up(lg) {
			margin-left: 0;
		}
	}

	.product-compare {
		display: none; // temporary hidden
		position: absolute;
		top: -40px;
		right: 0;
		z-index: 9;

		@include media-breakpoint-up(xl) {
			top: -80px;
		}

		:deep(svg) {
			//height: 20px;

			path {
				fill: $white;
			}
		}
	}
}
</style>
