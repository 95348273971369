import { Emitter } from '@f/core/Emitter'
import { ReactiveClass } from '@f/core/ReactiveClass'

class DefaultFilterStrategy {
	constructor (filter) {
		this.filter = filter
	}

	valueActiveChanged (filterValue) {}

	getActiveIds () {
		const ids = this.filter.values
			.filter(value => value.active)
			.map(value => value.id)

		if (ids.length === 0) return false
		return ids
	}
}

class OnlyOneActiveFilterStrategy extends DefaultFilterStrategy {
	valueActiveChanged (filterValue) {
		this.filter.values
			.filter(value => value.alias !== filterValue.alias)
			.forEach(value => value.setActive(false))
	}

	getActiveIds () {
		const activeValue = this.filter.values.find(value => value.active)
		if (!activeValue) return false
		return activeValue.id
	}
}

function getStrategyByFilterAlias (alias, filter) {
	if (['frequency', 'technology', 'connector'].includes(alias)) return new OnlyOneActiveFilterStrategy(filter)
	return new DefaultFilterStrategy(filter)
}
export class FiltersService {
	static alias = 'filters'

	constructor (app) {
		this.app = app
	}

	getFilterManagerForTraitsObject (traits, order = []) {
		const filters = order.map(alias => {
			if (!traits[alias]) return false

			return new Filter(
				alias,
				traits[alias].map(value => FilterValue.create(value))
			)
		}).filter(item => !!item)

		return new FiltersManager(filters)
	}

	async getFilterManagerWithAllFilters (order) {
		const rextService = this.app.getService('rext')
		const filters = await rextService.getFilters()
		return this.getFilterManagerForTraitsObject(filters, order)
	}

	async getFilterManagerWithSelectedFilters (aliases) {
		const rextService = this.app.getService('rext')
		const traits = await rextService.getFilters()

		const filters = aliases
			.filter(alias => traits[alias])
			.map(alias => new Filter(
				alias,
				traits[alias].map(value => FilterValue.create(value))
			))
		return new FiltersManager(filters)
	}
}

class FiltersManager extends Emitter {
	constructor (filters) {
		super()
		this.setFilters(filters)
	}

	setFilters (filters) {
		this.filters = filters
		this.filters.forEach(filter => filter.on('state:changed', this.filterStateChanged.bind(this)))
	}

	filterStateChanged () {
		this._emit('state:changed')
	}

	getQuery () {
		return this.filters
			.map(filter => [filter.alias, filter.getActiveAliases()])
			.filter(([filterAlias, activeAliases]) => activeAliases.length)
			.reduce((query, [filterAlias, activeAliases]) => {
				query[filterAlias] = activeAliases
				return query
			}, {})
	}

	getQueryWithIds () {
		return this.filters
			.map(filter => [filter.alias, filter.getActiveIds()])
			.filter(([filterAlias, activeIds]) => activeIds)
			.reduce((query, [filterAlias, activeIds]) => {
				query[filterAlias] = activeIds
				return query
			}, {})
	}

	setActiveFiltersFromQuery (query) {
		try {
			this.filters.forEach(filter => {
				filter.setActiveAliases(query[filter.alias])
			})
		} catch (err) {
			console.error(err)
		}
	}

	getFiltersByAlias (alias) {
		return this.filters.find(filter => filter.alias === alias)
	}
}

class Filter extends Emitter {
	constructor (alias, values) {
		super()
		this.alias = alias
		this.strategy = getStrategyByFilterAlias(alias, this)
		this.setValues(values)
	}

	setValues (values) {
		this.values = values
		this.values.forEach(value => value.on('active:changed', this.valueActiveChanged.bind(this)))
	}

	valueActiveChanged (filterValue) {
		this.strategy.valueActiveChanged(filterValue)
		this._emit('state:changed')
	}

	getActiveAliases () {
		return this.values
			.filter(value => value.active)
			.map(value => value.alias)
	}

	getActiveIds () {
		return this.strategy.getActiveIds()
	}

	setActiveAliases (aliases = []) {
		if (!Array.isArray(aliases)) aliases = [aliases]

		this.values.forEach(value => {
			value.setActive(aliases.includes(value.alias))
		})
	}
}

class FilterValue extends ReactiveClass {
	active = false

	constructor ({ id, alias }, active) {
		super()
		this.id = id
		this.alias = alias
		this.active = active
	}

	toggle () {
		this.active = !this.active
		this._emit('active:changed', this)
	}

	setActive (active) {
		this.active = active
	}
}
