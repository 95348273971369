<template>
	<div
		class="buttons-wrapper"
		v-if="value.length"
	>
		<base-link
			v-for="(item, i) in value"
			v-bind="item.button"
			:key="item.button.value + i + item.button.content + item.button.value"
		>
			<base-svg v-if="item.icon" :name="item.icon.path" />
			<base-font variant="button-text"> {{item.button ? item.button.content : 'Translation not set'}} </base-font>
		</base-link>
	</div>
</template>

<script>
import BaseFont from '~/website/front/components/atoms/BaseFont/BaseFont'
import BaseSvg from '~/website/front/components/atoms/BaseSvg/BaseSvg'
export default {
	components: {
		BaseSvg,
		BaseFont
	},
	props: {
		value: [Object, Array]
	}
}
</script>
<style lang="scss" scoped>
.buttons-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 20px;
	margin-top: 3rem;
	flex-wrap: wrap;

	@include media-breakpoint-up(md) {
		gap: 30px;
		flex-direction: row;
		align-items: flex-start;
		&.left {
			justify-content: flex-start;
		}
		&.center {
			justify-content: center;
		}
		&.right {
			justify-content: flex-end;
		}
	}

	@include media-breakpoint-up(xl) {
		margin-top: 6rem;
	}
}
</style>
