import { config } from '@f/config/config.js'
import { Seo } from '~/cms/front/core/seo/Seo'

class ProductSeo extends Seo {
	get openGraph () {
		return [
			{
				property: 'og:title',
				content: this.page.meta.title
			},
			{
				property: 'og:description',
				content: this.page.meta.description
			},
			{
				property: 'og:type',
				content: 'website'
			},
			{
				property: 'og:image',
				content: (() => {
					const productImageUrl = this.page.product?.gallery.length && this.page.product.gallery[0].path
					const layoutImageUrl = this.layout.value?.open_graph.main_picture?.path
					return `${this.app.settings.env.origin}${config.services.rext.imagesUrl}${productImageUrl || layoutImageUrl}?width=1200&height=630&fit=contain&background=transparent`
				})()
			},
			{
				property: 'og:site_name',
				content: this.app.settings.env.origin
			},
			{
				property: 'og:url',
				content: this.app.settings.env.origin + this.page.route.path
			}
		]
	}
}

export { ProductSeo }
