<template>
	<section class="contact-section" v-custom-background="backgroundImage" :style="{'background-color': backgroundColor}">
		<base-container>
			<base-animation
				v-if="value.section_title"
				animation="slideRight"
			>
				<custom-title
					:title="`${prefix}.section_title.title`"
					:subtitle="`${prefix}.section_title.subtitle`"
					:header_tag="value.section_title?.title_tag"
					:color="value.section_title.title_color"
					:transform="value.section_title.title_transform"
					:type="value.section_title_type"
				/>
			</base-animation>

			<div class="grid-wrapper">
				<base-animation animation="slideRight">
					<div class="text-content">
					<cms-text
						class="contact-section-text"
						:value="`${prefix}.section_description`"
						:props="{
							variant: 'paragraph',
							tag: 'div'
						}"
					/>
					<div class="contact-section-contacts">
						<contact-section-info
							v-for="item in value.contacts"
							:text="item.text"
							:icon="item.icon"
							:type="item.type"
							:key="item.text"
						/>
					</div>
					<buttons :value="value.section_buttons" />
				</div>
				</base-animation>
				<base-animation animation="slideLeft">
					<div class="img-wrapper">
						<cms-image
							:value="`${prefix}.image`"
							:class="{shadow: value.shadow}"
							v-bind="defaultCmsImageBreakpoints"
							lazy
						/>
					</div>
				</base-animation>
			</div>
		</base-container>
	</section>
</template>

<script>
import BaseContainer from '~/website/front/components/atoms/BaseContainer/BaseContainer.vue'
import { backgroundSectionMixin } from '~/website/front/utils/backgroundSection.mixin.js'
import CustomTitle from '~/website/front/components/atoms/CustomTitle/CustomTitle.vue'
import Buttons from '~/website/front/sections/Buttons/Buttons.vue'
import ContactSectionInfo from '~/website/front/sections/ContactSection/components/ContactSectionInfo.vue'
import BaseAnimation from '@f/components/BaseAnimation/BaseAnimation'
import { defaultCmsImageBreakpoints } from '~/cms/front/mixins/defaultCmsImageBreakpoints'
export default {
	components: { ContactSectionInfo, Buttons, CustomTitle, BaseContainer, BaseAnimation },
	mixins: [backgroundSectionMixin, defaultCmsImageBreakpoints],
	props: {
		prefix: String,
		value: Object
	}
}
</script>

<style lang="scss" scoped>

:deep(.img-wrapper) {
	.image-wrapper.shadow {
		img {
			box-shadow: 6px 6px 20px #0000003D;
		}
	}
	min-height: 200px;

	@include media-breakpoint-up(lg) {
		min-height: 300px;
	}

	.base-image {
		height: 100%;
		width: 100%;
		object-fit: cover;
		border-radius: 10px;
		margin-bottom: 0;
	}
}

.contact-section{
	padding-bottom: 6rem;
	background-color: #fff;

	@include media-breakpoint-up(xl) {
		padding-bottom: 10rem;
	}

	:deep(.contact-section-text){
		height: fit-content;
	}

	.grid-wrapper {
		display: grid;
		grid-template-rows: repeat(1, 1fr);
		grid-template-columns: 1fr;
		gap: 4rem;

		&.reversed {
			grid-auto-flow: dense;
			direction: rtl;
		}

		@include media-breakpoint-up(xl){
			gap: 15rem;
		}

		@include media-breakpoint-up(lg) {
			grid-template-columns: 3fr 4fr;
			align-items: center;
		}

		:deep(.text-content) {
			.section-elements {

				h1,
				h2,
				h3 {
					direction: ltr;
					font-weight: 600;
					text-transform: uppercase;
					font-size: 20px;
					color: $secondary;
					font-style: normal;
					font-family: $primary-font;
					margin-bottom: 19px;
					@include media-breakpoint-up(lg){
						margin-bottom: 30px;
						font-size: 38px;
					}
				}

				.ql-editor {
					padding: 0;
					p {
						color: $secondary;
						font-weight: 500;
						direction: ltr;
						font-size: 14px;
						line-height: 22px;
						letter-spacing: 0.14px;
						@include media-breakpoint-up(xl) {
							font-size: 18px;
							line-height: 24px;
							letter-spacing: 0.18px;
						}
					}
				}
			}

		}

		:deep(.buttons-wrapper){
			align-items: center;
			justify-content: center;
			@include media-breakpoint-up(lg){
				justify-content: inherit;
			}
		}
	}

	.contact-section-contacts{
		> * {
			margin-top: 10px;
		}
	}

	.img-wrapper {
		&.content-wrapper {
			margin-bottom: 0;
		}
		@include media-breakpoint-up(lg){
			grid-column: 2;
		}

	}

	.text-content {
		grid-column: 1;
	}
}
</style>
