<template>
	<div
		class="search-product-section"
		v-custom-background="backgroundImage"
		:style="{'background-color': backgroundColor}"
	>
		<base-container>
			<div class="section-inner">
				<base-animation
					v-if="value.section_title"
					animation="slideRight"
				>
					<custom-title
						:title="`${prefix}.section_title.title`"
						:subtitle="`${prefix}.section_title.subtitle`"
						:header_tag="value.section_title?.title_tag"
						:color="value.section_title.title_color"
						:transform="value.section_title.title_transform"
						:type="value.section_title_type"
					/>
				</base-animation>
				<div class="section-cards">
					<base-animation animation="slideRight">
						<search-by-brand :prefix="prefix" :value="value"/>
					</base-animation>

					<base-animation animation="slideLeft">
						<search-by-parameters :prefix="prefix"/>
					</base-animation>

				</div>
			</div>
		</base-container>
	</div>
</template>

<script>
import BaseContainer from '~/website/front/components/atoms/BaseContainer/BaseContainer'
import CustomTitle from '~/website/front/components/atoms/CustomTitle/CustomTitle'
import SearchByBrand from '~/website/front/sections/SearchProductSection/components/SearchByBrand'
import SearchByParameters from '~/website/front/sections/SearchProductSection/components/SearchByParameters'
import { backgroundSectionMixin } from '~/website/front/utils/backgroundSection.mixin.js'
import BaseAnimation from '@f/components/BaseAnimation/BaseAnimation'
export default {
	components: { SearchByParameters, SearchByBrand, CustomTitle, BaseContainer, BaseAnimation },
	props: {
		prefix: String,
		value: Object
	},
	mixins: [backgroundSectionMixin]
}
</script>

<style lang="scss" scoped>
.search-product-section {
	@include spacing-section();
	background-color: #fff;

	.section-cards{
		display: flex;
		flex-direction: column;
		gap: 20px;
		@include media-breakpoint-up(lg) {
			display: grid;
			grid-template-columns: 5fr 7fr;
			align-items: center;
			gap: 30px;
		}
	}
}
</style>
