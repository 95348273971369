var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"company-details-map"},[_c('div',{staticClass:"map-wrapper"},[_c('cms-image',{attrs:{"src":"contact/map-bg.jpg","base":{
				width: 330,
				height: 268,
				fit: 'cover'
			},"sm":{
				width: 510,
				height: 414,
				fit: 'cover'
			},"md":{
				width: 720,
				height: 584,
				fit: 'cover'
			},"lg":{
				width: 480,
				height: 390,
				fit: 'cover'
			},"xl":{
				width: 513,
				height: 416,
				fit: 'cover'
			},"xxl":{
				width: 587,
				height: 481,
				fit: 'cover'
			}}}),_c('div',{staticClass:"map-overflow"},[_c('base-link',{staticClass:"map-link",attrs:{"target":"_blank","url":"https://goo.gl/maps/VqdyqNut7J4gnc99A","title":"Find Quwireless on google maps","button_type":"secondary"}},[_c('base-font',{attrs:{"weight":"bold"}},[_vm._v("Find us on google maps")])],1)],1)],1),_c('div',{staticClass:"map-buttons-wrapper"},[_c('base-link',{staticClass:"white",attrs:{"type":"internal","to":"where-to-buy","button_type":"secondary","title":"Check where you can buy 4G/LTE, 5G or Wi-Fi antennas"}},[_c('base-font',{attrs:{"color":"primary","weight":"bold"}},[_vm._v("Buy")])],1),_c('base-link',{staticClass:"border-white",attrs:{"type":"internal","to":"ask-a-question","button_type":"secondary","title":"Get technical support from our experts"}},[_c('base-font',{attrs:{"color":"white","weight":"bold"}},[_vm._v("Technical support")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }