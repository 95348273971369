<template>
	<div class="company-details-content">

		<div class="content-inner">
			<base-svg name="logo" class="logo-icon"/>

			<div class="company-details-description">
				<div class="description-title-wrapper">
					<base-font weight="bold" class="description-title">{{data?.owner}}</base-font>
				</div>
				<base-font variant="paragraph" :html="address" />
			</div>

			<div class="company-details-numbers">
				<a
					v-for="number in data?.numbers"
					:key="number.numer"
					:href="`tel:${number.numer?.replaceAll(' ', '')}`"
					:title="`Call to experts in outdoor 4G/LTE, 5G and Wi-Fi antennas`"
					class="number-wrapper"
				>
					<base-svg name="phone_footer" />
					<base-font
						weight="bold"
						uppercase
						size="h1"
						class="phone"
					>
						{{number.numer}}
					</base-font>
				</a>
			</div>

			<div class="company-details-emails">
				<div
					v-for="email in data?.emails"
					:key="email?.email"
					class="email-wrapper"
				>
					<base-svg name="mail_footer" />
					<base-font color="primary" weight="bold"> {{email?.text}}:</base-font>
					<a
						:href="`mailto:${email?.email}`"
						:title="`You can send an email to us to this email: ${email?.email}`"
						class="email-link"
					>
						<base-font
							class="email"
							weight="semibold"
						>
							{{email?.email}}
						</base-font>
					</a>
				</div>
			</div>
		</div>

		<div class="company-details-info">
			<div class="info-item">
				<base-font><strong>VAT ID:</strong> {{data?.vatid}}</base-font>
			</div>
			<div class="info-item">
				<base-font><strong>REGON:</strong> {{data?.regon}}</base-font>
			</div>
			<div class="info-item">
				<base-font><strong>KRS:</strong> {{data?.krs}}</base-font>
			</div>
			<div class="info-item">
				<base-font><strong>EORI:</strong> {{data?.EORI}}</base-font>
			</div>
		</div>

	</div>
</template>

<script>
import BaseFont from '~/website/front/components/atoms/BaseFont/BaseFont.vue'
export default {
	components: { BaseFont },
	props: {
		data: Object
	},

	computed: {
		address () {
			return `${this.data?.streetAddress},<br />${this.data?.postalCode} ${this.data?.addressLocality}`
		}
	}
}
</script>

<style lang="scss" scoped>
.company-details-content {
	background-color: $white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	border-radius: 0 10px 10px 0;
	@include media-breakpoint-up(xl) {
		padding-top: 60px;
	}
	.content-inner {
		padding: 30px 20px 20px 20px;
		width: 100%;
		@include media-breakpoint-up(xxl) {
			padding: 60px 50px 50px 50px;
		}
		.logo-icon {

			:deep(svg) {
				max-width: 330px;
				width: 100%;
			}

		}
	}

	.company-details-description {
		margin-top: 20px;
		@include media-breakpoint-up(xl) {
			margin-top: 40px;
		}
		.description-title-wrapper{
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 20px;

			&:after, &:before {
				@include pseudo;
				height: 2px;
				opacity: .3;
				background: linear-gradient(90deg, rgb(51, 51, 51) 0%, rgba(51, 51, 51, 0) 100%);
				width: 30%;
			}

			&:before {
				transform: rotate(180deg);
			}

			.description-title {
				font-size: 18px;
				line-height: 24px;

			}
		}

	}

	.company-details-numbers{
		margin-top: 30px;

		.number-wrapper{
			display: flex;
			align-items: center;
			justify-content: center;
			text-decoration: none;

			&:hover {
				:deep(.phone) {
					color: $primary;
				}
			}

			.svg-icon {
				:deep(svg) {
					width: 20px;
					height: 20px;
					margin-right: 10px;
					@include media-breakpoint-up(xl) {
						width: 39px;
						height: 39px;
						margin-right: 20px;
					}
				}
			}
			:deep(.phone) {
				font-size: 24px;
				line-height: 30px;
				@include transition;
				@include media-breakpoint-up(xl) {
					font-size: 54px;
					line-height: 60px;
				}
			}
		}
	}

	.company-details-emails{
		margin-top: 20px;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;

		@include media-breakpoint-up(xxl) {
			flex-direction: row;
			margin-top: 40px;
			gap: 30px;
		}
		.email-wrapper {
			display: flex;
			align-items: center;
			@include media-breakpoint-up(lg) {
				margin-bottom: 1rem;
			}
			@include media-breakpoint-up(xxl) {
				margin-bottom: 0;
			}

			.svg-icon {
				margin-right: 6px;
				max-height: 18px;
			}

			.email-link {
				margin-left: 20px;
				@include transition;

				:deep(.email) {
					@include transition;
				}

				&:hover {
					color: $primary;

					:deep(.email) {
						color: $primary;
					}
				}

				.email {
					@include transition;
					font-size: 16px;
					line-height: 28px;
					@include media-breakpoint-up(lg) {
						font-size: 22px;
					}
				}
			}
		}
	}

	.company-details-info{
		background-color: #F6F6F6;
		width: 100%;
		display: flex;
		justify-content: center;
		padding: 20px;
		border-bottom: 1px solid #e9e9e9;
		flex-direction: column;
		align-items: center;
		@include media-breakpoint-up(lg) {
			flex-direction: row;
			gap: 40px;
		}
		.info-item{
			display: flex;
		}
	}
}
</style>
