<template>
    <div class="section-wrapper section-wrapper--dark" v-if="recommendedArticles.length">
		<base-container>
			<cms-text v-if="langs.recommended_title" :props="{variant: 'heading', tag: 'h2', color: null }" class="h2" :additionalHtml="langs.recommended_title"/>
			<grid-template cols="6" className="grid-wrapper" :container="false" v-if="recommendedArticles">
				<recommended-article v-for="article in recommendedArticles" :key="article.id" :article="article" />
			</grid-template>
		</base-container>
    </div>
</template>

<script>
import RecommendedArticle from '~/blog/front/components/RecommendedArticles/RecommendedArticle'
import GridTemplate from '~/blog/front/components/GridTemplate/GridTemplate'

export default {
	components: {
		RecommendedArticle,
		GridTemplate
	},
	computed: {
		recommendedArticles () {
			return this.$app.page.value.recommendedArticles.slice(0, this.$app.page.value.recommendedArticles.length)
		},
		langs () {
			return this.$app.translator.get('articles')
		}
	},
	async prefetch () {
		await this.$app.translator.prefetch('articles')
	}
}
</script>

<style scoped lang="scss">
::v-deep .h2 {
	margin-bottom: 4rem;
	@include media-breakpoint-up(xl) {
		margin-bottom: 6rem;
	}
}
::v-deep .grid-wrapper {
	grid-template-columns: auto;

	@include media-breakpoint-up(md) {
		grid-template-columns: repeat(2, 1fr);
	}
}

::v-deep .blog-heading {
    margin-bottom: 2rem;
    @include media-breakpoint-up(sm) {
        margin-bottom: 0;
    }
}
.recommended-articles {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-template-rows: auto;
	gap: 2rem;
    @include media-breakpoint-up(sm) {
        padding: 4rem 0;
    }
	@include media-breakpoint-up(md) {
		grid-template-columns: repeat(2, 1fr);
	}
	@include media-breakpoint-up(xl) {
		grid-template-columns: repeat(3, 1fr);
	}
    &-wrapper {
        padding: 2rem 0;
        @include media-breakpoint-up(sm) {
            padding: 4rem;
        }
        display: flex;
        flex-direction: column;
        background: $gray-100;
        margin-top: 2rem;
        @include media-breakpoint-up(xl) {
            margin-top: 4rem;
        }
    }
}
</style>
