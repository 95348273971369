<template>
	<section
		:class="`table-section ${padding || ''}`"
	>
		<base-container>
			<div class="table-section-inner">
				<table class="table-section-table">
					<tr
						v-for="(row, i) in value.table"
						:key="`row-${i}`"
						class="table-section-row"
					>
						<td
							v-for="(column, y) in row"
							:key="`column-${y}`"
							class="table-section-column"
							:class="{heading: i === 0}"
						>
							<base-font
								variant="paragraph"
								:weight="i === 0 ? 'bold' : 'regular'"
							>
								{{column}}
							</base-font>
						</td>
					</tr>
				</table>
			</div>
		</base-container>

	</section>
</template>

<script>
import { paddingSectionMixin } from '~/website/front/utils/paddingSection.mixin.js'

export default {
	props: {
		prefix: String,
		value: Object
	},
	mixins: [paddingSectionMixin]
}
</script>

<style lang="scss" scoped>
.table-section{

	&-inner {}

	&-table {
		border-spacing: 0;
	}

	&-row {}

	&-column {
		padding: 10px;
		border: 1px solid #CFCFCF;

		&.heading {
			font-weight: 700;
		}
	}
}
</style>
