<template>
	<div class="plot-card">
		<div class="title-wrapper">
			<cms-text class="plod-card-title" :value="`${prefix}.cards.${index}.title`" :props="{tag: 'h4', color: 'white', weight: 'bold'}" />
		</div>
		<cms-image
			class="plot-card-image"
			:src="{
				path: plot.image.path,
				alt: `${plot.title} of ${productName}`,
				title: `${plot.title} of ${productName}`
			}"
			:base="{width: 1020, height: 388, fit: 'contain', background: 'transparent'}"
			lazy
		/>
	</div>
</template>

<script>
export default {
	props: {
		plot: Object,
		index: Number,
		prefix: String,
		productName: {
			type: String,
			default: ''
		}
	}
}
</script>

<style lang="scss" scoped>
.plot-card {
	.title-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $primary;
		border-radius: 5px;
		text-transform: uppercase;
		margin-bottom: 10px;
		padding: 15px 0;

		.plod-card-title{
			font-size: 18px;
			line-height: 24px;
		}
	}

	&-image {
		text-align: center;
	}

	:deep(img) {
		max-width: 100%;
		margin: 0 auto;
	}
}
</style>
