import { Breadcrumbs } from '~/website/front/core/elements/Breadcrumb/Breadcrumbs.js'

export class BreadcrumbsBuilder {
	constructor (app) {
		this.app = app
	}

	build () {
		return new Breadcrumbs(this.app.layout.value.menu.items)
	}
}
