var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"questions-section"},[_c('base-container',[_c('div',{staticClass:"section-inner"},[(_vm.value.section_title)?_c('custom-title',{attrs:{"title":`${_vm.prefix}.section_title.title`,"subtitle":`${_vm.prefix}.section_title.subtitle`,"header_tag":_vm.value.section_title?.title_tag,"color":_vm.value.section_title.title_color,"transform":_vm.value.section_title.title_transform,"type":_vm.value.section_title_type}}):_vm._e(),(_vm.value.questions.length)?_c('basic-accordion',_vm._l((_vm.value.questions),function(item,i){return _c('base-animation',{key:i,attrs:{"animation":_vm.evenAnimation(i, 'slideRight', 'slideLeft')}},[_c('accordion-item',{attrs:{"type":"primary"},scopedSlots:_vm._u([{key:"accordion-trigger",fn:function(){return [_c('base-font',{staticClass:"accordion-button__title",attrs:{"weight":"bold","color":"white"}},[_vm._v(_vm._s(item.item_title))])]},proxy:true},_vm._l((item.items_answers),function(subitem,j){return {key:"accordion-content",fn:function(){return [(subitem.type === 'text')?_c('cms-text',{key:`data${j}`,staticClass:"accordion-content__description",attrs:{"value":`${_vm.prefix}.questions.${i}.items_answers.${j}.value.item_text`,"props":{
									variant: 'paragraph',
									color: 'black',
									tag: 'div'
								}}}):_vm._e(),(subitem.type === 'image')?_c('cms-image',{key:`data${j}`,attrs:{"value":`${_vm.prefix}.questions.${i}.items_answers.${j}.value.item_image`,"base":{height: 300}}}):_vm._e()]},proxy:true}})],null,true)})],1)}),1):_vm._e(),_c('div',{staticClass:"questions-buttons-wrapper"},[_c('questions-button',{attrs:{"text":_vm.lang.faq_section?.text || '',"primaryLink":{
						type: 'internal',
						to: 'contact',
						title: _vm.lang.faq_section?.button_title,
						content: _vm.lang.faq_section?.button_text,
						button_type: 'primary'
					}}})],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }