<template>
	<opinion-reviews-item
		:class="{active: isActive, review__item: true}"
		:opinion="{
			...slide,
			pageLink: link
		}"
	/>

</template>

<script>
import OpinionReviewsItem from '~/opinions/front/components/OpinionReviewsItem.vue'
export default {
	components: {
		OpinionReviewsItem
	},
	props: {
		slide: Object,
		isActive: {
			type: Boolean,
			default: false
		},
		link: Object
	}
}
</script>

<style lang="scss" scoped>
.review__cards-inner {

	.review__item{
		overflow: hidden;
		transition: all 1s ease-in-out;
		opacity: 0.3;
		min-width: 100%;

		&.active,
		&.swiper-slide-active {
			opacity: 1;
		}

		&.transparent {
			opacity: 0;
			transition: none;
		}
	}
}
</style>
