<template>
	<span class="product-menu-item" :title="getSectionTitle" @click="scrollToSection()" :class="{active: isItemActive}">
		<base-font class="menu-item-text">{{getSectionTitle}}</base-font>
	</span>
</template>

<script>
import BaseFont from '~/website/front/components/atoms/BaseFont/BaseFont'
export default {
	components: { BaseFont },
	props: {
		item: Object,
		index: Number,
		activeSection: Number
	},
	computed: {
		getSectionTitle () {
			if (this.item.section.alias === 'why_our_enclosures') return `${this.lang.why_our_product_prefix} ${this.$app.page.product?.name}`
			else if (this.item.section.value.section_short_title) return this.item.section.value.section_short_title.toLowerCase()
			else if (this.item.section.value.menu_info?.title) return this.item.section.value.menu_info.title.toLowerCase()
			else if (this.item.section.value.section_title?.title) return this.item.section.value.section_title.title.toLowerCase()
			else if (typeof this.item.section.value.section_title === 'string') return this.item.section.value.section_title.toLowerCase()
			else if (this.item.section.value.grid_title) return this.item.section.value.grid_title.toLowerCase()
			return null
		},
		isItemActive () {
			return this.index === this.activeSection
		},
		lang () {
			return this.$app.translator.get('products')
		}
	},
	methods: {
		scrollToSection () {
			const section = document.querySelector(`[alias=${this.item.section.alias}_${this.item.index}]`)
			if (!section) return null
			this.$nextTick(() => {
				window.scrollTo({ top: section.offsetTop + 75, behavior: 'smooth' })
			})
		}
	},
	async prefetch () {
		await this.$app.translator.prefetch('products')
	}
}
</script>

<style lang="scss" scoped>
	.product-menu-item {
		text-decoration: none;
		position: relative;
		&:hover {
			cursor: pointer;
		}
		&.active{
			&:after {
				content: '';
				display: block;
				position: absolute;
				bottom: -12px;
				right: 0;
				left: 0;
				width: 100%;
				height: 3px;
				background-color: $primary;
			}

			.menu-item-text {
				color: $primary;
				font-weight: 700;
			}
		}
	}
</style>
