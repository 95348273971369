<template>
	<div class="nav-screen" v-custom-background="'homepage/slider/slider_bg_1.jpg'">
			<div class="left-row">
			<base-link to="homepage" class="navbar-logo" @click="toggleMobileMenu">
				<base-svg name="logo" class="logo-icon"/>
			</base-link>
			<div class="left-row-content">
				<base-link class="navbar-button" v-bind="buy_link" @click="toggleMobileMenu"> BUY </base-link>

				<!-- Hide translations but not remove at all -->
				<base-translations v-if="false"/>
			</div>
		</div>
		<div class="right-row">
			<button-hamburger :activeMenu="isMobileMenuActive" @handleMenuToggle="toggleMobileMenu"/>
			<social-mobile-icons />
		</div>
	</div>
</template>

<script>
import ButtonHamburger from '~/website/front/components/organisms/BaseMenu/components/Mobile/atoms/ButtonHamburger'
import SocialMobileIcons from '~/website/front/components/organisms/BaseMenu/components/Mobile/atoms/SocialMobileIcons'
import BaseTranslations from '~/website/front/components/molecules/BaseTranslations/BaseTranslations'
export default {
	name: 'NavMobileScreen',
	components: { BaseTranslations, SocialMobileIcons, ButtonHamburger },
	data () {
		return {
			buy_link: {
				type: 'internal',
				to: 'where-to-buy',
				title: 'where to buy',
				button_type: 'primary'
			}
		}
	},
	props: {
		isMobileMenuActive: Boolean
	},
	methods: {
		toggleMobileMenu () {
			this.$emit('toggleMobileMenu')
		}
	}
}
</script>

<style lang="scss" scoped>
.nav-screen {
	display: flex;
	justify-content: space-between;
	gap: 55px;
	padding: 22px 30px 21px 17px;
	background-position: center;
	background-size: cover;
	position: relative;

	&:after {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,.5);
		z-index: 0;
	}

	.left-row {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		z-index: 9;
		.navbar-logo {

			.logo-icon {
				padding-top: 15px;
				:deep(svg) {
					width: 100%;

					#Group_1 {
						fill: $white;
					}

					#Group_1586 path {
						fill: $white;
					}

					#path_7108 {
						fill: $white;
					}
				}
			}
		}

		.left-row-content {
			margin-top: 30px;
			display: flex;
			align-items: center;
			gap: 20px;
			position: relative;

			.navbar-button{
				background-color: $primary;
				color: white;

				&:hover {
					color: $secondary;
					background-color: $white;
				}
			}
			.translate {
				&:after {
					content: '';
					display: block;
					position: absolute;
					top: 0;
					right: 0;
					left: 0;
					bottom: 0;
					margin: auto;
					height: 50%;
					width: 1px;
					background-color: $white;
				}

				:deep(p) {
					color: $white;
					opacity: .5;

					&.active {
						opacity: 1;
					}
				}
			}
		}
	}

	.right-row {
		z-index: 9;

		.hamburger {
			margin-bottom: 28px;

			:deep(.hamburger-inner) {

				.hamburger-lines {

					&:after,
					&:before {
						background-color: $white;
					}

					&:after {
						transform: rotate(45deg);
					}

					&:before {
						transform: rotate(-45deg);
					}
				}
			}
		}
	}
}
</style>
