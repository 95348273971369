import DedicatedAntennasViewComponent from './DedicatedAntennasView.vue'
import GroupByBandsViewComponent from './GroupByBandsView.vue'
import DefaultViewComponent from './DefaultView.vue'

class SubcategoryView {
	static keepAlive = true
	component
	app
	isLoading = false

	constructor (app, page) {
		this.app = app
		this.page = page
	}
}

export class DedicatedAntennasView extends SubcategoryView {
	static keepAlive = true
	component = DedicatedAntennasViewComponent
	form = null
	response = []
	dedicatedAntennas = []
	dedicatedAccessories = []
	compatibleAntennasLists = []

	async init () {
		this.form = await this.app.getService('brand').getFormByRoute(this.page.route)

		this.form.on('brand:changed', () => {
			this.isLoading = true
			this.form.activeBrand.goTo(this.app)
		})

		this.form.on('model:changed', () => {
			this.isLoading = true
			this.form.activeModel.goTo(this.app)
		})

		this.fetchProducts()
	}

	async fetchProducts () {
		this.isLoading = true
		try {
			if (this.form.activeModel) await this.fetchForModel()
			else if (this.form.activeBrand) await this.fetchForBrand()
		} catch (err) {
			console.error(err)
		}
		this.isLoading = false
	}

	async fetchForModel () {
		const service = this.app.getService('rext')
		const response = await service.getDedicatedAntennasListsForModel(this.form.activeModel.id)
		this.dedicatedAntennas = response.dedicatedAntennas
		this.dedicatedAccessories = response.dedicatedAccessories
		this.compatibleAntennasLists = response.compatibleAntennasLists
	}

	async fetchForBrand () {
		const service = this.app.getService('rext')
		const response = await service.getDedicatedAntennasForBrand(this.form.activeBrand.id)
		this.dedicatedAntennas = response.dedicatedAntennas
		this.dedicatedAccessories = []
		this.compatibleAntennasLists = response.compatibleAntennasLists
	}
}

class FiltersView extends SubcategoryView {
	constructor () {
		super(...arguments)
		this.createFilterManager()
	}

	async init () {
		this.filtersManager.setActiveFiltersFromQuery(this.page.query)
		await this.getProducts()
	}

	async getProducts () {}

	createFilterManager () {
		if (!this.page.subcategory.traits) return false

		const service = this.app.getService('filters')
		this.filtersManager = service.getFilterManagerForTraitsObject(this.page.subcategory.traits, ['frequency', 'mimo', 'directionality'])
		this.filtersManager.on('state:changed', () => {
			this.app.router.push({ query: this.filtersManager.getQuery() })
		})
	}
}

export class GroupByBandsView extends FiltersView {
	component = GroupByBandsViewComponent

	products = {
		narrowBandAntennas: [],
		wideBandAntennas: []
	}

	async getProducts () {
		this.isLoading = true
		const { narrowBandAntennas, wideBandAntennas } = await this.app.getService('rext').getLTEAntennaFilter({
			subcategory: this.page.subcategory.id,
			filterParameters: this.filtersManager.getQueryWithIds()
		})
		this.products.narrowBandAntennas = narrowBandAntennas || []
		this.products.wideBandAntennas = wideBandAntennas || []
		this.isLoading = false
	}
}

export class DefaultView extends FiltersView {
	component = DefaultViewComponent
	products = []

	async getProducts () {
		this.isLoading = true
		const products = await this.app.getService('rext').getAntennaFilter({
			subcategory: this.page.subcategory.id,
			filterParameters: this.filtersManager.getQueryWithIds()
		})
		this.products = products || []
		this.isLoading = false
	}
}

export class AccesoryView extends SubcategoryView {
	component = DefaultViewComponent
	products = []

	async init () {
		await this.getProducts()
	}

	async getProducts () {
		this.isLoading = true
		const products = await this.app.getService('rext').getAccessory({
			product: {
				subcategory: {
					alias: this.page.subcategory.alias
				}
			}
		})
		this.products = products || []
		this.isLoading = false
	}
}

export class EnclosureView extends SubcategoryView {
	component = DefaultViewComponent
	products = []

	async init () {
		await this.getProducts()
	}

	async getProducts () {
		this.isLoading = true
		const products = await this.app.getService('rext').getEnclosures()
		this.products = products || []
		this.isLoading = false
	}
}
