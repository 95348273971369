<template>
	<section class="article-blocks-section" :class="setPadding">
		<base-container>
			<cms-text class="section-title" :value="`${prefix}.section_title`" :props="{variant: 'h2', tag: value.header_tag }" v-if="value.section_title"/>

			<cms-text
				v-if="value.text_content_before"
				:value="`${prefix}.text_content_before`"
				:props="{variant: 'paragraph', tag: 'div'}"
				class="paragraph paragraph-top"
			/>

			<div class="article-section-wrapper" v-if="value.items.length !== 0">
				<article-block-item
					v-for="(item, i) in value.items"
					:prefix="`${prefix}.items.${i}`"
					:key="item.title"
					:item="item"
				/>
			</div>

			<cms-text
				v-if="value.text_content_after"
				:value="`${prefix}.text_content_after`"
				:props="{variant: 'paragraph', tag: 'div'}"
				class="paragraph paragraph-bottom"
			/>
		</base-container>
	</section>
</template>

<script>
import ArticleBlockItem from '~/blog/front/sections/ArticleBlocksSection/components/ArticleBlockItem'
import BaseContainer from '~/website/front/components/atoms/BaseContainer/BaseContainer'
export default {
	components: { BaseContainer, ArticleBlockItem },
	props: {
		value: Object,
		prefix: String
	},

	computed: {
		setPadding () {
			const obj = {}
			this.value.padding.forEach(item => {
				obj[item] = true
			})
			return obj
		}
	}
}
</script>

<style lang="scss" scoped>
.article-blocks-section {
	&.top {
		padding-top: 4rem;
	}
	&.bottom {
		padding-bottom: 4rem;
	}

	.section-title{
		margin-bottom: 3rem;
	}

	.article-section-wrapper {
		display: flex;
		flex-direction: column;
		gap: 3rem;
	}

	:deep(.paragraph) {
		direction: ltr;

		&.paragraph-bottom {
			margin-top: 3rem;
		}
	}
}
</style>
