import { Page } from '~/cms/front/core/pages/Page'
import Component from './AskAQuestionPage.vue'
import { Form } from '@f/core/forms/Form.js'
import { InputCheckbox, InputEmail, InputFile, InputText, Textarea } from '@f/core/forms/inputs/index.js'
import { email, extensions, required } from 'utils/inputs/validation-front.js'
import { phoneNumber } from 'utils/inputs/validation.js'

class AskAQuestionPage extends Page {
	static alias = 'ask-a-question'
	component = Component
	form

	get header () {
		if (!this.value.title) return false
		return {
			title: this.value.title
		}
	}

	async init () {
		await super.init(...arguments)
		await this.prefetchLangs()
		this.createForm()
	}

	async prefetchLangs () {
		await this.app.translator.prefetch('contact')
		await this.app.translator.prefetch('errors')
		await this.app.translator.prefetch('forms')
	}

	createForm () {
		const langs = this.app.translator.get('forms').supportForm
		this.form = new Form()
		this.form.addInput(InputText.create({
			translations: () => langs.inputName,
			attribute: 'firstName',
			name: 'name',
			validation: [required()]
		}))
		this.form.addInput(InputText.create({
			translations: () => langs.inputSurname,
			attribute: 'lastName',
			name: 'surname',
			validation: []
		}))
		this.form.addInput(InputEmail.create({
			translations: () => langs.inputEmail,
			attribute: 'email',
			name: 'email',
			validation: [email()]
		}))
		this.form.addInput(InputText.create({
			translations: () => langs.inputPhoneNumber,
			attribute: 'phoneNumber',
			name: 'phoneNumber',
			validation: [phoneNumber()]
		}))
		this.form.addInput(InputText.create({
			translations: () => langs.inputCompany,
			attribute: 'companyName',
			name: 'company',
			validation: [required()]
		}))

		this.form.addInput(Textarea.create({
			translations: () => langs.inputTextarea,
			attribute: 'content',
			name: 'content',
			validation: [required()]
		}))
		this.form.addInput(InputFile.create({
			translations: () => langs.inputFile,
			attribute: 'attachments',
			name: 'attachments',
			multiple: true,
			validation: [extensions(['.pdf', '.docx', '.doc', '.jpg', '.png'])]
		}))
		this.form.addInput(InputCheckbox.create({
			translations: () => langs.inputPolice,
			attribute: 'police',
			name: 'police',
			validation: [required()]
		}))
	}
}

export { AskAQuestionPage }
