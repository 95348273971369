const structuredDataPlugin = {
	async beforeBuild (response, context, app) {
		const manager = context.router.app.$app.websiteStructuredDataManager
		response.structuredData = manager.script
	},

	async rendered (response, context, app) {
		response.body = response.body.replace('</head>', `${response.structuredData}</head>`)
	}
}

export default structuredDataPlugin
