import component from '~/blog/front/core/pages/ArticleListPageKnowledge/ArticleListPageKnowledge.vue'
import { Article } from '~/blog/front/core/entities/Article'
import { AbstractArticleLazyListPage } from '~/blog/front/core/pages/AbstractArticleLazyList/AbstractArticleLazyList'

class ArticleListPageKnowledge extends AbstractArticleLazyListPage {
	static alias = 'article-list-page-knowledge'

	component = component

	async fetchArticles () {
		if (this.loading) return
		this.loading = true

		const fetchOptions = {
			app: Object.assign(this.app, { page: this }),
			take: this.loadedArticles + this.take,
			skip: 0,
			where: {
				language: this.app.language,
				category: {
					alias: 'knowledge'
				}
			},
			relations: ['category']
		}
		const { numberOfAllArticles } = await Article.fetch(fetchOptions)
		this.loadedArticles += this.take
		this.allArticlesNumber = numberOfAllArticles

		this.loading = false
	}
}

export { ArticleListPageKnowledge }
