<template>
	<section class="section-wrapper">
		<base-container>
			<div class="heading-wrapper">
				<custom-title
					:use-string="true"
					:title="value.title"
					:subtitle="value.subtitle"
					header_tag="h1"
					header_style="h1"
				/>
			</div>
			<div class="articles-wrapper">
				<skeleton-loader class=" article" v-for="(article, i) in articles" :key="i">
					<base-article :article="article"/>
				</skeleton-loader>
			</div>

			<div
				ref="articlesAfter"
				class="articles-after"
				v-show="hasMoreArticles"
			>
				<svg
					class="articles-loading"
					:class="{'articles-loading--active': isLoading}"
					width="20"
					height="20"
					viewBox="0 0 50 50"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M27 47C14.8497 47 5 36.9264 5 24.5C5 12.0736 14.8497 2 27 2"
						stroke="#0099FF"
						stroke-width="3"
					/>
				</svg>
			</div>
		</base-container>
	</section>
</template>

<script>
import SkeletonLoader from '~/website/front/components/molecules/loaders/SkeletonLoader'
import CustomTitle from '~/website/front/components/atoms/CustomTitle/CustomTitle.vue'
import BaseArticle from '~/blog/front/components/article/BaseArticle.vue'

export default {
	components: {
		BaseArticle,
		CustomTitle,
		SkeletonLoader
	},
	data () {
		return { observer: null }
	},
	computed: {
		articles () { return this.sortArticles(this.$app.page.articles) },

		value () { return this.$app.page.value },

		page () { return this.$app.page },

		langs () { return this.$app.translator.get('articles') },

		hasMoreArticles () {
			return this.$app.page.loadedArticles < this.$app.page.allArticlesNumber
		},

		isLoading () { return this.$app.page.loading }
	},
	methods: {
		toggleDropdown () {
			this.activeDropdown = !this.activeDropdown
			const dropdownNode = this.$refs.dropdown.$el
			if (this.activeDropdown) dropdownNode.style.height = dropdownNode.scrollHeight + 'px'
			else dropdownNode.style.height = '0px'
		},

		loadNext () {
			this.page.fetchArticles()
		},

		sortArticles (articles) {
			return articles?.sort((a, b) => {
				return new Date(a.datePublished).getTime - new Date(b.datePublished).getTime
			})
		},

		createIntersectionObserver (callback) {
			return new IntersectionObserver(entries => {
				entries.map(entry => {
					const intersecting = entry.isIntersecting
					if (callback) callback(intersecting)
				})
			})
		},

		observe () {
			if (!this.observer) return console.error('Observer is not object!')
			if (!this.$refs.articlesAfter) return console.error('ArticlesAfter ref is not element!')

			this.observer.observe(this.$refs.articlesAfter)
		}
	},

	async prefetch () {
		await this.$app.translator.prefetch('articles')
	},

	mounted () {
		this.observer = this.createIntersectionObserver((isIntersecting) => {
			if (!isIntersecting || this.isLoading) return false
			return this.loadNext()
		})
		if (this.$refs.articlesAfter) this.observer.observe(this.$refs.articlesAfter)
	},

	beforeDestroy () {
		this.observer.disconnect()
	}
}
</script>

<style lang="scss" scoped>
@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.section-wrapper {
	@include spacing-section();

	.article {
		width: 100%;
		min-height: 214px;

		& ::v-deep .article {
			height: 100%;
			@include media-breakpoint-landscape() {
				max-height: none;
			}
		}

		@include media-breakpoint-up(xl) {
			& ::v-deep .article {
				max-height: none;
			}
		}
	}

	.articles-wrapper {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: $cards-gap-mobile;
		@include media-breakpoint-up(md) {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
		}
		@include media-breakpoint-up(lg) {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
		}
	}

	.articles-after {
		margin-top: 40px;
		text-align: center;

		@include media-breakpoint-up(lg) {
			margin-top: 60px;
		}
	}

	.articles-loading {
		margin-top: 20px;
		opacity: 0;
		visibility: hidden;
		transition: all .3s liner;

		&--active {
			opacity: 1;
			visibility: visible;
			animation: rotate 2s infinite linear;
		}
	}

}
</style>
