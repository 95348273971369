<template>
	<div
		class="base-animation-content-wrapper show"
	>
		<slot/>
	</div>
</template>

<script>
export default {
	props: {
		animation: {
			type: String,
			default: 'fade'
		},
		duration: {
			type: Number,
			default: 1
		},
		delay: {
			type: Number,
			default: 0
		},
		threshold: {
			type: Number,
			default: 0.5
		}
	}
}
</script>

<style lang="scss" scoped>
.base-animation-content-wrapper {
	height: 100%;
	@include media-breakpoint-up(xl) {
		opacity: 0;

		&.show {
			opacity: 1;
		}
	}
}

</style>
