<template>
	<div class="radio-group">
		<base-font
			class="field-checkbox-label"
			weight="bold"
			uppercase
			size="paragraph"
			:html="label"
		/>

		<div class="radio-group-inputs">
			<input-template
				v-for="radio in radioGroup.inputs"
				:input="radio"
				:key="radio?.attribute"
				:class="{active: radio.name === radioGroup.value}"
			/>
		</div>
		<base-error :error="radioGroup.error[this.$app.page.language]" v-if="radioGroup.error"/>
	</div>
</template>

<script>
import BaseFont from '~/website/front/components/atoms/BaseFont/BaseFont.vue'
import InputTemplate from '~/website/front/components/organisms/InputTemplate/InputTemplate.vue'
import BaseError from '@f/components/BaseError'
export default {
	name: 'FieldRadioGroup',
	components: { InputTemplate, BaseFont, BaseError },
	props: {
		radioGroup: Object,
		label: String
	}
}
</script>

<style lang="scss" scoped>
.radio-group{
	margin-bottom: 20px;

	:deep(.field-checkbox-label){
		margin-bottom: 10px;
	}

	:deep(.error-wrapper) {
		text-align: left;
	}

	.radio-group-inputs{
		display: flex;
		flex-wrap: wrap;
		gap: 20px;

		& > div.active {
			:deep(label) {
				background-color: $white;
				color: $primary;
			}

		}

		:deep(label) {
			@include transition;
			background-color: transparent;
			position: static;
			width: auto;
			height: auto;
			padding: 1rem 3rem;
			font-weight: 700;
			font-size: 16px;
			line-height: 24px;
			text-transform: uppercase;
		}

		:deep(p) {
			margin: 0;
		}
	}

}
</style>
