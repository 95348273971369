<template>
	<div>
		<base-mobile-menu
			v-if="isDeviceMobile"
			:items="menuItems"
			:shouldBgBeBlue="shouldBgBeBlue"
			:title="defaultTitle"
		/>
		<base-desktop-menu
			v-else-if="isDeviceDesktop"
			:items="menuItems"
			:shouldBgBeBlue="shouldBgBeBlue"
			:title="defaultTitle"
		/>

	</div>
</template>
<script>
import BaseDesktopMenu from '~/website/front/components/organisms/BaseMenu/components/Desktop/BaseDesktopMenu'
import BaseMobileMenu from '~/website/front/components/organisms/BaseMenu/components/Mobile/BaseMobileMenu'
export default {
	data: () => ({
		windowWidth: 0,
		activeMenu: null,
		activeMobileMenu: false,
		isScrolled: false,
		shouldBgBeBlue: false
	}),
	props: {
		menuItems: Array
	},
	components: {
		BaseMobileMenu,
		BaseDesktopMenu
	},
	computed: {
		isDeviceMobile () {
			return this.windowWidth < 1200
		},
		isDeviceDesktop () {
			return this.windowWidth >= 1200
		},
		isPageNavColorBlue () {
			return this.$app.page.route.meta.menuBackgroundColor === 'blue'
		},
		defaultTitle () {
			return this.$app.settings.general.seo.title
		}
	},
	methods: {
		onScrollWindow () {
			this.isScrolled = !!window.scrollY
			this.checkShouldBgBeBlue()
		},

		onResizeWindow () {
			this.windowWidth = window.innerWidth
		},

		checkShouldBgBeBlue () {
			this.shouldBgBeBlue = this.isPageNavColorBlue || this.isScrolled
		}
	},
	mounted () {
		if (process.client) {
			this.onScrollWindow()
			this.onResizeWindow()
			window.addEventListener('scroll', this.onScrollWindow)
			window.addEventListener('resize', this.onResizeWindow)
		}
	},
	beforeDestroy () {
		window.removeEventListener('scroll', this.onScrollWindow)
		window.removeEventListener('resize', this.onResizeWindow)
	}
}
</script>
<style lang="scss" scoped>
</style>
