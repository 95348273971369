<template>
	<base-link
		:to="item.to"
		:params="{ param: item.param }"
		class="submenu-link"
		:class="{
			'btn-link': isLinkTypeButton,
			'active-route': isCurrentRoute
		}"
	>
		<base-font
			variant="menu"
			class="submenu-title"
			:class="{
				'bold': isMoreManufacturers
			}"
		>
			{{item.name}}
		</base-font>
	</base-link>
</template>

<script>
import currentRouteMixin from '~/website/front/components/organisms/MenuBreadcrumbs/currentRouteMixin'

export default {
	props: {
		item: {
			type: Object,
			default: () => {
				return {
					children: [],
					link_type: '',
					name: '',
					param: '',
					to: ''
				}
			}
		}
	},

	mixins: [currentRouteMixin],

	computed: {
		isLinkTypeButton () {
			return this.item.link_type === 'button'
		},

		isMoreManufacturers () {
			return this.item.param === 'more-manufacturers'
		}
	}
}
</script>

<style lang="scss" scoped>
.submenu-link.btn-link{
	:deep(.submenu-title){
		text-transform: none!important;
	}
}

:deep(.submenu-title.bold){
	font-weight: 700!important;
}

</style>
