import generateRandomString from 'utils/strings'

class Slide {
	id = generateRandomString(10)
	slider
	thumbnailElement
	image

	constructor (slider, image) {
		this.slider = slider
		this.image = image
	}

	get elementIndex () {
		return this.slider.slides.indexOf(this)
	}

	goTo () {
		this.slider.goToSlide(this)
	}
}

export { Slide }
