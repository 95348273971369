<template>
	<section class="installation-manual" :style="{'background-color': value.background.color}" :class="padding">
		<base-container>
			<div class="installation-manual-inner">
				<custom-title
					v-if="value.section_title"
					:title="`${prefix}.section_title.title`"
					:subtitle="`${prefix}.section_title.subtitle`"
					:header_tag="value.section_title?.title_tag"
					:color="value.section_title.title_color"
					:transform="value.section_title.title_transform"
					:type="value.section_title_type"
				/>

				<div class="installation-manual-items">
					<div
						class="item"
						v-for="(step, i) in value.steps"
						:key="step.image.path"
					>
						<cms-image
							lazy
							:src="{
								alt: `Step ${i + 1}`,
								title: `Mounting manual step ${i + 1} - ${pageProduct?.name}`,
								path: step.image.path
							}"
							v-bind="defaultCmsImageBreakpoints"
						/>
					</div>
				</div>
			</div>
		</base-container>
	</section>
</template>

<script>
import { paddingSectionMixin } from '~/website/front/utils/paddingSection.mixin'
import CustomTitle from '~/website/front/components/atoms/CustomTitle/CustomTitle'
import { productSectionMixin } from '~/catalog/front/sections/product-section.mixin'
import { defaultCmsImageBreakpoints } from '~/cms/front/mixins/defaultCmsImageBreakpoints.js'

export default {
	components: { CustomTitle },
	props: {
		prefix: String,
		value: Object
	},
	mixins: [paddingSectionMixin, productSectionMixin, defaultCmsImageBreakpoints]
}
</script>

<style lang="scss" scoped>
	.installation-manual {
		.installation-manual-inner{
			:deep(.section-title-wr){
				margin-top: 0;
				padding-top: 0;
			}

			.installation-manual-items{
				display: grid;
				grid-auto-rows: 1fr;
				gap: 15px;
				grid-template-columns: repeat(1, minmax(0, 1fr));

				@include media-breakpoint-up(sm) {
					grid-template-columns: repeat(2, minmax(0, 1fr));
				}

				@include media-breakpoint-up(md) {
					grid-template-columns: repeat(3, minmax(0, 1fr));
				}

				@include media-breakpoint-up(lg) {
					grid-template-columns: repeat(4, minmax(0, 1fr));
					gap: 30px;
				}

				:deep(img) {
					max-width: 100%;

				}
			}
		}
	}
</style>
