<template>
	<base-font
		v-bind="props"
		:html="html"
		:contenteditable="editable"
		@blur="saveValue"
	/>
</template>

<script>
import { getValue, setValue } from 'utils/objects'
export default {
	props: {
		value: String,
		layout: Boolean,
		useString: {
			type: Boolean,
			default: false
		},
		noInnerLinks: {
			type: Boolean,
			default: false
		},
		props: Object
	},
	computed: {
		html () {
			if (this.useString) {
				return this.insertBaseLinkWebComponent(this.value)
			}
			let value
			if (this.layout) value = this.$app.layout.value
			else value = this.$app.page.value

			return this.insertBaseLinkWebComponent(getValue(this.value, value, ''))
		},
		mode () {
			if (this.layout) return 'layout'
			return 'page'
		},
		editable () {
			return this.$app.wyswig.mode === this.mode
		}
	},
	methods: {
		saveValue (e) {
			if (!this.editable) return false
			let wrapper = this.$app.page
			if (this.layout) wrapper = this.$app.layout
			setValue(this.value, wrapper.value, e.target.innerHTML)
		},
		insertBaseLinkWebComponent (value) {
			const baseLinks = value.match(/(<a href-.+?>.+?<\/a>)/g)
			if (!baseLinks) return value

			const activeLanguageAlias = this.$app.language

			baseLinks.forEach(link => {
				try {
					const hrefActiveLangReg = new RegExp(`href-${activeLanguageAlias}="(?<href>[^"]+)"`, 'g')
					const hrefActiveLang = link.matchAll(hrefActiveLangReg).next().value?.groups?.href
					const title = link.matchAll(/title="(?<title>[^"]+)"/g).next().value?.groups?.title
					const content = link.matchAll(/<a[^>]*>(?<content>[^<]+)<\/a>/g).next().value?.groups?.content
					const webComponent = `<web-component-base-link to="${hrefActiveLang}" title="${title || ''}">${content}</web-component-base-link>`

					value = value.replace(link, this.noInnerLinks ? content : webComponent)
				} catch (e) {
					console.error(e)
				}
			})

			return value
		}
	}
}
</script>
