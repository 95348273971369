<template>
	<div class="slider-wrapper slider-product">
		<transition :name="slider.slideTransition" mode="out-in">
			<product-slide
				v-if="currentSlide"
				class="slider-inner"
				:slide="currentSlide"
				:handleClick="toggleGallery"
				:product="product"
			/>
		</transition>
		<product-slider-thumbnails
			:slider="slider"
			:product="product"
		/>
		<product-slider-arrows :slider="slider"/>
		<product-slider-gallery
			:slider="slider"
			:isOpened="isGalleryOpened"
			:toggleHandler="toggleGallery"
			:product="product"
			:shouldShowImage="wasOpenedOnce"
		/>
	</div>
</template>

<script>
import ProductSliderThumbnails
from '~/catalog/front/components/organisms/ProductSlider/components/ProductSliderThumbnails'
import ProductSliderArrows from '~/catalog/front/components/organisms/ProductSlider/components/ProductSliderArrows'
import ProductSlide from '~/catalog/front/components/organisms/ProductSlider/components/ProductSlide'
import ProductSlider from '~/catalog/front/components/organisms/ProductSlider/ProductSlider'
import ProductSliderGallery from '~/catalog/front/components/organisms/ProductSlider/components/ProductSliderGallery'

export default {
	components: {
		ProductSliderGallery,
		ProductSlide,
		ProductSliderArrows,
		ProductSliderThumbnails
	},
	props: {
		sliderConfig: Object,
		images: Array,
		product: Object
	},
	data () {
		return {
			slider: null,
			isGalleryOpened: false,
			wasOpenedOnce: false
		}
	},
	computed: {
		currentSlide () {
			if (this.slider) return this.slider.currentSlide
			return null
		}
	},
	methods: {
		toggleGallery () {
			if (!this.isGalleryOpened && !this.wasOpenedOnce) {
				this.wasOpenedOnce = true
				setTimeout(() => {
					this.isGalleryOpened = !this.isGalleryOpened
				}, 300)
			} else {
				this.isGalleryOpened = !this.isGalleryOpened
			}
		}
	},
	created () {
		this.slider = new ProductSlider(this.sliderConfig, this.images)
	},
	watch: {
		images: function (images) {
			this.slider.setNewImages(images)
		}
	}
}
</script>

<style lang="scss" scoped>
.slider-wrapper {
	width: 100%;
	position: relative;
	overflow: hidden;
	height: 600px;
	max-height: calc(100vh - 100px);

	@include media-breakpoint-up(xl) {
		height: 100%;
		max-height: none;
	}

	:deep(.slider-inner) {
		max-width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.slide-right-leave-active,
.slide-right-enter-active {
	transition: .5s;
}

.slide-right-enter {
	transform: translate(100%, 0);
}

.slide-right-leave-to {
	transform: translate(-100%, 0);
}

.slide-left-leave-active,
.slide-left-enter-active {
	transition: .5s;
}

.slide-left-enter {
	transform: translateX(-100%);
}

.slide-left-leave-to {
	transform: translateX(100%);
}
</style>
