<template>
	<div class="products-wrapper">
		<div class="inner-wrapper">
			<div
				class="product-card"
				v-for="card in cardsNumber"
				:key="`skeleton-card-${card}`"
			/>
		</div>
	</div>
</template>

<script>

export default {
	props: {
		cardsNumber: {
			type: Number,
			default: 4
		}
	}
}
</script>

<style scoped lang="scss">
@keyframes pulse-bg {
	0% { background-color: $gray-100; }
	50% { background-color: $gray-500; }
	100% { background-color: $gray-100; }
}

.inner-wrapper{
	margin-top: 4rem;
	display: grid;
	grid-template-columns: 1fr;
	gap: 30px;

	@include media-breakpoint-up(xl) {
		grid-template-columns: repeat(2, minmax(0, 1fr));
		grid-auto-rows: 1fr;
	}
}

.product-card{
	width: 100%;
	height: 340px;
	border-radius: 3px;
	background-color: $gray-400;
	animation: pulse-bg 1s infinite alternate;
}
</style>
