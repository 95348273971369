<template>
	<div class="wrapper">
		<base-blur :blur="!!status">
			<form class="form" v-if="form">
				<div class="form-inner-wrapper">
					<input-template wider :input="form.inputs.name" />
					<input-template wider :input="form.inputs.surname" />
				</div>
				<div class="form-inner-wrapper">
					<input-template wider :input="form.inputs.email" />
					<input-template wider :input="form.inputs.phoneNumber" />
				</div>
				<input-template wider :input="form.inputs.company" />

				<input-template class="form-content" wider :input="form.inputs.content" />

				<input-template wider :input="form.inputs.attachments" />

				<div class="form-inner-wrapper form-buttom-police">
					<input-template :input="form.inputs.police" />
					<button-submit
						button_type="primary"
						title="Send message to QuWireless"
						@click="submit"
						:loading="loading"
						class="form-btn"
						:rightSlot="true"
					>
						<base-font weight="bold" size="paragraph" color="white" tag="span">
							{{langs.button}}
						</base-font>
					</button-submit>
				</div>
				<div class="form-additional-info">
					<base-font size="paragraph">
						Fields with <span class="police-star">*</span> are required.
					</base-font>
				</div>
			</form>
			<template #status>
				<div class="status" >
					<base-font size="md" family="primary" color="primary">
						{{statusTranslation[status] || statusTranslation.error}}
					</base-font>
					<base-svg class="close-btn" name="close" @click="resetForm" />
				</div>
			</template>
		</base-blur>
		<div class="wrapper-image">
			<cms-image
				lazy
				v-if="image"
				:src="image"
				:base="{width: 694, height: 940}"
				:xxl="{width: 694, height: 800}"
			/>
		</div>
	</div>

</template>
<script>
import ButtonSubmit from '~/website/front/components/molecules/buttons/ButtonSubmit'

import { InputTemplate } from '@f/core/forms/inputs'
import { productHistoryService } from '~/catalog/front/services/ProductHistoryService'

export default {
	data () {
		return {
			status: '',
			loading: false
		}
	},
	computed: {
		statusTranslation () {
			return this.langs.status
		},
		isFromProductPage () {
			return this.$app.page.from.meta.isProductPage
		},
		image () {
			return this.$app.page.value.form_image || null
		},
		langs () {
			return this.$app.translator.get('forms').supportForm
		},
		form () {
			return this.$app.page.form
		}
	},
	components: {
		InputTemplate,
		ButtonSubmit
	},
	methods: {
		async submit () {
			if (!await this.form.validate()) return false
			this.loading = true

			const product = !this.isFromProductPage
				? null
				: productHistoryService.getLast()

			const status = await this.$app.getService('rext').sendSupportFormMessage({
				...this.form.getValues(),
				name: `${this.form.inputs.name.value || ''} ${this.form.inputs.surname.value || ''}`,
				productPage: product
					? JSON.stringify({
						path: this.$app.page.from.fullPath,
						alias: this.$app.page.from.params.alias,
						productName: product?.name || null,
						productPartNumber: product?.activeVariant?.partNumber || null
					})
					: null
			})
			this.loading = false
			this.status = status

			if (status === 201) this.$app._emit('contact_form:message:sent')
		},

		resetForm () {
			this.status = ''
		}
	}
}
</script>
<style lang="scss" scoped>

.wrapper {
	display: grid;
	overflow: visible;
	gap: 30px;

	@include media-breakpoint-up(xl) {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}

	&-image {
		display: none;
		overflow: hidden;
		max-width: 100%;
		border-radius: 1rem;

		@include media-breakpoint-up(xl) {
			display: block;
		}
	}
}

:deep(.status) {
	p {
		margin-bottom: 0;
	}
}

:deep(.police-star) {
	color: $red-600;
}

.form {
	min-height: max-content;

	:deep(label) {
		color: $secondary;
	}

	:deep(input::placeholder) {
		font-style: normal;
	}

	&-inner-wrapper {
		@include media-breakpoint-up(xl) {
			display: flex;
			align-items: center;
		}
	}

	.form-content {
		margin-bottom: 3rem;
		:deep(textarea) {
			border-radius: $border-radius;
			border: 1px solid #E9E9E9;
			height: 122px;
		}
	}

	.field-checkbox-wrapper {
		:deep(a) {

		}
	}

	.form-buttom-police{
		@include media-breakpoint-up(xxl) {
			gap: 80px;
		}
	}
}

.inner-wrapper {
	.status {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 50px;
		background-color: #fff;
		border-radius: 10px;
		box-shadow: $base-shadow;

		:deep(.status-text) {
			margin: 0;
		}

		:deep(.close-btn) {
			position: absolute;
			top: 5px;
			right: 5px;
			cursor: pointer;
			@include transition;

			&:hover {
				opacity: 0.8;
			}
		}
	}
}

.form-additional-info {
	margin-top: 8px;
	.police-star {
		color: $danger;
	}
}
</style>
