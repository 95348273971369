<template>
	<div class="search-brands" v-custom-background="getBackgroundImage">
		<div class="search-brands-inner">
			<cms-text class="brands_title" :value="`${prefix}.brands_title`" :props="{tag: 'h4', color: 'white'}" />
			<div class="inputs-wrapper" v-if="isFormCreated">
				<input-template :input="form.inputs.brand" valueKey="alias" @change="handleChangeBrand" />
				<input-template :input="form.inputs.model" @change="setQuery" />
			</div>
			<base-button
				class="brands-btn"
				to="general-product-list"
				:query="query"
				type="primary"
				title="Find dedicated antenna for your router!"
			>
				<base-svg name="search"/>
				<base-font tag="span" uppercase> {{ lang.search.button || '' }} </base-font>
			</base-button>
			<cms-text
				class="search-brands-additional-info"
				v-if="value.additional_info"
				:value="`${prefix}.additional_info`"
				:props="{
					size: 'paragraph',
					color: 'white'
				}"
			/>
		</div>
	</div>
</template>

<script>
import BaseSvg from '~/website/front/components/atoms/BaseSvg/BaseSvg'
import BaseButton from '~/cms/front/components/BaseButton/BaseButton'
import BaseFont from '~/website/front/components/atoms/BaseFont/BaseFont'
import InputTemplate from '~/website/front/components/organisms/InputTemplate/InputTemplate'
import { Form } from '@f/core/forms/Form'
import { InputSelect } from '@f/core/forms/inputs'

export default {
	components: { BaseFont, BaseButton, BaseSvg, InputTemplate },
	data () {
		return {
			brands: [],
			models: [],
			loading: true,
			query: {},
			params: {},
			form: null,
			isFormCreated: false
		}
	},
	props: {
		prefix: String,
		value: Object

	},
	computed: {
		getBackgroundImage () {
			if (!this.value?.params_bg?.path) return
			const path = this.value.params_bg.path
			return {
				src: path
			}
		},
		lang () {
			return this.$app.translator.get('products')
		}
	},
	methods: {
		async fetchBrands () {
			const service = this.$app.getService('brand')
			const data = await service.getBrands()
			this.brands = data
		},

		handleChangeBrand (option) {
			this.fetchModels(option)
			this.setQuery()
		},

		async fetchModels (option) {
			this.form.inputs.model.value = ''
			const service = this.$app.getService('brand')
			const data = await service.getModels(option.alias)
			this.models = data
		},
		setQuery () {
			const model = this.form.inputs.model.getValue()
			const brand = this.form.inputs.brand.getValue()

			const query = {}

			if (model) query.model = model.name
			if (brand) query.brand = brand.alias

			this.query = query
		},
		createForm () {
			const lang = this.$app.translator.get('forms')
			this.form = new Form()
			this.form.addInput(InputSelect.create({
				translations: () => lang.productFilter.brand,
				name: 'brand',
				validation: [],
				optionsGetter: () => this.brands,
				component: 'custom'

			}))
			this.form.addInput(InputSelect.create({
				translations: () => lang.productFilter.model,
				name: 'model',
				validation: [],
				optionsGetter: () => this.models,
				component: 'custom'

			}))

			this.isFormCreated = true
		}
	},
	async mounted () {
		this.createForm()
		await this.fetchBrands()

		this.loading = false
	},
	async prefetch () {
		await this.$app.translator.prefetch('products')
		await this.$app.translator.prefetch('forms')
	}
}
</script>

<style lang="scss" scoped>
.search-brands {
	padding: 20px;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	border-radius: 5px;
	background-color: #2E98DD;

	@include media-breakpoint-up(xl) {
		padding: 40px 20px;
		height: 100%;
	}

	&-additional-info {
		margin-top: 20px;
	}

	.search-brands-inner {

		@include media-breakpoint-up(xl) {
			height: 100%;
		}

		.brands_title{
			font-size: 19px;
			line-height: 25px;
			text-align: center;
			margin-bottom: 20px;
			@include media-breakpoint-up(lg) {
				font-size: 35px;
				line-height: 48px;
				margin-bottom: 25px;
			}
			@include media-breakpoint-up(xxl) {
				font-size: 38px;
			}
		}

		.brands-btn{
			margin-top: 30px;
			@include transition;
			cursor: pointer;
			width: fit-content;
			border-radius: 5px;
			display: flex;
			gap: 10px;
			padding: 13px 40px 13px 30px;
			align-items: center;
			border: 1px solid transparent;
			background-color: rgba(255, 255, 255, .17);
			margin-left: auto;
			margin-right: auto;
			text-decoration: none;

			@include media-breakpoint-up(xl) {
				padding: 15px 40px 15px 30px;
			}

			&:hover{
				border-color: $white;
			}

			::v-deep(svg) {
				margin-bottom: -8px;

				path {
					fill: $white;
				}
			}

			::v-deep(span) {
				font-weight: 700;
				font-size: 16px;
				line-height: 21px;
				letter-spacing: 0.32px;
				color: $white;
			}
		}

		.inputs-wrapper{
			display: flex;
			flex-direction: column;
			gap: 20px;
			align-items: center;

			:deep(.field-wrapper) {
				margin: 0;
			}

			:deep(.select-wrapper) {
				.select.disabled {
					background-color: #ededed;
				}
			}
		}
	}
}
</style>
