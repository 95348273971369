const productSectionMixin = {
	computed: {
		pageProduct () {
			return this.$app.page.product
		}
	}
}

export {
	productSectionMixin
}
