<template>
	<base-container v-if="value.use_container">
		<base-animation
			animation="slideRight"
		>
			<cms-text :class="padding" :value="`${prefix}.content`" :props="{variant: 'paragraph', tag: 'div'}"/>
		</base-animation>
	</base-container>
	<base-animation
		v-else
		animation="slideRight"
	>
		<cms-text
			:class="padding"
			:value="`${prefix}.content`"
			:props="{variant: 'paragraph', tag: 'div'}"
		/>
	</base-animation>

</template>

<script>
import { paddingSectionMixin } from '~/website/front/utils/paddingSection.mixin.js'
import BaseContainer from '~/website/front/components/atoms/BaseContainer/BaseContainer.vue'
import BaseAnimation from '@f/components/BaseAnimation/BaseAnimation'
export default {
	components: { BaseContainer, BaseAnimation },
	mixins: [paddingSectionMixin],
	props: {
		prefix: String,
		value: Object
	}
}
</script>
