<template>
	<div class="company-details-map">
		<div class="map-wrapper">
			<cms-image
				src="contact/map-bg.jpg"
				:base="{
					width: 330,
					height: 268,
					fit: 'cover'
				}"
				:sm="{
					width: 510,
					height: 414,
					fit: 'cover'
				}"
				:md="{
					width: 720,
					height: 584,
					fit: 'cover'
				}"
				:lg="{
					width: 480,
					height: 390,
					fit: 'cover'
				}"
				:xl="{
					width: 513,
					height: 416,
					fit: 'cover'
				}"
				:xxl="{
					width: 587,
					height: 481,
					fit: 'cover'
				}"
			/>
			<div class="map-overflow">
				<base-link
					class="map-link"
					target="_blank"
					url="https://goo.gl/maps/VqdyqNut7J4gnc99A"
					title="Find Quwireless on google maps"
					button_type="secondary"
				>
					<base-font weight="bold">Find us on google maps</base-font>
				</base-link>
			</div>
		</div>
		<div class="map-buttons-wrapper">
			<base-link
				type="internal"
				to="where-to-buy"
				button_type="secondary"
				title="Check where you can buy 4G/LTE, 5G or Wi-Fi antennas"
				class="white"
			>
				<base-font color="primary" weight="bold">Buy</base-font>
			</base-link>
			<base-link
				type="internal"
				to="ask-a-question"
				button_type="secondary"
				title="Get technical support from our experts"
				class="border-white"
			>
				<base-font color="white" weight="bold">Technical support</base-font>
			</base-link>
		</div>
	</div>
</template>

<script>
export default {
	name: 'CompanyDetailsMap'
}
</script>

<style lang="scss" scoped>
.company-details-map {
	position: relative;
	display: flex;
	flex-direction: column;
	background-color: #2092DD;
	border-radius: 1rem;
	@include media-breakpoint-up(lg) {
		border-radius: 10px 0 0 10px;
	}
	.map-wrapper {
		position: relative;
		border-radius: 1rem;
		@include media-breakpoint-up(lg) {
			border-radius: 10px 0 0 0;
		}
		.image-wrapper {
			display: block;
			max-height: 350px;
			overflow: hidden;
			width: 100%;
			border-radius: 1rem;
			@include media-breakpoint-up(lg) {
				max-height: 100%;
				border-radius: 0;
			}
			:deep(picture) {

				img {
					width: 100%;
					border-radius: 10px 0 0 0;
				}
			}
		}

		.map-overflow {
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 1rem;
			@include media-breakpoint-up(lg) {
				border-radius: 10px 0 0 10px;
			}
			.map-link{
				color: $white;
				border-color: $white;

				&:after {
					border-color: $white;
				}

				:deep(p){
					color: $white;
				}
			}
		}
	}

	.map-buttons-wrapper{
		padding: 10px;
		border-radius: 1rem;
		height: 100%;
		display: flex;
		gap: 10px;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		@include media-breakpoint-up(md) {
			flex-direction: row;
			padding: 10px;
			gap: 30px;
		}
		@include media-breakpoint-up(lg) {
			border-radius: 0 0 0 10px;
		}
		@inclue media-breakpoint-up(xxl) {
			padding:30px;
		}

		.white{
			background-color: $white;
			border: none;

			&:hover {
				background-color: #EFEFEF;
			}

			&:after {
				display: none;
			}
		}

		.border-white {
			border-color: $white;

			&:after {
				border-color: $white;
			}
		}
	}
}
</style>
