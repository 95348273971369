<template>
	<div class="cards-wrapper">
		<card
			v-for="(card, i) in cards"
			:card="card"
			:prefix="`${prefix}.cards.${i}`"
			:key="card.title"
		/>
	</div>
</template>

<script>
import Card from '~/website/front/sections/AntennaCardsSection/components/Card'
import { evenAnimationMixin } from '~/cms/front/mixins/evenAnimationMixin'
export default {
	components: { Card },
	props: {
		cards: Array,
		prefix: String
	},
	mixins: [evenAnimationMixin]
}
</script>

<style lang="scss" scoped>
.cards-wrapper {
	margin-top:3rem;
	@include media-breakpoint-up(lg) {
		margin-top:6rem;
	}
}
</style>
