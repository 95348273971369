<template>
	<div
		class="filter-group-item"
		:class="{active: isItemActive}"
		@click="handleClick"
	>
		<base-font class="group-item-title" weight="bold" >{{item.name}}</base-font>
	</div>
</template>

<script>
import BaseFont from '~/website/front/components/atoms/BaseFont/BaseFont.vue'
export default {
	components: { BaseFont },
	props: {
		item: Object,
		activeItemId: Number
	},
	computed: {
		isItemActive () {
			return this.item.id === this.activeItemId
		}
	},
	methods: {
		handleClick () {
			if (!this.isItemActive) { this.$emit('change', this.item) }
		}
	}
}
</script>

<style lang="scss" scoped>
.filter-group-item{
	padding: 10px 15px;
	border-radius: $border-radius;
	border: 1px solid #D8D8D8;
	cursor: pointer;
	text-transform: capitalize;
	@include transition;

	@include media-breakpoint-up(lg) {
		padding: 10px 30px;
	}

	&.active {
		background-color: $primary;

		:deep(.group-item-title){
			color: #fff;
		}
	}

	.group-item-title{
		@include transition;
	}
}
</style>
