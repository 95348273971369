<template>
	<div ref="wrapper" class="image-wrapper" :class="{inline: !isLoaded}">
		<picture>
			<template v-for="(source, i) in sources" >
				<source :type="source.type"
						:media="source.media"
						:srcset="source.src"
						:width="getSourceImageSize(source.breakpoint, 'width', source.type)"
						:height="getSourceImageSize(source.breakpoint, 'height', source.type)"
						:key="i"
				>
			</template>
			<base-image
				v-show="shouldBeShown"
				:loading="loading"
				:decoding="decoding"
				:fetchpriority="fetchpriority"
				:url="url"
				:alt="alt"
				:title="title"
				@imageLoaded="setImageLoaded"
			/>
		</picture>
		<picture ref="lazyPicture" v-show="!isLoaded">
			<template v-for="(source, i) in lazyLoadSources">
				<source
					:type="source.type"
					:media="source.media"
					:srcset="base64Images[i]"
					:width="getSourceImageSize(i, 'width', source.type)"
					:height="getSourceImageSize(i, 'height')" :key="i"
				>
			</template>
			<base-image
				class="lazy"
				loading="lazy"
				decoding="sync"
				ref="bluredImage"
				:alt="alt"
				:title="title"
			/>
		</picture>
	</div>
</template>

<script>
import { config } from '@f/config'
import { serialize } from 'utils/strings'
import { getValue } from 'utils/objects'
import BaseImage from '@f/components/BaseImage/BaseImage'
import { getFileUrl } from 'utils/getFileUrl'

const breakpoints = [...Object.keys(config.mediaQueries), 'base']
const breakpointProps = {
	type: [Object, Boolean]
}
const breakpointsProps = breakpoints.reduce((props, breakpoint) => {
	props[breakpoint] = breakpointProps
	return props
}, {})

export default {
	components: { BaseImage },
	props: {
		preload: Boolean,
		lazy: {
			type: Boolean,
			default: false
		},
		src: [String, Object],
		value: String,
		layout: Boolean,
		...breakpointsProps,
		position: {
			type: String,
			default: 'entropy'
		},
		loading: {
			type: String,
			default: 'eager'
		},
		decoding: {
			type: String,
			default: 'async'
		},
		fetchpriority: {
			type: String,
			default: 'low'
		}
	},
	data: () => ({
		isLoaded: false,
		canBeShown: false,
		base64Images: []
	}),
	created () {
		if (!this.lazy) {
			this.isLoaded = true
			this.canBeShown = true
		}
	},
	async mounted () {
		if (this.lazy && IntersectionObserver) {
			this.observer = new IntersectionObserver(() => {
				this.canBeShown = true
			}, {
				rootMargin: '200px'
			})
			this.observer.observe(this.$refs.wrapper)
		} else {
			this.canBeShown = true
		}
	},
	computed: {
		imageSizes () {
			const { base, sm, md, lg, xl, xxl } = this.$props
			return {
				base, sm, md, lg, xl, xxl
			}
		},
		shouldBeShown () {
			return this.isLoaded && this.canBeShown
		},
		imageObj () {
			if (this.src) return this.src
			let value
			if (this.layout) value = this.$app.layout.value
			else value = this.$app.page.value
			const obj = getValue(this.value, value, {})
			return obj
		},
		alt () {
			return this.imageObj.alt
		},
		title () {
			return this.imageObj.title
		},
		url () {
			return getFileUrl(this.$app, this.imageObj)
		},
		webpSources () {
			const breakpointsMinWidths = {
				xs: 0,
				sm: 576,
				md: 768,
				lg: 992,
				xl: 1200,
				xxl: 1400
			}

			const getWebpSource = ({ breakpoint, format, minWidth }) => ({
				src: this.url + serialize({
					position: this.position,
					...this[breakpoint],
					format
				}),
				minWidth
			})

			return breakpoints
				.filter(breakpoint => this[breakpoint])
				.reduce((sources, breakpoint) => {
					const sourceWebp = getWebpSource({
						breakpoint,
						format: 'webp',
						minWidth: breakpointsMinWidths[breakpoint]
					})
					sources.push(sourceWebp)
					return sources
				}, [])
		},
		sources () {
			return breakpoints
				.filter(breakpoint => this[breakpoint])
				.reduce((sources, breakpoint) => {
					const sourceWebp = this.getSource({
						breakpoint,
						format: 'webp',
						type: 'image/webp'
					})
					sources.push(sourceWebp)
					const sourceNoWebp = this.getSource({
						breakpoint
					})
					sources.push(sourceNoWebp)
					return sources
				}, [])
		},
		lazyLoadSources () {
			return breakpoints
				.reduce((sources, breakpoint) => {
					const sourceNoWebp = this.getSource({
						breakpoint
					})
					sources.push(sourceNoWebp)
					return sources
				}, [])
		}
	},
	methods: {
		async getBase64ForImage (imageSize) {
			const service = this.$app.getService('rext')
			const blob = await service.getLazyLoadImage(this.url, imageSize)
			const base64 = Buffer.from(blob, 'binary').toString('base64')
			return `data:image;base64,${base64}`
		},
		addPreloadTag () {
			this.$app.websiteHeadManager.addImagePreload(this.webpSources)
		},
		setImageLoaded () {
			this.isLoaded = true
		},
		getSourceImageSize (breakpoint, type, sourceType) {
			if (sourceType) return ''
			return this.imageSizes[breakpoint] && this.imageSizes[breakpoint][type] ? this.imageSizes[breakpoint][type] : ''
		},
		async getBase64Images () {
			const getSize = (imageSize) => {
				const minSize = 10
				const width = imageSize.width
				const height = imageSize.height

				if (!width && !height) return { width: minSize }
				if (!width) return { height: minSize }
				if (!height) return { width: minSize }
				if (width > height) {
					return {
						width: Math.floor((width / height) * minSize),
						height: minSize
					}
				}
				return {
					width: minSize,
					height: Math.floor((height / width) * minSize)
				}
			}

			this.base64Images = await Promise.all(Object.entries(this.imageSizes).map(([key, value]) => {
				if (!value) return
				const computedImageSize = getSize(value)
				return this.getBase64ForImage(computedImageSize)
			}))
		},
		getSource ({ breakpoint, format, type }) {
			return {
				breakpoint,
				media: config.mediaQueries[breakpoint],
				src: this.url + serialize({
					position: this.position,
					...this[breakpoint],
					format
				}),
				type
			}
		}
	},
	async prefetch () {
		if (process.server) {
			if (this.lazy) await this.getBase64Images()
			if (this.preload) this.addPreloadTag()
		}
	},
	beforeDestroy () {
		this.observer && this.observer.disconnect()
	}
}
</script>

<style lang="scss" scoped>
picture {
	overflow: hidden;
	.lazy {
		@include media-breakpoint-up(lg) {
			filter: blur(20px);
		}
	}
}
.image-wrapper {
	min-width: 1px;
	min-height: 1px;
	&.inline {
		display: inline;
	}
}
</style>
