<template>
	<div
		class="slider-slide"
		@click="handleClick"
	>
		<cms-image
			:src="{
				alt: product.name,
				path: slide.image?.path,
				title: `Product ${product.name}`
			}"
			:base="{
				width: 345,
				height: 415,
				fit: 'contain',
				background: 'transparent'
			}"
			:xl="{
				width: 340,
				height: 410,
				fit: 'contain',
				background: 'transparent'
			}"
		/>
	</div>
</template>

<script>
export default {
	props: {
		slide: Object,
		handleClick: Function,
		product: Object
	}
}
</script>

<style lang="scss" scoped>
	.slider-slide {
		cursor: pointer;
		min-width: 100%;
		height: 80%;
	}

	::v-deep(.image-wrapper) {
		width: auto;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		picture {
			max-height: 100%;
			display: flex;
			justify-content: center;

			img {
				object-fit: contain;
				max-height: 100%;
				max-width: 100%;
			}
		}

	}
</style>
