<template functional>
	<div class="container" :class="{
		...$options.classes(props, data)
		}"
	>
		<slot/>
	</div>
</template>

<script>
import BaseContainer from '@f/components/BaseContainer'
export default {
	extends: BaseContainer,
	name: 'BaseContainer',
	globalComponent: true
}
</script>

<style lang="scss" scoped>
@mixin make-container() {
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	padding-inline: 1.5rem;

	@include media-breakpoint-up(md) {
		padding: 0;
	}
}
@mixin make-grid-containers() {
	@each $breakpoint, $container-max-width in $container-max-widths {
		.container-#{$breakpoint} {
			@extend .container-fluid !optional;
		}

		@include media-breakpoint-up($breakpoint, $grid-breakpoints) {
			%responsive-container-#{$breakpoint} {
				max-width: $container-max-width;
			}

		$extend-breakpoint: true;

			@each $name, $width in $grid-breakpoints {
				@if ($extend-breakpoint) {
					.container#{breakpoint-infix($name, $grid-breakpoints)} {
						@extend %responsive-container-#{$breakpoint};
				}

					@if ($breakpoint == $name) {
						$extend-breakpoint: false;
					}
				}
			}
		}
	}
}

@include make-grid-containers();
.container {
	&-fluid {
		width: 100%;
		margin-right: auto;
		margin-left: auto;
	}
	@include make-container();
}
</style>
