
<template>
	<a
		:style="{
			color: '#0099FF'
		}"
		:href="to"
		:title="title"
		@click.prevent="handleClick()"
	>
		<slot />
	</a>
</template>

<script>

export default {
	props: {
		to: String,
		title: String
	},
	methods: {
		handleClick () {
			this.$emit('click')
			this.$app.router.push(this.to)
		}
	}
}
</script>
