import { rextServiceExtend } from './core/services/RextService'
import { ArticleCategory } from '~/blog/front/core/entities/ArticleCategory/ArticleCategory'
const ArticlePage = async () => (await import('./core/pages/Article')).ArticlePage
const ArticleListPage = async () => (await import('./core/pages/ArticleList')).ArticleListPage
const ArticleListCategoryPage = async () => (await import('./core/pages/ArticleListCategory')).ArticleListCategoryPage
const UseCasesAndStoriesPage = async () => (await import('./core/pages/UseCasesAndStories/UseCasesAndStoriesPage.js')).UseCasesAndStoriesPage
const ArticleListPageKnowledge = async () => (await import('./core/pages/ArticleListPageKnowledge/ArticleListPageKnowledge.js')).ArticleListPageKnowledge
const NewestBlogPosts = () => import('./sections/NewestBlogPosts')
const ContentSection = () => import('./sections/ContentSection')
const ArticlePhotoSection = () => import('./sections/ArticlePhotoSection')
const ArticleBlocksSection = () => import('./sections/ArticleBlocksSection')
const ArticlePhotoTopSection = () => import('./sections/ArticlePhotoTopSection')
const ArticleButtons = () => import('./sections/ArticleButtons')
const ArticleBlockquoteSection = () => import('./sections/ArticleBlockquoteSection')
const ArticleIframeSection = () => import('./sections/ArticleIframeSection')

class BlogModule {
	readyToExtendServices (app) {
		app.extendService('rext', rextServiceExtend)
	}

	readyToAddPages (app) {
		app.addPage('article', ArticlePage)
		app.addPage('article-list', ArticleListPage)
		app.addPage('article-list-category', ArticleListCategoryPage)
		app.addPage('use-cases', UseCasesAndStoriesPage)
		app.addPage('article-list-page-knowledge', ArticleListPageKnowledge)
	}

	readyToAddEntities (app) {
		app.addEntity('article-category', ArticleCategory)
	}

	beforeInitReactive (app) {
		app.addSection('newest_blog_posts', NewestBlogPosts)
		app.addSection('content_section', ContentSection)
		app.addSection('article_photo_section', ArticlePhotoSection)
		app.addSection('article_blocks_section', ArticleBlocksSection)
		app.addSection('article_photo_top_section', ArticlePhotoTopSection)
		app.addSection('article_buttons', ArticleButtons)
		app.addSection('article_blockquote_section', ArticleBlockquoteSection)
		app.addSection('article_iframe_section', ArticleIframeSection)
	}
}

export { BlogModule }
