class Trait {
	static async fetch ({ app, where, relations }) {
		const service = app.getService('rext')

		const data = await service.getTraits({ where, relations })

		if (data.error) return []

		return data
	}
}

export { Trait }
