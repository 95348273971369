<template>
	<div class="use-case" v-if="useCase.item_type === 'image'">
		<cms-image
			class="use-case-image"
			:src="{
					path: useCase.item.use_case_image.path,
					alt: `Use case ${productName}`,
					title: `Best spot for ${productName}`
				}"
			:base="{width: 450, height: 328, background: 'transparent', fit: 'cover'}"
			lazy
		/>
	</div>

	<base-article
		v-else-if="useCase.item_type === 'article' && article"
		:article="article"
	/>
</template>

<script>
import BaseArticle from '~/blog/front/components/article/BaseArticle.vue'
export default {
	components: {
		BaseArticle
	},
	props: {
		useCase: {
			type: Object,
			default: () => ({
				item_type: 'image',
				item: {
					id: 1
				}
			})
		},
		article: Object,
		productName: {
			type: String,
			default: ''
		}
	},
	methods: {
		handleArticleClick (e) {
			e.preventDefault()
			this.$app.router.push(`/post/${this.article.alias}`)
		}
	}
}
</script>

<style lang="scss" scoped>
.use-case {
	&--article {
		cursor: pointer;
	}

	&-image :deep(img) {
		border-radius: 1rem;
		box-shadow: .6rem .6rem 1rem rgb(0, 0, 0, .24);
		margin-bottom: 1rem;
		max-width: 100%;
		width: 100%;
		height: auto;
		@include transition;
		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
		}

		&:hover {
			transform: translateY(-5px);
		}
	}

}
</style>
