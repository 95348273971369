export class Breadcrumbs {
	crumbs = []
	menuTreeRoot

	constructor (items) {
		this.menuTreeRoot = this.prepareMenuTree(Array.from(items))
	}

	prepareMenuTree (items) {
		const index = items.findIndex(item => item.to === 'homepage')
		const homepageItemData = items.splice(index, 1)
		const homepageItem = {
			...homepageItemData[0],
			children: items
		}

		return homepageItem
	}

	generateFromMenuTree (to, param) {
		const checkTreeNodes = (item) => {
			if (item.children?.length) {
				const items = item.children
					.map(child => checkTreeNodes(child))
					.find(a => a)
				if (!items) return false
				return [this.createCrumbFromItem(item), ...items]
			}
			if (to === item.to && (!param || param === item.param)) return [this.createCrumbFromItem(item)]
			return false
		}

		this.crumbs = checkTreeNodes(this.menuTreeRoot)

		if (to === 'homepage') {
			this.crumbs = [this.createCrumbFromItem({ name: 'Home', to: 'homepage' })]
		}

		return this
	}

	createCrumbFromItem (item) {
		return this.createCrumb(item.name, item.to, item.param)
	}

	createCrumb (name, to, param) {
		return {
			name: name,
			to: to,
			params: {
				param: param
			}
		}
	}

	addCrumb (name, to, param) {
		const crumb = this.createCrumb(name, to, param)
		this.crumbs.push(crumb)

		return this
	}

	deleteCrumbByIndex (index) {
		try {
			this.crumbs.splice(index, 1)
		} catch (e) {
			console.error(e)
		}

		return this
	}

	findCrumb (to, param, ignoreHomepage = false) {
		if (this.crumbs) {
			if (ignoreHomepage) {
				return this.crumbs?.filter(crumb => crumb.to !== 'homepage').find(crumb => {
					if ((crumb.params?.param === param || (!param && !crumb.params?.param)) && crumb.to === to) {
						return true
					} else {
						return false
					}
				})
			}
			return this.crumbs.find(crumb => {
				if ((crumb.params?.param === param || (!param && !crumb.params?.param)) && crumb.to === to) {
					return true
				} else {
					return false
				}
			})
		}
		return false
	}
}
