<template>
	<section class="newsletter" v-custom-background="backgroundNewsletter">
		<base-container>
			<base-animation>
				<custom-title
					title="newsletter.title"
					subtitle="newsletter.subtitle"
					header_tag="h2"
					layout
				/>
			</base-animation>

			<base-blur :blur="!!status" >
					<form class="form" v-if="form" >
						<base-svg class="icon-sendingMail" name="newsletter-sendingMail"/>
						<div class="form-inner-wrapper">
							<base-svg class="icon-mail" name="newsletter_mail"/>
							<cms-text value="newsletter.under_title_text" :props="{tag: 'p', color:'secondary', weight:'medium'}" layout/>
							<input-template class="form-input input-name" :input="form.inputs.nameNewsletter" secondary/>
							<input-template class="form-input input-email" :input="form.inputs.emailNewsletter" autocomplete="email" secondary/>
							<div class="form-button-wrapper">
								<input-template class="input-checkbox" :input="form.inputs.privacyNewsletter" secondary/>
								<base-button
									class="newsletter-submit primary"
									@click="submit"
									:loading="loading"
									type="primary"
									title="Subscribe and get news first!"
									icon="phone"
								>

									<base-svg name="mail-icon-newsletter-button"/>
									<cms-text :props="{clear: true}" value="newsletter.btn_text" layout/>
								</base-button>
							</div>
							<div class="form-additional-info">
								<base-font size="paragraph">
									Fields with <span class="police-star">*</span> are required.
								</base-font>
							</div>
						</div>
					</form>

				<template #status>
					<div class="status" >
						<base-font class="status-text" weight="bold" size="md" color="primary">
							{{statusTranslation[status] || statusTranslation.error}}
						</base-font>
						<base-svg class="close-btn" name="close" @click="resetForm" />
					</div>
				</template>
			</base-blur>
		</base-container>
	</section>
</template>

<script>

import BaseAnimation from '@f/components/BaseAnimation/BaseAnimation'
import { Form } from '@f/core/forms/Form'
import { InputTemplate, InputText, InputEmail, InputCheckbox } from '@f/core/forms/inputs'
import { required, email } from 'utils/inputs/validation-front'
import CustomTitle from '~/website/front/components/atoms/CustomTitle/CustomTitle'

export default {
	data () {
		return {
			status: false,
			form: false,
			loading: false
		}
	},
	components: {
		InputTemplate,
		CustomTitle,
		BaseAnimation
	},
	computed: {

		statusTranslation () {
			const forms = this.$app.translator.get('forms')
			return forms.newsletter.status
		},
		backgroundNewsletter () {
			let path = ''
			if (this.$app.layout.value.newsletter.bg_picture) path = this.$app.layout.value.newsletter.bg_picture.path

			return {
				src: path,
				breakpoints: {
					base: { width: 1920, height: 733 }
				}
			}
		}
	},
	async mounted () {
		await this.$app.translator.prefetch('errors')
		await this.$app.translator.prefetch('forms')
		this.form = new Form()
		this.form.addInput(InputText.create({
			translations: () => this.$app.translator.get('forms').newsletter.inputName,
			attribute: 'name',
			name: 'nameNewsletter',
			validation: [required()]
		}))
		this.form.addInput(InputEmail.create({
			translations: () => this.$app.translator.get('forms').newsletter.inputEmail,
			attribute: 'email',
			name: 'emailNewsletter',
			validation: [email()]
		}))
		this.form.addInput(InputCheckbox.create({
			translations: () => this.$app.translator.get('forms').newsletter.inputPolice,
			attribute: 'police',
			name: 'privacyNewsletter',
			validation: [required()]
		}))
	},
	methods: {
		async submit () {
			this.status = ''
			if (!await this.form.validate()) return false
			this.loading = true
			const { name, email } = this.form.getValues()
			const status = await this.$app.getService('rext').addSubscriber({ name, email })
			this.loading = false
			this.status = status

			if (status === 201) this.$app._emit('newsletter:subscriber:new')
		},

		resetForm () {
			this.status = ''
		}
	},
	watch: {
		$route () {
			this.form && this.form.hideErrors()
		}
	}
}
</script>
<style lang="scss" scoped>
.newsletter {
	content-visibility: auto;
	@include spacing-section();
	position: relative;
	overflow: hidden;
	z-index: 0;
	background-repeat: no-repeat;
	background-size: cover;
	@include media-breakpoint-up(lg) {
		width: 100%;
	}
	.form {
		position: relative;
		.icon-sendingMail {
			position:absolute;
			display: none;
			left: 50%;
			bottom: 0rem;
			@include media-breakpoint-up(lg) {
				display:block;
			}
			@include media-breakpoint-up(xl) {
				left: calc(50% - 15rem);
				bottom: 6rem;
			}
			::v-deep(svg) {
				max-width: 90%;
				@include media-breakpoint-up(xl) {
					max-width: 100%;
				}
			}
		}
		p {
			font-size:1.4rem;
			line-height: 2.4rem;
			letter-spacing: 0.01em;
			margin-bottom:2rem;
			@include media-breakpoint-up(lg) {
				font-size: 1.8rem;
			}

		}
		::v-deep(label) {
			font-size: 1.6rem;
			letter-spacing: 0.01em;
			@include media-breakpoint-up(lg) {
				font-size: 1.8rem;
			}

		}
		&-button-wrapper {
			margin-top:1rem;
			@include media-breakpoint-up(lg) {
				margin-top: 5rem;
				display:flex;
				justify-content: space-between;
				align-content: center;
			}
			.btn {
				margin:0 auto;
				@include media-breakpoint-up(md) {
					margin:initial;
				}
			}
			::v-deep(.field-checkbox-label) {
				a {
					color:$primary;
				}
			}
		}
		&-inner-wrapper {
			background: #FFFFFF;
			border-radius:1rem;
			max-width: 57rem;
			padding:2rem;
			position: relative;
			box-shadow: 0 .3rem .6rem rgba(0,0,0,.16);
			@include media-breakpoint-up(lg) {
				padding:3rem;
			}
			.icon-mail {
				position: absolute;
				right:-3rem;
				::v-deep(svg) {
					max-width:15rem;
					top:7rem;
					display: none;
					@include media-breakpoint-up(md) {
						max-width:100%;
						display: block;
					}
				}
				@include media-breakpoint-up(md) {
					top:7rem;
					right:-15rem;
				}
			}
		}
		&-additional-info {
			margin-top: 8px;
			.police-star {
				color: $danger;
			}
		}
	}

	.inner-wrapper {
		.status {
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 50px;
			background-color: #fff;
			border-radius: 10px;
			box-shadow: $base-shadow;

			:deep(.status-text) {
				margin: 0;
			}

			.close-btn {
				position: absolute;
				top: 5px;
				right: 5px;
				cursor: pointer;
				@include transition;

				&:hover {
					opacity: 0.8;
				}
			}
		}
	}
}
</style>
