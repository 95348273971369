<template>
	<div class="product">
		<cms-image
			:value="`${prefix}.img`"
			:base="{
				width: 166,
				height: 127,
				background: 'transparent',
				fit: 'contain'
			}"
			:md="{
				width: 240,
				height: 183,
				background: 'transparent',
				fit: 'contain'
			}"
			:xl="{
				width: 330,
				height: 252,
				background: 'transparent',
				fit: 'contain'
			}"
		/>
		<cms-text
			:value="`${prefix}.title`"
			:props="{
				tag: 'h3',
				color: 'primary',
				weight:'semibold',
				uppercase: true
			}"
		/>
		<cms-text
			v-if="product.description"
			class="product-description"
			:value="`${prefix}.description`"
			:props="{variant: 'paragraph', tag: 'div'}"
		/>
	</div>
</template>

<script>
export default {
	props: {
		product: Object,
		prefix: String
	}
}
</script>

<style lang="scss" scoped>
.product {
	:deep(h3) {
		text-align: center;
		padding-top: 1rem;
		color: $primary-product-title;
		margin-bottom: 1rem;
		@include media-breakpoint-up(lg) {
			font-size: 2.6rem;
		}
	}
	&-description {
		padding:0;
		text-align: center;
		:deep(p) {
			line-height: 2.4rem;
			opacity: .3;
			font-weight: 600;
			margin-bottom: 0;
		}

	}
}
</style>
