var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"plots-section"},[_c('base-container',[_c('div',{staticClass:"plots-inner"},[(_vm.value.section_title)?_c('custom-title',{attrs:{"title":`${_vm.prefix}.section_title.title`,"subtitle":`${_vm.prefix}.section_title.subtitle`,"header_tag":_vm.value.section_title?.title_tag,"color":_vm.value.section_title.title_color,"transform":_vm.value.section_title.title_transform,"type":_vm.value.section_title_type}}):_vm._e(),_c('div',{staticClass:"plot-cards-wrapper"},_vm._l((_vm.value.cards),function(card,i){return _c('plot-card',{key:card.title,attrs:{"plot":card,"index":i,"prefix":_vm.prefix,"productName":_vm.pageProduct?.name}})}),1),_c('div',{staticClass:"plots-range-cards-wrapper"},_vm._l((_vm.value.range_cards),function(plot,i){return _c('plot-range-card',{key:`plot-range-card-${i}`,attrs:{"plot":plot,"index":i,"prefix":_vm.prefix,"productName":_vm.pageProduct?.name}})}),1),_c('div',{staticClass:"questions-buttons-wrapper"},[_c('questions-button',{attrs:{"text":_vm.lang.plots_section.text,"primaryLink":{
						type: 'internal',
						to: 'contact',
						title: _vm.lang.plots_section.button_title,
						content: _vm.lang.plots_section.button_text,
						button_type: 'primary'
					}}})],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }