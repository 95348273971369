export const getBlockedLinks = (routes) => {
	const blockedLinks = routes.filter(route => {
		const { path } = route

		if (path === '/' || path === '/blog') return route

		route.path = '#!'

		return route
	})

	return blockedLinks
}
