<template>
	<div class="section-title-wr" v-if="useString" :class="{WiFi: wifiTitle}">

		<div :class="`section-title-wr h1 color-default ${!margin ? 'no-margin' : ''}`" v-if="isTypeH1 && isColorDefault">
			<div class="section-title-wrapper" v-if="title">
				<base-svg class="icon" name="title_icon" v-if="showBaseSvg"/>
				<base-font class="title" :tag="header_tag" color="secondary" variant="h1" :uppercase="isTransformUppercase" :layout="layout" :html="this.wifiTitle ? this.wifiTitle : this.title"/>
			</div>
			<div class="section-subtitle-wrapper" v-if="subtitle">
				<base-font tag="span" color="secondary"> {{ subtitle }}</base-font>
				<div class="subtitle-after" v-if="showUnderline"></div>
			</div>
		</div>

		<div :class="`section-title-wr h1 color-white ${!margin ? 'no-margin' : ''}`" v-if="isTypeH1 && isColorWhite">
			<div class="section-title-wrapper" v-if="title">
				<base-svg class="icon" name="title_icon" v-if="showBaseSvg"/>
				<base-font class="title" :tag="header_tag" color="white" variant="h1" :uppercase="true" :layout="layout" :html="this.wifiTitle ? this.wifiTitle : this.title"/>
			</div>
			<div class="section-subtitle-wrapper" v-if="subtitle">
				<base-font tag="span" color="white"> {{ subtitle }}</base-font>
				<div class="subtitle-after" v-if="showUnderline"></div>
			</div>
		</div>

		<div :class="`section-title-wr h2 color-white ${!margin ? 'no-margin' : ''}`" v-if="isTypeH2 && isColorWhite">
			<div class="section-title-wrapper">
				<base-svg class="icon" name="title_icon" v-if="showBaseSvg"/>
				<base-font  class="title" :tag="header_tag" color="white" variant="h2" :uppercase="isTransformUppercase" :layout="layout" :html="this.wifiTitle ? this.wifiTitle : this.title"/>
			</div>
			<div class="section-subtitle-wrapper" v-if="subtitle">
				<base-font tag="span" color="white"> {{ subtitle }}</base-font>
				<div class="subtitle-after"></div>
			</div>
		</div>

		<div :class="`section-title-wr h2 color-default ${!margin ? 'no-margin' : ''}`"  v-else-if="isTypeH2 && isColorDefault">
			<div class="section-title-wrapper">
				<base-svg class="icon" name="title_icon" v-if="showBaseSvg"/>
				<base-font class="title" :tag="header_tag" variant="h2" :uppercase="isTransformUppercase" :layout="layout" :html="this.wifiTitle ? this.wifiTitle : this.title"/>
			</div>
			<div class="section-subtitle-wrapper" v-if="subtitle">
				<base-font tag="span"> {{ subtitle }}</base-font>
				<div class="subtitle-after"></div>
			</div>
		</div>

	</div>

	<div class="section-title-wr" v-else-if="!useString">

		<div :class="`section-title-wr h1 color-default ${!margin ? 'no-margin' : ''}`" v-if="isTypeH1 && isColorDefault">
			<div class="section-title-wrapper" v-if="title">
				<base-svg class="icon" name="title_icon" v-if="showBaseSvg"/>
				<cms-text class="title" :value="title"  :props="{tag: header_tag, color: 'secondary', variant: 'h1', uppercase: isTransformUppercase }" :layout="layout"/>
			</div>
			<div class="section-subtitle-wrapper" v-if="subtitle">
				<cms-text :value="subtitle" :props="{tag: 'span', color: 'secondary'}" :layout="layout"/>
				<div class="subtitle-after" v-if="showUnderline"></div>
			</div>
		</div>

		<div :class="`section-title-wr h1 color-white ${!margin ? 'no-margin' : ''}`" v-if="isTypeH1 && isColorWhite">
			<div class="section-title-wrapper" v-if="title">
				<base-svg class="icon" name="title_icon" v-if="showBaseSvg"/>
				<cms-text class="title" :value="title"  :props="{tag: header_tag, color: 'white', variant: 'h1', uppercase: isTransformUppercase }" :layout="layout"/>
			</div>
			<div class="section-subtitle-wrapper" v-if="subtitle">
				<cms-text :value="subtitle" :props="{tag: 'span', color: 'white', uppercase: true}" :layout="layout"/>
				<div class="subtitle-after" v-if="showUnderline"></div>
			</div>
		</div>

		<div :class="`section-title-wr h2 color-white ${!margin ? 'no-margin' : ''}`" v-if="isTypeH2 && isColorWhite">
			<div class="section-subtitle-wrapper" v-if="subtitle">
				<cms-text :value="subtitle" :props="{tag: header_tag, color: 'white', variant: 'h2'}" :layout="layout"/>
			</div>
		</div>

		<div :class="`section-title-wr h2 color-default ${!margin ? 'no-margin' : ''}`" v-else-if="isTypeH2 && isColorDefault">
			<div class="section-subtitle-wrapper" v-if="subtitle">
				<cms-text :value="subtitle" :props="{tag: header_tag, variant: 'h2'}" :layout="layout"/>
			</div>
		</div>

	</div>

</template>

<script>
import BaseFont from '~/website/front/components/atoms/BaseFont/BaseFont'

export default {
	components: { BaseFont },
	props: {
		title: String,
		subtitle: String,
		useString: {
			type: Boolean,
			default: false
		},
		header_tag: {
			type: String,
			default: 'h2'
		},
		layout: Boolean,
		type: {
			type: String,
			default: 'h1'
		},
		showBaseSvg: {
			type: Boolean,
			default: true
		},
		color: {
			type: String,
			default: 'default'
		},
		transform: {
			type: String,
			default: 'uppercase'
		},
		showUnderline: {
			type: Boolean,
			default: true
		},
		margin: {
			type: Boolean,
			default: true
		}
	},

	computed: {

		isTransformUppercase () {
			return this.transform === 'uppercase'
		},

		isColorDefault () {
			return this.color === 'default'
		},

		isColorWhite () {
			return this.color === 'white'
		},

		isTypeH1 () {
			return this.type === 'h1'
		},

		isTypeH2 () {
			return this.type === 'h2'
		},

		wifiTitle () {
			if (!this.title) return null
			const searchedStrings = [
				'Wi-fi',
				'wi-fi',
				'wifi',
				'WIFI',
				'Wifi',
				'Wi-Fi'
			]
			let wifiString = ''
			searchedStrings.forEach((substring) => {
				const position = this.title?.indexOf(substring)
				if (position !== -1) {
					wifiString = this.title.slice(0, position).toUpperCase() +
						'Wi-Fi' + this.title.slice(position + substring.length, this.title.length).toUpperCase()
				}
			})
			return wifiString
		}
	}
}

</script>

<style lang="scss">
.section-title-wr {
	position: relative;
	z-index: 1;

	&.WiFi {
		.section-title-wr{
			.section-title-wrapper{
				.title {
					text-transform: none;
				}
			}

		}

	}

	&.h1 {
		padding-top: 60px;
		margin-bottom: 40px;

		@include media-breakpoint-up(lg) {
			padding-top: 100px;
			margin-bottom: 60px;
		}

		@include media-breakpoint-up(xl) {
			max-width: 80%;
		}

		.section-title-wrapper {
			display: flex;
			align-items: center;
			gap: 15px;

			.svg-icon{
				align-self: flex-start;
			}

			svg {
				width: 15px;
				height: 31px;
				transform: translateY(40%);
			}
		}

		.section-subtitle-wrapper {
			padding-left: 30px;
			display: flex;
			align-items: center;
			gap: 20px;

			@include media-breakpoint-up(lg) {
				padding-left: 0;
			}

			span {
				display: block;
				font-weight: 400;
				font-size: 15px;
				text-transform: uppercase;

				@include media-breakpoint-up(lg) {
					font-size: 26px;
					line-height: 33px;
				}
			}

			.subtitle-after {
				display: none;
				opacity: 0.3;
				max-width: 30%;
				width: 100%;
				height: 2px;
				background: rgb(51, 51, 51);
				background: linear-gradient(90deg, rgba(51, 51, 51, 1) 0%, rgba(51, 51, 51, 0) 100%);

				@include media-breakpoint-up(lg) {
					display: block;
				}
			}
		}

		&.color-default {
		}

		&.color-white {

			.section-title-wrapper {

				.svg-icon{

					svg {

						path {
							fill: $white;
						}
					}
				}
			}

			.section-subtitle-wrapper {

				.subtitle-after{
					background: transparent linear-gradient(270deg, #FFFFFF00 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
				}
			}
		}

		&.no-margin {
			padding-top: 0;

			@include media-breakpoint-up(lg) {
				padding-top: 0;
			}
		}
	}

	&.h2 {
		padding-top: 40px;
		margin-bottom: 20px;
		@include media-breakpoint-up(lg) {
			padding-top: 60px;
			margin-bottom: 30px;
		}

		&.no-margin {
			padding-top: 0;

			@include media-breakpoint-up(lg) {
				padding-top: 0;
			}
		}

		&.color-white {

		}
	}
}

.icon {
	display: inline-block;
	width: 15px;
	height: 31px;
}

</style>
