<template>
	<div class="questions-section">
		<base-container>
			<div class="section-inner">
				<custom-title
					v-if="value.section_title"
					:title="`${prefix}.section_title.title`"
					:subtitle="`${prefix}.section_title.subtitle`"
					:header_tag="value.section_title?.title_tag"
					:color="value.section_title.title_color"
					:transform="value.section_title.title_transform"
					:type="value.section_title_type"
				/>
				<basic-accordion v-if="value.questions.length">
					<base-animation
						v-for="(item, i) in value.questions"
						:animation="evenAnimation(i, 'slideRight', 'slideLeft')"
						:key="i"
					>
						<accordion-item
							type="primary"
						>
							<template v-slot:accordion-trigger>
								<base-font weight="bold" color="white" class="accordion-button__title">{{item.item_title}}</base-font>
							</template>
							<template v-slot:accordion-content v-for="(subitem, j) in item.items_answers">
								<cms-text
									v-if="subitem.type === 'text'"
									:value="`${prefix}.questions.${i}.items_answers.${j}.value.item_text`"
									:key="`data${j}`"
									:props="{
										variant: 'paragraph',
										color: 'black',
										tag: 'div'
									}"
									class="accordion-content__description"
								/>
								<cms-image
									v-if="subitem.type === 'image'"
									:value="`${prefix}.questions.${i}.items_answers.${j}.value.item_image`"
									:base="{height: 300}"
									:key="`data${j}`"
								/>
							</template>

						</accordion-item>
					</base-animation>
				</basic-accordion>

				<div class="questions-buttons-wrapper">
					<questions-button
						:text="lang.faq_section?.text || ''"
						:primaryLink="{
							type: 'internal',
							to: 'contact',
							title: lang.faq_section?.button_title,
							content: lang.faq_section?.button_text,
							button_type: 'primary'
						}"
					/>
				</div>
			</div>
		</base-container>
	</div>
</template>

<script>
import BaseContainer from '~/website/front/components/atoms/BaseContainer/BaseContainer'
import CustomTitle from '~/website/front/components/atoms/CustomTitle/CustomTitle'
import AccordionItem from '~/website/front/components/molecules/accordion/AccordionItem/AccordionItem.vue'
import BasicAccordion from '~/website/front/components/molecules/accordion/BasicAccordion/BasicAccordion.vue'
import QuestionsButton from '~/website/front/components/molecules/QuestionsButton/QuestionsButton'
import { evenAnimationMixin } from '~/cms/front/mixins/evenAnimationMixin'
import BaseAnimation from '@f/components/BaseAnimation/BaseAnimation'

export default {
	components: { QuestionsButton, BasicAccordion, AccordionItem, CustomTitle, BaseContainer, BaseAnimation },
	mixins: [evenAnimationMixin],
	props: {
		prefix: String,
		value: Object
	},
	data () {
		return {
			structuredData: this.getStructuredData()
		}
	},
	computed: {
		lang () {
			return this.$app.translator.get('faq') || {}
		}
	},
	methods: {
		getStructuredData () {
			return {
				'@context': 'https://schema.org',
				'@type': 'FAQPage',
				mainEntity: this.value.questions?.map(question => ({
					'@type': 'Question',
					name: question.item_title,
					acceptedAnswer: {
						'@type': 'Answer',
						text: question.items_answers?.filter(item => item.type === 'text').reduce((acc, valueObj) => acc + valueObj.value.item_text, '')
					}
				}))
			}
		}
	},
	async prefetch () {
		this.$app.websiteStructuredDataManager.createStructuredData(this.structuredData)
		await this.$app.translator.prefetch('faq')
	}
}
</script>

<style lang="scss" scoped>
.questions-section{
	@include spacing-section();

	.questions-buttons-wrapper{
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 30px;

		@include media-breakpoint-up(lg) {
			margin-top: 60px;
		}
	}
}
</style>
