import { Page } from '~/cms/front/core/pages/Page'

class AbstractOpinionListPage extends Page {
	loading = true
	opinions = []

	async fetchData () {
		await super.fetchData()
		const Opinion = this.app.getEntity('opinion')

		const data = await Opinion.fetch({
			app: this.app,
			take: 10,
			skip: 0,
			type: 'list'
		})

		this.opinions = data
		this.loading = false
	}

	restore () {
		super.restore()
		this.opinions = window.__PAGE__.opinions
	}

	store () {
		const store = super.store()
		store.opinions = this.opinions
		return store
	}
}

export { AbstractOpinionListPage }
