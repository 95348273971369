<template>
	<div class="social-mobile">
		<base-link v-for="link in getSocialData" :url="link.link" :key="link.link" class="social-link">
			<base-svg :name="link.icon.path" class="social-icon"/>
		</base-link>
	</div>
</template>

<script>
import BaseLink from '~/cms/front/components/BaseLink/BaseLink'
import BaseSvg from '~/website/front/components/atoms/BaseSvg/BaseSvg'
export default {
	components: { BaseSvg, BaseLink },
	computed: {
		getSocialData () {
			const socialRepeater = this.$app.layout.value
			return socialRepeater.social_repeater
		}
	}
}
</script>

<style lang="scss" scoped>
.social-mobile{
	display: flex;
	flex-direction: column;
	gap: 12px;
	justify-self: flex-end;

	.social-link{
		max-width: 20px;

		::v-deep(svg) {
			height: 20px;
			width: 20px;
		}
	}
}
</style>
