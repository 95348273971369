<template>
	<div
		class="product-variant"
		:class="{active}"
		@click="handleClick"
	>
		<base-font
			weight="bold"
			color="secondary"
			size="product-variant-button"
		>
			{{name}}
		</base-font>
	</div>
</template>

<script>
export default {
	name: 'ProductVariantButton',
	props: {
		active: Boolean,
		name: String,
		value: [String, Number]
	},
	methods: {
		handleClick () {
			if (!this.active) this.$emit('handleClick', this.value)
		}
	}
}
</script>

<style lang="scss" scoped>
.product-variant {
	transition: all .3s ease-in-out;
	display: flex;
	min-width: 60px;
	min-height: 35px;
	align-items: center;
	justify-content: center;
	border: 1px solid #eee;
	border-radius: 5px;
	cursor: pointer;

	@include media-breakpoint-up(lg) {
		padding: 8px 20px;
	}

	&.active {
		border-color: $primary;
		cursor: default;
	}
}
</style>
