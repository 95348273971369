<template>
	<div class="contact-page">
		<form-section
			:background-image="backgroundImage"
			:image="formImage"
		/>
		<base-container>
			<custom-title
				header_tag="h1"
				type="h1"
				:title="translate.title"
				:subtitle="translate.subtitle"
				:use-string="true"
			/>
		</base-container>
		<company-details
			:translate="translate"
			:settings-langs="settingsLangs"
			:page-langs="pageLangs"
		/>
		<bank-accounts
			:translate="translate"
			:currency_items="currencyItems"
		/>
	</div>
</template>
<script>
import { pageMixin } from '~/website/front/core/pages/page.mixin'
import CompanyDetails from '~/website/front/core/pages/ContactPage/components/CompanyDetails.vue'
import FormSection from '~/website/front/core/pages/ContactPage/components/FormSection.vue'
import BankAccounts from '~/website/front/core/pages/ContactPage/components/BankAccounts.vue'
import CustomTitle from '~/website/front/components/atoms/CustomTitle/CustomTitle.vue'

export default {
	components: {
		CustomTitle,
		BankAccounts,
		FormSection,
		CompanyDetails
	},
	mixins: [
		pageMixin
	],
	computed: {
		translate () {
			return this.$app.translator.get('contact') || {}
		},
		settingsLangs () {
			return this.$app.settings.general.contact
		},
		pageLangs () {
			return this.$app.page.value.contact_page
		},
		formImage () {
			return this.$app.page.value.contact_page?.formImage || null
		},
		currencyItems () {
			return this.$app.page.value.contact_currency?.items || []
		},
		backgroundImage () {
			if (!this.pageLangs) return null

			const path = this.pageLangs.background.path

			return {
				src: path,
				breakpoints: {
					base: { width: 1920, height: 1136 }
				}
			}
		}
	},
	async prefetch () {
		await this.$app.translator.prefetch('contact')
	}
}
</script>
