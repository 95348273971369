<template>
	<section class="router-photo-section" :class="padding">
		<base-container>

			<div v-if="isTitleStyleH1" class="title-style-h1">
				<div>
					<base-animation
						animation="slideRight"
						v-if="value.section_title"
					>
						<custom-title
							:title="`${prefix}.section_title.title`"
							:subtitle="`${prefix}.section_title.subtitle`"
							:header_tag="value.section_title?.title_tag"
							:color="value.section_title.title_color"
							:transform="value.section_title.title_transform"
							:type="value.section_title_type"
						/>
					</base-animation>

				</div>
				<div class="section-wrapper">
					<base-animation animation="slideRight">
						<cms-text
							v-if="value.content"
							class="router-photo-text"
							:value="`${prefix}.content`"
							:props="{tag: 'div'}"
						/>
					</base-animation>
					<base-animation animation="slideLeft">
						<cms-image
							:class="{shadow: value.shadow}"
							:value="`${prefix}.img`"
							v-bind="defaultCmsImageBreakpoints"
							lazy
						/>
					</base-animation>

				</div>
			</div>
			<div v-else-if="hasSectionImage && !isReversed" class="section-wrapper" :class="{'image-large': isImageStyleLarge}">
				<div>
					<base-animation
						v-if="value.section_title"
						animation="slideRight"
					>
						<custom-title
							:title="`${prefix}.section_title.title`"
							:subtitle="`${prefix}.section_title.subtitle`"
							:header_tag="value.section_title?.title_tag"
							:color="value.section_title.title_color"
							:transform="value.section_title.title_transform"
							:type="value.section_title_type"
						/>
						<cms-text
							v-if="value.content"
							class="router-photo-text"
							:value="`${prefix}.content`"
							:props="{tag: 'div'}"
						/>
					</base-animation>
				</div>
				<base-animation animation="slideLeft">
					<cms-image
						:class="{shadow: value.shadow}"
						:value="`${prefix}.img`"
						v-bind="defaultCmsImageBreakpoints"
						lazy
					/>
				</base-animation>
			</div>

			<div v-else-if="hasSectionImage && isReversed" class="section-wrapper reversed" :class="{'image-large': isImageStyleLarge}">
				<base-animation animation="slideRight">
					<cms-image
						:class="{shadow: value.shadow}"
						:value="`${prefix}.img`"
						v-bind="defaultCmsImageBreakpoints"
						lazy
					/>
				</base-animation>

				<base-animation animation="slideLeft">
					<custom-title
						v-if="value.section_title"
						:title="`${prefix}.section_title.title`"
						:subtitle="`${prefix}.section_title.subtitle`"
						:header_tag="value.section_title?.title_tag"
						:color="value.section_title.title_color"
						:transform="value.section_title.title_transform"
						:type="value.section_title_type"
					/>
					<cms-text
						v-if="value.content"
						class="router-photo-text"
						:value="`${prefix}.content`"
						:props="{tag: 'div'}"
					/>
				</base-animation>
			</div>

			<div v-else class="section-wrapper no-img">
				<base-animation animation="slideRight">
					<custom-title
						v-if="value.section_title"
						:title="`${prefix}.section_title.title`"
						:subtitle="`${prefix}.section_title.subtitle`"
						:header_tag="value.section_title?.title_tag"
						:color="value.section_title.title_color"
						:transform="value.section_title.title_transform"
						:type="value.section_title_type"
					/>
				</base-animation>

				<base-animation animation="slideRight">
					<cms-text
						v-if="value.content"
						class="router-photo-text"
						:value="`${prefix}.content`"
						:props="{tag: 'div'}"
					/>
					<buttons v-if="value.buttons?.length" :value="value.buttons" />
				</base-animation>
			</div>
		</base-container>
	</section>
</template>

<script>
import BaseContainer from '~/website/front/components/atoms/BaseContainer/BaseContainer'
import CustomTitle from '~/website/front/components/atoms/CustomTitle/CustomTitle'
import Buttons from '~/website/front/sections/Buttons/Buttons'
import { paddingSectionMixin } from '~/website/front/utils/paddingSection.mixin.js'
import BaseAnimation from '@f/components/BaseAnimation/BaseAnimation'
import { defaultCmsImageBreakpoints } from '~/cms/front/mixins/defaultCmsImageBreakpoints'
export default {
	components: { Buttons, CustomTitle, BaseContainer, BaseAnimation },
	props: {
		prefix: String,
		value: Object
	},
	computed: {

		hasSectionImage () {
			return this.value.img
		},

		isReversed () {
			return this.value?.reversed
		},
		isTitleStyleH1 () {
			return this.value?.section_title_type === 'h1'
		},
		isImageStyleLarge () {
			return this.value?.image_style
		}
	},
	mixins: [paddingSectionMixin, defaultCmsImageBreakpoints]
}
</script>

<style lang="scss" scoped>
.router-photo-section {
	@include media-breakpoint-up(lg) {
		overflow: hidden;
	}

	.title-style-h1{
		:deep(.section-title-wr) {
			padding-top: 0;
		}
	}

	.section-wrapper {
		position: relative;

		@include media-breakpoint-up(lg) {
			display: grid;
			grid-template-columns: 1fr 1fr;
			align-items: center;
			gap:30px;
		}

		@include media-breakpoint-up(xxl) {
			gap: 150px;
		}

		&.image-large{
			@include media-breakpoint-up(lg) {
				gap: 0;
			}

			@include media-breakpoint-up(xxl) {
				grid-template-columns: 2fr 3fr;
			}

			:deep(img) {

				@include media-breakpoint-up(lg) {
					transform: translateX(100px);
				}

				@include media-breakpoint-up(xxl) {
					transform: translateX(150px);
				}
			}

			&.reversed {
				display: flex;
				flex-direction: column-reverse;
				@include media-breakpoint-up(lg) {
					display: grid;
					grid-template-columns: 1fr 1fr;
				}

				@include media-breakpoint-up(xxl) {
					grid-template-columns: 3fr 2fr;
					gap: 0;
				}

				:deep(img) {
					@include media-breakpoint-up(lg) {
						transform: translateX(-100px);
					}

					@include media-breakpoint-up(xxl) {
						transform: translateX(-150px);
					}
				}
			}
		}

		&.no-img {
			display: block;

			:deep(.router-photo-text) {
				@include media-breakpoint-up(xl) {
					max-width: none;
				}
			}
		}

		:deep(.router-photo-text) {
			@include media-breakpoint-up(xxl) {
				max-width: 570px;
			}
		}

		:deep(.section-title-wr.h2) {
			padding-top: 0;
		}

		:deep(.image-wrapper){

			&.shadow {
				img {
					box-shadow: 6px 6px 20px #0000003D;
				}
			}

			img {
				margin-top:2rem;
				max-width: 100%;
				margin-left: auto;
				margin-right: auto;
				display: flex;
				height: 100%;
				border-radius: 10px;
				@include media-breakpoint-up(lg) {
					margin-top:0;
				}
			}
		}

		:deep(.base-animation-content-wrapper) {
			height: auto;
		}
	}
	:deep(.ql-editor) {
		padding:0;
		height: auto;
	}

}
</style>
