<template>
	<div class="grid-items-wrapper" :style="{gridTemplateAreas: gridArea}">
		<div class="grid-item"
			v-for="(item, i) in items"
			:key="item.alias"
			:style="{gridArea: item.alias}"
			:class="{
				small: isTemplateSmall(item),
				hasDescBottom: hasItemDescBottom(item),
				hasDescTop: hasItemDescTop(item)
			}"
		>
			<div class="item-inner" v-if="isTemplateDefault(item)">
				<cms-image
					v-if="item.data.image"
					:value="`${prefix}.grid_builder.items.${i}.data.image`"
					:src="{
						alt: `Parts of ${productName}`,
						path: item.data.image.path,
						title: `In the box ${productName}`
					}"
					lazy
					:key="i"
				/>
				<base-font variant="grid-item-desc" class="text-top" v-if="item.data.text_top"> {{item.data.text_top}} </base-font>
				<base-font variant="grid-item-desc-bottom" class="text-bottom" v-if="item.data.text_bottom"> {{item.data.text_bottom}} </base-font>
			</div>

			<div class="item-inner" v-else-if="isTemplateSmall(item)">
				<div class="img-bottom-wrapper">
					<cms-image
						v-if="item.data.image"
						:value="`${prefix}.grid_builder.items.${i}.data.image`"
						:src="{
						alt: `Parts of ${productName}`,
						path: item.data.image.path,
						title: `In the box ${productName}`
					}"
						lazy
						:key="i"
					/>
					<base-font variant="grid-item-desc-bottom" class="text-bottom" v-if="item.data.text_bottom"> {{item.data.text_bottom}} </base-font>
				</div>
				<base-font variant="grid-item-desc" class="text-top" v-if="item.data.text_top"> {{item.data.text_top}} </base-font>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		items: Array,
		gridArea: String,
		prefix: String,
		productName: {
			type: String,
			default: ''
		}
	},
	methods: {
		isTemplateDefault (item) {
			return item.data.template === 'default'
		},

		isTemplateSmall (item) {
			return item.data.template === 'small'
		},

		hasItemDescBottom (item) {
			return !!item.data.text_bottom
		},

		hasItemDescTop (item) {
			return !!item.data.text_top
		}
	}
}
</script>

<style lang="scss" scoped>
.grid-items-wrapper {
	display: flex;
	flex-direction: column;
	gap: 30px;

	@include media-breakpoint-up(lg) {
		display: grid;
		grid-auto-rows: 100px;
		grid-auto-columns: minmax(0, 1fr);
		grid-auto-flow: column;
	}

	.grid-item {
		@include transition;
		position: relative;
		background-color: $white;
		display: flex;
		border-radius: 5px;
		box-shadow: 6px 6px 20px #0000000F;
		align-items: center;
		justify-content: center;

		&:hover {
			transform: translateY(-5px);
		}

		.item-inner{
			width: 100%;
			height: 100%;
			overflow: hidden;
		}

		&:not(.small) {
			.item-inner{
				overflow: hidden;
			}

			:deep(.image-wrapper) {
				width: 100%;
				height: 100%;

				picture{
					width: 100%;
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					img{
						max-width: 80%;
						height: auto;
						max-height: 80%;
					}
				}
			}
		}

		:deep(.image-wrapper) {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 100%;
			padding: 10px;

			@include media-breakpoint-up(lg) {
				padding: 0;
			}

			img {
				max-width: 100%;
			}
		}

		::v-deep(.text-top) {
			display: block;
			position: absolute;
			top: 10px;
			left: 10px;
		}

		::v-deep(.text-bottom){
			display: block;
			position: absolute;
			bottom: 5px;
			left: 0;
			right: 0;
			margin: auto;
			width: fit-content;
			@include media-breakpoint-up(lg) {
				bottom: 10px;
			}
		}
	}

	.grid-item {

		&.small {
			@include media-breakpoint-up(lg) {
				height: 70px;
				justify-content: flex-end;
				align-items: flex-start;
				align-self: end;
				padding: 6px 15px 0 0;

				::v-deep(.text-bottom){
					position: static;
				}

				@include media-breakpoint-up(lg) {
					height: 105px;
					padding: 0;
				}

				@include media-breakpoint-up(xxl) {
					height: 65px;
				}
			}
			.item-inner{
				@include media-breakpoint-up(xl) {
					display: flex;
					justify-content: flex-end;
					padding-right: 10px;
				}

				.img-bottom-wrapper{
					@include media-breakpoint-up(xl) {
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						width: fit-content;
					}

					::v-deep(.image-wrapper) {
						@include media-breakpoint-up(xl) {
							width: fit-content;
						}

					}

					::v-deep(.text-bottom) {
						@include media-breakpoint-up(xl) {
							margin: 0;
						}
					}
				}
			}

		}

		&.hasDescBottom {

			&:not(.small) {
				padding-bottom: 30px;

				@include media-breakpoint-up(lg) {

				}
			}

		}

		&.hasDescTop {
			padding-top: 30px;

			@include media-breakpoint-up(xl) {
				padding-top: 0;
			}

			&.small {
				@include media-breakpoint-up(lg) {
					padding-top: 45px;
				}

				@include media-breakpoint-up(xxl) {
					padding-top: 10px;
				}
			}
		}
	}
}
</style>
