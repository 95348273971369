import { Page } from '~/cms/front/core/pages/Page'
import { BreadcrumbsBuilder } from '~/website/front/core/elements/Breadcrumb/BreadcrumbsBuilder.js'
import Component from './ProductPage.vue'
import { isPageFromNull } from 'utils/ssrCheck.js'
import { ProductSeo } from '~/cms/front/core/seo/ProductSeo'
import { productHistoryService } from '~/catalog/front/services/ProductHistoryService'

class ProductPage extends Page {
	static alias = 'product'
	component = Component
	static keepAlive = true
	historyService = productHistoryService

	breadcrumbs = false

	setConstructorData ({ app, route, from }) {
		this.app = app
		this.route = route
		this.from = from
		this.seo = new ProductSeo(this.app, { page: this })
	}

	async init () {
		await super.init(...arguments)
		await this.app.translator.prefetch('product')
		this.loading = false

		try {
			this.breadcrumbs = new BreadcrumbsBuilder(this.app)
				.build()
				.generateFromMenuTree('product-list', this.product?.subcategory)
				.addCrumb(this.product.name, '')
		} catch (err) {
			console.error(err)
		}

		this.fetchCompatibleRouters()
		if (!isPageFromNull({ page: this })) {
			window.scrollTo({
				behavior: 'smooth',
				top: 0
			})
		}

		this.historyService.pushOne(this.product)
	}

	product = null
	loading = true
	hasRelatedProducts = false

	get data () {
		return {
			name: this.product ? this.product.name : '',
			subcategory: this.product ? this.product.subcategory.name : ''
		}
	}

	async fetchCompatibleRouters () {
		if (!this.product.antennaId) return

		const service = this.app.getService('rext')
		const compatibleRouters = await service.getBrandsWithCompatibleRouters(this.product.antennaId)
		if (compatibleRouters.error) return

		this.product.compatibleRouters = compatibleRouters
	}

	handleNotFound () {
		return this.app.vue.$redirect({ path: '/404' })
	}

	async fetchData () {
		const service = this.app.getService('rext')
		const page = await service.getPage(this.alias, {
			relations: [
				'product',
				'product.subcategory',
				'product.antenna',
				'product.enclosure',
				'product.accessory'
			],
			response: 'front'
		})

		if (page.alias === 'NOT_FOUND') return this.handleNotFound()
		if (!page.product) return this.handleNotFound()
		if (!page.product.antenna && !page.product.accessory && !page.product.enclosure) return this.handleNotFound()

		const activeVariantName = this.route.query.variant
		let activeVariant = activeVariantName ? page.product.antenna?.variants.find(variant => variant.name === activeVariantName) : null
		if (page.product.antenna?.variants.length === 1) {
			activeVariant = page.product.antenna.variants[0]
		}
		const activeGallery = page.value.gallery?.map(item => item.image) ||
			activeVariant?.value.gallery.map(item => item.image) ||
			page.product.antenna?.variants.map(variant => variant.value.gallery[0]?.image) ||
			[]

		const product = {
			id: page.product.id,
			alias: this.alias,
			antennaId: page.product?.antenna?.id,
			name: page.product.name,
			description: page.value.description,
			variants: page.product.antenna?.variants || [],
			icons: page.value.icons?.map(item => item.icon) || [],
			gallery: activeGallery,
			activeVariant: activeVariant || null,
			subcategory: page.product.subcategory.alias,
			compatibleRouters: []
		}
		await this.setPageData({
			...page,
			product
		})
	}

	handleVariantClick (name) {
		this.app.router.push({
			query: {
				variant: name
			}
		})
	}

	async setPageData (data) {
		await super.setPageData(data)
		this.product = data.product
	}

	restore () {
		this.componentsData = window.__PAGE__.componentsData
		this.value = window.__PAGE__.value
		this.type = window.__PAGE__.type
		this.product = window.__PAGE__.product
		this.layout = this.app.getLayout(window.__PAGE__.layout)
		if (this.layout) this.layout.restore()
	}

	store () {
		return {
			componentsData: this.componentsData,
			value: this.value,
			layout: this.layout ? this.layout.alias : undefined,
			type: this.type,
			product: this.product
		}
	}
}

export { ProductPage }
