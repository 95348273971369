class ProductHistoryService {
	_products = []

	pushOne (product) {
		this._products.push(product)
	}

	getLast () {
		return this._products.at(-1)
	}
}

export const productHistoryService = new ProductHistoryService()
