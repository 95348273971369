<template>
	<base-container>
		<div class="inner">
			<compatible-product-list
				v-if="dedicatedAntennas.length"
				:title="`ANTENNAS DEDICATED TO ${this.dedicatedTo}`"
				:products="dedicatedAntennas"
			/>
			<template v-if="compatibleAntennasLists">
				<compatible-product-list
					v-for="list in compatibleAntennasLists"
					:key="list.name"
					:title="list.name"
					:products="list.products"
					:load-more-mode="!!dedicatedAntennas.length"
				/>
			</template>
			<compatible-product-list
				v-if="dedicatedAccessories?.length"
				:title="`ACCESSORIES DEDICATED TO ${this.dedicatedTo}`"
				:products="dedicatedAccessories"
			/>
			<client-only>
				<select-model-notification v-if="shouldShowSelectModelNotification" />
				<no-filtered-products-notification v-else-if="shouldShowNoFilteredProductsNotification" />
			</client-only>
		</div>
	</base-container>
</template>

<script>
import CompatibleProductList from '~/catalog/front/components/organisms/CompatibleProductList/CompatibleProductList'
import NoFilteredProductsNotification from '~/catalog/front/components/organisms/NoFilteredProductsNotification/NoFilteredProductsNotification'
import SelectModelNotification from '~/catalog/front/components/organisms/SelectModelNotification/SelectModelNotification'
import ClientOnly from 'vue-client-only'

export default {
	components: {
		SelectModelNotification,
		CompatibleProductList,
		NoFilteredProductsNotification,
		ClientOnly
	},
	props: {
		dedicatedAntennas: Array,
		dedicatedAccessories: [Array, Boolean],
		compatibleAntennasLists: [Array, Boolean],
		form: Object,
		isLoading: Boolean
	},
	computed: {
		hasAnyProducts () {
			return this.dedicatedAntennas?.length ||
				this.dedicatedAccessories?.length ||
				this.compatibleAntennasLists?.length
		},
		dedicatedTo () {
			if (this.form.activeModel) return `${this.form.activeBrand.name} ${this.form.activeModel.name}`
			if (this.form.activeBrand) return `${this.form.activeBrand.name}`
			return ''
		},
		shouldShowSelectModelNotification () {
			return !this.hasAnyProducts && this.form.activeBrand && !this.form.activeModel && !this.isLoading
		},
		shouldShowNoFilteredProductsNotification () {
			return (!this.hasAnyProducts && !this.isLoading) || (!this.form.activeBrand && !this.form.activeModel)
		}
	}
}
</script>

<style scoped lang="scss">
.inner {
	padding-top: 40px;
	display: flex;
	flex-direction: column;
	gap: 20px;

	@include media-breakpoint-up(lg) {
		padding-top: 60px;
	}
}
</style>
