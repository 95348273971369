import { isObjectLike } from 'utils/objects'
import { config } from '@f/config'

export const getImageUrl = (app, imagePathOrObject) => {
	const origin = app.settings.env.origin
	const imagesPath = config.services.rext.imagesUrl

	if (!imagePathOrObject) return ''
	if (isObjectLike(imagePathOrObject) && imagePathOrObject.path) {
		return origin + imagesPath + imagePathOrObject.path
	} else {
		return origin + imagesPath + imagePathOrObject
	}
}
