var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"iconLeft__item"},[_c('div',{staticClass:"iconLeft__item-overlay"}),_c('base-svg',{staticClass:"iconLeft__item-svg",attrs:{"name":_vm.svg}}),_c('cms-text',{staticClass:"iconLeft__item-text",attrs:{"value":_vm.subtitle,"props":{
				tag: 'span',
				color: 'white',
				weight: 'bold',
				uppercase: true,
				size: 'icons-card-text'
			}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }