<template>
		<div class="grid-section-footer">
			<base-svg class="logo" name="logo" />
			<div class="signal-wrapper">
				<base-font>Designed For</base-font>
				<base-svg name="teltonika-logo"/>
			</div>
		</div>
</template>

<script>
import BaseFont from '~/website/front/components/atoms/BaseFont/BaseFont'
export default {
	components: { BaseFont }
}
</script>

<style lang="scss" scoped>
.grid-section-footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 15px;
	margin-top: 40px;
	background: rgba(32, 147, 223, 0.8);
	border-radius: 5px;

	@include media-breakpoint-up(xl) {
		padding: 20px 50px;
	}

	.logo {
		::v-deep(svg) {
			width: 120px;
			height: auto;
			fill: $white;

			@include media-breakpoint-up(xl) {
				width: 210px;
				height: 37px;
			}

			#Group_1586 {
				path {
					fill: $white;
				}
			}
		}
	}

	.signal-wrapper{
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		gap: 10px;

		@include media-breakpoint-up(sm) {
			flex-direction: row;
		}
		:deep(p) {
			color: $white;
		}
		p {
			color: $white;
			letter-spacing: 0.14px;
			font-weight: 600;
			font-size: 14px;
			line-height: 24px;
		}
	}
}
</style>
