
class Mimo {
	static async fetch ({ app }) {
		const service = app.getService('rext')
		const response = await service.getMimos()

		if (response.error) return []
		return response
	}
}

export { Mimo }
