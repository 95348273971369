<template>
	<div class="gallery-wrapper">
		<base-svg name="search" class="gallery-icon" @click="toggleHandler"/>
		<Transition>
			<product-slider-modal
				v-if="shouldShowImage"
				:isOpened="isOpened"
				:slider="slider"
				:active-image-index="activeSlideIndex"
				:product="product"
				@closeModal="toggleHandler"
			/>
		</Transition>
	</div>
</template>

<script>
import BaseSvg from '~/website/front/components/atoms/BaseSvg/BaseSvg'
import ProductSliderModal from '~/catalog/front/components/organisms/ProductSlider/components/ProductSliderModal'

export default {
	components: { BaseSvg, ProductSliderModal },
	props: {
		slider: Object,
		isOpened: Boolean,
		toggleHandler: Function,
		product: Object,
		shouldShowImage: Boolean
	},
	computed: {
		images () {
			return this.slider.images
		},
		activeSlideIndex () {
			return this.slider.activeSlideIndex
		}
	}
}
</script>

<style lang="scss" scoped>
.v-enter-active,
.v-leave-active {
	transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
	opacity: 0;
}
	.gallery-wrapper {
		.gallery-icon {
			position: absolute;
			bottom: 110px;
			right: 30px;
			width: 20px;
			cursor: pointer;
		}

		::v-deep(.vgs__container) {
			background-color: #fff;
			top: 0;
			bottom: 0;
			margin: auto;
			padding: 2rem;
		}

		::v-deep(.vgs__gallery) {
			display: block;
		}
	}
</style>
