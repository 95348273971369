<template>
	<div
		class="nav-items-wrapper"
		:class="{active: isMobileMenuActive}"
	>
		<nav-mobile-section
			:isMobileMenuActive="isMobileMenuActive"
			@toggleMobileMenu="toggleMobileMenu"
		/>

		<nav class="nav">
			<ul class="nav-list">
				<menu-item-mobile
					v-for="item in items"
					:key="item.name"
					:item="item"
					@close="toggleMobileMenu"
				/>
			</ul>
		</nav>
	</div>
</template>

<script>
import MenuItemMobile from '~/website/front/components/organisms/BaseMenu/components/Mobile/atoms/MenuItemMobile'
import NavMobileSection from '~/website/front/components/organisms/BaseMenu/components/Mobile/atoms/NavMobileSection'

export default {
	name: 'NavMobileScreen',
	components: { MenuItemMobile, NavMobileSection },
	props: {
		isMobileMenuActive: Boolean,
		items: Array
	},
	methods: {
		toggleMobileMenu () {
			this.$emit('toggleMobileMenu')
		}
	}
}
</script>

<style lang="scss" scoped>
.nav-items-wrapper {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #fff;
	z-index: 999;
	opacity: 0;
	visibility: hidden;
	@include transition();

	&.active {
		opacity: 1;
		visibility: visible;
	}

	.nav {
		padding-bottom: 200px;
		overflow-y: scroll;
		height: 100%;
		@include transition();

		.nav-list {
			list-style-type: none;
			margin-block-start: 0;
			margin-block-end: 0;
			padding-inline-start: 0;
			margin: 0;
			padding: 0;
			overflow: scroll;
			background: $white;
		}
	}
}

</style>
