<template>
	<section class="review">
		<base-container>
			<base-animation
				animation="slideRight"
			>
				<div class="review__title-wrapper">

					<custom-title
						:title="`${prefix}.section_title.title`"
						:subtitle="`${prefix}.section_title.subtitle`"
						:header_tag="value.section_title?.title_tag"
						:color="value.section_title.title_color"
						:transform="value.section_title.title_transform"
						:type="value.section_title_type"
					/>
				</div>
			</base-animation>

			<div class="review__cards-wrapper swiper" v-if="opinions.length">
				<div class="review__cards-inner swiper-wrapper">
					<opinion-slider-item
						v-for="(item, i) in opinions"
						:slide="{
							title: item.author,
							subtitle: item.place,
							description: item.content,
							image: item.image
						}"
						:link="item.pageLink"
						:isActive="slider?.activeIndex - 1 === i"
						:key="i"
						class="swiper-slide"
					/>
				</div>

				<div class="review__arrows-title">
					<base-svg
						name="opinion-arrow"
						class="slide-left swiper-button-prev"
						@click="slider.slidePrev()"
					/>
					<base-svg
						name="opinion-arrow"
						class="slide-right swiper-button-next"
						@click="slider.slideNext()"
					/>
				</div>

			</div>

			<buttons
				:value="[
					{button: value.section_secondaryLink},
					{button: value.section_primaryLink, icon: {path: 'button-chart'}}
				]"
			/>
		</base-container>
	</section>
</template>

<script>

import BaseContainer from '~/website/front/components/atoms/BaseContainer/BaseContainer'
import CustomTitle from '~/website/front/components/atoms/CustomTitle/CustomTitle'
import Buttons from '~/website/front/sections/Buttons/Buttons'
import { isPageFromNull } from 'utils/ssrCheck'
import BaseAnimation from '@f/components/BaseAnimation/BaseAnimation'
import OpinionSliderItem from '~/opinions/front/sections/OpinionSliderListSection/OpinionSliderItem.vue'
import Swiper from 'swiper'

export default {
	components: {
		OpinionSliderItem,
		Buttons,
		CustomTitle,
		BaseContainer,
		BaseAnimation
	},

	data () {
		return {
			opinions: [],
			slider: null
		}
	},

	props: {
		prefix: String,
		value: Object
	},

	methods: {

		recreateDataFromComponentsData () {
			this.opinions = this.$app.page.componentsData.opinionsSlider.opinions
		},

		async getOpinions (queryObject) {
			const service = this.$app.getService('rext')

			const data = await service.fetchOpinions(queryObject)

			if (data.error) {
				this.opinions = []
				return
			}

			this.opinions = data
		}
	},

	async mounted () {
		if (isPageFromNull(this.$app)) {
			this.recreateDataFromComponentsData()
		} else {
			await this.getOpinions({
				app: this.app,
				take: 999,
				skip: 0,
				type: 'list'
			})
		}

		if (process.client) {
			this.$nextTick(() => {
				this.slider = new Swiper('.swiper', {
					slidesPerView: 1,
					direction: 'horizontal',
					loop: true,
					spaceBetween: 30,
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev'
					}
				})
			})
		}
	},
	async prefetch () {
		if (process.server) {
			await this.getOpinions({
				app: this.app,
				take: 999,
				skip: 0,
				type: 'list'
			})
			this.$app.page.componentsData.opinionsSlider = {}
			this.$app.page.componentsData.opinionsSlider.opinions = this.opinions
		}
	},
	beforeDestroy () {
	}
}

</script>
<style lang="scss" scoped>
@mixin slider_fade {
	background: linear-gradient(rgba(246, 246, 246, 0) 0%, rgba(246, 246, 246, 0.69) 46.09%, rgba(246, 246, 246, 0.84) 59.16%, rgba(246, 246, 246, 0.9) 64.6%, rgba(246, 246, 246, 0.98) 82.8%, rgba(246, 246, 246, 1) 95.55%, #f6f6f6 100%);
	top: 0;
	height: 100%;
	width: 455px;
}

.review {
	@include spacing-section();
	overflow: hidden;
	position: relative;

	&__title-wrapper {
		@include media-breakpoint-up(lg) {
			display: grid;
			align-items: center;
			grid-template-columns: 10fr 2fr;
		}
	}

	&__arrows-title {
		display: none;
		align-items: center;

		@include media-breakpoint-up(lg) {
			display: flex;
		}

		:deep(.svg-icon) {
			position: absolute;
			top: 0;
			bottom: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			@include transition;

			&:hover {
				opacity: .5;
			}

			&.slide-left {
				left: 30px;
				transform: rotate(180deg);
			}

			&.slide-right {
				right: 30px;
			}
		}
	}

	&__cards-wrapper {
		width: 100%;
		:deep(.base-animation-content-wrapper) {
			display: flex;
			justify-content: center;
			width: 100%;
			min-height: 200px;
		}
	}

	&__cards-inner {
		display: flex;
		width: 100%;
	}

	.buttons-wrapper {
		justify-content: center;
	}
}

.indicators {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	margin-top: 3rem;
	gap: 1rem .8rem;
	padding: 0 2rem;
	min-height: 1.3rem;

	@include media-breakpoint-up(lg) {
		padding: 0;
	}

	&__btn {
		border: none;
		display: inline-block;
		width: .9rem;
		height: .9rem;
		padding: 0;
		background-color: $black;
		opacity: .1;
		border-radius: 50px;
		cursor: pointer;
		@include transition;

		@include media-breakpoint-up(xl) {
			&:hover {
				background-color: $primary;
				opacity: 1;
			}
		}

		&.active {
			background-color: $primary;
			opacity: 1;
			width: 1.3rem;
			height: 1.3rem;
		}
	}
}
</style>
