class Article {
	static async fetch ({ app, take, skip, where, relations }) {
		const service = app.getService('rext')

		const data = await service.getArticles({ take, skip, where, relations })
		const { count } = await service.getArticlesLength({ where, relations })

		if (data.error) return []

		app.page.articles = data

		return {
			numberOfAllArticles: count
		}
	}
}
export { Article }
