<template>
	<component
		:is="tag"
		:href="path"
		class="btn"
		:class="classes"
		:disabled="disabled"
		:title="title"
		@click.prevent="handleClick()"
	>
		<slot />
	</component>
</template>

<script>
import BaseSvg from '@f/components/BaseSvg/component/BaseSvg.vue'
import { NavigationMixin } from '~/cms/front/mixins/NavigationMixin'

export default {
	components: {
		BaseSvg
	},
	mixins: [NavigationMixin],
	props: {
		marginReset: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		title: String,
		button_type: {
			type: String,
			default: ''
		}
	},
	computed: {
		classes () {
			return [
				(() => this.marginReset ? 'margin-reset' : null)(),
				(() => this.disabled ? 'btn-disabled' : null)(),
				(() => this.button_type && `btn ${this.button_type}`)()
			].filter(c => !!c)
		}
	}
}
</script>
