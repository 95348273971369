<template>
	<div class="slider-arrows">
		<base-svg name="arrow_down" class="slider-arrow-prev" @click="prevSlide" />
		<base-svg name="arrow_down" class="slider-arrow-next" @click="nextSlide" />
	</div>
</template>

<script>
import BaseSvg from '~/website/front/components/atoms/BaseSvg/BaseSvg'
export default {
	components: { BaseSvg },
	props: {
		slider: Object
	},
	methods: {
		nextSlide () {
			this.slider.nextSlide()
		},
		prevSlide () {
			this.slider.prevSlide()
		}
	}
}
</script>

<style lang="scss" scoped>
	.slider-arrows{

		.slider-arrow-prev,
		.slider-arrow-next {
			position: absolute;
			bottom: 30px;
			cursor: pointer;
		}

		.slider-arrow-prev {
			left: 0;
		}

		.slider-arrow-next {
			right: 0;
			bottom: 37px;
			transform: rotate(180deg);

			@include media-breakpoint-up(xl) {
				right: 32px;
			}
		}
	}
</style>
