<template>
	<div class="downloads-dedicated-panel">
		<app-select
			:options="brands"
			@valueChanged="handleBrandChanged"
		/>
		<app-select
			:options="models"
			:disabled="!models.length"
			@valueChanged="handleModelChanged"
		/>
	</div>
</template>

<script>
import AppSelect from '~/catalog/front/components/molecules/AppSelected/AppSelect.vue'
export default {
	name: 'DownloadsDedicatedPanel',
	components: { AppSelect },
	props: {
		brands: Array,
		models: Array,
		activeBrand: Object,
		activeModel: Object
	},
	methods: {
		handleBrandChanged (brand) {
			this.$emit('brandChanged', brand)
		},

		handleModelChanged (model) {
			this.$emit('modelChanged', model)
		}
	}
}
</script>

<style lang="scss" scoped>
.downloads-dedicated-panel {
	display: flex;
	flex-direction: column;
	gap: 20px;
	flex-wrap: wrap;
	margin-top: 20px;

	@include media-breakpoint-up(lg) {
		flex-direction: row;
		:deep(.select) {
			width: 200px;
		}
	}
}
</style>
