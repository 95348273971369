<template>
	<section class="antenna-products">
		<base-container>
			<div class="antenna-products-inner">
				<base-animation
					v-if="value.section_title"
					animation="slideRight"
				>
					<custom-title
						:title="`${prefix}.section_title.title`"
						:subtitle="`${prefix}.section_title.subtitle`"
						:header_tag="value.section_title?.title_tag"
						:color="value.section_title.title_color"
						:transform="value.section_title.title_transform"
						:type="value.section_title_type"
					/>
				</base-animation>
				<base-animation animation="slideRight">
					<cms-text
						v-if="value.content"
						:value="`${prefix}.content`"
						:props="{tag: 'div'}"
					/>
				</base-animation>

				<base-animation>
					<div class="products-wrapper">
						<antenna-product
							v-for="(product,i) in value.items"
							:product="product"
							:key="product.title"
							:prefix="`${prefix}.items.${i}`"
						/>
					</div>
				</base-animation>

				<base-animation>
					<buttons
						:value="value.buttons"
						class="antenna-products-buttons"
					/>
				</base-animation>

			</div>
		</base-container>
	</section>
</template>

<script>
import BaseContainer from '~/website/front/components/atoms/BaseContainer/BaseContainer'
import CustomTitle from '~/website/front/components/atoms/CustomTitle/CustomTitle'
import AntennaProduct from '~/website/front/sections/AntennaProductsSection/components/AntennaProduct'
import BaseAnimation from '@f/components/BaseAnimation/BaseAnimation'
import Buttons from '~/website/front/sections/Buttons/Buttons'
export default {
	components: { AntennaProduct, CustomTitle, BaseContainer, BaseAnimation, Buttons },
	props: {
		prefix: String,
		value: Object
	}
}
</script>

<style lang="scss" scoped>
.antenna-products{
	:deep(.ql-editor) {
		padding:0;
	}
}
.products-wrapper {
	overflow: hidden;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 30px;
	margin-top:3rem;
	@include media-breakpoint-up(lg) {
		grid-template-columns: 1fr 1fr 1fr 1fr;
		margin-top:6rem;
	}
	:deep(.image-wrapper img) {
		max-width: 100%;
		height: auto;
	}

}
</style>
