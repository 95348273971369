<template>
	<div class="social-item">
		<base-link
			class="social-link"
			:url="card.link"
			type="external"
			title="Follow us in social networks"
		>
			<div class="wrapper-center ">
				<base-svg class="social-icon" :name="card.icon.path" />
				<base-font color="secondary" uppercase tag="h3" weight="semibold" :html="card.alias" />
			</div>
			<cms-image
				class="social-image"
				lazy
				:src="card.image"
				:base="{width: 333, height: 187}"
				:sm="{width: 300, height: 170}"
				:md="{width: 410, height: 231}"
			/>
		</base-link>
	</div>
</template>

<script>
export default {
	props: {
		card: Object
	}
}
</script>

<style lang="scss" scoped>

.wrapper-center {
	display:flex;
	align-items:center;
}

.social {
	&-link {
		width: 100%;
	}

	&-item{
		background: #FFFFFF;
		border-radius: 1rem;
		padding:1.5rem;
		@include transition();
		@include media-breakpoint-up(lg) {
			padding:2rem;
		}
		&:hover {
			transform: translateY(-10px)!important;
		}
		:deep(h3) {
			@include transition();
			margin-left:2rem;
			font-size:2.6rem;
			line-height:3.3rem;
			text-decoration: none;
			@include media-breakpoint-up(sm){
				font-size:2rem;
			}
			@include media-breakpoint-up(lg){
				font-size:2.6rem;
				line-height:3.3rem;
				margin-left: 3rem;
			}
		}
	}

	&-image {
		:deep(img) {
			border-radius: 1rem;
			width: 100%;
			max-width: 100%;
			height: auto;
			@include media-breakpoint-up(sm) {
				max-width: 100%;
				height:auto;
			}
		}
	}

	&-icon {
		::v-deep(svg) {
			width:9rem;
			height:9rem;
			@include media-breakpoint-up(sm) {
				width:6rem;
				height:6rem;
			}
			@include media-breakpoint-up(xl) {
				width: 9rem;
				height:9rem;
			}
		}
	}

}
</style>
