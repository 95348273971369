var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product"},[_c('cms-image',{attrs:{"value":`${_vm.prefix}.img`,"base":{
			width: 166,
			height: 127,
			background: 'transparent',
			fit: 'contain'
		},"md":{
			width: 240,
			height: 183,
			background: 'transparent',
			fit: 'contain'
		},"xl":{
			width: 330,
			height: 252,
			background: 'transparent',
			fit: 'contain'
		}}}),_c('cms-text',{attrs:{"value":`${_vm.prefix}.title`,"props":{
			tag: 'h3',
			color: 'primary',
			weight:'semibold',
			uppercase: true
		}}}),(_vm.product.description)?_c('cms-text',{staticClass:"product-description",attrs:{"value":`${_vm.prefix}.description`,"props":{variant: 'paragraph', tag: 'div'}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }