<template>
	<section class="why-our-enclosures" v-custom-background="getBackgroundImage">
		<base-container>
			<custom-title
				:title="title"
				:use-string="true"
				:subtitle="subtitle"
				:header_tag="value.section_title.title_tag"
				:color="value.section_title.title_color"
				:transform="value.section_title.title_transform"
				:type="value.section_title_type"
			/>
			<div class="wrapper">
				<div class="enclosure-wrapper">
					<enclosure
						v-for="(item, i) in value.list"
						:item="item"
						:prefix="prefix"
						:index="i"
						:key="item.item"
					/>
				</div>
				<cms-image
					class="image"
					:src="{
						alt: pageProduct?.name,
						path: value.section_image.path,
						title: `Why ${pageProduct?.name}`
					}"
					:base="{
						width: 323,
						height: 351,
						fit: 'contain',
						background: 'transparent'
					}"
					:xl="{
						width: 646,
						height: 702,
						fit: 'contain',
						background: 'transparent'
					}"
					lazy
				/>
			</div>
		</base-container>
	</section>
</template>

<script>
import BaseContainer from '~/website/front/components/atoms/BaseContainer/BaseContainer'
import CustomTitle from '~/website/front/components/atoms/CustomTitle/CustomTitle'
import Enclosure from './components/Enclosure'
import { productSectionMixin } from '~/catalog/front/sections/product-section.mixin'
import { formatProductName } from '~/catalog/front/utils/formatProductName.js'

export default {
	components: {
		BaseContainer,
		CustomTitle,
		Enclosure
	},
	mixins: [productSectionMixin],
	props: {
		prefix: String,
		value: Object
	},
	computed: {
		lang () {
			return this.$app.translator.get('products')
		},

		getBackgroundImage () {
			const path = this.value.background_image.path
			return {
				src: path,
				breakpoints: {
					base: { width: 1920, height: 896 }
				}
			}
		},
		title () {
			return `${this.lang.why_our_product_prefix} ${this.$app.page.product ? formatProductName(this.$app.page.product.name) : ''}`
		},
		subtitle () {
			return this.lang.why_our_product_subtitle
		}
	},
	async prefetch () {
		await this.$app.translator.prefetch('products')
	}
}

</script>

<style lang="scss" scoped>

.why-our-enclosures {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: right;
	@include spacing-section();
	padding-bottom: 0;

	@include media-breakpoint-up(lg) {
		padding-bottom: 0;
	}
	.section-title-wr {
		:deep(.section-title-wrapper .svg-icon.icon) path {
			fill: white;
		}
		:deep(.section-subtitle-wrapper .subtitle-after) {
			background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%)
		}
	}
	.wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: column;
		@include media-breakpoint-up(xl) {
			flex-direction: row;
		}

		.enclosure-wrapper {
			z-index: 1;
		}
	}
	.image :deep(picture){
		display: flex;
	}
	:deep(.ql-editor) {
		width: 100%;
		strong {
			display: inline;
		}
	}
}

</style>
