<template>
	<base-blur :blur="!!status" class="form-blur">
		<form class="form" v-if="form.inputs">

			<div class="form-inner-wrapper">
				<input-template wider :input="form.inputs.email"/>
				<input-template wider :input="form.inputs.phoneNumber"/>
			</div>
			<input-template wider :input="form.inputs.company"/>

			<field-radio-group :radio-group="form.inputs.topic" :label="langs.inputTopic.label"/>

			<input-template class="form-content" wider :input="form.inputs.content"/>

			<input-template wider :input="form.inputs.attachments"/>

			<div class="form-inner-wrapper form-buttom-police">
				<input-template :input="form.inputs.police"/>
				<button-submit
					button_type="primary-white"
					title="Send message to QuWireless"
					@click="submit"
					:loading="loading"
					class="form-btn"
					:rightSlot="true"
				>
					<base-font weight="bold" size="paragraph" color="primary" tag="span">
						{{ langs.button }}
					</base-font>
				</button-submit>
			</div>
			<div class="form-additional-info">
				<base-font size="paragraph" color="white">
					Fields with <span class="police-star">*</span> are required.
				</base-font>
			</div>
		</form>
		<template #status>
			<div class="status">
				<base-font size="md" family="primary" color="primary">
					{{ statusTranslation[status] || statusTranslation.error }}
				</base-font>
				<base-svg class="close-btn" name="close" @click="resetForm"/>
			</div>
		</template>
	</base-blur>
</template>
<script>
import ButtonSubmit from '~/website/front/components/molecules/buttons/ButtonSubmit'

import { Form } from '@f/core/forms/Form'
import {
	InputTemplate,
	InputText,
	InputEmail,
	Textarea,
	InputCheckbox,
	InputFile,
	InputRadio, RadioGroup
} from '@f/core/forms/inputs'
import { required, email, extensions } from 'utils/inputs/validation-front'
import FieldRadioGroup from '~/website/front/components/molecules/fileds/FieldRadioGroup/FieldRadioGroup.vue'
import { phoneNumber } from 'utils/inputs/validation.js'
import { productHistoryService } from '~/catalog/front/services/ProductHistoryService'

export default {
	data () {
		return {
			status: '',
			form: {},
			loading: false
		}
	},
	computed: {
		statusTranslation () {
			return this.langs.status
		},
		langs () {
			return this.$app.translator.get('forms').contactForm
		},
		isFromProductPage () {
			return this.$app.page.from.meta.isProductPage || false
		}
	},
	components: {
		FieldRadioGroup,
		InputTemplate,
		ButtonSubmit
	},
	methods: {
		async submit () {
			if (!await this.form.validate()) return false
			this.loading = true

			const product = !this.isFromProductPage
				? null
				: productHistoryService.getLast()

			const status = await this.$app.getService('rext').sendContactFormMessage({
				...this.form.getValues(),
				productPage: product
					? JSON.stringify({
						path: this.$app.page.from.fullPath,
						alias: this.$app.page.from.params.alias,
						productName: product?.name || null,
						productPartNumber: product?.activeVariant?.partNumber || null
					})
					: null
			})
			this.loading = false
			this.status = status

			if (status === 201) this.$app._emit('contact_form:message:sent')
		},

		resetForm () {
			this.status = ''
		},

		createForm () {
			const langs = this.$app.translator.get('forms').contactForm
			this.form = new Form()
			this.form.addInput(InputEmail.create({
				translations: () => langs.inputEmail,
				attribute: 'email',
				name: 'email',
				validation: [email()]
			}))
			this.form.addInput(InputText.create({
				translations: () => langs.inputPhoneNumber,
				attribute: 'phoneNumber',
				name: 'phoneNumber',
				validation: [phoneNumber()]
			}))
			this.form.addInput(InputText.create({
				translations: () => langs.inputCompany,
				attribute: 'companyName',
				name: 'company'
			}))

			const radioGroup = new RadioGroup('topic')
			radioGroup.addInput(InputRadio.create({
				translations: () => langs.inputTopic,
				attribute: 'sales',
				name: 'sales',
				radioName: 'sales',
				value: 'sales',
				checked: false,
				parent: radioGroup
			}))
			radioGroup.addInput(InputRadio.create({
				translations: () => langs.inputTopic,
				attribute: 'support',
				name: 'support',
				radioName: 'support',
				value: 'support',
				checked: false,
				parent: radioGroup
			}))
			radioGroup.addInput(InputRadio.create({
				translations: () => langs.inputTopic,
				attribute: 'rma',
				name: 'rma',
				radioName: 'rma',
				value: 'rma',
				checked: false,
				parent: radioGroup
			}))
			radioGroup.addInput(InputRadio.create({
				translations: () => langs.inputTopic,
				attribute: 'other',
				name: 'other',
				radioName: 'other',
				value: 'other',
				checked: false,
				parent: radioGroup
			}))
			this.form.addInput(radioGroup)

			this.form.addInput(Textarea.create({
				translations: () => langs.inputTextarea,
				attribute: 'content',
				name: 'content',
				validation: [required()]
			}))
			this.form.addInput(InputFile.create({
				translations: () => langs.inputFile,
				attribute: 'attachments',
				name: 'attachments',
				multiple: true,
				validation: [extensions(['.pdf', '.docx', '.doc', '.jpg', '.png'])]
			}))
			this.form.addInput(InputCheckbox.create({
				translations: () => langs.inputPolice,
				attribute: 'police',
				name: 'police',
				validation: [required()]
			}))
		}
	},
	async prefetch () {
		await this.$app.translator.prefetch('errors')
		await this.$app.translator.prefetch('forms')
	},
	mounted () {
		this.$nextTick(() => {
			this.createForm()
		})
	}
}
</script>
<style lang="scss" scoped>
.form-blur {
	position: relative;
}

:deep(.status) {
	p {
		margin-bottom: 0;
	}
}

.form {
	min-height: max-content;

	:deep(input::placeholder) {
		font-style: normal;
	}

	:deep(label) {
		color: $white;

		.police-star {
			color: $red-600;
		}
	}

	:deep(.radio-group) {
		.field-checkbox-label {
			color: $white;

			.police-star {
				color: $danger;
			}
		}
	}

	:deep(.field-checkbox-label) {
		a {
			color: $white;
		}
	}

	&-inner-wrapper {
		@include media-breakpoint-up(xxl) {
			display: flex;
			align-items: center;
		}
	}

	.form-content {
		:deep(textarea) {
			border-radius: $border-radius;
			border: 1px solid #E9E9E9;
			height: 122px;
		}
	}

	:deep(.error-wrapper) {
		.field-error {
			color: $white;
		}
	}

	:deep(.field-file) {
		margin-top: 20px;

		.extensions {
			color: $white;
		}

		.add-file-btn {
			border-color: $white;

			&:after {
				border-color: $white;
			}

			span {
				color: $white;
			}
		}
	}

	.field-checkbox-wrapper {
		:deep(a) {

		}
	}

	:deep(.files-list) {
		svg {
			fill: $white;
		}

		.file-name {
			color: $white;
		}
	}

	.form-buttom-police {
		margin-top: 40px;
		@include media-breakpoint-up(xxl) {
			gap: 80px;
		}
	}

	.form-inner-wrapper {
		.form-btn {
			:deep(span) {
				line-height: 1.5rem;
			}
		}
	}
}

.inner-wrapper {
	.status {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 50px;
		background-color: #fff;
		border-radius: 10px;
		box-shadow: $base-shadow;

		:deep(.status-text) {
			margin: 0;
		}

		:deep(.close-btn) {
			position: absolute;
			top: 5px;
			right: 5px;
			cursor: pointer;
			@include transition;

			&:hover {
				opacity: 0.8;
			}
		}
	}
}

.form-additional-info {
	margin-top: 8px;
	.police-star {
		color: $danger;
	}
}
</style>
