<template>
	<section class="advantages-section" v-custom-background="value.section_bgImg.path">
		<base-container>
			<custom-title
				v-if="value.section_title"
				:title="`${prefix}.section_title.title`"
				:subtitle="`${prefix}.section_title.subtitle`"
				:header_tag="value.section_title?.title_tag"
				:color="value.section_title.title_color"
				:transform="value.section_title.title_transform"
				:type="value.section_title_type"
			/>
			<div class="advantages__inner" v-if="value.cards">
				<base-animation
					v-for="(card, i) in value.cards"
					:key="card.cards_title"
					:animation="evenAnimation(i, 'slideRight', 'slideLeft')"
				>
					<advantage-card
						:title="`${prefix}.cards.${i}.cards_title`"
						:image="card.card_icon ? card.card_icon.path : false"
						:bgimage="card.card_image"
						:link="card.link"
					/>
				</base-animation>
			</div>
			<buttons
				:value="[
					{ button: value.section_secondaryLink },
					{ button: value.section_primaryLink, icon: {path: 'svg/button-chart.svg'} }
				]"
			/>

		</base-container>
	</section>
</template>

<script>
import CustomTitle from '../../components/atoms/CustomTitle'
import AdvantageCard from './components/AdvantageCard.vue'
import Buttons from '~/website/front/sections/Buttons/Buttons'
import BaseAnimation from '@f/components/BaseAnimation/BaseAnimation'
import { evenAnimationMixin } from '~/cms/front/mixins/evenAnimationMixin'

export default {
	props: {
		prefix: String,
		value: Object
	},
	methods: {},
	components: { Buttons, CustomTitle, AdvantageCard, BaseAnimation },
	mixins: [evenAnimationMixin]
}
</script>

<style lang="scss" scoped>
.advantages-section {
	overflow: hidden;
	position: relative;
	@include spacing-section;

	.advantages__inner {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		gap: 10px;
		grid-auto-rows: 1fr;

		@include media-breakpoint-up(md) {
			grid-template-columns: repeat(2, 1fr);
			gap: 30px;
			grid-auto-rows: auto;
		}
	}

	::v-deep(.buttons-wrapper) {
		@include media-breakpoint-up(lg) {
			justify-content: center;
		}
	}

	.advantages__bg-image {
		position: absolute;
		left: 0;
		right: 0;
		width: 100%;

		::v-deep(img) {
			z-index: 0;
		}
	}
}
</style>
