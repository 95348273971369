import { Page } from '~/cms/front/core/pages/Page'
import Component from './FaqPage.vue'
import { FaqSeo } from '~/seo/front/core/structuredData/Faq'

class FaqPage extends Page {
	static alias = 'faq'
	component = Component

	async init () {
		await super.init(...arguments)
		this.seo = new FaqSeo(this.app, { page: this })
	}

	get header () {
		if (!this.value.title) return false
		return {
			title: this.value.title
		}
	}
}

export { FaqPage }
