<template>
	<div class="plots-section">
		<base-container>
			<div class="plots-inner">
				<custom-title
					v-if="value.section_title"
					:title="`${prefix}.section_title.title`"
					:subtitle="`${prefix}.section_title.subtitle`"
					:header_tag="value.section_title?.title_tag"
					:color="value.section_title.title_color"
					:transform="value.section_title.title_transform"
					:type="value.section_title_type"
				/>
				<div class="plot-cards-wrapper">
					<plot-card
						v-for="(card, i) in value.cards"
						:plot="card"
						:index="i"
						:prefix="prefix"
						:key="card.title"
						:productName="pageProduct?.name"
					/>
				</div>
				<div class="plots-range-cards-wrapper">
					<plot-range-card
						v-for="(plot, i) in value.range_cards"
						:plot="plot"
						:index="i"
						:prefix="prefix"
						:key="`plot-range-card-${i}`"
						:productName="pageProduct?.name"
					/>
				</div>
				<div class="questions-buttons-wrapper">
					<questions-button
						:text="lang.plots_section.text"
						:primaryLink="{
							type: 'internal',
							to: 'contact',
							title: lang.plots_section.button_title,
							content: lang.plots_section.button_text,
							button_type: 'primary'
						}"
					/>
				</div>
			</div>
		</base-container>
	</div>
</template>

<script>
import BaseContainer from '~/website/front/components/atoms/BaseContainer/BaseContainer'
import CustomTitle from '~/website/front/components/atoms/CustomTitle/CustomTitle'
import PlotCard from '~/catalog/front/sections/PlotsSection/components/PlotCard'
import PlotRangeCard from '~/catalog/front/sections/PlotsSection/components/PlotRangeCard'
import QuestionsButton from '~/website/front/components/molecules/QuestionsButton/QuestionsButton'
import { evenAnimationMixin } from '~/cms/front/mixins/evenAnimationMixin'
import { productSectionMixin } from '~/catalog/front/sections/product-section.mixin.js'
export default {
	components: { QuestionsButton, PlotRangeCard, PlotCard, CustomTitle, BaseContainer },
	props: {
		prefix: String,
		value: Object
	},
	mixins: [evenAnimationMixin, productSectionMixin],
	computed: {
		lang () {
			const langs = this.$app.translator.get('products')
			return langs
		}
	},
	async prefetch () {
		await this.$app.translator.prefetch('products')
	}
}
</script>

<style lang="scss" scoped>
.plots-section {
@include spacing-section();
	background: $white;
	.plots-inner {

		.plot-cards-wrapper{
			display: flex;
			flex-direction: column;
			gap: 30px;
		}

		.plots-range-cards-wrapper{
			margin-top: 30px;
			display: grid;
			grid-template-columns: 1fr;
			gap: 30px;

			@include media-breakpoint-up(xl) {
				margin-top: 80px;
				grid-template-columns: repeat(1, minmax(0, 1fr));
			}
		}

		.questions-buttons-wrapper{
			margin-top: 30px;
			display: flex;
			justify-content: center;
			align-items: center;

			@include media-breakpoint-up(lg) {
				margin-top: 60px;
			}
		}
	}
}
</style>
