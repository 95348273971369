<template>
	<section>
		<base-container>
			<div class="currency-wrapper">
				<currency-item
					v-for="currency in currency_items"
					:title="currency.currencyName"
					:account-number-label="translate.accountNumber"
					:account-number="currency.currencyAccountNumber"
					:address-label="translate.bankAddress"
					:address="currency.bankAddress"
					:swift-label="translate.swiftLabel"
					:swift="currency.swift"
					:currency="currency.currencyCurrency"
					:key="currency.accountNumber"
				/>
			</div>
		</base-container>
	</section>
</template>

<script>
import CurrencyItem from '~/website/front/components/molecules/CurrencyItem/CurrencyItem.vue'
export default {
	components: { CurrencyItem },
	props: {
		translate: Object,
		currency_items: [Object, Array]
	}
}
</script>

<style lang="scss" scoped>
.currency-wrapper {
	display: grid;
	gap: 30px;
	padding: 60px 0;

	@include media-breakpoint-up(md) {
		grid-template-columns: repeat(2, minmax(0, 1fr));;
	}

	@include media-breakpoint-up(xl) {
		padding: 100px 0;
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
}
</style>
