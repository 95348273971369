<template>
	<ul class="submenu" :class="{'submenu-categories-wrapper': hasSubmenuCategories}">
		<li class="submenu-item" v-for="item in items" :class="{'submenu-categories': hasSubmenuCategories}" :key="item.name">
			<div class="item-wrapper item-categories" v-if="isLinkTypeCategory(item)">
				<div class="subitem-background" v-if="item.bg_image">
					<cms-image lazy :src="item.bg_image" :base="{width: 282, height: 194}" />
				</div>
				<div class="categories-title"><base-font variant="menu-category" :html="item.name"/></div>
				<div class="subitems-wrapper">
					<menu-dropdown-mobile-item
						v-for="subItem in item.children"
						:key="subItem.name"
						:item="subItem"
						@close="emitClose"
					/>
				</div>
			</div>
			<div class="item-wrapper" v-else>
				<menu-dropdown-mobile-item
					:item="item"
					@close="emitClose"
				/>
			</div>
		</li>
	</ul>
</template>
<script>
import BaseFont from '~/website/front/components/atoms/BaseFont/BaseFont'
import MenuDropdownMobileItem
from '~/website/front/components/organisms/BaseMenu/components/Mobile/atoms/MenuDropdownMobileItem'
export default {
	components: { MenuDropdownMobileItem, BaseFont },
	props: {
		items: Array,
		active: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		isLinkTypeCategory (item) {
			return item.link_type === 'category'
		},
		isLinkTypeButton (item) {
			return item.link_type === 'button'
		},

		emitClose () {
			this.$emit('close')
		}

	},
	computed: {
		hasSubmenuCategories () {
			return this.items.filter(item => item.link_type === 'category').length !== 0
		}
	}
}
</script>
<style lang="scss" scoped>

.submenu {
	list-style-type: none;
	padding-inline-start: 0;
	flex-direction: column;
	justify-content: center;
	display: flex;
	gap: 20px;
	padding: 1.5rem 2rem;

	&.submenu-categories-wrapper{
		flex-direction: column;
		gap: 0;
		justify-content: flex-start;
		padding: 0 0 2rem 0;

		.item-categories{
			width: 100%;

			.subitems-wrapper{
			}
		}
	}

	&-link{
		width: 100%;
	}

	.item-wrapper{
		width: 100%;
	}

	.submenu-item{
		position: relative;
		width: 330px;

		&.submenu-categories {
			height: 100%;
			width: 280px;

		}

		.submenu-link {
			padding: 0 2rem;

			&.active-route {
				:deep(span) {
					color: $primary!important;
				}

			}
		}

		.subitem-background{
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;
			width: 100%;
			height: 100%;

			.image-wrapper{
				height: 100%;

				::v-deep(img) {
					width: 100%;
					height: 100%;
					object-fit: cover;
					filter: grayscale(99%) sepia(100%) opacity(10%) contrast(115%);
					-webkit-filter: grayscale(99%) sepia(100%) opacity(10%) contrast(115%);
					-moz-filter: grayscale(99%) sepia(100%) opacity(10%) contrast(115%);
				}
			}
		}

		.categories-title{
			padding: 2rem;

			::v-deep(span) {
				font-size: 14px;
				letter-spacing: 0.28px;
				line-height: 18px;
				color: $primary;
				font-weight: 700;

			}
		}

		::v-deep(.subitems-wrapper){
			display: flex;
			flex-direction: column;
			gap: 20px;
			padding-left: 10px;
		}

		::v-deep(.submenu-link){
			.submenu-title {
				display: block;
				color: $secondary;
				font-weight: 600;

				&:hover {
					color: $primary;
				}
			}
		}
	}
}
</style>
