import { ReactiveClass } from '@f/core/ReactiveClass'
import { getValue } from 'utils/objects'

class Translator extends ReactiveClass {
	translations = []
	processing = []

	constructor () {
		super()
		if (process.client) this.translations = window.__TRANSLATIONS__
	}

	setApp (app) {
		this.app = app
	}

	async prefetch (path) {
		if (this.get(path) || this.processing.includes(path)) return
		this.processing.push(path)

		const translation = await this.app.getService('rext').getTranslations(path)
		this.translations.push({
			path,
			translation
		})
	}

	get (path) {
		const object = this.translations
			.find(record => {
				return path === record.path || (path.startsWith(record.path) && record.path.translations)
			})

		if (!object) return false
		else if (path === object.path) return object.translation
		else {
			const pathToObject = path.replace(`${object.path}.`, '')
			return getValue(pathToObject, object.translation, false)
		}
	}
}

export { Translator }
