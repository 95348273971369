import { BlogModule } from '~/blog/front/index'
import { CMSModule, installComponents } from '~/cms/front/index'
import { WebsiteModule } from '~/website/front/index'
import { NewsletterModule } from '~/newsletter/front/index'
import { ContactFormModule } from '~/contactForm/front/index'
import { TranslationsModule } from '~/translations/front/index'
import { AuthorizationModule } from '~/authorization/front/index'
import { SettingsModule } from '~/settings/front/index'
import { CatalogModule } from '~/catalog/front/index'
import { OpinionModule } from '~/opinions/front'

function useModules (app, Vue) {
	installComponents(Vue)
	app.use(new CMSModule())
	app.use(new WebsiteModule(app))
	app.use(new BlogModule())
	app.use(new NewsletterModule())
	app.use(new ContactFormModule())
	app.use(new TranslationsModule())
	app.use(new AuthorizationModule())
	app.use(new SettingsModule())
	app.use(new CatalogModule())
	app.use(new OpinionModule())
}
export {
	useModules
}
