<template v-once>
    <div class="iconLeft__item">
        <div class="iconLeft__item-overlay"></div>
        <base-svg :name="svg" class="iconLeft__item-svg" />
        <cms-text
			class="iconLeft__item-text"
			:value="subtitle"
			:props="{
				tag: 'span',
				color: 'white',
				weight: 'bold',
				uppercase: true,
				size: 'icons-card-text'
			}"
		/>
    </div>
</template>

<script>
export default {
	props: {
		svg: String,
		subtitle: String
	}
}
</script>

<style lang="scss" scoped>
.iconLeft__item{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
    border: 1px solid $white;
    border-radius: 1rem;
    padding: 25px 25px;
	@include transition;
    @include media-breakpoint-up(lg){
        gap: 15px;
        padding: 40px 35px;
    }

	&:hover {
		transform: scale(1.05);
	}
    &-overlay{
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: #0099FF;
        opacity: .5;
        z-index: -1;
		border-radius: 1rem;
	}

    &-svg,
    &-text{
        color: $white;
        position: relative;

    }
}
</style>
