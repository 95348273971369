<template v-once>
	<a
		:href="path"
		:class="classes"
		@click.prevent="handleClick()"
		:target="target"
		:title="title"
	>
		<slot />
	</a>
</template>

<script>

import { NavigationMixin } from '~/cms/front/mixins/NavigationMixin'

export default {
	mixins: [NavigationMixin],
	props: {
		type: {
			type: String
		},
		underline: {
			type: Boolean,
			default: false
		},
		title: {
			type: String,
			default: ''
		},
		button_type: {
			type: String,
			default: ''
		}
	},
	computed: {
		classes () {
			return [
				'link',
				(() => this.type && `link-${this.type}`)(),
				(() => this.underline ? this.underline : 'no-underline')(),
				(() => this.marginReset && 'margin-reset')(),
				(() => this.disabled && 'btn-disabled')(),
				(() => this.button_type && `btn ${this.button_type}`)()
			].filter(c => !!c)
		}
	}
}
</script>
