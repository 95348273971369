<template>
	<base-container>
		<div
			class="buttons-wrapper"
			:style="{justifyContent: setAlignment}"
			:class="setPaddingClasses"
		>
			<base-link
				v-for="(item, i) in buttons"
				v-bind="item.button"
				:key="item.button.value + i + item.button.content"
			>
				<base-svg v-if="item.icon" :name="item.icon.path" />
				<base-font variant="button-text"> {{item.button ? item.button.content : 'Translation not set'}} </base-font>
			</base-link>
		</div>
	</base-container>
</template>

<script>
import BaseContainer from '~/website/front/components/atoms/BaseContainer/BaseContainer'
export default {
	components: {
		BaseContainer
	},
	props: {
		prefix: String,
		value: Object
	},
	methods: {
		isExternalLink (value) {
			return !!value.includes('/')
		}
	},
	computed: {
		setAlignment () {
			if (this.value.alignment === 'right') {
				return 'flex-end'
			} else if (this.value.alignment === 'left') {
				return 'flex-start'
			} else if (this.value.alignment === 'center') {
				return 'center'
			} else {
				return 'center'
			}
		},

		setPaddingClasses () {
			if (!this.value.padding) return ''

			const classes = {}
			this.value.padding.forEach(item => { classes[`padding-${item}`] = true })
			return classes
		},
		buttons () {
			return this.value['buttons-group'] || []
		}
	}
}
</script>
<style lang="scss" scoped>
.buttons-wrapper {

	&.padding-bottom {
		padding-bottom: 3rem;
	}

	&.padding-top {
		padding-top: 3rem;
	}

	display: flex;
	gap: 2rem;
	flex-wrap: wrap;
	@include media-breakpoint-up(xl) {
		display: flex;
		justify-content: center;
		flex-wrap: nowrap;
	}
	@include media-breakpoint-up(xxl) {
		justify-content: flex-start;
		gap: 3.3rem;
	}

	.btn {
		@include media-breakpoint-up(xl) {
			display: block;
		}
	}
}
</style>
