var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"review"},[_c('base-container',[_c('base-animation',{attrs:{"animation":"slideRight"}},[_c('div',{staticClass:"review__title-wrapper"},[_c('custom-title',{attrs:{"title":`${_vm.prefix}.section_title.title`,"subtitle":`${_vm.prefix}.section_title.subtitle`,"header_tag":_vm.value.section_title?.title_tag,"color":_vm.value.section_title.title_color,"transform":_vm.value.section_title.title_transform,"type":_vm.value.section_title_type}})],1)]),(_vm.opinions.length)?_c('div',{staticClass:"review__cards-wrapper swiper"},[_c('div',{staticClass:"review__cards-inner swiper-wrapper"},_vm._l((_vm.opinions),function(item,i){return _c('opinion-slider-item',{key:i,staticClass:"swiper-slide",attrs:{"slide":{
						title: item.author,
						subtitle: item.place,
						description: item.content,
						image: item.image
					},"link":item.pageLink,"isActive":_vm.slider?.activeIndex - 1 === i}})}),1),_c('div',{staticClass:"review__arrows-title"},[_c('base-svg',{staticClass:"slide-left swiper-button-prev",attrs:{"name":"opinion-arrow"},on:{"click":function($event){return _vm.slider.slidePrev()}}}),_c('base-svg',{staticClass:"slide-right swiper-button-next",attrs:{"name":"opinion-arrow"},on:{"click":function($event){return _vm.slider.slideNext()}}})],1)]):_vm._e(),_c('buttons',{attrs:{"value":[
				{button: _vm.value.section_secondaryLink},
				{button: _vm.value.section_primaryLink, icon: {path: 'button-chart'}}
			]}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }