<template>
	<div class="title">
		<base-container>
			<div class="title-inner">
				<custom-title
					:title="`${prefix}.section_title`"
					:subtitle="`${prefix}.section_subtitle`"
					:header_tag="value.header_tag"
					:type="value.header_style"
					:show-base-svg="isHeaderStyleH1"
				/>
			</div>
		</base-container>
	</div>
</template>

<script>
import BaseContainer from '~/website/front/components/atoms/BaseContainer/BaseContainer.vue'
import CustomTitle from '~/website/front/components/atoms/CustomTitle/CustomTitle.vue'
export default {
	components: { CustomTitle, BaseContainer },
	props: {
		prefix: String,
		value: Object
	},
	computed: {
		isHeaderStyleH1 () {
			return this.value.header_style === 'h1'
		}
	}
}
</script>

<style lang="scss" scoped>

</style>
