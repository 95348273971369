<template>
	<base-link
		:to="item.to"
		:params="{ param: item.param }"
		class="submenu-link"
		:class="{
			'btn-link': isLinkTypeButton,
			'active-route': isCurrentRoute
		}"
		@click="emitClose"
	>
		<base-font variant="menu" class="submenu-title">{{ item.name }}</base-font>
	</base-link>
</template>

<script>
import currentRouteMixin from '~/website/front/components/organisms/MenuBreadcrumbs/currentRouteMixin'

export default {
	props: {
		item: {
			type: Object,
			default: () => {
				return {
					children: [],
					link_type: '',
					name: '',
					param: '',
					to: ''
				}
			}
		}
	},

	mixins: [currentRouteMixin],

	computed: {
		isLinkTypeButton () {
			return this.item.link_type === 'button'
		}
	},
	methods: {
		emitClose () {
			this.$emit('close')
		}
	}
}
</script>

<style scoped>

</style>
