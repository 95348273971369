<template>
	<div ref="svg" class="svg-icon" v-html="svgData" v-on="$listeners"/>
</template>

<script>

export default {
	props: {
		name: String
	},
	data: () => ({
		svgData: ''
	}),
	computed: {
		pathToSvg () {
			if (!this.name.match(/(.*\/.*.svg)/)) return `/filemanager/files${this.$app.settings.general?.files.pathToSvg}/${this.name}.svg`
			else {
				return `/filemanager/files/${this.name}`
			}
		}
	},
	async created () {
		if (process.server) this.svgData = await this.$app.websiteSvgPrefetchManager.getSVG(this.pathToSvg)
	},
	async mounted () {
		this.svgData = await this.$app.websiteSvgPrefetchManager.getSVG(this.pathToSvg)
	}
}
</script>
<style lang="scss" scoped>
.svg-icon {
	min-height: 1px;
	@include transition;
	path {
		@include transition;
	}
}
</style>
