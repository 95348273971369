import { Seo } from '../seo/Seo'
import { BreadcrumbsBuilder } from '~/website/front/core/elements/Breadcrumb/BreadcrumbsBuilder.js'
import { getQuery } from 'utils/query'
class Page {
	static alias = 'default'

	static checkRoute (to) {
		return true
	}

	visible = () => true

	state = {}
	component
	componentsData = {
		baseSvg: {},
		cmsEditorText: {}
	}

	breadcrumbs = {}

	app
	_value = {}
	route
	from
	seo
	statusCode
	meta = {}

	get params () {
		return this.route.params
	}

	get query () {
		return this.route.query
	}

	get alias () {
		return this.route.params.alias || this.route.meta.alias
	}

	get value () {
		return this._value
	}

	set value (value) {
		this._value = value
	}

	get layout () {
		return this.app.layout
	}

	set layout (layout) {
		this.app.layout = layout
	}

	get language () {
		if (this._language) return this._language
		return this.route.meta.language
	}

	set language (language) {
		this._language = language
	}

	constructor (data) {
		this.setConstructorData(data)
		this.checkRedirect()
	}

	checkRedirect () {
		const lowerCasePath = this.route.path.toLowerCase()
		let query = this.route.query || ''
		if (query) query = getQuery(query)
		if (this.route.path !== lowerCasePath) this.app.vue.$redirect({ path: `${lowerCasePath}${query}` })
	}

	setConstructorData ({ app, route, from }) {
		this.app = app
		this.route = route
		this.from = from
		this.seo = new Seo(this.app, { page: this })
	}

	async init () {
		await this.fetch()

		if (this.route.meta.showBreadcrumbs) {
			this.breadcrumbs = new BreadcrumbsBuilder(this.app)
				.build()
				.generateFromMenuTree(this.alias)
		}
	}

	async fetch () {
		if (this.from.meta.alias || process.server) await this.fetchData()
		else this.restore()
	}

	async fetchData () {
		const data = await this.app.getService('rext').getPage(this.alias)
		await this.setPageData(data)
	}

	async setPageData (data) {
		this.createdAt = data.createdAt
		this.updatedAt = data.updatedAt
		this.value = data.value
		this.meta = data.meta
		this.type = data.type
		this.layout = this.app.getLayout(data.layout)
		if (this.layout) await this.layout.fetch()
	}

	restore () {
		this.componentsData = window.__PAGE__.componentsData
		this.value = window.__PAGE__.value
		this.type = window.__PAGE__.type
		this.layout = this.app.getLayout(window.__PAGE__.layout)
		if (this.layout) this.layout.restore()
	}

	store () {
		return {
			componentsData: this.componentsData,
			value: this.value,
			layout: this.layout ? this.layout.alias : undefined,
			type: this.type
		}
	}

	afterRedirect () {}
}

export { Page }
