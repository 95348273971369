<template>
	<div class="downloadable-card">
		<base-font
			class="card-title"
			tag="span"
			color="secondary"
			weight="bold"
			:html="name"
		/>
		<a
			:href="href"
			class="card-download"
			download
			:title="`Download ${name}`"
		>
			<base-svg v-if="icon" :name="icon" class="download-icon"/>
			<base-font class="download-text" tag="span" color="secondary" weight="bold">Download</base-font>
		</a>
	</div>
</template>

<script>
import BaseFont from '~/website/front/components/atoms/BaseFont/BaseFont'
import BaseSvg from '~/website/front/components/atoms/BaseSvg/BaseSvg'

export default {
	name: 'DownloadableCard',
	components: { BaseSvg, BaseFont },
	props: {
		card: Object,
		icon: String,
		productName: {
			type: String,
			default: ''
		},
		alias: String
	},
	computed: {
		href () {
			if (this.card.type === 'autogenerated') return `/api/product/${this.alias}/datasheet`
			return `/api/filemanager/files/${this.card.value.link.path}`
		},
		name () {
			const getReadyTitleName = (name = '') => name?.replaceAll('/', '/<wbr>') || ''

			if (this.card.card_title) return getReadyTitleName(this.card.card_title)
			return 'Datasheet ' + getReadyTitleName(this.productName)
		}
	}
}
</script>

<style lang="scss" scoped>
:deep(span.card-title) {
	font-size: 22px;
}

.card-download {
	text-decoration: none;
	text-transform: uppercase;
}

.downloadable-card {
	background-color: $white;
	padding: 30px 30px 20px 30px;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	justify-content: space-between;

	.card-download {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		width: fit-content;
		margin-left: auto;
		@include transition;

		&:hover{
			transform: translateX(-5px);
		}

		.download-icon {
			margin-right: 10px;
			display: flex;
		}

		:deep(span) {
			font-size: 16px;
			line-height: 21px;
		}
	}
}
</style>
