<template>
	<section class="opinion-list-section">
		<base-container>
			<div class="opinion-list-section-inner">
				<custom-title
					v-if="value.section_title"
					:title="`${prefix}.section_title.title`"
					:subtitle="`${prefix}.section_title.subtitle`"
					:header_tag="value.section_title?.title_tag"
					:color="value.section_title.title_color"
					:transform="value.section_title.title_transform"
					:type="value.section_title_type"
				/>

				<div class="opinions-wrapper" v-if="opinions.length">
					<base-animation
						v-for="(item, i) in opinions"
						:animation="evenAnimation(i, 'slideRight', 'slideLeft')"
						:key="item.id"
					>
						<opinion-reviews-item
							:opinion="{
								title: item.author,
								subtitle: item.place,
								description: item.content,
								image: item.image,
								article: item.article,
								pageLink: item.pageLink
							}"
						/>
					</base-animation>
				</div>
			</div>
		</base-container>
	</section>
</template>

<script>
import BaseContainer from '~/website/front/components/atoms/BaseContainer/BaseContainer'
import CustomTitle from '~/website/front/components/atoms/CustomTitle/CustomTitle'
import OpinionReviewsItem from '~/opinions/front/components/OpinionReviewsItem'
import { evenAnimationMixin } from '~/cms/front/mixins/evenAnimationMixin'
import BaseAnimation from '@f/components/BaseAnimation/BaseAnimation.vue'
export default {
	components: { OpinionReviewsItem, CustomTitle, BaseContainer, BaseAnimation },
	props: {
		value: Object,
		prefix: String
	},
	computed: {
		opinions () {
			return this.$app.page.opinions
		}
	},
	mixins: [evenAnimationMixin]
}
</script>

<style lang="scss" scoped>
.opinion-list-section {
	@include spacing-section();
	padding-bottom: 10px;
	.opinion-list-section-inner{
		.opinions-wrapper{
			display: flex;
			flex-direction: column;
			gap: 30px;
		}
	}
}
</style>
