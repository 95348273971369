<template>
	<div class="readmore" :class="{active: isActive}" v-if="Object.keys(recommendedArticle).length !== 0">
		<div class="top-wrapper">
			<base-font marginReset>{{langs.read_more}}</base-font>
			<span class="line"></span>
			<button-close @close.stop="close"/>
		</div>
		<base-link to="article" :params="{alias: recommendedArticle.alias}">
			<base-font variant="small-header" class="readmore-title">{{recommendedArticle.title}}</base-font>
			<base-font variant="paragraph" class="readmore-description" :html="recommendedArticle.description" />
		</base-link>
	</div>
</template>
<script>
import ButtonClose from '~/website/front/components/molecules/buttons/ButtonClose/ButtonClose'
import { debounce } from 'utils/debounce'

export default {
	components: { ButtonClose },
	props: {
		height: Number
	},
	computed: {
		recommendedArticle () {
			return this.$app.page.value.recommendedArticles.slice(0, 1)[0]
		},
		langs () {
			return this.$app.translator.get('articles')
		}
	},
	data: () => ({
		isActive: false,
		openDebounce: null,
		wasOpen: false
	}),
	methods: {
		close () {
			this.isActive = false
			this.wasOpen = true
		},
		open () {
			if (!this.wasOpen && window.scrollY > (this.height - (window.innerHeight + 120))) this.isActive = true
			else this.isActive = false
		}
	},
	mounted () {
		this.openDebounce = debounce(this.open, 100)
		window.addEventListener('scroll', this.openDebounce)
	},
	beforeDestroy () {
		window.removeEventListener('scroll', this.openDebounce)
	},
	async prefetch () {
		await this.$app.translator.prefetch('articles')
	}

}
</script>
<style lang="scss" scoped>
.readmore {
	display: none;
	opacity: 0;
	pointer-events: none;
	width: 45rem;
	padding: 2rem;
	background: $white;
	box-shadow: 0 3px 6px rgba($black, .12);
	position: fixed;
	bottom: 3rem;
	right: 3rem;
	z-index: 2005;
	cursor: pointer;
	transition: 0.3s opacity;

	@include media-breakpoint-up(xl) {
		display: block;
	}

	&.active {
		opacity: 1;
		pointer-events: all;
	}
	&-description {
		color: $primary;
		font-size: 14px;
        line-height: 1.8rem;
	}
	.top-wrapper {
		display: flex;
		align-items: center;
		gap: 2rem;
        margin-bottom: 2rem;
		.line {
			border-bottom: 1px solid $primary;
			flex: 1;
		}
		.svg-icon {
			height: 17px;
			width: 17px;
		}
	}
}
</style>
