<template>
	<article class="article">
		<div>
			<div>
				<div class="article-body" ref="sectionContent">
					<base-container>
						<custom-title
							:use-string="true"
							:title="article.title"
							header_tag="h1"
							header_style="h1"
						/>
						<base-font
							class="article-date"
							variant="paragraph"
							weight="bold"
							color="secondary"
						>
							{{ article.datePublished | date }}
						</base-font>
					</base-container>
					<cms-multi-section class="article-text" value="content"/>
				</div>
			</div>
			<div class="back-button-wrapper">
				<base-link
					v-if="!isSuccessStory"
					title="Read more our articles!"
					button_type="secondary"
					class="back-button"
					to="article-list"
				>
					{{ langs.back_button }}
				</base-link>
				<base-link
					v-else
					button_type="secondary"
					title="Read more our articles!"
					class="back-button"
					to="use-cases-and-stories"
				>
					{{ langs.back_button_stories }}
				</base-link>
			</div>
		</div>
		<recommended-articles-wrapper v-if="false"/>
		<article-readmore v-if="false" :height="articleHeight"/>
	</article>
</template>

<script>
import ArticleReadmore from '~/blog/front/components/ArticleReadmore/ArticleReadmore.vue'
import CmsMultiSection from '~/cms/front/components/CmsMultiSection'
import RecommendedArticlesWrapper from '~/blog/front/components/RecommendedArticles/RecommendedArticlesWrapper'
import { pageMixin } from '~/website/front/core/pages/page.mixin'
import BaseContainer from '~/website/front/components/atoms/BaseContainer/BaseContainer'
import CustomTitle from '~/website/front/components/atoms/CustomTitle/CustomTitle.vue'

export default {
	props: {
		prefix: String
	},
	components: {
		CustomTitle,
		BaseContainer,
		RecommendedArticlesWrapper,
		CmsMultiSection,
		ArticleReadmore
	},
	data: () => ({
		articleHeight: 0,
		progress: 0
	}),
	computed: {
		article () {
			return this.$app.page.value || {}
		},
		langs () {
			return this.$app.translator.get('articles')
		},

		isSuccessStory () {
			return this.article.category?.alias.includes('use-cases-and-stories')
		}
	},
	async prefetch () {
		await this.$app.translator.prefetch('articles')
	},
	async mounted () {
		this.articleHeight = this.$refs.sectionContent.offsetHeight
	},
	mixins: [
		pageMixin
	]
}
</script>
<style lang="scss" scoped>
.article {
	:deep(.back-button-wrapper) {
		display: flex;
		justify-content: center;
		margin-top: 3rem;
		padding-bottom: 3rem;
		@include media-breakpoint-up(lg) {
			margin-top: 6rem;
			padding-bottom: 6rem;
		}
	}

	::v-deep(.ql-editor) {
		padding: 0;
	}
}

.article-body {

	::v-deep .blog-header {
		margin-bottom: 1rem;
	}

	.article-introduction {
		font-size: 1.6rem;
		color: $secondary;
		text-align: justify;
		font-style: italic;
		margin-bottom: 2rem;

		@include media-breakpoint-up(xl) {
			font-size: 1.8rem;
			margin-bottom: 3rem;
		}
	}

	::v-deep .article-date {
		width: 100%;
		position: relative;
		overflow: hidden;

		&::after {
			content: '';
			position: absolute;
			top: 50%;
			left: 10rem;
			width: 100%;
			border-bottom: .1rem solid $gray-800;
		}
	}
}
</style>
