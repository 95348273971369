
class Antenna {
	static async fetchFilteredByParameters ({ app, subcategory, filterParameters }) {
		const service = app.getService('rext')
		const response = await service.getFilteredByParameter({ subcategory, filterParameters })

		if (response.error) return []
		return response
	}

	static async fetch ({ app, query }) {
		const service = app.getService('rext')
		const response = await service.getAllProducts(query)

		if (response.error) return []
		return response
	}

	static async fetchBestSellers ({ app, productsIds }) {
		const service = app.getService('rext')
		const response = await service.getBestSellers(productsIds)

		if (response.error) return []
		return response
	}
}

export { Antenna }
