<template>
	<div class="filters-panel">
		<div
			class="filters-panel-group"
			v-for="filter in filtersManager.filters"
			:key="`filter-${filter.alias}`"
		>
			<base-font
				class="filters-panel-title"
				variant="filter-category-title"
			>
				{{lang[filter.alias]}}
			</base-font>
			<div class="filters-panel-values">
				<div
					v-for="value in filter.values"
					class="filters-panel-value"
					:class="{active: value.active}"
					:key="value.id"
					@click="value.toggle()"
				>
					<base-font class="text-item" weight="bold" color="secondary">
						{{value.alias}}
					</base-font>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
export default {
	props: {
		filtersManager: Object
	},
	computed: {
		lang () {
			return this.$app.translator.get('products').products_list.filters
		}
	},
	async prefetch () {
		await this.$app.translator.prefetch('products')
	}
}
</script>

<style lang="scss" scoped>
.filters-panel{
	padding: 20px;
	background-color: #fff;
	border-radius: 5px;
	display: flex;
	flex-wrap: wrap;
	gap: 15px;

	@include media-breakpoint-up(xl) {
		gap: 30px;
	}

	&-group {

	}

	&-title {

	}

	&-values {
		margin-top: 10px;
		display: flex;
		flex-wrap: wrap;
		gap: 10px;

		@include media-breakpoint-up(xl) {
			gap: 20px;
		}
	}

	&-value {
		transition: all .3s ease-in-out;
		display: flex;
		align-items: center;
		border: 1px solid #d8d8d8;
		border-radius: 5px;
		gap: 15px;
		padding: 8px 20px;
		cursor: pointer;

		&.active {
			background-color: $primary;
			:deep(.text-item) {
				color: $white;
			}
		}
	}
}
</style>
