<template>
	<section
		class="specification"
		id="product_specification_section"
		v-custom-background="getBackgroundImage"
	>
		<base-container>
			<div class="specification-inner">
				<custom-title
					v-if="value.section_title"
					:title="`${prefix}.section_title.title`"
					:subtitle="`${prefix}.section_title.subtitle`"
					:header_tag="value.section_title?.title_tag"
					:color="value.section_title.title_color"
					:transform="value.section_title.title_transform"
					:type="value.section_title_type"
				/>
				<specification-tabs :items="cards"/>
			</div>
		</base-container>
	</section>
</template>

<script>
import BaseContainer from '~/website/front/components/atoms/BaseContainer/BaseContainer'
import CustomTitle from '~/website/front/components/atoms/CustomTitle/CustomTitle'
import SpecificationTabs from '~/website/front/components/organisms/SpecificationTabs/SpecificationTabs'
export default {
	components: { SpecificationTabs, CustomTitle, BaseContainer },
	props: {
		value: Object,
		prefix: String
	},
	computed: {
		getBackgroundImage () {
			const path = this.$app.layout.value.product_page.specification_bg.path
			return {
				src: path,
				breakpoints: {
					base: { width: 1920, height: 1425 }
				}
			}
		},

		lang () {
			return this.$app.translator.get('products')
		},

		compatibleRouters () {
			return this.$app.page.product?.compatibleRouters || []
		},

		brands () {
			const variantId = this.$app.page.product?.activeVariant?.id
			const Brand = this.$app.getService('brand').constructor.Brand
			const brands = this.compatibleRouters.map(brand => {
				const models = variantId ? brand.models.filter(model => model.variants.includes(variantId)) : brand.models
				if (!models.length) return undefined
				return Brand.from({
					...brand,
					models: models
				})
			}).filter(brand => !!brand)
			return brands
		},

		cards () {
			if (!this.compatibleRouters?.length) return this.value.repeater

			return [
				...this.value.repeater,
				{
					menu_title: this.lang.product_specification_tab_name,
					section_content: {
						alias: 'specification_compatible_section',
						value: this.brands
					}
				}
			]
		}
	},
	async prefetch () {
		await this.$app.translator.prefetch('products')
	}
}
</script>

<style lang="scss" scoped>
.specification{
	overflow: hidden;
	background-size: cover;
	background-repeat: no-repeat;
	@include spacing-section();
	@include base-overlay;
}
</style>
