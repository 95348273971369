<template>
	<section v-if="breadcrumbsItems?.length" class="menu-breadcrumbs" :class="{ 'product-breadcrumbs': isProductTypePage }">
		<base-container>
			<div class="breadcrumbs-container">
				<breadcrumbs-item
					v-for="(breadcrumb, i) in breadcrumbsItems"
					:breadcrumb="breadcrumb"
					:key="`${breadcrumb.name}-${i}`"
					:isLast="isBreadcrumbLast(breadcrumb)"
				/>
			</div>
		</base-container>
	</section>
</template>

<script>
import BreadcrumbsItem from '~/website/front/components/molecules/BreadcrumbsItem/BreadcrumbsItem'

export default {
	components: {
		BreadcrumbsItem
	},
	computed: {
		isProductTypePage () {
			return this.$app.page.type === 'product'
		},

		breadcrumbsItems () {
			return this.$app.page.breadcrumbs?.crumbs || []
		}
	},
	methods: {

		isBreadcrumbLast (breadcrumb) {
			return breadcrumb.name === this.breadcrumbsItems[this.breadcrumbsItems?.length - 1].name
		}

	}
}
</script>

<style lang="scss" scoped>
.menu-breadcrumbs {
	display: flex;
	align-items: center;
	background: $breadcrumbs-background;
	z-index: $level0;
	:deep(.breadcrumbs-container) {
		display: flex;
		align-items: center;
		padding-top: 23px;
		padding-bottom: 11px;
		column-gap: 1rem;
		flex-wrap: wrap;

		@include media-breakpoint-up(md) {
			margin: 0;
			column-gap: 2rem;
			justify-content: start;
		}

		@include media-breakpoint-up(xl) {
			padding-top: 13px;
		}

		.breadcrumbs-item {
			&:nth-child(2) {
				cursor: default;
				pointer-events: none;
			}
		}
	}
}
.product-breadcrumbs {
	position: relative;
	padding-top: 50px;
}
</style>
