import { getQuery } from './query'

function getPath ({ app, routes, to, params = {}, query = {}, returnIfNotFound = '#' }) {
	if (!routes) routes = app.vue.$routes
	const route = routes.find(route => route.meta.alias === to && route.meta.language === app.language)
	if (!route) {
		// if (to !== '!#' || to !== '#') console.warn(new Error(`There is no route with alias: ${to}`))
		return returnIfNotFound
	}
	let path = route.path
	Object.entries(params).map(([key, value]) => {
		path = path.replace(`:${key}`, value)
	})

	return path + getQuery(query)
}

export {
	getPath
}
