<template>
	<div class="questions-wrapper">
		<base-font color="secondary" size="paragraph" class="questions-any"> {{ text }} </base-font>
		<base-link v-bind="primaryLink">
			<base-svg name="button-chart"/>
			<base-font variant="button-text">{{primaryLink?.content && primaryLink.content}}</base-font>
		</base-link>
	</div>
</template>

<script>
import BaseSvg from '~/website/front/components/atoms/BaseSvg/BaseSvg'
import BaseFont from '~/website/front/components/atoms/BaseFont/BaseFont'
export default {
	components: { BaseFont, BaseSvg },
	props: {
		text: String,
		primaryLink: Object,
		margin: Boolean
	}
}
</script>

<style lang="scss" scoped>
.questions-wrapper{
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;

	&.margin {
		margin-top: 30px;

		@include media-breakpoint-up(lg) {
			margin-top: 60px;
		}
	}

	::v-deep(.buttons-wrapper) {
		margin-top: 0;
	}

	::v-deep(.questions-any) {
		text-transform: uppercase;
		font-size: 16px;

		@include media-breakpoint-up(xl) {
			font-size: 20px;
		}
		@include media-breakpoint-up(xxl) {
			font-size: 26px;
			text-transform: uppercase;
			line-height: 33px;
		}
	}

	@include media-breakpoint-up(lg) {
		flex-direction: row;
	}
}
</style>
