<template>
	<ul class="submenu" :class="{'submenu-categories-wrapper': hasSubmenuCategories}">
		<li class="submenu-item" v-for="item in items" :class="{'submenu-categories': hasSubmenuCategories}" :key="item.name">
			<div v-if="isLinkTypeCategory(item)" class="item-wrapper item-categories">
				<div class="subitem-background" v-if="item.bg_image">
					<cms-image lazy :src="item.bg_image" :base="{width: 282, height: 194}" />
				</div>
				<div class="categories-title"><base-font variant="menu-category" :html="item.name"/></div>
				<div class="subitems-wrapper">
					<menu-dropdown-item
						v-for="subItem in item.children"
						:key="subItem.name"
						:to="subItem.to"
						:item="subItem"
					>
						<base-font variant="menu" class="submenu-title">{{ subItem.name }}</base-font>
					</menu-dropdown-item>
				</div>
			</div>
			<div class="item-wrapper" v-else>
				<menu-dropdown-item :item="item" />
			</div>
		</li>
	</ul>
</template>
<script>
import BaseFont from '~/website/front/components/atoms/BaseFont/BaseFont'
import MenuDropdownItem from '~/website/front/components/organisms/BaseMenu/components/Desktop/MenuDropdownItem'
export default {
	components: { MenuDropdownItem, BaseFont },
	props: {
		items: Array,
		active: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		isLinkTypeCategory (item) {
			return item.link_type === 'category'
		},
		isLinkTypeButton (item) {
			return item.link_type === 'button'
		}

	},
	computed: {
		hasSubmenuCategories () {
			return this.items.filter(item => item.link_type === 'category').length !== 0
		}
	}
}
</script>
<style lang="scss" scoped>

.submenu {
	list-style-type: none;
	padding-inline-start: 0;
	flex-direction: column;
	justify-content: center;
	display: flex;
	gap: 20px;
	padding: 1.5rem 2rem;

	@include media-breakpoint-up(xl) {
		padding: 30px 0;
	}

	&.submenu-categories-wrapper{
		flex-direction: column;
		gap: 0;
		justify-content: flex-start;
		padding: 0 0 2rem 0;

		@include media-breakpoint-up(xl) {
			flex-direction: row;
			height: 100%;
			padding: 0;
		}

		.item-categories{
			width: 100%;

			.subitems-wrapper{
				@include media-breakpoint-up(xl) {
					padding: 20px 0 30px 0;
				}
			}

			::v-deep(.submenu-link){
				padding: 0 2rem 0 4rem;
				@include media-breakpoint-up(xl) {
					padding: 0 2rem 0 2rem;
				}
			}
		}
	}

	&-link{
		width: 100%;
	}

	.item-wrapper{
		width: 100%;
	}

	.submenu-item{
		position: relative;
		width: 330px;

		&.submenu-categories {
			height: 100%;
			width: 280px;

			@include media-breakpoint-up(xl) {
				width: 235px;
			}

			@include media-breakpoint-up(xxl) {
				width: 280px;
			}
		}

		:deep(.submenu-link) {
			padding: 0 2rem;

			&.active-route {
				span {
					color: $primary;
				}
			}
		}

		.subitem-background{
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;
			width: 100%;
			height: 100%;

			.image-wrapper{
				height: 100%;

				::v-deep(img) {
					width: 100%;
					height: 100%;
					object-fit: cover;
					filter: grayscale(99%) sepia(100%) opacity(10%) contrast(115%);
					-webkit-filter: grayscale(99%) sepia(100%) opacity(10%) contrast(115%);
					-moz-filter: grayscale(99%) sepia(100%) opacity(10%) contrast(115%);
				}
			}
		}

		.categories-title{
			padding: 2rem;

			@include media-breakpoint-up(xl) {
				background-color: #2092DD;
				display: flex;
				justify-content: center;
				padding: 15px 0;
			}

			::v-deep(span) {
				color: $primary;
				font-weight: 700;

				@include media-breakpoint-up(xl) {
					color: $white;
				}
			}
		}

		::v-deep(.subitems-wrapper){
			display: flex;
			flex-direction: column;
			gap: 20px;
		}

		::v-deep(.submenu-link){
			.submenu-title {
				display: block;
				color: $secondary;
				font-weight: 600;

				@include media-breakpoint-up(xl) {
					text-align: center;
				}

				&:hover {
					color: $primary;
				}
			}
		}
	}
}
</style>
