var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"testimonials"},[_c('base-container',[_c('div',{staticClass:"testimonials-items"},_vm._l((_vm.opinions),function(testimonial,index){return _c('base-animation',{key:`${_vm.prefix}.cards.${index}`,attrs:{"animation":_vm.evenAnimation(index, 'slideRight', 'slideLeft')}},[_c('opinion-reviews-item',{attrs:{"opinion":{
						title: testimonial.author,
						subtitle: testimonial.place,
						description: testimonial.content,
						image: testimonial.image,
						pageLink: testimonial.pageLink
					}}})],1)}),1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }