<template>
	<div class="tech-icon">
		<cms-image
			:src="icon"
			:base="{format: 'webp'}"
		/>
	</div>
</template>

<script>
export default {
	props: {
		icon: Object,
		type: {
			type: String,
			default: 'icon'
		}
	}
}
</script>

<style lang="scss" scoped>
	.tech-icon{
		@include transition;
		display: flex;
		min-width: 60px;
		min-height: 35px;
		align-items: center;
		justify-content: center;
		gap: 10px;

		@include media-breakpoint-up(lg) {
			gap: 15px;
		}
	}
</style>
