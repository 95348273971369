<template>
	<div
		class="filter-group"
		:class="{bottomBorder}"
	>
		<base-font v-if="title" weight="bold" class="filter-group-title">{{title}}</base-font>
		<div class="items-wrapper">
			<filter-group-item
				v-for="item in items"
				:item="item"
				:active-item-id="activeItem?.id"
				:key="item.name"
				@change="handleChange"
			/>
		</div>

	</div>
</template>

<script>
import FilterGroupItem from '~/website/front/core/pages/DownloadsPage/components/FilterGroupItem.vue'
export default {
	components: { FilterGroupItem },
	props: {
		items: [Object, Array],
		title: String,
		bottomBorder: {
			default: false,
			type: Boolean
		},
		activeItem: Object,
		form: Object
	},
	methods: {
		handleChange (item) {
			this.$emit('change', item)
		}
	}
}
</script>

<style lang="scss" scoped>

.filter-form{
	padding-top: 20px;

	& > div {
		margin-bottom: 10px;
		&:last-child {
			margin-bottom: 0;
		}
	}

	@include media-breakpoint-up(md) {
		display: flex;
	}

	:deep(.field-label) {
		label {
			color: $secondary;
		}
	}
}

.filter-group {
	padding-top: 20px;

	@include media-breakpoint-up(lg) {
		padding-top: 40px;
	}

	:deep(.filter-group-title){
		font-size: 18px;
		line-height: 24px;
		margin-bottom: 10px;
	}

	.items-wrapper {
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
		@include media-breakpoint-up(lg) {
			gap: 20px;
		}
	}

	&.bottomBorder{
		padding-top: 0;
		padding-bottom: 10px;

		@include media-breakpoint-up(lg) {
			padding-bottom: 30px;
		}

		&:after {
			@include pseudo;
			width: 20%;
			height: 1px;
			background-color: #E9E9E9;
			bottom: -15px;
			@include media-breakpoint-up(lg) {
				bottom: -30px;
			}
		}
	}
}
</style>
