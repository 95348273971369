import { ReactiveClass } from '@f/core/ReactiveClass'

class WebsiteSvgPrefetchManager extends ReactiveClass {
	app
	svgs = {}
	requests = {}
	constructor (app) {
		super()
		this.app = app
		if (process.client) this.svgs = window.__SVGS__
	}

	async getSVG (path) {
		if (this.svgs[path]) return this.svgs[path]
		if (!this.requests[path]) this.requests[path] = new Promise((resolve) => this.fetchSVG(path, resolve))
		return this.requests[path]
	}

	async fetchSVG (path, resolve) {
		const service = this.app.getService('rext')
		const data = await service.getSvg(path)
		if (!data) console.error(`Can't fetch SVG with path: ${path}`)
		else {
			this.svgs[path] = data
			resolve(data)
		}
	}
}

export { WebsiteSvgPrefetchManager }
