<template>
	<cms-text :value="`${prefix}.content`" :props="{variant: 'medium-header'}"/>
</template>

<script>

export default {
	props: {
		prefix: String,
		value: Object
	}
}
</script>
