<template>
	<cms-multi-section :optimization="true" value="content"/>
</template>

<script>
import CmsMultiSection from '~/cms/front/components/CmsMultiSection'
import { pageMixin } from '~/website/front/core/pages/page.mixin'

export default {
	components: {
		CmsMultiSection
	},
	mixins: [
		pageMixin
	]
}
</script>
