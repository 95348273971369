import { getQuery } from 'utils/query'

export function rextServiceExtend (Service) {
	return class RextService extends Service {
		async sendNotFoundPage (path, fullPath) {
			const { data } = await this.http.post('/not-found', {
				path,
				fullPath
			})
			return data
		}

		async getFile (path) {
			if (!path) return { error: true }
			const response = await this.http.get(`/filemanager/files/${path}`, {
				responseType: 'arraybuffer'
			})

			if (response.status !== 200) return { error: true }

			return response.data
		}

		async getProductsDownloadable (queryObject) {
			if (!queryObject) {
				const { data, status } = await this.http.get('/product-files?take=999')
				if (status !== 200) return { error: true }
				return data
			}

			const query = getQuery(queryObject)

			const { data, status } = await this.http.get(`/product-files${query}`)
			if (status !== 200) return { error: true }
			return data
		}
	}
}
