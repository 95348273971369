<template>
	<div class="no-products-notification-wrapper">
		<base-font class="header" color="secondary" size="menu-category" weight="bold">{{ lang.title }}</base-font>
		<base-font color="secondary" size="paragraph">{{ lang.subtitle }}</base-font>
		<base-link
			to="contact"
			button_type="primary"
			:title="lang.buttonText"
		>
			<base-svg name="button-chart" />
			{{ lang.buttonText }}
		</base-link>
	</div>
</template>

<script>

export default {
	components: {
	},
	computed: {
		lang () {
			const langs = this.$app.translator.get('products')
			return langs?.filter_product_section?.select_model || {}
		}
	},
	async prefetch () {
		await this.$app.translator.prefetch('products')
	}
}
</script>

<style lang="scss" scoped>
.no-products-notification-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 4rem;

	@include media-breakpoint-up(lg) {
		margin-top: 6rem;
	}

	:deep(.header) {
		font-size: 16px;
		text-align: center;
		margin-bottom: 10px;
		text-transform: uppercase;

		@include media-breakpoint-up(xl) {
			font-size: 24px;
		}
	}

	:deep(.btn) {
		margin-top: 30px;
	}
}
</style>
