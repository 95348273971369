const config = {
	services: {
		leaflet: {
			leafletApiKey: process.server ? '' : window.__CONFIG__.leafletKey
		}
	},
	mediaQueries: {
		xxl: '(min-width: 1600px)',
		xl: '(min-width: 1200px)',
		lg: '(min-width: 992px)',
		md: '(min-width: 768px)',
		sm: '(min-width: 576px)'
	}
}

export {
	config
}
