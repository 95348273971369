<template>
	<div class="downloads-search">
		<base-container>
			<div class="search-inner">
				<custom-title
					type="h1"
					header_tag="h1"
					:title="lang.title"
					:subtitle="lang.subtitle"
					:use-string="true"
				/>
				<filter-group
					v-if="categories"
					title="Filters:"
					:items="categories"
					:activeItem="activeCategory"
					:bottom-border="true"
					@change="handleCategoryChanged"
				/>
				<filter-group
					v-if="activeCategory?.subcategories && !isDedicatedCategory"
					:items="activeCategory?.subcategories"
					:form="activeCategory?.form"
					:activeItem="activeSubcategory"
					@change="handleSubcategoryChanged"
					ref="subcategoriesFilters"
				/>
				<downloads-dedicated-panel
					v-if="isDedicatedCategory"
					:brands="brands"
					:models="models"
					@brandChanged="handleBrandChanged"
					@modelChanged="handleModelChanged"
				/>
				<files-group
					ref="files"
					v-if="products.length"
					:products="products"
				/>
				<no-filtered-products-notification
					v-else
					ref="noFiles"
				/>
			</div>
		</base-container>
	</div>
</template>

<script>
import CustomTitle from '~/website/front/components/atoms/CustomTitle/CustomTitle.vue'
import FilterGroup from '~/website/front/core/pages/DownloadsPage/components/FilterGroup.vue'
import FilesGroup from '~/website/front/core/pages/DownloadsPage/components/FilesGroup.vue'
import NoFilteredProductsNotification
from '~/catalog/front/components/organisms/NoFilteredProductsNotification/NoFilteredProductsNotification.vue'
import DownloadsDedicatedPanel from '~/website/front/core/pages/DownloadsPage/components/DownloadsDedicatedPanel.vue'
export default {
	components: { DownloadsDedicatedPanel, NoFilteredProductsNotification, FilesGroup, FilterGroup, CustomTitle },
	props: {
		categories: [Object, Array],
		activeCategory: Object,
		activeSubcategory: Object,
		products: Array,
		isDedicatedCategory: Boolean,
		brands: Array,
		models: Array
	},
	computed: {
		lang () {
			return this.$app.translator.get('support').downloadables_section || {}
		}
	},
	methods: {
		handleCategoryChanged (newActiveCategory) {
			this.$emit('categoryChanged', newActiveCategory)
		},

		handleSubcategoryChanged (newActiveSubcategory) {
			this.$emit('subcategoryChanged', newActiveSubcategory)
		},
		handleBrandChanged (brand) {
			this.$emit('brandChanged', brand)
		},
		handleModelChanged (model) {
			this.$emit('modelChanged', model)
		}
	},
	async prefetch () {
		await this.$app.translator.prefetch('support')
	}
}
</script>

<style lang="scss" scoped>
.downloads-search {
	@include spacing-section();
}
</style>
