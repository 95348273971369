var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slider-slide",on:{"click":_vm.handleClick}},[_c('cms-image',{attrs:{"src":{
			alt: _vm.product.name,
			path: _vm.slide.image?.path,
			title: `Product ${_vm.product.name}`
		},"base":{
			width: 345,
			height: 415,
			fit: 'contain',
			background: 'transparent'
		},"xl":{
			width: 340,
			height: 410,
			fit: 'contain',
			background: 'transparent'
		}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }