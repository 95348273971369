<template>
	<div class="specification-tab-content" v-if="isTabActive">
		<div class="specification-content-inner small-cards" v-if="isRangeSection(item.section_content[0]?.alias)">
			<div
				class="cards-row"
				v-for="(row, i) in item.section_content"
				:key="`${i}-default-middle`"
			>
				<div
					class="row-title"
					:style="{backgroundColor: getCardColor(i)}"
				>
					<base-font weight="bold" color="white" v-if="row.value.section_title" class="row-title-text">
						{{ row.value.section_title }}
					</base-font>
				</div>
				<div class="values-wrapper">
					<div class="row-frequency frequency-before" v-if="row.value.first_range">
						<base-font weight="bold" color="secondary">{{ row.value.first_range }}</base-font>
					</div>
					<div class="row-values" v-if="isRangeSection(row.alias)">
						<base-font
							weight="bold"
							color="secondary"
							v-for="value in cutPerItem(row.value.repeater_value)"
							:key="`row-value-${value}`"
							class="row-value"
						>
							{{ value }}
						</base-font>
					</div>
					<div class="row-frequency frequency-after" v-if="row.value.second_range">
						<base-font weight="bold" color="secondary">{{ row.value.second_range }}</base-font>
					</div>
				</div>
			</div>
		</div>

		<div class="specification-content-inner middle-cards" v-if="isDefaultSection(item.section_content[0]?.alias)">
			<div
				class="cards-row"
				v-for="(row, i) in item.section_content"
				:key="`${i}-default-middle`"
			>
				<div
					class="row-title"
				>
					<base-font weight="bold" color="white" v-if="row.value.section_title" class="row-title-text">
						{{ row.value.section_title }}
					</base-font>
				</div>
				<div class="row-values" v-if="row.stringify">
					<base-font
						weight="bold"
						color="secondary"
						class="row-value"
						variant="paragraph"
					>
						{{ row.stringify ? stringify(row.values) : row.value }}
					</base-font>
				</div>
				<div class="row-values" v-else>
					<base-font
						weight="bold"
						color="secondary"
						variant="paragraph"
						v-for="value in row.value.repeater"
						:key="`row-value-${value.repeater_value}`"
						class="row-value"
					>
						{{ value.repeater_value }}
					</base-font>
				</div>
			</div>
		</div>

		<div
			class="specification-content-inner compatible-cards"
			v-if="isCompatibleSection(item.section_content?.alias)"
		>
			<div
				v-for="(brand, i) in item.section_content.value"
				class="cards-row"
				:key="`brand-routers-${i}`"
			>
				<div class="row-title">
					<base-font weight="bold" color="white" class="row-title-text">
						{{ brand.name }}
					</base-font>
				</div>

				<div class="row-values">
					<a
						v-for="model in brand.models"
						:href="model.getPath($app)"
						class="row-value-link"
						:title="`Show antennas dedicated to ${brand.name} ${model.name}`"
						@click="(e) => { e.preventDefault(); model.goTo($app) }"
						:key="model.url"
					>
						<base-font
							weight="bold"
							class="row-value"
							variant="paragraph"
							color="primary"
						>
							{{ model.name }}
						</base-font>
					</a>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
import BaseFont from '~/website/front/components/atoms/BaseFont/BaseFont'

export default {
	components: { BaseFont },
	data () {
		return {
			colors: ['#868686', '#B9D8ED', '#6BB9ED', '#0099FF']
		}
	},
	props: {
		item: Object,
		activeTab: Number,
		index: Number
	},
	methods: {
		getCardColor (i) {
			return this.colors[(i % this.colors.length + this.colors.length) % this.colors.length]
		},

		stringify (arr) {
			return arr.join(', ')
		},

		cutPerItem (str) {
			return str.split(', ')
		},

		isRangeSection (alias) {
			return alias === 'specification_range_section'
		},

		isDefaultSection (alias) {
			return alias === 'specification_default_section'
		},

		isCompatibleSection (alias) {
			return alias === 'specification_compatible_section'
		}
	},
	computed: {
		isTabActive () {
			return this.index === this.activeTab
		}
	}
}
</script>

<style lang="scss" scoped>

.specification-tab-content {
	.specification-content-inner {
		&.small-cards {
			display: flex;
			flex-direction: column;
			gap: 20px;

			.cards-row {

				@include media-breakpoint-up(lg) {
					display: flex;
				}

				.row-title {
					background-color: $primary;
					color: $white;
					padding: 15px 25px;
					border-radius: $border-radius;
					font-size: 17px;
					line-height: 22px;
					display: flex;
					align-items: center;
					justify-content: center;

					@include media-breakpoint-up(lg) {
						width: 20%;
						max-width: 120px;
					}

					::v-deep(p) {
						text-align: center;
						font-size: 17px;
						line-height: 22px;
						text-transform: uppercase;
						letter-spacing: 0.34px;
					}
				}

				.values-wrapper {
					border-radius: $border-radius;
					background-color: #EEEEEE;
					padding: 20px;
					margin: 10px 0;
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: space-between;
					gap: 10px;

					.row-frequency {
						display: flex;
						justify-content: center;
						align-items: center;
						max-width: 60px;

						::v-deep(p) {
							font-size: 17px;
							line-height: 22px;
							text-align: center;
							word-break: break-word;
						}

						&.frequency-before {

						}

						&.frequency-after {

						}
					}

					.row-values {
						width: 100%;
						display: grid;
						gap: 10px;
						align-items: center;
						grid-template-columns: repeat(4, 1fr);

						@include media-breakpoint-up(sm) {
							grid-template-columns: repeat(6, 1fr);
						}

						@include media-breakpoint-up(md) {
							grid-template-columns: repeat(10, 1fr);
						}

						@include media-breakpoint-up(lg) {
							grid-template-columns: repeat(7, 1fr);
						}

						@include media-breakpoint-up(xl) {
							grid-template-columns: repeat(9, 1fr);
						}

						@include media-breakpoint-up(xxl) {
							grid-template-columns: repeat(11, 1fr);
						}

						::v-deep(.row-value) {
							display: block;
							padding: 9px;
							background-color: $white;
							border-radius: $border-radius;
							text-align: center;
							height: fit-content;
							font-size: 14px;
							line-height: 18px;

							@include media-breakpoint-up(lg) {
								padding: 9px 11px;
							}
						}
					}
				}

			}
		}

		&.middle-cards {
			display: flex;
			flex-direction: column;
			gap: 10px;

			.cards-row {
				display: flex;
				gap: 20px;
				align-items: stretch;

				.row-title {
					text-transform: uppercase;
					background-color: $primary;
					color: $white;
					padding: 15px 15px;
					border-radius: $border-radius;
					font-size: 17px;
					width: 120px;
					line-height: 22px;
					display: flex;
					align-items: center;
					justify-content: center;

					@include media-breakpoint-up(md) {
						width: 200px;
					}

					@include media-breakpoint-up(lg) {
						width: 330px;
					}

					::v-deep(p) {
						text-align: center;
						@include media-breakpoint-up(xl) {
							font-size: 17px;
							line-height: 22px;
							letter-spacing: 0.34px;
						}
					}
				}

				.row-values {
					width: 60%;
					display: flex;
					flex-direction: column;
					justify-content: center;
					padding: 10px 0;
					position: relative;

					&:after {
						content: '';
						display: block;
						position: absolute;
						bottom: -5px;
						width: 100%;
						height: 1px;
						background-color: #E6E6E6;
					}

					::v-deep(.row-value) {
						font-weight: 600;
						display: inline;

						@include media-breakpoint-up(xl) {
							font-size: 17px;
							line-height: 22px;
						}
					}
				}

			}

			.cards-row:last-child {
				.row-values {
					&:after {
						display: none;
					}
				}
			}
		}

		&.compatible-cards {
			display: flex;
			flex-direction: column;
			gap: 10px;

			.cards-row {
				display: flex;
				gap: 20px;
				align-items: stretch;

				.row-value-link {
					display: block;
					@include transition;
					:deep(p) {
						@include transition;
					}

					&:hover {
						color: $primary-strong;
						:deep(p) {
							color: $primary-strong;
						}
					}
				}

				.row-title {
					text-transform: uppercase;
					background-color: $primary;
					color: $white;
					padding: 15px 15px;
					border-radius: $border-radius;
					font-size: 17px;
					width: 120px;
					line-height: 22px;
					display: flex;
					align-items: center;
					justify-content: center;

					@include media-breakpoint-up(md) {
						width: 200px;
					}

					@include media-breakpoint-up(lg) {
						width: 330px;
					}

					::v-deep(p) {
						text-align: center;
						@include media-breakpoint-up(xl) {
							font-size: 17px;
							line-height: 22px;
							letter-spacing: 0.34px;
						}
					}
				}

				.row-values {
					width: 60%;
					display: flex;
					padding: 10px 0;
					position: relative;
					gap: 5px;
					flex-wrap: wrap;

					&:after {
						content: '';
						display: block;
						position: absolute;
						bottom: -5px;
						width: 100%;
						height: 1px;
						background-color: #E6E6E6;
					}

					::v-deep(.row-value) {
						font-weight: 600;
						display: inline;

						@include media-breakpoint-up(xl) {
							font-size: 17px;
							line-height: 22px;
						}
					}
				}

			}

			.cards-row:last-child {
				.row-values {
					&:after {
						display: none;
					}
				}
			}
		}
	}
}
</style>
