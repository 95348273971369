import { Slide } from '~/catalog/front/components/organisms/ProductSlider/Slide'

const SlideTransitions = {
	SLIDE_RIGHT: 'slide-right',
	SLIDE_LEFT: 'slide-left'
}

class ProductSlider {
	activeSlideIndex = 0
	slides = []
	slideTransition = SlideTransitions.SLIDE_RIGHT

	constructor (config, images) {
		this.config = config
		this.slides = images.map((image) => new Slide(this, image))

		if (this.config.autoplay) this.autoplay()
	}

	setNewImages (images) {
		this.slides = images.map((image) => new Slide(this, image))
	}

	get currentSlide () {
		return this.slides[this.activeSlideIndex]
	}

	autoplay () {
		setTimeout(() => {
			this.nextSlide()
		}, this.config.interval)
	}

	nextSlide () {
		this.slideTransition = SlideTransitions.SLIDE_RIGHT

		const nextSlideIndex = this.currentSlide.elementIndex + this.config.slidePerClick
		const nextSlide = this.slides[nextSlideIndex]

		this.goToSlide(nextSlide)
	}

	prevSlide () {
		this.slideTransition = SlideTransitions.SLIDE_LEFT

		const prevSlideIndex = this.currentSlide.elementIndex - this.config.slidePerClick
		const prevSlide = this.slides[this.slides.length + prevSlideIndex]

		this.goToSlide(prevSlide)
	}

	goToSlide (slide) {
		if (this.currentSlide === slide) return
		this.setSlideTransition(slide)

		const elementsBeforeSlide = this.slides.splice(0, slide.elementIndex)
		this.slides.push(...elementsBeforeSlide)
	}

	setSlideTransition (slide) {
		if (this.currentSlide.elementIndex + 1 === slide.elementIndex) this.slideTransition = SlideTransitions.SLIDE_RIGHT
		else this.slideTransition = SlideTransitions.SLIDE_LEFT
	}
}

export default ProductSlider
