<template>
	<div :class="{
		'breadcrumbs-item': true,
		'breadcrumbs-item--disabled': !breadcrumbRoute?.length
	}">
		<base-link :to="breadcrumbRoute" :params="breadcrumb.params" v-if="!isLast">
			<breadcrumb-text>
				{{ name }}
			</breadcrumb-text>
		</base-link>
		<breadcrumb-text v-else :class="{ 'breadcrumb-last': isLast }">
			{{ name }}
		</breadcrumb-text>
		<base-svg class="icon" name="breadcrumb" v-if="!isLast" />
	</div>
</template>

<script>
import BreadcrumbText from '~/website/front/components/atoms/BreadcrumbText/BreadcrumbText'

export default {
	components: {
		BreadcrumbText
	},
	props: {
		breadcrumb: Object,
		isLast: Boolean
	},
	computed: {
		name () {
			return this.breadcrumb.name
		},
		breadcrumbRoute () {
			return this.breadcrumb.to
		}
	}
}
</script>

<style scoped lang="scss">
.breadcrumbs-item {
	width: fit-content;
	display: flex;
	align-items: center;
	column-gap: 1rem;

	&--disabled {
		cursor: pointer;
		pointer-events: none;
	}

	@include media-breakpoint-up(lg) {
		column-gap: 2rem;
		padding-bottom: 0;
	}

	:deep(.breadcrumb) {
		opacity: .30;
		letter-spacing: 0.16px;
		font-size: 1.4rem;
		line-height: 1.8rem;
		text-transform: none;
		white-space: nowrap;

		@include media-breakpoint-up(lg) {
			font-size: 16px;
			line-height: 2.1rem;
		}
	}
	:deep(.breadcrumb-last) {
		opacity: 1;
		font-weight: bold;
	}

	.icon {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
</style>
