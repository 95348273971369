<template>
	<div class="section-wrapper">
		<base-container>
			<div :reverse="value.reverse">
				<div>
					<base-animation
						v-if="value.section_title"
						animation="slideRight"
					>

						<custom-title
							:title="`${prefix}.section_title.title`"
							:subtitle="`${prefix}.section_title.subtitle`"
							:header_tag="value.section_title?.title_tag"
							:color="value.section_title.title_color"
							:transform="value.section_title.title_transform"
							:type="value.section_title_type"
						/>
					</base-animation>

				</div>
				<div class="articles-wrapper">
					<base-article
						v-for="(article, i) in articles"
						:key="i"
						:article="article"
						:prefix="prefix"
						:imageSize="i === 0 ? 'lg' : 'sm'"
					/>
				</div>
			</div>
			<buttons
				:value="{
					0: { button: value.secondary_button_text },
					1: { button: value.primary_button_text }
				}"
			/>
		</base-container>
	</div>
</template>

<script>
import { isPageFromNull } from 'utils/ssrCheck'
import BaseArticle from '../../components/article/BaseArticle.vue'
import CustomTitle from '~/website/front/components/atoms/CustomTitle/CustomTitle.vue'
import Buttons from '~/website/front/sections/Buttons/Buttons'
import BaseAnimation from '@f/components/BaseAnimation/BaseAnimation'

export default {
	async prefetch () {
		if (process.server) {
			await this.fetchArticles()

			this.$app.page.componentsData.newest_blog_posts = {}
			this.$app.page.componentsData.newest_blog_posts.articles = this.articles

			await this.$app.translator.prefetch('articles')
		}
	},
	data () {
		return {
			articles: Array(3).fill({}),
			loading: true
		}
	},
	components: {
		Buttons,
		BaseArticle,
		CustomTitle,
		BaseAnimation
	},
	props: {
		prefix: String,
		value: Object
	},
	computed: {
		lang () {
			return this.$app.translator.get('articles')
		}
	},
	methods: {
		async fetchArticles () {
			const data = await this.$app.getService('rext').getArticles({ take: 3, response: 'front-preview' })
			if (data.error) return this.articles
			this.articles = data
			this.timeout()
		},
		recreateDataFromComponentsData () {
			this.articles = this.$app.page.componentsData.newest_blog_posts.articles.slice(0, 3)
			this.timeout()
		},
		timeout () {
			setTimeout(() => {
				this.loading = false
			}, 500)
		}
	},
	mounted () {
		if (isPageFromNull(this.$app)) {
			this.recreateDataFromComponentsData()
		} else {
			this.fetchArticles()
		}
	}
}
</script>
<style lang="scss" scoped>

.section-wrapper {
	@include spacing-section();
	background-color: $white;
}

.article {
	width: 100%;
	min-height: 214px;

	& ::v-deep .article {
		height: 100%;
		@include media-breakpoint-landscape() {
			max-height: none;
		}
	}

	@include media-breakpoint-up(xl) {
		& ::v-deep .article {
			max-height: none;
		}
	}
}

.articles-wrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: $cards-gap-mobile;
	@include media-breakpoint-up(md) {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
	}
	@include media-breakpoint-up(lg) {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
	}
}

.buttons-wrapper {
	@include media-breakpoint-up(lg) {
		justify-content: center;
	}
}

</style>
