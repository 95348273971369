<template>
	<section class="section">
		<base-container>
			<div class="section-inner">
				<custom-title
					:title="`${prefix}.section_title.title`"
					:subtitle="`${prefix}.section_title.subtitle`"
					:header_tag="value.section_title?.title_tag"
					:color="value.section_title.title_color"
					:transform="value.section_title.title_transform"
					:type="value.section_title_type"
				/>
				<div class="section-iframe" v-html="value.iframe" />
			</div>
		</base-container>
	</section>
</template>

<script>
import BaseContainer from '~/website/front/components/atoms/BaseContainer/BaseContainer.vue'
import CustomTitle from '~/website/front/components/atoms/CustomTitle/CustomTitle.vue'

export default {
	components: { CustomTitle, BaseContainer },
	props: {
		prefix: String,
		value: Object
	}
}
</script>

<style scoped lang="scss">
.section {
	@include spacing-section();
	&-iframe {
		:deep(iframe) {
			width: 100%;
			height: 296px;

			@include media-breakpoint-up(sm) {
				height: 288px;
			}

			@include media-breakpoint-up(md) {
				height: 405px;
			}

			@include media-breakpoint-up(lg) {
				height: 540px;
			}

			@include media-breakpoint-up(xl) {
				height: 642px;
			}

			@include media-breakpoint-up(xxl) {
				height: 793px;
			}
		}
	}
}
</style>
