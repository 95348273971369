<template>
	<div class="search-engine">
		<div
			class="search-engine-dedicated"
			:style="{
				backgroundImage: `url(${config.services.rext.imagesUrl}${backgroundImage?.path})`
			}"
		>
			<base-font
				weight="semibold"
				size="h2"
				color="white"
				class="search-engine-dedicated-title"
				:style="{textAlign: 'center'}"
			>
				{{ lang.products_list.search_by_brand }}
			</base-font>

			<div class="search-engine-dedicated-form">
				<app-select
					:options="$app.page.brandForm.options.brands"
					:label="lang.products_list.filters.brand"
					:default-value="$app.page.brandForm.brandValue"
					@valueChanged="alias => $app.page.brandForm.brandChangedHandler(alias)"
				/>
				<app-select
					:options="$app.page.brandForm.options.models"
					:label="lang.products_list.filters.model"
					:default-value="$app.page.brandForm.modelValue"
					:disabled="!$app.page.brandForm.activeBrand?.models.length"
					@valueChanged="name => $app.page.brandForm.modelChangedHandler(name)"
				/>
			</div>

			<cms-text
				v-if="additionalInfo"
				class="search-engine-dedicated-additional-info"
				:value="additionalInfo"
				:use-string="true"
				:props="{color: 'white', size: 'paragraph'}"
			/>
		</div>
		<div class="search-engine-params">
			<base-font
				class="search-engine-params-title"
				weight="semibold"
				size="h2"
				:style="{textAlign: 'center'}"
			>
				{{ lang.products_list.search_by_params }}
			</base-font>
			<div class="search-engi	ne-params-inner">
				<filters-panel
					v-if="$app.page.filtersManager"
					:filtersManager="$app.page.filtersManager"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import AppSelect from '~/catalog/front/components/molecules/AppSelected/AppSelect.vue'
import BaseFont from '~/website/front/components/atoms/BaseFont/BaseFont.vue'
import FiltersPanel from '~/catalog/front/core/pages/ProductListPage/components/FiltersPanel.vue'
import { config } from '@f/config/config.js'

export default {
	name: 'GlobalSearchEngine',
	components: {
		BaseFont,
		AppSelect,
		FiltersPanel
	},
	data () {
		return { config }
	},
	props: {
		backgroundImage: {
			type: Object,
			required: true
		},
		additionalInfo: String
	},
	computed: {
		lang () {
			return this.$app.translator.get('products')
		}

	},
	async prefetch () {
		await this.$app.translator.prefetch('products')
	}
}
</script>

<style lang="scss" scoped>
.search-engine {
	display: flex;
	flex-direction: column;
	gap: 20px;

	@include media-breakpoint-up(xl) {
		display: grid;
		grid-template-columns: 5fr 7fr;
		align-items: center;
		gap: 30px;
	}

	&-dedicated {
		padding: 20px;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		border-radius: 5px;
		background-color: #2e98dd;

		@include media-breakpoint-up(xl) {
			padding: 60px 20px;
			height: 100%;
		}

		&-title {
			text-align: center;
		}

		&-form {
			margin-top: 20px;
			display: flex;
			align-items: center;
			flex-direction: column;
			gap: 20px;
			width: 100%;

			:deep(.select) {
				width: 100%;
				max-width: 330px;
				display: flex;
				flex-direction: column;
				justify-content: center;
			}

			:deep(.select-inner) {
				width: 100%;
			}
		}

		&-additional-info {
			margin-top: 20px;
		}
	}

	&-params {
		padding: 20px 10px;
		background-color: $white;
		border-radius: 5px;

		@include media-breakpoint-up(md) {
			padding: 20px;
		}

		@include media-breakpoint-up(lg) {
			padding: 60px 30px 20px 30px;
			height: 100%;
		}

		&-title {
			text-align: center;
		}

		&-inner {
			margin-top: 25px;
			display: flex;
			gap: 20px;
			flex-direction: column;

			@include media-breakpoint-up(lg) {
				flex-direction: row;
				flex-wrap: wrap;
			}
		}

		&-row {

			&-title {

			}
		}

		&-list {
			margin-top: 10px;
			display: flex;
			gap: 10px;
			flex-wrap: wrap;

			&-item {
				cursor: pointer;
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 10px 15px;
				letter-spacing: .32px;
				text-transform: uppercase;
				border: 1px solid #d8d8d8;
				border-radius: 5px;
				@include transition();

				@include media-breakpoint-up(lg) {
					padding: 10px 20px;
				}

				@include media-breakpoint-up(xl) {
					padding: 10px 15px;
				}

				@include media-breakpoint-up(xxl) {
					padding: 10px 17px;
				}

				&.active {
					background-color: $primary;

					:deep(.search-engine-list-item-text) {
						color: $white;
					}
				}

				:deep(.search-engine-list-item-text) {
					@include transition();
				}
			}
		}
	}
}
</style>
