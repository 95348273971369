<template>
	<section class="gallery" ref="gallery">
		<base-container>

			<cms-text
				:value="`${prefix}.gallery_title`"
				:props="{tag: 'h2', variant: 'h2', uppercase: true}"
				uppercase
			/>

			<div>
				<div class="gallery-main-photo">
					<image-wrapper>
						<cms-image
							:value="`${prefix}.gallery_images.${currentImgIndex}.gallery_image`"
							lazy
							:base="{width: 316, height: 210}"
							:md="{width: 900, height: 400}"
							:xl="{width: 1200, height: 600}"
							class="current-image"
						/>
						<cms-image
							:value="`${prefix}.gallery_images.${lastImgIndex}.gallery_image`"
							lazy
							:base="{width: 316, height: 210}"
							:md="{width: 900, height: 400}"
							:xl="{width: 1200, height: 600}"
							class="last-image animate"
							ref="last-image"
						/>
					</image-wrapper>
					<button class="gallery-icon gallery-icon-prev" @click="prev">
						<base-svg name="chevron_left"/>
					</button>
					<button class="gallery-icon gallery-icon-next" @click="next">
						<base-svg name="chevron_right"/>
					</button>
				</div>
				<div class="gallery-thumbnails-wrapper" ref="galleryWrapper">
					<figure class="gallery-item" v-for="(image,i) in images" :key="i" @click="goto(i)">
						<cms-image
							:value="`${prefix}.gallery_images.${i}.gallery_image`"
							lazy
							:base="{width: 168, height: 110}"
							:md="{width: 130, height: 110}"
							:lg="{width: 170, height: 110}"
							:xl="{width: 270, height: 160}"
						/>
					</figure>
				</div>
			</div>

		</base-container>
	</section>
</template>
<script>
import ImageWrapper from '~/website/front/components/atoms/ImageWrapper'

export default {
	data: () => ({
		currentImgIndex: 0,
		lastImgIndex: 0
	}),
	components: {
		ImageWrapper
	},
	props: {
		prefix: String,
		value: Object
	},
	computed: {
		images () {
			return this.value.gallery_images
		}
	},
	methods: {
		next () {
			let nextIndex = parseInt(this.currentImgIndex) + 1

			if (this.currentImgIndex === this.images.length - 1) nextIndex = 0

			this.lastImgIndex = this.currentImgIndex
			this.goto(nextIndex)
		},
		prev () {
			let prevIndex = parseInt(this.currentImgIndex) - 1

			if (parseInt(this.currentImgIndex) === 0) prevIndex = this.images.length - 1

			this.lastImgIndex = this.currentImgIndex
			this.goto(prevIndex)
		},
		goto (index) {
			this.cancelAnimation()
			if (this.currentImgIndex === index) return
			this.scrollToItem(index)
			this.currentImgIndex = index
			clearInterval(this.interval)
			this.autoPlay()
			this.playAnimation()
		},
		scrollToItem (index) {
			const wrapper = this.$refs.galleryWrapper

			if (index === 0 || this.images.length - 1 === index) {
				const scrollToStartOrEnd = index === 0 ? 0 : wrapper.scrollWidth
				wrapper.scroll({
					left: scrollToStartOrEnd,
					behavior: 'smooth'
				})
				return
			}
			const item = wrapper.querySelector('.gallery-item')
			const direction = this.currentImgIndex < index ? 1 : -1
			const itemsOnScreen = Math.floor(wrapper.clientWidth / item.clientWidth)
			const itemIsInFirstHalfItemsOnScreen = itemsOnScreen / 2 < index
			const isMobile = this.$app.utils.mobile
			if (itemIsInFirstHalfItemsOnScreen || direction === -1 || isMobile) {
				wrapper.scrollBy({
					left: item.clientWidth * direction,
					behavior: 'smooth'
				})
			}
		},
		autoPlay () {
			this.interval = setInterval(this.next, 5000)
		},
		playAnimation () {
			this.$refs['last-image'].$el.classList.add('animate')
		},
		cancelAnimation () {
			this.$refs['last-image'].$el.classList.remove('animate')
		}
	},
	mounted () {
		this.autoPlay()
	},
	beforeDestroy () {
		clearInterval(this.interval)
	}
}
</script>
<style lang="scss" scoped>
.gallery {
	@include spacing-section();

	h2 {
		margin-bottom: 2rem;
		@include media-breakpoint-up(lg) {
			margin-bottom: 3rem;
		}
	}

	&-main-photo {
		position: relative;
		margin-bottom: 3rem;
		min-height: 210px;

		:deep(img) {
			border-radius: 1rem;
		}
	}

	//@include media-breakpoint-up(xxl) {
	//	min-height: 1100px;
	//}
	&-thumbnails-wrapper {
		display: flex;
		margin: 0 -5px;
		overflow: hidden;

		& .gallery-item {
			position: relative;
			cursor: pointer;
			margin: 0 5px 5px 5px;
			box-shadow: 0 3px 12px rgba($black, .16);
			min-height: 110px;
			border-radius: 1rem;
			@include media-breakpoint-up(xl) {
				min-height: 160px;
				margin-bottom: 1rem;
				&:hover::before {
					opacity: 1;
				}
				&::before {
					position: absolute;
					content: '';
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					background-color: rgba($gray-200, 0.4);
					opacity: 0;
					@include transition;
				}
			}
		}

		& :deep(img) {
			display: block;
			height: 100%;
			border-radius: 1rem;
		}
	}

	&-icon {
		position: absolute;
		top: 50%;
		left: 2rem;
		transform: translateY(-50%);
		border: none;
		background-color: transparent;
		cursor: pointer;

		&-next {
			left: inherit;
			right: 2rem;
		}

		@include media-breakpoint-up(xxl) {
			left: -6rem;
			&-next {
				left: inherit;
				right: -6rem;
			}
		}

		& :deep(svg) {
			width: 40px;
			height: 40px;
			fill: $primary;
			@include transition();

			&:hover {
				opacity: .5;
			}
		}
	}

	.current-image {
		position: relative;
	}

	.last-image {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		height: 100%;
		width: 100%;
	}

	.animate {
		animation-name: changeSlideAnim;
		animation-fill-mode: forwards;
		animation-duration: 1s;
		animation-iteration-count: 1;
	}

	@keyframes changeSlideAnim {
		from {
			opacity: 1;
		}
		to {
			opacity: 0;
		}
	}
}
</style>
