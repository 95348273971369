var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"opinion-list-section"},[_c('base-container',[_c('div',{staticClass:"opinion-list-section-inner"},[(_vm.value.section_title)?_c('custom-title',{attrs:{"title":`${_vm.prefix}.section_title.title`,"subtitle":`${_vm.prefix}.section_title.subtitle`,"header_tag":_vm.value.section_title?.title_tag,"color":_vm.value.section_title.title_color,"transform":_vm.value.section_title.title_transform,"type":_vm.value.section_title_type}}):_vm._e(),(_vm.opinions.length)?_c('div',{staticClass:"opinions-wrapper"},_vm._l((_vm.opinions),function(item,i){return _c('base-animation',{key:item.id,attrs:{"animation":_vm.evenAnimation(i, 'slideRight', 'slideLeft')}},[_c('opinion-reviews-item',{attrs:{"opinion":{
							title: item.author,
							subtitle: item.place,
							description: item.content,
							image: item.image,
							article: item.article,
							pageLink: item.pageLink
						}}})],1)}),1):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }