var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.hasItemChildren(_vm.item))?_c('li',{staticClass:"nav-list-item with-dropdown",class:{
		'active-page': _vm.isChildrenActive,
		'dropdown-category': _vm.hasCategorySubmenu,
		'show-title': _vm.item.showTitleInMenu,
		'active-route': _vm.isCurrentRoute
	}},[_c('button',{staticClass:"nav-link nav-dropdown-button",on:{"click":_vm.toggleDropdown}},[(_vm.hasIcon)?_c('base-svg',{staticClass:"menu-icon",attrs:{"name":_vm.item.icon.path}}):_vm._e(),_c('base-font',{staticClass:"nav-title",class:{'show-title': _vm.item.showTitleInMenu},attrs:{"variant":"menu"}},[_vm._v(_vm._s(_vm.item.name))]),_c('base-svg',{staticClass:"dropdown-arrow",class:{active: _vm.activeDropdown},attrs:{"name":'menu-arrow-dropdown'}})],1),_c('div',{ref:"dropdown",staticClass:"submenu-wrapper",class:{active: _vm.activeDropdown}},[_c('menu-dropdown',{attrs:{"items":_vm.item.children,"active":_vm.activeDropdown}})],1),_c('div',{staticClass:"nav-list-item-overlay",class:{active: _vm.activeDropdown}})]):_c('li',{staticClass:"nav-list-item",class:{
		'active-page': _vm.isActive,
		'show-title': _vm.item.showTitleInMenu,
		'active-route': _vm.isCurrentRoute
}},[_c('base-link',{staticClass:"nav-link",attrs:{"to":_vm.item.to,"params":{param: _vm.item.param}}},[(_vm.hasIcon)?_c('base-svg',{staticClass:"menu-icon",attrs:{"name":_vm.item.icon.path}}):_vm._e(),_c('base-font',{staticClass:"nav-title",class:{'show-title': _vm.item.showTitleInMenu},attrs:{"variant":"menu"}},[_vm._v(_vm._s(_vm.item.name))])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }