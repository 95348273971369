<template>
	<section class="downloadable" v-custom-background="getBackgroundImage">
		<base-container>
			<div class="downloadable-inner">
				<custom-title
					v-if="value.section_title"
					:title="`${prefix}.section_title.title`"
					:subtitle="`${prefix}.section_title.subtitle`"
					:header_tag="value.section_title?.title_tag"
					:color="value.section_title.title_color"
					:transform="value.section_title.title_transform"
					:type="value.section_title_type"
					:show-base-svg="false"
					:show-underline="false"
				/>
				<div class="downloadable-grid">
					<downloadable-card
						v-for="card in value.cards"
						:card="card"
						:key="pageProduct.alias + card.card_title"
						:icon="value.card_icon.path"
						:productName="pageProduct?.name"
						:alias="pageProduct.alias"
					/>
				</div>
			</div>
		</base-container>
	</section>
</template>

<script>
import BaseContainer from '~/website/front/components/atoms/BaseContainer/BaseContainer'
import DownloadableCard from '~/catalog/front/sections/DownloadableFilesSection/components/DownloadableCard'
import CustomTitle from '~/website/front/components/atoms/CustomTitle/CustomTitle'
import { evenAnimationMixin } from '~/cms/front/mixins/evenAnimationMixin'
import { productSectionMixin } from '~/catalog/front/sections/product-section.mixin'
export default {
	name: 'DownloadableFilesSection',
	components: { CustomTitle, DownloadableCard, BaseContainer },
	props: {
		prefix: String,
		value: Object
	},
	mixins: [evenAnimationMixin, productSectionMixin],
	computed: {
		getBackgroundImage () {
			if (this.value.section_bg) {
				return {
					src: this.value.section_bg.path ? this.value.section_bg.path : '',
					breakpoints: {
						base: { width: 1920, height: 733 }
					}
				}
			} else return ''
		}
	}
}
</script>

<style lang="scss" scoped>
	.downloadable{
		background-attachment: fixed;
		@include spacing-section();
		background-repeat: no-repeat;
		background-size: cover;

		position: relative;
		&:after {
			content:"";
			position: absolute;
			top:0;
			left:0;
			width: 100%;
			height: 100%;
			background-color: $primary;
			opacity:.85;
		}
		* {
			position: relative;
			z-index: 1;
		}

		.downloadable-grid{
			display: grid;
			gap: 30px;

			@include media-breakpoint-up(sm) {
				grid-template-columns: repeat(2, 1fr);
				grid-auto-rows: 1fr;
			}

			@include media-breakpoint-up(xl) {
				grid-template-columns: repeat(3, 1fr);
			}

			@include media-breakpoint-up(xxl) {
				grid-template-columns: repeat(4, 1fr);
			}
		}
	}

	::v-deep(.downloads-title) {
		margin-bottom: 30px;

		@include media-breakpoint-up(lg) {
			margin-bottom: 60px;
		}
	}
</style>
