
class Brand {
	static async fetch ({ app, where, relations }) {
		const service = app.getService('rext')

		const data = await service.getBrands({ where, relations })

		if (data.error) {
			return []
		}

		return data
	}

	static async fetchOne ({ app, alias }) {
		const service = app.getService('rext')

		const data = await service.getBrand(alias)

		if (data.error) return null

		return data
	}
}

export { Brand }
