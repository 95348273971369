<template>
	<section class="section">
		<product-menu-section :content="page.value.content"/>
		<cms-multi-section :optimalization="true" value="content"/>
	</section>
</template>

<script>
import CmsMultiSection from '~/cms/front/components/CmsMultiSection'
import { pageMixin } from '~/website/front/core/pages/page.mixin'
import ProductMenuSection from '~/catalog/front/sections/ProductMenuSection/ProductMenuSection'

export default {
	components: {
		ProductMenuSection,
		CmsMultiSection
	},
	mixins: [
		pageMixin
	]
}
</script>
