<template>
	<div class="plot-range-card">
		<div class="range">
			<div class="range-circle range-left"></div>
			<div class="range-line"></div>
			<cms-text :value="`${prefix}.range_cards.${index}.description`" :props="{tag: 'span', weight: 'bold'}" class="range-text"/>
			<div class="range-circle range-right"></div>
		</div>
		<div class="images">
			<div class="images-item" v-for="(plot, i) in plot.images" :key="plot.image.path">
				<cms-text
					:value="`${prefix}.range_cards.${index}.images.${i}.title`"
					:props="{tag: 'h6', uppercase: true, weight: 'semibold', variant: 'paragraph', color: 'secondary'}"
					class="range-title"
				/>
				<base-svg class="plot-image-svg" :name="plot.image?.path"/>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		plot: Object,
		index: Number,
		prefix: String
	}
}
</script>

<style lang="scss" scoped>
.plot-range-card {
	padding-top: 30px;

	.range {
		display: flex;
		align-items: flex-start;
		margin-bottom: 37px;
		position: relative;

		@include media-breakpoint-up(xl) {
			width: 85%;
			margin: 0 auto 37px auto;
		}

		@include media-breakpoint-up(xl) {
			width: 60%;
		}

		.range-text{
			font-size: 18px;
			line-height: 24px;
			position: absolute;
			top: -30px;
			left: 0;
			right: 0;
			width: fit-content;
			display: block;
			color: $secondary;
			margin: auto;
		}

		.range-circle {
			margin-bottom: -14px;
			width: 13px;
			height: 13px;
			border: 3px solid $primary;
			border-radius: 50%;

			&.range-left {
				transform: translateX(-10px)
			}

			&.range-right {
				transform: translateX(10px);
			}
		}

		.range-line{
			width: 100%;
			background-color: $secondary;
			height: 2px;
			align-self: flex-end;
			margin-bottom: -10px;
		}
	}

	.images{
		display: flex;
		gap: 28px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		position: relative;

		@include media-breakpoint-up(md) {
			flex-direction: row;
		}

		@include media-breakpoint-up(xl) {
			padding: 0 57px;
			gap: 100px;
		}

		.images-item{
			.range-title{
				text-align: center;
				letter-spacing: 0.18px;
				margin-bottom: 10px;
			}

			.plot-image-svg{
				width: 330px;
				height: 300px;

				@include media-breakpoint-up(xl) {
					width: 380px;
					height: 360px;
				}
			}
		}

	}

	:deep(svg) {
		max-width: 100%;
		@include transition;
		backface-visibility: hidden;
		&:hover {
			transform: translateY(-3px);
		}
	}
}
</style>
