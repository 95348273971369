<template>
	<div class="default-layout-wrapper">
		<base-menu :menuItems="$app.layout.value.menu.items"/>
		<menu-breadcrumbs v-if="shouldShowBreadcrumbs" />
		<main class="content">
			<slot />
		</main>
<!--		<base-cookie/>-->
		<lazy-hydrate
			when-idle
			:idle-timeout="5000"
		>
			<base-newsletter v-if="shouldShowNewsletter"/>
		</lazy-hydrate>
		<lazy-hydrate
			when-idle
			:idle-timeout="5000"
		>
			<social-section/>
		</lazy-hydrate>
		<lazy-hydrate
			when-idle
			:idle-timeout="5000"
		>
			<base-footer/>
		</lazy-hydrate>
	</div>
</template>

<script>
import BaseMenu from '~/website/front/components/organisms/BaseMenu'
// import BaseCookie from '~/website/front/components/organisms/BaseCookie'
import BaseFooter from '~/website/front/components/sections/BaseFooter'
import BaseNewsletter from '~/website/front/components/sections/BaseNewsletter'
import MenuBreadcrumbs from '~/website/front/components/organisms/MenuBreadcrumbs/MenuBreadcrumbs'
import { pageMixin } from '~/website/front/core/pages/page.mixin'
import SocialSection from '~/website/front/sections/SocialSection/SocialSection'
import LazyHydrate from 'vue-lazy-hydration'

export default {
	components: {
		SocialSection,
		BaseMenu,
		BaseNewsletter,
		BaseFooter,
		// BaseCookie,
		MenuBreadcrumbs,
		LazyHydrate
	},
	computed: {
		shouldShowBreadcrumbs () {
			return this.$app.page.route.meta.showBreadcrumbs
		},
		shouldShowNewsletter () {
			return this.$app.page.route.meta.showNewsletter
		}
	},
	mixins: [
		pageMixin
	]
}
</script>
<style lang="scss" scoped>

.default-layout-wrapper {
	padding-top: $menuHeightMobile;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	@include media-breakpoint-up(xl) {
		padding-top: $menuHeightDesktop;
	}
}
.default-layout-footer {
	margin-top: auto;
}

main {
	position: relative;
	&:after {
		opacity: 0;
		@include transition();
		content: '';
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,.5);
		display: block;
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		z-index: -1;
	}

	&.opened {
		&:after {
			opacity: 1;
			z-index: 99;
		}
	}
}
</style>
