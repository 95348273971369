import { DefaultLayout } from './core/layouts/DefaultLayout'
import { rextServiceExtend } from './core/services/RextService'
import { rextServiceExtend as baseSvgRextServiceExtend } from '@f/components/BaseSvg/service/RextService'
import registerGlobalComponents from './components/_globals'
import { BlankLayout } from './core/layouts/BlankLayout'
const FaqPage = async () => (await import('./core/pages/FaqPage')).FaqPage
const ContactPage = async () => (await import('./core/pages/ContactPage')).ContactPage
const DefaultPage = async () => (await import('./core/pages/DefaultPage')).DefaultPage
const NotFoundPage = async () => (await import('./core/pages/NotFoundPage')).NotFoundPage
const WhereToBuyPage = async () => (await import('./core/pages/WhereToBuyPage')).WhereToBuyPage
const DownloadsPage = async () => (await import('./core/pages/DownloadsPage')).DownloadsPage
const AskAQuestionPage = async () => (await import('./core/pages/AskAQuestionPage')).AskAQuestionPage
const PhotoSection = () => import('~/website/front/sections/PhotoSection/')
const TextContent = () => import('~/website/front/sections/TextContent/')
const GallerySection = () => import('~/website/front/sections/GallerySection/')
const Subtitle = () => import('~/website/front/sections/Subtitle/')
const Buttons = () => import('~/website/front/sections/Buttons/')
const BaseSlider = () => import('~/website/front/sections/BaseSlider/')
const AdvantagesSection = () => import('~/website/front/sections/AdvantagesSection/')
const IconsLeftSection = () => import('~/website/front/sections/IconsLeftSection/')
const MeansSection = () => import('~/website/front/sections/MeansSection/')
const PartnersSection = () => import('~/website/front/sections/PartnersSection/')
const SocialSection = () => import('~/website/front/sections/SocialSection/')
const OurBestSellers = () => import('~/website/front/sections/OurBestSellers')
const SearchProductSection = () => import('~/website/front/sections/SearchProductSection/')
const PageContentSection = () => import('~/website/front/sections/PageContentSection')
const Testimonials = () => import('~/website/front/sections/Testimonials')
const AntennaPhotoSection = () => import('~/website/front/sections/AntennaPhotoSection')
const RouterPhotoSection = () => import('~/website/front/sections/RouterPhotoSection')
const AntennaCardsSection = () => import('~/website/front/sections/AntennaCardsSection')
const AntennaImageSection = () => import('~/website/front/sections/AntennaImageSection')
const AntennaCallSection = () => import('~/website/front/sections/AntennaCallSection')
const AntennaProductsSection = () => import('~/website/front/sections/AntennaProductsSection')
const WhyChooseUs = () => import('~/website/front/sections/WhyChooseUs')
const Team = () => import('~/website/front/sections/Team')
const Title = () => import('~/website/front/sections/Title')
const RouterExpressionSection = () => import('~/website/front/sections/RouterExpressionSection')
const DistributorsSection = () => import('~/website/front/sections/DistributorsSection')
const ContactSection = () => import('~/website/front/sections/ContactSection')
const TableSection = () => import('~/website/front/sections/TableSection')

class WebsiteModule {
	sections = []

	constructor (app) {
		this.app = app
		this.app.sections = {}
		this.app.addSection = (alias, component) => {
			this.app.sections[alias] = component
		}
	}

	afterRouterCreated (app) {
		app.router.afterEach(async (to, from) => {
			if (process.client &&
				from.meta.alias &&
				(!to.meta.keepedAlive || to.meta.alias !== from.meta.alias)
			) window.scrollTo(0, 0)
		})
	}

	readyToAddPages (app) {
		app.addPage('not-found', NotFoundPage)
		app.addPage('default', DefaultPage)
		app.addPage('faq', FaqPage)
		app.addPage('contact', ContactPage)
		app.addPage('where-to-buy', WhereToBuyPage)
		app.addPage('downloads', DownloadsPage)
		app.addPage('ask-a-question', AskAQuestionPage)
	}

	readyToAddLayouts (app) {
		app.addLayout(DefaultLayout)
		app.addLayout(BlankLayout)
	}

	beforeInitReactive (app) {
		registerGlobalComponents(app)
		app.addSection('slider', BaseSlider)
		app.addSection('subtitle', Subtitle)
		app.addSection('buttons', Buttons)
		app.addSection('text_content', TextContent)
		app.addSection('photo_section', PhotoSection)
		app.addSection('gallery_section', GallerySection)
		app.addSection('advantages_section', AdvantagesSection)
		app.addSection('iconsleft_section', IconsLeftSection)
		app.addSection('means_section', MeansSection)
		app.addSection('partners_section', PartnersSection)
		app.addSection('social_section', SocialSection)
		app.addSection('our_bestsellers', OurBestSellers)
		app.addSection('search_product_section', SearchProductSection)
		app.addSection('page_content_section', PageContentSection)
		app.addSection('testimonials', Testimonials)
		app.addSection('antenna_photo_section', AntennaPhotoSection)
		app.addSection('antenna_cards_section', AntennaCardsSection)
		app.addSection('antenna_image_section', AntennaImageSection)
		app.addSection('antenna_call_section', AntennaCallSection)
		app.addSection('antenna_items_section', AntennaProductsSection)
		app.addSection('why_choose_us', WhyChooseUs)
		app.addSection('team', Team)
		app.addSection('title', Title)
		app.addSection('router_expression_section', RouterExpressionSection)
		app.addSection('router_photo_section', RouterPhotoSection)
		app.addSection('distributors_section', DistributorsSection)
		app.addSection('contact_section', ContactSection)
		app.addSection('table_section', TableSection)
	}

	readyToExtendServices (app) {
		app.extendService('rext', rextServiceExtend)
		app.extendService('rext', baseSvgRextServiceExtend)
	}
}
export { WebsiteModule }
