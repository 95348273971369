var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.product)?_c('section',{staticClass:"product-card-section"},[_c('base-container',[_c('div',{staticClass:"product-card-inner"},[_c('base-link',{staticClass:"product-compare",attrs:{"button_type":"primary"}},[_c('base-svg',{attrs:{"name":"justice-scale"}}),_c('base-font',{attrs:{"variant":"button-text"}},[_vm._v(" Porównaj ")])],1),_c('product-slider',{attrs:{"sliderConfig":_vm.sliderConfig,"images":_vm.product.gallery,"product":_vm.pageProduct}}),_c('div',{staticClass:"content-product"},[_c('div',{staticClass:"title-wrapper"},[_c('base-font',{staticClass:"product-card-title",attrs:{"variant":"product-card-title"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.productName)}})]),_c('div',{staticClass:"subtitle-wrapper"},[_c('div',{staticClass:"subtitle-line line-first"}),(_vm.isAntenna && _vm.product.activeVariant?.partNumber)?_c('base-font',{staticClass:"card-subtitle"},[_vm._v(" "+_vm._s(_vm.lang.pn)+": "+_vm._s(_vm.product.activeVariant?.partNumber || 'To check PN please select variant below')+" ")]):(_vm.isAntenna && !_vm.product.activeVariant?.partNumber)?_c('base-font',{staticClass:"card-subtitle",attrs:{"uppercase":false}},[_vm._v(" To check PN please select variant below ")]):(!_vm.isAntenna && _vm.value.section_subtitle)?_c('base-font',{staticClass:"card-subtitle"},[_vm._v(" "+_vm._s(_vm.value.section_subtitle)+" ")]):_vm._e(),_c('div',{staticClass:"subtitle-line line-second"})],1)],1),_c('div',{staticClass:"product-content content-wrapper"},[_c('cms-text',{staticClass:"content-heading",attrs:{"value":`${_vm.prefix}.section_title`,"props":{weight:'bold', color: 'primary', tag: 'h4'}}}),_c('cms-text',{staticClass:"content-text",attrs:{"props":{
							color: 'secondary',
							variant: 'paragraph',
							tag: 'div'
						},"value":_vm.product.description,"use-string":""}})],1),(_vm.product.icons.length)?_c('div',{staticClass:"product-icons-wrapper"},_vm._l((_vm.product.icons),function(item){return _c('product-tech-icon',{key:item.path,attrs:{"icon":item}})}),1):_vm._e(),(_vm.value.notice)?_c('cms-text',{staticClass:"text-amplification",attrs:{"value":`${_vm.prefix}.notice`,"props":{variant: 'notice'}}}):_vm._e(),(_vm.product.variants.length > 1)?_c('div',{staticClass:"variants-wrapper"},[_c('base-font',{staticClass:"variants-title",attrs:{"weight":"bold","color":"secondary"}},[_vm._v("Variants:")]),_c('div',{staticClass:"product-variants"},_vm._l((_vm.product.variants),function(variant){return _c('product-variant-button',{key:variant.name,attrs:{"name":variant.name,"value":variant.name,"active":variant.partNumber === _vm.product.activeVariant?.partNumber},on:{"handleClick":_vm.handleVariantClick}})}),1)],1):_vm._e(),(!_vm.isDeviceMobile)?_c('div',{staticClass:"wrapper-btns"},[_c('base-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.doesHasSpecification),expression:"doesHasSpecification"}],staticClass:"scroll-btn",attrs:{"title":_vm.lang.secondary_button,"button_type":"secondary"},on:{"click":_vm.scrollToSpecification}},[_c('base-font',{attrs:{"variant":"button-text"}},[_vm._v(" "+_vm._s(_vm.lang.secondary_button)+" ")])],1),_c('questions-button',{attrs:{"text":_vm.lang.any_question,"primaryLink":{
							type: 'internal',
							to: 'contact',
							content: _vm.lang.primary_button,
							button_type: 'primary',
							title: 'Contact with QuWireless experts'
						}}})],1):_c('div',{staticClass:"wrapper-btns tablet"},[_c('base-link',{attrs:{"button_type":"secondary","title":_vm.lang.secondary_button}},[_c('base-font',{attrs:{"variant":"button-text"}},[_vm._v(" "+_vm._s(_vm.lang.secondary_button)+" ")])],1),_c('questions-button',{attrs:{"text":_vm.lang.any_question,"click-handler":_vm.scrollToSpecification,"primaryLink":{
							type: 'internal',
							to: 'contact',
							content: _vm.lang.primary_button,
							button_type: 'primary',
							title: 'Contact with QuWireless experts'
						}}})],1)],1)],1)])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }