import { config } from '@f/config'
import { isObjectLike } from 'utils/objects'
import { generateSpecificRandomString, serialize } from 'utils/strings'
import { supportWebp } from 'utils/support'

class BackgroundImage {
	static classNamesHistory = []

	constructor (options) {
		this.options = options
		this.className = this.generateClassName()
		this.style = ''
	}

	get url () {
		return this.options.pathBeforeSrc + this.options.src
	}

	generateClassName () {
		const className = generateSpecificRandomString(6)

		if (BackgroundImage.classNamesHistory.includes(className)) return this.generateClassName()
		BackgroundImage.classNamesHistory.push(className)

		return className
	}

	createInlineStyle () {
		const style = document.createElement('style')
		const styleContent = this.getStyleContent()

		style.rel = 'stylesheet'
		style.appendChild(document.createTextNode(styleContent))

		this.style = style
	}

	getStyleContent () {
		const defaultUrl = this.url + serialize({
			...this.options.default,
			format: this.options.format
		})

		return `
			.${this.className} {
				background-image: url(${defaultUrl});
			}
			${this.getMediaQueriesStyles()}
		`
	}

	getMediaQueriesStyles () {
		const breakpoints = Object.entries(this.options.breakpoints)
		return breakpoints.reduce((styles, [key, value]) => {
			const query = serialize({ ...value, format: this.options.format })

			styles += this.mediaStyleTemplate({
				media: config.mediaQueries[key],
				className: this.className,
				url: this.url + query
			})

			return styles
		}, '')
	}

	mediaStyleTemplate ({ media, className, url }) {
		return `
			@media ${media} {
				.${className} {
					background-image: url('${url}');
				}
			}
		`
	}
}

const backgroundDirective = (defaultWidth = 320) => {
	BackgroundImage.classNamesHistory = []

	const defaultFormat = supportWebp() ? 'webp' : 'png'
	const getDefaultOptions = () => ({
		src: '',
		format: defaultFormat,
		pathBeforeSrc: config.services.rext.imagesUrl,
		default: { width: defaultWidth },
		breakpoints: {
			xl: { width: 1080 }
		}
	})

	const generateAndAppendBackground = (el, binding) => {
		const options = getDefaultOptions()

		if (typeof binding.value === 'string') options.src = binding.value
		else if (isObjectLike(binding.value)) Object.assign(options, binding.value)

		if (!options.src) return

		const backgroundImage = new BackgroundImage(options)
		backgroundImage.createInlineStyle()

		document.head.appendChild(backgroundImage.style)
		el.classList.add(backgroundImage.className)
	}

	return {
		bind: generateAndAppendBackground,
		update (el, binding) {
			const backgroundClass = BackgroundImage.classNamesHistory.find(className => {
				return el.classList.contains(className)
			})

			if (backgroundClass) {
				el.classList.remove(backgroundClass)
				BackgroundImage.classNamesHistory = BackgroundImage.classNamesHistory.filter(className => {
					return className !== backgroundClass
				})
			}

			generateAndAppendBackground(el, binding)
		}
	}
}

const backgroundCustomDirective = () => backgroundDirective(1920)

export {
	backgroundDirective,
	backgroundCustomDirective
}
