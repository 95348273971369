<template>
	<div
		class="slide"
		v-background="background"
	>
		<div class="slide__overlay" />
		<base-container class="slide__container">
			<div class="slide-content">
				<div class="slide-content-left">
					<cms-text
						class="slide-title"
						:props="{ variant: 'slider-title', tag: 'h2' }"
						:value="getActiveSlideData.title"
					/>
					<cms-text
						ref="title_colored"
						class="slide-title-colored"
						:props="{ variant: 'slider-colored', color: 'slider-colored' }"
						:value="getActiveSlideData.coloredTitle"
					/>
					<cms-text
						ref="description"
						class="slide-description"
						:props="{ color: 'white', tag: 'div' }"
						:value="getActiveSlideData.description"
					/>
					<cms-image
						v-if="getActiveSlideData.company"
						ref="image"
						class="slide-company"
						:src="getActiveSlideData.company"
						fetchpriority="low"
					/>
					<buttons class="slide-buttons-mobile-horizontal" :value="getActiveSlideData.buttons" />
				</div>
				<div class="slide-content-right">
					<cms-image
						v-if="getActiveSlideData.image"
						ref="image_behind_text"
						class="slide-image"
						:value="getActiveSlideData.image"
						:base="{width: 230, height: 230, background: 'transparent', fit: 'contain'}"
						:lg="{width: 415, height: 500, background: 'transparent', fit: 'contain'}"
						fetchpriority="high"
					/>
				</div>
				<buttons class="slide-buttons" :value="getActiveSlideData.buttons" />
			</div>
		</base-container>
	</div>
</template>
<script>
import Buttons from '~/website/front/sections/Buttons/Buttons'
import VBackgroundPreloadMixin from '@f/mixins/VBackgroundPreload.mixin.js'

export default {
	components: {
		Buttons
	},
	data () {
		return {
			dataActive: 0
		}
	},
	props: {
		prefix: String,
		active: Number,
		slideTime: Number,
		slider: Array,
		changeContentDelay: Number,
		animateBgDelay: Number
	},
	mixins: [VBackgroundPreloadMixin],
	computed: {
		background () {
			const path = this.slider[this.dataActive].slide_photo.path

			return {
				src: path,
				breakpoints: {
					base: { width: 800, height: 300 },
					lg: { width: 1400, height: 535 },
					xxl: { width: 1920, height: 733 }
				}
			}
		},

		getActiveSlideData () {
			return {
				title: `${this.prefix}.slider.${this.dataActive}.slide_title`,
				coloredTitle: `${this.prefix}.slider.${this.dataActive}.slide_title_colored`,
				description: `${this.prefix}.slider.${this.dataActive}.text_under_title`,
				image: `${this.prefix}.slider.${this.dataActive}.image_behind_text`,
				company: this.slider[this.dataActive].slide_company,
				buttons: this.slider[this.dataActive].content.find(section => section.alias === 'buttons').value.buttons
			}
		}
	},
	methods: {
		fadeAndNext () {
			Object.keys(this.$refs).forEach(el => {
				this.$refs[el].$el.classList.add('before-leave')
				setTimeout(() => {
					this.dataActive = this.active
					this.$refs[el].$el.classList.remove('before-leave')
				}, this.changeContentDelay)
			})
		},
		animateBg () {
			document.querySelector('.slide').classList.add('animate-bg-first')
			setTimeout(() => {
				document.querySelector('.slide').classList.remove('animate-bg-first')
				document.querySelector('.slide').classList.add('animate-bg-second')
				document.querySelector('.slide').classList.remove('animate-bg-second')
			}, this.animateBgDelay)
		}
	},
	watch: {
		active: function () {
			this.fadeAndNext()
			this.animateBg()
		}
	}
}
</script>
<style lang="scss" scoped>

.animate-bg-first {
	background-position-x: 50px!important;
}

.before-leave {
	opacity: 0.2;
}

.slide {
	background-position: 0 center;
	top: 0;
	transform: translateX(-5%);
	display: flex;
	align-items: center;
	height: 100%;
	background-size: cover;
	width: 110%;
	z-index: 1;
	transition: .5s linear;

	&.next {
		opacity: 0;
		transform: translateX(5%);
	}
	&.active {
		opacity: 1;
		transform: translateX(0%);
		z-index: 10;
	}
	&.prev {
		opacity: 0;
		transform: translateX(-5%);
	}

	&__container {
		@media (max-width: 920px) and (max-height: 420px) {
			max-width: none!important;
		}
	}

	&-content {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		flex-direction: column;
		column-gap: 10px;

		@media (max-width: 920px) and (max-height: 420px) {
			flex-wrap: nowrap;
			flex-direction: row;
		}

		@include media-breakpoint-up(lg){
			flex-direction: row;
		};

		&-left{
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			width: 100%;

			text-align: center;

			@include media-breakpoint-up(lg){
				align-items: flex-end;
				width: 50%;
				text-align: right;
			}
		}

		&-right{
			display: flex;
			height: 100%;

			@include media-breakpoint-up(lg){
				width: 27%;
			}
		}
	}

	:deep(.container) {
		width: 90%;
	}

	&-title,
	&-title-colored{
		line-height: initial;
		@include transition-slider;
		margin: 0;
	}

	&-title-colored{
		@include media-breakpoint-up(lg) {
			text-shadow: 0 0 40px $primary;
			overflow: visible;
		}
	}

	&-image{
		z-index: -1;
		@include transition;

		:deep(img){
			max-height: 400px;

			@include media-breakpoint-up(xxl){
				max-height: 500px;
			}
		}
	}

	&-description{

		@include transition-slider;
		max-width: 930px;
		text-align: center;
		font-size: 14px;
		line-height: 22px;
		font-weight: 500;
		margin-top: 10px;

		@media screen and (max-width:992px) and (min-height: 320px) and (max-height: 639px) {
			display: -webkit-box;
			-webkit-line-clamp: 4;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}

		@include media-breakpoint-up(lg){
			font-size: 18px;
			line-height: 24px;
			margin-top: 20px;
			text-align: right;
		}

		@include media-breakpoint-up(xxl) {
			margin-top: 40px;
		}

		@include media-slider-low {
			font-size: 16px;
			line-height: 22px;
			margin-top: 20px;
		}

	}

	&-company{
		@include transition-slider;
		max-width: 100px;
		margin-top: 10px;
		@include media-breakpoint-up(lg){
			max-width: 150px;
		}

		::v-deep(img){
			width: 100%;
		}
	}

	&__overlay{
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		background: rgba(0,0,0,0.3);

		@include media-breakpoint-up(lg) {
			background: linear-gradient(180deg, rgba(0,0,0,0.9023984593837535) 0%, rgba(0,0,0,0.5046393557422969) 10%, rgba(0,0,0,0.4990371148459384) 50%, rgba(0,0,0,0.4990371148459384) 89%, rgba(0,0,0,0.8995973389355743) 100%);
		}
	}

	:deep(.buttons-wrapper) {
		align-items: center;
		width: 100%;
		max-width: 930px;
		justify-content: center;
		gap: 10px;
		margin-top: 0;

		@media (max-width: 920px) and (max-height: 420px) {
			min-width: 190px;
		}

		@include media-breakpoint-up(md) {
			margin-top: 10px;
			gap: 20px;
		}

		@include media-breakpoint-up(xl) {
			flex-wrap: nowrap;
			@media screen and (max-height: 650px) {
				margin-top: -40px;
			}
		}

		@include media-breakpoint-up(xxl) {
			@media screen and (max-height: 775px) {
				margin-top: -45px;
			}
			margin-top: 20px;
		}

		@include media-slider-low {
			margin-top: -180px;
		}

		.svg-icon {
			width: 20px;
		}

		.btn.secondary {
			border-color: $white;
			color: $white;

			&:before{
				@include pseudo;
				position: absolute;
				width: 100%;
				height: 100%;
				background-color: rgba(0,0,0,.25);
				z-index: -1;
			}

			&:after {
				border-color: $white;
			}
		}

		a {
			span {
				@include media-breakpoint-up(xl) {
					width: max-content;
					display: block;
				}
			}
		}

		&:after,
		&:before {
			@include pseudo;
			height: 1px;
			width: 0;

			@media screen and (max-width: 992px) and (min-height: 320px) and (max-height: 639px) {
				display: none;
			}

			@include media-breakpoint-up(md) {
				width: 20%;
			}

			@include media-breakpoint-up(lg) {
				width: 25%;
			}
		}
	}

	&-buttons {
		@media (max-width: 920px) and (max-height: 420px) {
			display: none!important;
		}
	}

	// TODO: fix important
	&-buttons-mobile-horizontal {
		margin-top: 20px!important;
		display: none!important;
		flex-direction: row!important;
		flex-wrap: nowrap!important;

		@media (max-width: 920px) and (max-height: 420px) {
			display: flex!important;;
		}
	}
}
</style>
