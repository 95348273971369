<template>
	<main>
		<div class="filters-and-products">
			<base-container>
				<custom-title
					header_tag="h1"
					type="h1"
					:title="title"
					:subtitle="lang.find_the_best_solution"
					:use-string="true"
				/>
				<filters-panel
					v-if="view.filtersManager?.filters.length"
					:filtersManager="view.filtersManager"
				/>
				<div class="products-inner">
					<compatible-product-list
						v-if="view.products.narrowBandAntennas.length"
						title="NARROW BAND ANTENNAS"
						description="Narrow band antenna means that the antenna works only in a narrow bandwidth of the router. Such antennas usually have a greater gain (greater range), but only connect to the base station on a specific frequency. Narrow Band antennas are used primarily at very long distances from the base station where commonly used wideband 4G/LTE/5G antennas don’t work, and such antennas are also applicable if the operator operates only on one frequency (band). Before choosing such an antenna, you need to check if this frequency is supported in your area by the operator and remember that you will probably completely lose the Carrier Aggregation functionality and the option to change the operator."
						:products="view.products.narrowBandAntennas"
					/>
					<compatible-product-list
						v-if="view.products.wideBandAntennas.length"
						title="WIDE BAND ANTENNAS"
						:products="view.products.wideBandAntennas"
					/>
					<no-filtered-products-notification v-if="!hasAnyProducts" />
				</div>
			</base-container>
		</div>
		<cms-multi-section value="content" />
	</main>
</template>

<script>
import CmsMultiSection from '~/cms/front/components/CmsMultiSection'
import BaseContainer from '~/website/front/components/atoms/BaseContainer/BaseContainer.vue'
import CustomTitle from '~/website/front/components/atoms/CustomTitle/CustomTitle.vue'
import FiltersPanel from '~/catalog/front/core/pages/ProductListPage/components/FiltersPanel.vue'
import CompatibleProductList from '~/catalog/front/components/organisms/CompatibleProductList/CompatibleProductList'
import NoFilteredProductsNotification
from '~/catalog/front/components/organisms/NoFilteredProductsNotification/NoFilteredProductsNotification.vue'
export default {
	components: {
		NoFilteredProductsNotification,
		FiltersPanel,
		CustomTitle,
		BaseContainer,
		CmsMultiSection,
		CompatibleProductList
	},
	props: {
		view: Object
	},
	computed: {
		title () {
			return this.$app.page.subcategory.name || ''
		},
		lang () {
			return this.$app.translator.get('products').products_list
		},
		hasAnyProducts () {
			const productsCount = Object.values(this.view.products).reduce((acc, currentValue) => {
				return acc + currentValue.length
			}, 0)
			return !!productsCount
		}
	},
	async prefetch () {
		await this.$app.translator.prefetch('products')
	}
}
</script>

<style lang="scss" scoped>
.filters-and-products{
	@include spacing-section();

	.products-inner {
		margin-top: 60px;
		display: flex;
		flex-direction: column;
		gap: 20px;

		@include media-breakpoint-up(lg) {
			gap: 40px;
		}

		@include media-breakpoint-up(xl) {
			margin-top: 100px;
		}
	}
}

</style>
