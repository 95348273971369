<template>
	<base-animation>
		<div class="means__item" ref="item">
			<div class="title-wrapper">
				<number
					translate="no"
					animationPaused
					class="iconLeft__item-text"
					tag="h4"
					:format="theFormat"
					ref="number"
					from="0"
					:to="card.card_title_number"
					:duration="3"
					easing="Power1.easeOut"
				/>
				<cms-text ref="counter" class="iconLeft__item-text" :value="`${prefix}.card_title_text`" :props="{tag: 'h4'}" />
			</div>
			<cms-text class="iconLeft__item-text" :value="`${prefix}.card_description`" :props="{tag: 'span'}" />
		</div>
	</base-animation>

</template>

<script>

import BaseAnimation from '@f/components/BaseAnimation/BaseAnimation'
import Number from 'vue-number-animation/Number.vue'

export default {
	components: {
		BaseAnimation,
		Number
	},
	data () {
		return {
			index: 0
		}
	},
	props: {
		card: Object,
		prefix: String
	},
	methods: {
		playAnimation () {
			this.$refs.number.play()
		},

		theFormat (number) {
			const thousand = Math.round(number / 1000) === 0 ? '' : Math.round(number / 1000)
			const hundred = (number % 1000) === 0 ? '000' : Math.round(number % 1000)
			if (!thousand) return hundred
			return `${thousand} ${hundred}`
		}
	},
	mounted () {
		if (process.client) {
			this.observer = new IntersectionObserver((entries, observer) => {
				entries.forEach(entry => {
					if (entry.isIntersecting) {
						this.playAnimation()
						observer.unobserve(this.$refs.item)
					}
				})
			})
			this.observer.observe(this.$refs.item)
		}
	},
	beforeDestroy () {
		this.observer.disconnect()
	}
}
</script>

<style lang="scss" scoped>

.means__item{
	height: 100%;
	background-color: rgba(0, 153, 255, .5);
    display: flex;
	text-align: center;
    flex-direction: column;
    align-items: center;
    padding: 40px 37px 30px 37px;
    border-radius: 10px;
    border: 1px solid $white;
	@include transition;

	&:hover {
		transform: translateY(-10px);
	}

	.title-wrapper{
		position: relative;

		&:after{
			content: '';
			display: block;
			position: relative;
			bottom: 0;
			left: 0;
			right: 0;
			height: 1px;
			width: 90px;
			background-color: $white;
			margin: 16.5px auto 0 auto;

		}
	}

    h4{
		font-size: 28px;
		line-height: 36px;
        position: relative;
        color: $white;
        text-transform: uppercase;
        font-weight: 700;
		display: inline;
		text-align: center;

		@include media-breakpoint-up(md) {
			font-size: 50px;
			line-height: 69px;
		}
    }

    span {
        display: block;
        color: $white;
        text-align: center;
        text-transform: uppercase;
        font-size: 18px;
        line-height: 22px;
        font-weight: 500;
        margin-top: 20.5px;
        letter-spacing: $letter-spacing;

		@include media-breakpoint-up(md) {
			font-size: 22px;
			line-height: 26px;
		}
    }
}
</style>
