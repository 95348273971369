<template v-once>
	<section id="slider">
		<div class="slider">
			<base-svg
				class="slider__arrow-next"
				name="slider_arrow"
				v-on:click="nextClick"
			/>
			<base-slide
				:prefix="prefix"
				:active="activeIndex"
				:slideTime="slideTime"
				:slider="value.slider"
				:changeContentDelay="changeContentDelay"
				:animateBgDelay="animateBgDelay"
			/>
			<base-svg
				class="slider__arrow-back"
				name="slider_arrow"
				v-on:click="backClick"
			/>
		</div>
		<div class="slider__arrow-scroll" @click="scrollToNext">
			<span class="slider__arrow-scroll-text">
				Scroll Down
			</span>
			<div class="slider__arrow-scroll-line">
				<div></div>
				<div></div>
			</div>
		</div>
	</section>
</template>
<script>
import BaseSlide from './components/BaseSlide'
import BaseSvg from '~/website/front/components/atoms/BaseSvg/BaseSvg'

export default {
	data () {
		return {
			activeIndex: 0,
			slideTime: 10000,
			changeContentDelay: 500,
			animateBgDelay: 500
		}
	},
	props: {
		prefix: String,
		value: Object
	},
	components: {
		BaseSvg,
		BaseSlide
	},
	computed: {
		header () {
			return document.querySelector('.navbar-wrapper') || document.querySelector('.navbar-wrapper-mobile')
		},

		nextSection () {
			return document.getElementById('slider').nextSibling
		},

		nextSectionHeader () {
			return this.nextSection.querySelector('.section-title-wr')
		}
	},

	methods: {
		goTo (index) {
			this.activeIndex = index
			clearInterval(this.timer)
		},
		startInterval () {
			this.timer = setInterval(this.next, this.slideTime)
		},
		next () {
			if (this.activeIndex === this.value.slider.length - 1) this.activeIndex = 0
			else this.activeIndex++
		},
		nextClick () {
			clearTimeout(this.timer)
			this.next()
			this.timer = setInterval(this.next, this.slideTime)
		},
		backClick () {
			clearTimeout(this.timer)
			this.back()
			this.timer = setInterval(this.next, this.slideTime)
		},
		back () {
			if (this.activeIndex === 0) this.activeIndex = this.value.slider.length - 1
			else this.activeIndex--
		},
		scrollToNext (scrollTo) {
			const top = this.nextSectionHeader.offsetTop + window.innerHeight - this.header.offsetHeight
			window.scrollTo({
				top: window.innerWidth >= 992 ? top : top + 30,
				behavior: 'smooth'
			})
		}
	},
	mounted () {
		if (process.client) {
			this.$nextTick(function () {
				setTimeout(() => {
					this.startInterval()
				}, 5000)
			})
		}
	},
	beforeDestroy () {
		clearInterval(this.timer)
	}
}
</script>
<style lang="scss" scoped>

#slider{
	background-color: #0F2027;
	position: relative;
}

.slider {
	position: relative;
	display: flex;
	flex-direction: column-reverse;
	overflow: hidden;
	height: calc(100vh - $menuHeightMobile);

	@include media-breakpoint-up(xl) {
		height: calc(90vh - $menuHeightDesktop);

		@media screen and (max-height: 646px) {
			height: calc(97vh - $menuHeightDesktop);
		}
	}

	&__arrow-next,
	&__arrow-back{
		display: none;
		position: absolute;
		top: 0;
		z-index: 200;
		bottom: 0;
		opacity: .5;
		align-items: center;
		cursor: pointer;
		@include transition;
		@include media-breakpoint-up(xl){
			display: flex;
		}

		&:hover{
			opacity: 1;
		}
	}

	&__arrow-next{
		right: 5%;
		transform: rotate(180deg);

		@include media-breakpoint-up(xl) {
			right: 7.5%;
		}

		@include media-breakpoint-up(xxl) {
			right: 15%;
		}
	}

	&__arrow-back{
		left: 5%;

		@include media-breakpoint-up(xl) {
			left: 7.5%;
		}

		@include media-breakpoint-up(xxl) {
			left: 15%;
		}
	}

	&__arrow-scroll {
		@include transition;
		cursor: pointer;
		position: absolute;
		color: $white;
		bottom: 0px;
		font-weight: 500;
		font-size: 18px;
		letter-spacing: .01em;
		left: 0;
		right: 0;
		z-index: 110;
		text-align: center;
		padding-bottom: 1.5rem;

		&-text {
			display: block;
			@include transition-slider;
			animation-name: stretch;
			animation-duration: 0.8s;
			animation-timing-function: ease-out;
			animation-direction: alternate;
			animation-iteration-count: infinite;
			animation-play-state: running;

			@keyframes stretch {
				0% {
					transform: scale(.8);
				}

				100% {
					transform: scale(1);
				}
			}
		}

		&-line{
			div:first-child,
			div:nth-child(2){
				margin: 0 auto;
				position: absolute;
				left: 0;
				right: 0;
				width: 1px;
				height: 1.5rem;
			}

			div:first-child{
				height: 17px;
				bottom:0rem;
				background-color: $white;
			}

			div:nth-child(2) {
				background-color: #000;
				height: 2.8rem;
				bottom: -2.8rem;
				@include media-breakpoint-up(lg) {
					height: 4rem;
					bottom: -4rem;
				}
			}
		}

		@media screen and (max-width: 992px) {
			display: none;
		}
	}
}
</style>
