import { Seo } from '~/cms/front/core/seo/Seo'

class FaqSeo extends Seo {
	get schemaJSON () {
		const schema = {
			'@context': 'https://schema.org/',
			'@type': 'FAQPage',
			mainEntity: []
		}
		const questionsSection = this.app.page.value.content.find(item => item.alias === 'questions_accordion_section')
		if (!questionsSection?.value?.questions) return schema

		schema.mainEntity = questionsSection.value.questions.map(item => {
			return {
				'@type': 'Question',
				name: item.item_title,
				acceptedAnswer: {
					'@type': 'Answer',
					text: item.items_answers[0]?.value.item_text || ''
				}
			}
		})

		return schema
	}
}

export { FaqSeo }
