<template>
	<a
		v-if="type === 'phone'"
		:href="`tel:${text?.replaceAll(' ', '')}`"
		:title="`Call us by number: ${text}`"
		class="contact-info-item item-tel"
	>
			<base-svg name="phone_footer" />
			<base-font weight="semibold" class="item-text">{{text}}</base-font>
	</a>
	<div
		v-else-if="type === 'skype'"
		class="contact-info-item"
	>
		<base-svg name="skype" />
		<base-font weight="semibold" class="item-text">{{text}}</base-font>
	</div>
</template>

<script>
export default {
	props: {
		type: String,
		icon: Object,
		text: String
	}
}
</script>

<style lang="scss" scoped>

.contact-info-item{
	width: fit-content;
	display: flex;
	gap: 10px;
	align-items: center;
	@include transition;

	:deep(p) {
		@include transition;
	}

	&.item-tel {

		&:hover {
			transform: translateX(5px);
			color: $primary;
			:deep(p) {
				color: $primary;
			}
		}
	}

	.item-text {
		font-size: 16px;
		@include media-breakpoint-up(lg) {
			font-size: 26px;
		}
	}

}

</style>
