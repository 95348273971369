<template>
	<div
		class="option"
		:class="{active: isActive}"
		@click="runClickHandler"
	>
		<base-font class="option-text">{{option.name}}</base-font>
	</div>
</template>

<script>
import BaseFont from '~/website/front/components/atoms/BaseFont/BaseFont'
export default {
	components: { BaseFont },
	props: {
		option: Object,
		clickHandler: Function,
		activeOption: [Object, Boolean]
	},

	computed: {
		isActive () {
			return this.activeOption.name === this.option.name || this.activeOption.id === this.option.id
		}
	},

	methods: {
		runClickHandler () {
			this.clickHandler(this.option)
		}
	}
}
</script>

<style lang="scss" scoped>
.option {
	@include transition;
	padding: 10px;
	background-color: #fff;

	&:hover,
	&.active{
		background-color: $primary;
		:deep(.option-text) {
			color: #fff;
		}
	}

	&.active {
		:deep(.option-text) {
			font-weight: bold;
		}
	}

	:deep(.option-text) {
		cursor: pointer;
		@include transition;
	}
}
</style>
