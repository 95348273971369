const backgroundSectionMixin = {
	computed: {
		backgroundImage () {
			if (!this.value.background) return null
			const path = this.value?.background?.image?.path || ''
			return {
				src: path,
				breakpoints: {
					base: { width: 360, height: this.backgroundHeight?.base || 640 },
					sm: { width: 576, height: this.backgroundHeight?.sm || 640 },
					md: { width: 768, height: this.backgroundHeight?.md || 640 },
					lg: { width: 992, height: this.backgroundHeight?.lg || 768 },
					xl: { width: 1200, height: this.backgroundHeight?.xl || 720 },
					xxl: { width: 1920, height: this.backgroundHeight?.xxl || 1080 }
				}
			}
		},

		backgroundColor () {
			if (!this.value.background) return null
			return this.value.background.color
		}
	}
}

export {
	backgroundSectionMixin
}
