<template>
	<img
		class="base-image"
		:loading="loading"
		:decoding="decoding"
		:fetchpriority="fetchpriority"
		:src="url"
		:alt="alt"
		:title="title"
		@load="$emit('imageLoaded')"
	/>
</template>

<script>
export default {
	props: {
		loading: {
			type: String,
			default: 'lazy'
		},
		decoding: {
			type: String,
			default: 'async'
		},
		fetchpriority: {
			type: String,
			default: 'auto'
		},
		url: {
			type: String
		},
		alt: {
			type: String,
			default: ''
		},
		title: {
			type: String,
			default: ''
		}
	}
}
</script>
