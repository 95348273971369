<template>
	<div class="compatible-product-list">
		<section class="compatible-list">
			<custom-title
				header_tag="h2"
				type="h1"
				:title="title"
				:use-string="true"
				:margin="false"
			/>
			<base-font variant="paragraph" :html="description"/>
			<product-list
				:products="productsToShow"
				:load-more="loadMore"
				@loadMore="loadAllProducts()"
			/>
		</section>
	</div>
</template>

<script>
import CustomTitle from '~/website/front/components/atoms/CustomTitle/CustomTitle'
import ProductList from '~/catalog/front/components/organisms/ProductList/ProductList'
export default {
	name: 'CompatibleProductList',
	components: { ProductList, CustomTitle },
	data () {
		return {
			loadMore: false,
			productsToShow: []
		}
	},
	props: {
		title: String,
		description: String,
		products: Array,
		loadMoreMode: Boolean
	},
	created () {
		this.init()
	},
	methods: {
		init () {
			if (this.loadMoreMode && this.products.length > 4) {
				this.productsToShow = this.products.slice(0, 3)
				this.loadMore = true
			} else this.loadAllProducts()
		},
		loadAllProducts () {
			this.productsToShow = this.products
			this.loadMore = false
		}
	},
	watch: {
		products: 'init',
		loadMoreMode: 'init'
	}
}
</script>

<style lang="scss" scoped>
:deep(.section-title-wr) {
	margin-bottom: 20px;
}
</style>
