<template>
	<section class="antenna-photo-section">
		<base-container>
			<div class="antenna-photo-inner">
				<base-animation animation="slideRight">
					<custom-title
						v-if="value.section_title"
						:title="`${prefix}.section_title.title`"
						:subtitle="`${prefix}.section_title.subtitle`"
						:header_tag="value.section_title?.title_tag"
						:color="value.section_title.title_color"
						:transform="value.section_title.title_transform"
						:type="value.section_title_type"
					/>
				</base-animation>

				<base-animation animation="slideRight">
					<cms-text
						v-if="value.content"
						:value="`${prefix}.content`"
						:props="{tag: 'div'}"
					/>
				</base-animation>

				<base-animation animation="slideTop">
					<cards-wrapper
						class="cards-wrapper"
						:cards="value.cards"
						:prefix="prefix"
					/>
				</base-animation>

			</div>

		</base-container>
	</section>
</template>

<script>
import BaseContainer from '~/website/front/components/atoms/BaseContainer/BaseContainer'
import CardsWrapper from '~/website/front/sections/AntennaCardsSection/components/CardsWrapper'
import CustomTitle from '~/website/front/components/atoms/CustomTitle/CustomTitle'
import BaseAnimation from '@f/components/BaseAnimation/BaseAnimation'
export default {
	components: { CustomTitle, CardsWrapper, BaseContainer, BaseAnimation },
	props: {
		prefix: String,
		value: Object
	},
	computed: {
	}
}
</script>

<style lang="scss" scoped>
.antenna-photo-inner {
	:deep(.ql-editor) {
		padding:0;
	}
}

.cards-wrapper {
	display: grid;
	gap:30px;
	grid-template-columns: 1fr;
	@include media-breakpoint-up(sm) {
		grid-template-columns: 1fr 1fr;
	}
	@include media-breakpoint-up(xl) {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
}

</style>
