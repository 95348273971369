<template>
	<div class="where-to-buy">
		<base-container>
			<custom-title header_tag="h1" title="Where to buy?" subtitle="Check our distributors" :use-string="true" type="h1" />
		</base-container>
		<base-skeleton :loading="isLoading">
			<l-map
				id="map"
				:zoom="leafletConfig.zoom"
				:minZoom="leafletConfig.minZoom"
				:maxZoom="leafletConfig.maxZoom"
				:center="leafletConfig.center"
				:options="leafletConfig.options"
				ref="where-to-by-map"
				@ready="handleMapLoaded"
			>
				<l-tile-layer
					:url="leafletConfig.url"
					:attribution="leafletConfig.attribution"
				/>
				<l-marker v-for="marker in markers" :lat-lng="marker.coordinates" :icon="icon" :key="marker.distributor.lat_lng">
					<l-popup>
						<base-font
							variant="paragraph"
							weight="bold"
						>
							{{ marker.distributor.title }}
						</base-font>
						<base-font :html="marker.distributor.text" />
						<a
							class="where-to-buy-marker-link"
							:href="getHrefByInfo(info)"
							v-for="info in marker.distributor.info"
							:key="info.value"
							target="_blank"
						>
							<base-font color="primary">{{info.value}}</base-font>
						</a>
					</l-popup>
				</l-marker>
			</l-map>
		</base-skeleton>

		<cms-multi-section value="description" />

		<div class="distributors">
			<cms-multi-section value="content" />
		</div>

	</div>
</template>

<script>
import 'leaflet/dist/leaflet.css'
import { pageMixin } from '~/website/front/core/pages/page.mixin.js'
import BaseContainer from '~/website/front/components/atoms/BaseContainer/BaseContainer'
import CustomTitle from '~/website/front/components/atoms/CustomTitle/CustomTitle'
import CmsMultiSection from '~/cms/front/components/CmsMultiSection/CmsMultiSection'
import BaseSkeleton from '~/website/front/components/atoms/BaseSkeleton/BaseSkeleton.vue'

export default {
	mixins: [pageMixin],
	components: {
		BaseSkeleton,
		CmsMultiSection,
		CustomTitle,
		BaseContainer,
		[process.client && 'LMarker']: () => import('vue2-leaflet/dist/components/LMarker'),
		[process.client && 'LMap']: () => import('vue2-leaflet/dist/components/LMap'),
		[process.client && 'LTileLayer']: () => import('vue2-leaflet/dist/components/LTileLayer'),
		[process.client && 'LPopup']: () => import('vue2-leaflet/dist/components/LPopup')
	},
	data () {
		return {
			icon: null,
			isLoading: true,
			leafletConfig: {
				url: `https://{s}.tile.thunderforest.com/landscape/{z}/{x}/{y}.png?apikey=${this.$app.page?.config?.leafletApiKey}`,
				zoom: 3,
				minZoom: 3,
				center: [51.505, -0.159],
				apikey: this.$app.page?.config?.leafletApiKey,
				maxZoom: 22,
				attributionstonia: '&copy; <a href="http://www.thunderforest.com/">Thunderforest</a>, &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
				options: {
					scrollWheelZoom: false
				}
			}
		}
	},
	computed: {
		markers () {
			const coordinatesArray = []
			if (this.page.value.content?.length) {
				this.page.value.content.forEach((section) => {
					section.value.cards.forEach((card) => coordinatesArray.push(card))
				})
			}
			return coordinatesArray.map(item => {
				return {
					coordinates: item.lat_lng.split(',').map(stringValue => parseFloat(stringValue)),
					distributor: item
				}
			})
		}
	},
	methods: {
		handleMapLoaded () {
			this.isLoading = false
		},

		getHrefByInfo (info) {
			return {
				phone: `tel:${info.value.replaceAll(' ', '')}`,
				email: `mailto:${info.value}`,
				site: info.value
			}[info.type]
		}
	},
	async mounted () {
		this.$nextTick(async () => {
			if (process.client) {
				const L = await import('leaflet')
				this.icon = L.icon({
					iconUrl: location.origin + '/api/filemanager/files/buy/maps-and-flags.png',
					iconSize: [32, 32],
					iconAnchor: [16, 32]
				})
			}
		})
	}
}
</script>

<style lang="scss" scoped>
#map,
:deep(.base-skeleton){
	height: 400px;
	overflow: hidden;

	@media screen and (min-height: 800px) {
		height: 600px;
	}

}

.photo_section {
	:deep(.ql-editor){
		p {
			margin: 0;
		}
	}
}

:deep(.leaflet-popup-content p) {
	margin: 0;
}

.distributors {
	@include spacing-section();
}

:deep(.where-to-buy-marker-link) {
	@include transition;

	&:hover {
		opacity: .8;
	}
}
</style>
