class Opinion {
	static async fetch ({ app, take, skip = 0, where, relations }) {
		const service = app.getService('rext')

		const data = await service.fetchOpinions({ take, skip, where, relations })

		if (data.error) return { error: true }

		return data
	}
}

export { Opinion }
