import { isPageFromNull } from 'utils/ssrCheck'

const getAnimation = (name, duration, delay) => {
	return [
		`
		animation-name: ${name};
		animation-duration: ${duration ? duration + 's' : '.8s'};
		animation-delay: ${delay ? delay + 's' : '0s'};
		animation-iteration-count: 1
		`
	]
}

function isInViewport (element) {
	const rect = element.getBoundingClientRect()
	return (
		rect.top >= 0 &&
		rect.left >= 0 &&
		rect.bottom <= (window.innerHeight + window.scrollY || document.documentElement.clientHeight) &&
		rect.right <= (window.innerWidth + window.scrollX || document.documentElement.clientWidth)
	)
}

export const animatedScrollObserver = () => (el, valueObj) => {
	const { name, duration, delay, threshold = 0, shouldAnimate } = valueObj.value

	if (!shouldAnimate) return false

	if (isPageFromNull(window.app) && isInViewport(el)) {
		return false
	}

	if (window.innerWidth >= 1200) {
		const options = {
			rootMargin: '0px',
			threshold: threshold
		}

		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						entry.target.style = getAnimation(name, duration, delay)
						observer.unobserve(entry.target)
					}
				})
			},
			options
		)
		observer.observe(el)
	} else {
		return false
	}
}
