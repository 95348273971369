<template>
	<div class="subheading-wrapper">
		<div class="subheading">
			<cms-text :value="`${prefix}.grid_heading`"/>
		</div>
		<div class="subheading">
			<cms-text :value="`${prefix}.grid_subheading`"/>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		prefix: String
	}
}
</script>

<style lang="scss" scoped>
.subheading-wrapper {
	display: grid;
	grid-template-columns: 3fr 9fr;
	margin-bottom: 40px;
	border-radius: 5px;

	@include media-breakpoint-up(xl) {
		gap: 30px;
	}

	.subheading {

		p {
			display: flex;
			align-items: center;
			text-transform: uppercase;
			text-align: center;
			height: 70px;
			color: $white;
			justify-content: center;
			padding: 10px;
			font-size: 18px;
			line-height: 18px;
			word-break: break-word;

			@include media-breakpoint-up(lg) {
				border-radius: 5px;
				font-size: 24px;
				padding: 15px 0;
				line-height: 28px;
			}
		}

		&:first-child {
			p {
				background-color: $secondary;
				font-weight: 700;
				border-radius: 5px 0 0 5px;

				@include media-breakpoint-up(xl) {
					font-size: 44px;
					line-height: 56px;
					border-radius: 5px;
				}
			}
		}

		&:nth-child(2) {
			p {
				background-color: #2092DD ;
				font-weight: 600;
				border-radius: 0 5px 5px 0;

				@include media-breakpoint-up(xl) {
					font-size: 30px;
					line-height: 38px;
					border-radius: 5px;
				}
			}
		}
	}

}
</style>
