<template>
	<div class="navbar-wrapper-mobile" :class="{'blue-bg': shouldBgBeBlue}">
		<mobile-navbar
			:shouldBgBeBlue="shouldBgBeBlue"
			:isMobileMenuActive="isMobileMenuActive"
			:title="title"
			@handleMenuToggle="toggleMobileMenu"
		/>
		<Transition>
			<nav-mobile-screen
				:isMobileMenuActive="isMobileMenuActive"
				:items="items"
				@toggleMobileMenu="toggleMobileMenu"
			/>
		</Transition>
	</div>
</template>

<script>
import MobileNavbar from '~/website/front/components/organisms/BaseMenu/components/Mobile/molecules/MobileNavbar'
import NavMobileScreen from '~/website/front/components/organisms/BaseMenu/components/Mobile/molecules/NavMobileScreen'
export default {
	components: { NavMobileScreen, MobileNavbar },
	data () {
		return {
			activeSubMenu: null,
			isMobileMenuActive: false
		}
	},
	props: {
		items: Array,
		shouldBgBeBlue: Boolean,
		title: String
	},
	methods: {
		toggleMobileMenu () {
			this.isMobileMenuActive = !this.isMobileMenuActive
		}
	}
}
</script>

<style lang="scss" scoped>
.navbar-wrapper-mobile {
	background-color: $white;
	padding: 22px 30px;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 1001;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	@include transition();

	&.blue-bg {
		background-color: #2092DD;

		.navbar {
			:deep(.hamburger-lines) {
				background-color: $white;

				&:after,
				&:before {
					background-color: $white;
				}
			}
		}

		:deep(.translate-choosen) {
			p {
				color: $white;
			}

			path {
				stroke: $white;
			}
		}

		:deep(#Group_1) {
			fill: $white;
		}

		:deep(#Group_1586 path) {
			fill: $white;
		}

		:deep(#path_7108) {
			fill: $white;
		}

		:deep(.hamburger) {
			.hamburger-inner {
				.hamburger-line {

					background-color: $white;
					&:after,
					&.before{
						background-color: $white;
					}
				}
			}
		}
	}

	.mobile-menu-screen {
		display: grid;
		align-items: flex-end;
		grid-template-columns: repeat(3, 1fr);
		gap: 23px;

		.navbar-button{
			background-color: $white;
			color: $secondary;

			&:hover{
				color: $white;
			}
		}

		.translate{
			margin-bottom: 12px;
			position: relative;
			:deep(.translate-list) {
				&:after{
					position: absolute;
					display: block;
					content: '';
					left: 0;
					right: 0;
					top: 0;
					margin: auto;
					bottom: 0;
					height: 100%;
					width: 2px;
					border-radius: 5px;
					background-color: $white;
				}
			}

			:deep(.translate-lang) {
				color: $white;
				opacity: 0.5;
				font-size: 14px;

				&.active {
					opacity: 1;
					color: $white;
				}
			}

			:deep(.translate-collapse) {
				display: flex;
				justify-content: space-around;
			}
		}
	}

	.nav-list {
		list-style-type: none;
	}

	:deep(.hamburger) {
		&.active {

			span {
				background-color: transparent;

				&:after {
					transform: rotate(45deg) translate(0, 0);
				}

				&:before {
					transform: rotate(-45deg) translate(0, 0);
				}
			}
		}
	}

	.nav-items-wrapper {

		&.v-enter-from,
		&.v-leave-to {
			opacity: 0;
		}
	}
}
</style>
