var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{directives:[{name:"custom-background",rawName:"v-custom-background",value:(_vm.getBackgroundImage),expression:"getBackgroundImage"}],staticClass:"why-our-enclosures"},[_c('base-container',[_c('custom-title',{attrs:{"title":_vm.title,"use-string":true,"subtitle":_vm.subtitle,"header_tag":_vm.value.section_title.title_tag,"color":_vm.value.section_title.title_color,"transform":_vm.value.section_title.title_transform,"type":_vm.value.section_title_type}}),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"enclosure-wrapper"},_vm._l((_vm.value.list),function(item,i){return _c('enclosure',{key:item.item,attrs:{"item":item,"prefix":_vm.prefix,"index":i}})}),1),_c('cms-image',{staticClass:"image",attrs:{"src":{
					alt: _vm.pageProduct?.name,
					path: _vm.value.section_image.path,
					title: `Why ${_vm.pageProduct?.name}`
				},"base":{
					width: 323,
					height: 351,
					fit: 'contain',
					background: 'transparent'
				},"xl":{
					width: 646,
					height: 702,
					fit: 'contain',
					background: 'transparent'
				},"lazy":""}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }