import { render, staticRenderFns } from "./BaseSlider.vue?vue&type=template&id=58260cdc&scoped=true&v-once=true"
import script from "./BaseSlider.vue?vue&type=script&lang=js"
export * from "./BaseSlider.vue?vue&type=script&lang=js"
import style0 from "./BaseSlider.vue?vue&type=style&index=0&id=58260cdc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58260cdc",
  null
  
)

export default component.exports