import { config } from '@f/config/config.js'
import { Seo } from '~/cms/front/core/seo/Seo'

class ArticleSeo extends Seo {
	get author () {
		const { name, surname } = this.app.page.value.author
		return `${name} ${surname}`
	}

	get schemaJSON () {
		return {
			'@context': 'https://schema.org/',
			'@type': 'Article',
			headline: this.app.page.value.title,
			datePublished: this.app.page.value.datePublished,
			image: `${this.app.settings.env.origin}/api/images/${this.app.page.value.thumbnail.path}`,
			author: {
				'@type': 'Person',
				name: this.author,
				url: `${this.app.settings.env.origin}/api/images/${this.app.page.value.author.avatar}`
			},
			publisher: {
				'@type': 'Organization',
				name: this.app.settings.general.contact.pageName,
				logo: {
					'@type': 'ImageObject',
					url: `${this.app.settings.env.origin}/api/images/svg/logo.svg`,
					width: 210,
					height: 60
				}
			}
		}
	}

	get openGraph () {
		return [
			{
				property: 'og:title',
				content: this.page.meta.title
			},
			{
				property: 'og:description',
				content: this.page.meta.description
			},
			{
				property: 'og:type',
				content: 'website'
			},
			{
				property: 'og:image',
				content: (() => {
					const articleImageUrl = this.page.value?.thumbnail.path
					const layoutImageUrl = this.layout.value?.open_graph.main_picture?.path
					return `${this.app.settings.env.origin}${config.services.rext.imagesUrl}${articleImageUrl || layoutImageUrl}?width=1200&height=630`
				})()
			},
			{
				property: 'og:site_name',
				content: this.app.settings.env.origin
			},
			{
				property: 'og:url',
				content: this.app.settings.env.origin + this.page.route.path
			}
		]
	}
}

export { ArticleSeo }
