<template>
	<section class="section">
		<base-container>
			<div class="section-inner">
				<custom-title
					:title="`${prefix}.section_title.title`"
					:subtitle="`${prefix}.section_title.subtitle`"
					:header_tag="value.section_title?.title_tag"
					:color="value.section_title.title_color"
					:transform="value.section_title.title_transform"
					:type="value.section_title_type"
				/>
				<div class="main-content">
					<cms-image
						class="main-content-image"
						:value="`${prefix}.main_image`"
						:src="{
							alt: `WiMount system - ${pageProduct?.name}`,
							title: `Much stronger antenna stability during strong winds - WiMount ${pageProduct?.name}`,
							path: value.main_image.path
						}"
						v-bind="defaultCmsImageBreakpoints"
					/>
					<mounting-presentation
						:gifs="value.gifs"
						:productName="pageProduct?.name"
					/>
				</div>
			</div>

		</base-container>
	</section>
</template>

<script>
import BaseContainer from '~/website/front/components/atoms/BaseContainer/BaseContainer.vue'
import CustomTitle from '~/website/front/components/atoms/CustomTitle/CustomTitle.vue'
import MountingPresentation from '~/catalog/front/sections/MountingBracketSection/components/MountingPresentation.vue'
import { productSectionMixin } from '~/catalog/front/sections/product-section.mixin'
import { defaultCmsImageBreakpoints } from '~/cms/front/mixins/defaultCmsImageBreakpoints.js'

export default {
	components: {
		MountingPresentation,
		CustomTitle,
		BaseContainer
	},
	mixins: [productSectionMixin, defaultCmsImageBreakpoints],
	props: {
		prefix: String,
		value: Object
	}
}
</script>

<style lang="scss" scoped>
.section {
	background-color: #fff;
	@include spacing-section();
}

.main-content {
	display: flex;
	align-items: center;
	gap: 30px;
	flex-direction: column;

	@include media-breakpoint-up(lg) {
		display: grid;
		grid-template-columns: minmax(0, 5fr) minmax(0, 3fr)
	}

	@include media-breakpoint-up(xl) {
		grid-template-columns: minmax(0, 7fr) minmax(0, 3fr)
	}

	@include media-breakpoint-up(xxl) {
		grid-template-columns: minmax(0, 9fr) minmax(0, 3fr)
	}
	&-image{
		max-width: 100%;
		:deep(img) {
			width: 100%;
		}
	}
}
</style>
