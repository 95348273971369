<template v-once>

	<section class="social" v-background="background">
		<base-container>
			<base-animation
				animation="slideBottom"
			>
				<div class="title-wrapper">
					<base-svg class="social-icon" name="social_title_icon_left" />
					<base-font class="title" :tag="'h2'" :uppercase="true" :weight="'bold'" :color="'white'">{{getSocialTitle}}</base-font>
					<base-svg class="icon" name="social_title_icon_right" />
				</div>
				<div class="subtitle-wrapper">
					<base-svg class="icon" name="social_subtitle_arrow_left" />
					<base-font class="subtitle" :tag="'h3'" :uppercase="true" :color="'white'" :weight="'regular'">{{getSocialSubtitle}}</base-font>
					<base-svg class="icon" name="social_subtitle_arrow_right" />
				</div>
			</base-animation>

			<div class="social-items">
				<base-animation
					v-for="card in getSocialData"
					animation="slideTop"
					:key="card.link"
				>
					<social-card
						:card="card"
					/>
				</base-animation>

			</div>
		</base-container>
	</section>
</template>

<script>
import SocialCard from './components/SocialCard'
import BaseAnimation from '@f/components/BaseAnimation/BaseAnimation'
export default {

	components: {
		SocialCard,
		BaseAnimation
	},
	props: {
		prefix: String,
		value: Object
	},
	computed: {

		getSocialData () {
			const socialRepeater = this.$app.layout.value
			return socialRepeater.social_repeater || []
		},

		background () {
			let path = ''
			if (!this.value) path = this.$app.layout.value.social_image_background.path
			else path = this.value.social_background.path
			return {
				src: path,
				breakpoints: {
					base: { width: 1920, height: 733 }
				}
			}
		},
		getSocialTitle () {
			const data = this.$app.layout.value
			return data.social_section_title
		},
		getSocialSubtitle () {
			const data = this.$app.layout.value
			return data.social_section_subtitle
		}
	}
}

</script>
<style lang="scss" scoped>
.social {
	content-visibility: auto;
	background-color: #0B499D;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	padding: 6rem 0;

	@include media-breakpoint-up(lg) {
		padding: 10rem 0;
	}
}

.title-wrapper {
	display: flex;
	align-content: center;
	justify-content: center;
	.svg-icon {
		display: flex;
		align-items: center;
	}
}

.subtitle-wrapper {
	display: flex;
	align-content: center;
	justify-content: center;
	padding-bottom: 4rem;
	@include media-breakpoint-up(lg) {
		padding-bottom: 6rem;
	}
	::v-deep(.svg-icon) {
		display: none;
		@include media-breakpoint-up(lg) {
			display: flex;
			align-items: center;
		}
	}
}

::v-deep(.title) {
	font-size: 3rem;
	line-height: 3rem;
	@include media-breakpoint-up(lg){
		font-size:5.4rem;
		line-height: 6.9rem;
	}
	margin-left:1.5rem;
	margin-right: 1.5rem;
}

::v-deep(.subtitle) {
	font-size:1.5rem;
	line-height:1.9rem;
	margin-right: 2rem;
	margin-left: 2rem;
	@include media-breakpoint-up(lg){
		font-size:2.6rem;
		line-height: 2.6rem;
	}
}

.social-items {
	display:grid;
	gap: 30px;
	grid-template-columns: repeat(1, 1fr);
	@include media-breakpoint-up(sm) {
		grid-template-columns: repeat(2, 1fr);
	}
	@include media-breakpoint-up(lg) {
		grid-template-columns: repeat(3, 1fr);
	}
}

</style>
