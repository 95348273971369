export function rextServiceExtend (Service) {
	return class RextService extends Service {
		async addSubscriber ({ name, email }) {
			const http = await this.getHttpWithRecaptcha()
			const { status } = await http.post('/newsletter/subscriber', {
				name,
				email
			})
			if (status === 400) return { error: true }
			return status
		}
	}
}
