<template>
	<div class="opinion-list-page">
		<cms-multi-section value="content" />
	</div>
</template>

<script>
import { pageMixin } from '~/website/front/core/pages/page.mixin'
import CmsMultiSection from '~/cms/front/components/CmsMultiSection/CmsMultiSection'

export default {
	components: { CmsMultiSection },
	mixins: [pageMixin]
}
</script>

<style lang="scss" scoped>
.opinion-list-page{

}
</style>
