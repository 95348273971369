import { Emitter } from '@f/core/Emitter'
import { getPath } from 'utils/getPath'

class Brand {
	static from (data) {
		const brand = new Brand(data)

		brand.models = data.models.map(data => Model.from(brand, data))

		return brand
	}

	models = []

	constructor ({ id, name, alias, pageReference }) {
		this.id = id
		this.name = name
		this.alias = alias
		this.pageReference = pageReference
	}

	toOptionObject () {
		return {
			name: this.name,
			value: this.alias
		}
	}

	compareAlias (alias) {
		return this.alias === alias
	}

	getModelsAsOptionObjects () {
		return this.models.map(model => model.toOptionObject())
	}

	getValue () {
		return this.alias
	}

	findModelByName (name) {
		return this.models.find(model => model.compareName(name))
	}

	getPathObject (app) {
		const path = {
			app,
			to: 'product-list',
			params: {
				param: this.pageReference || 'more-manufacturers'
			},
			query: {}
		}

		if (!this.pageReference) {
			path.query.brand = this.alias
		}

		return path
	}

	goTo (app) {
		const path = getPath(this.getPathObject(app))
		app.router.push(path)
	}
}

class Model {
	static from (brand, data) {
		return new Model(brand, data)
	}

	constructor (brand, { id, name }) {
		this.brand = brand
		this.id = id
		this.name = name
	}

	toOptionObject () {
		return {
			name: this.name,
			value: this.name
		}
	}

	getValue () {
		return this.name
	}

	compareName (name) {
		return this.name === name
	}

	getPathObject (app) {
		const pathObject = this.brand.getPathObject(app)

		pathObject.query.model = this.name

		return pathObject
	}

	goTo (app) {
		const path = this.getPath(app)
		app.router.push(path)
	}

	getPath (app) {
		return getPath(this.getPathObject(app))
	}
}

class BrandModelForm extends Emitter {
	activeBrand = null
	activeModel = null

	constructor (brands, brandAlias, modelName) {
		super()
		this.brands = brands
		if (brandAlias) this.setActiveBrandByAlias(brandAlias, false)
		if (modelName) this.setActiveModelByName(modelName, false)
	}

	get options () {
		return {
			brands: this.brands.map(brand => brand.toOptionObject()),
			models: this.activeBrand ? this.activeBrand.getModelsAsOptionObjects() : []
		}
	}

	get brandValue () {
		return this.activeBrand ? this.activeBrand.getValue() : null
	}

	get modelValue () {
		return this.activeModel ? this.activeModel.getValue() : null
	}

	get modelId () {
		return this.activeModel ? this.activeModel.id : null
	}

	get brandId () {
		return this.activeBrand ? this.activeBrand.id : null
	}

	brandChangedHandler (value) {
		this.setActiveBrandByAlias(value)
	}

	modelChangedHandler (value) {
		this.setActiveModelByName(value)
	}

	setActiveBrandByAlias (alias, emitEvent = true) {
		if (this.activeBrand && this.activeBrand.compareAlias(alias)) return

		this.activeBrand = this.brands.find(brand => brand.compareAlias(alias))
		this.activeModel = null

		if (emitEvent) this._emit('brand:changed')
	}

	setActiveModelByName (name, emitEvent = true) {
		if (this.activeModel && this.activeModel.compareName(name)) return

		this.activeModel = this.activeBrand.findModelByName(name)

		if (emitEvent) this._emit('model:changed')
	}

	getQuery () {
		const query = {}

		if (this.activeBrand) query.brand = this.activeBrand.getValue()
		if (this.activeModel) query.model = this.activeModel.getValue()

		return query
	}

	setActiveFromQuery (query) {
		this.activeBrand = this.brands.find(brand => brand.compareAlias(query.brand))
		if (this.activeBrand) this.activeModel = this.activeBrand.findModelByName(query.model)
		else this.activeModel = null
	}
}

export class BrandService {
	static alias = 'brand'
	static Brand = Brand

	brands = []

	constructor (app) {
		this.app = app
	}

	async getForm (brand = '', model) {
		if (process.server) return new BrandModelForm([])
		await this.fetchBrands()
		return new BrandModelForm(this.brands, brand.toLowerCase(), model)
	}

	async getFormByRoute (route) {
		const brandAlias = route.params.param === 'more-manufacturers' ? route.query.brand : route.params.param
		const modelName = route.query.model
		return this.getForm(brandAlias, modelName)
	}

	async fetchBrands () {
		const brandsData = await this.app.getService('rext').getBrands()
		this.brands = brandsData.map(Brand.from)
	}

	// Sad SearchByBrand component support :(
	async getBrands () {
		await this.fetchBrands()
		return this.brands
	}

	async getModels (alias) {
		return this.brands.find(brand => brand.compareAlias(alias)).models
	}
}
