var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"expression"},[_c('div',{staticClass:"expression-inner"},[_vm._l((_vm.row.images),function(image,i){return _c('div',{key:i,staticClass:"expression-image"},[_c('cms-image',{staticClass:"expression-item-image",attrs:{"value":`${_vm.prefix}.images.${i}.img`,"base":{
					width: 212,
					height: 284,
					fit: 'contain',
					background: 'transparent'
				},"lg":{
					width: 144,
					height: 193,
					fit: 'contain',
					background: 'transparent'
				},"xl":{
					width: 180,
					height: 241,
					fit: 'contain',
					background: 'transparent'
				},"xxl":{
					width: 212,
					height: 284,
					fit: 'contain',
					background: 'transparent'
				}}}),(i === _vm.row.images.length - 1)?_c('base-svg',{attrs:{"name":"router_expression_equals"}}):_c('base-svg',{attrs:{"name":"router_expression_plus"}})],1)}),_c('div',{staticClass:"expression-row-title-block"},[_c('cms-text',{staticClass:"title-block",attrs:{"value":`${_vm.prefix}.title`,"props":{
					color: 'white',
					weight: 'bold',
					size: 'h4',
					tag:'span'}}})],1)],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }