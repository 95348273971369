<template>
	<div class="search-params">
		<div class="search-params-inner">
			<cms-text class="params-title" :value="`${prefix}.params_title`" :props="{tag: 'h4'}" />
			<filters-panel
				class="search-params-filters-panel"
				v-if="filtersManager"
				:filtersManager="filtersManager"
			/>

			<div class="buttons-wrapper">
				<base-button class="search-button" @click="search" v-bind="primaryLink">
					<base-svg name="search" />
					<base-font variant="button-text">{{ primaryLink.content }}</base-font>
				</base-button>
			</div>
		</div>
	</div>
</template>

<script>
import { getPath } from 'utils/getPath'
import FiltersPanel from '~/catalog/front/core/pages/ProductListPage/components/FiltersPanel.vue'

export default {
	components: { FiltersPanel },
	props: {
		prefix: String
	},
	data () {
		return {
			primaryLink: {
				title: 'Search antennas and accessories by parameters!',
				type: 'internal',
				content: 'search',
				button_type: 'primary'
			},
			filtersManager: null,
			query: {}
		}
	},
	methods: {
		search () {
			this.$router.push({
				path: getPath({ app: this.$app, to: 'general-product-list' }),
				query: this.filtersManager.getQuery()
			})
		}
	},
	async mounted () {
		const service = this.$app.getService('filters')
		this.filtersManager = await service.getFilterManagerWithSelectedFilters(['technology', 'mimo', 'directionality'])
		this.filtersManager.on('state:changed', () => {
			this.query = this.filtersManager.getQuery()
		})
	}
}
</script>

<style lang="scss" scoped>
.search-params {
	padding: 20px 10px;
	background-color: #F5F5F5;
	border-radius: 5px;

	@include media-breakpoint-up(sm) {
		padding: 20px;
	}

	@include media-breakpoint-up(xl) {
		padding: 40px 20px;
		height: 100%;
	}

	&-filters-panel {
		background-color: #F5F5F5!important;
	}

	.search-params-inner{
		display: flex;
		flex-direction: column;
		align-items: center;

		.params-title{
			margin-bottom: 25px;
			font-size: 19px;
			line-height: 25px;
			font-weight: 600;
			color: $secondary;

			@include media-breakpoint-up(lg) {
				font-size: 38px;
				line-height: 48px;
			}

		}

		.search-button {
			margin-top: 2rem;
			font-weight: 700;
			font-size: 16px;
			line-height: 21px;
			letter-spacing: 0.32px;
		}

		.rows-wrapper{
			margin-top: 10px;
			display: flex;
			gap: 20px;
			flex-direction: column;

			@include media-breakpoint-up(lg) {
				flex-direction: row;
				flex-wrap: wrap;
				margin-top: 0;
			}
		}

		.buttons-wrapper{
			:deep(.btn.primary) {
				padding: 13px 40px 13px 30px;
				@include media-breakpoint-up(lg) {
					padding: 15px 40px 15px 30px;
				}
			}
		}

		::v-deep(svg) {
			height: auto!important;

			path {
				fill: $white;
			}
		}
	}
}
</style>
